import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  implementationCostCall,
  sectionFetch,
  deleteItemCall,
  addSingleAttachmentCall,
  itemAttachmentsFetch,
  singleItemFetch,
} from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration, editSuccessMessageDuration } from '../../utils'

export function* fetchImplementationCosts(action: actionTypes.fetchImplementationCostsAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'implementationCosts')

    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COSTS_SUCCEEDED,
      implementationCosts: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COSTS_ERROR,
      error: error?.response,
    })
  }
}

export function* createImplementationCost(action: actionTypes.createImplementationCostAction) {
  try {
    const response = yield call(implementationCostCall, action.payload)

    yield put({
      type: actionTypes.CREATE_IMPLEMENTATION_COST_SUCCEEDED,
      implementationCost: { ...action.payload.implementationCost, id: response.data.id },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_IMPLEMENTATION_COST_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_IMPLEMENTATION_COST_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK,
    })
  }
}

export function* editImplementationCost(action: actionTypes.editImplementationCostAction) {
  try {
    yield call(implementationCostCall, action.payload)
    yield put({
      type: actionTypes.EDIT_IMPLEMENTATION_COST_SUCCEEDED,
      implementationCost: action.payload.implementationCost,
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_IMPLEMENTATION_COST_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK,
    })
  }
}

export function* deleteImplementationCost(action: actionTypes.deleteImplementationCostAction) {
  try {
    yield call(deleteItemCall, action.payload, 'implementationCosts')
    yield put({
      type: actionTypes.DELETE_IMPLEMENTATION_COST_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_IMPLEMENTATION_COST_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_IMPLEMENTATION_COST_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK,
    })
  }
}

export function* fetchImplementationCostBoms(
  action: actionTypes.fetchImplementationCostBomsAction
) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `implementationCosts/getImplementationCostBoms/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_BOMS_SUCCEEDED,
      implementationCostBoms: response?.data?.boms || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_BOMS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchImplementationCostOffers(
  action: actionTypes.fetchImplementationCostOffersAction
) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `implementationCosts/getToolingOffers/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_SUCCEEDED,
      implementationCostOffers: response?.data?.offers || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchImplementationCostOrders(
  action: actionTypes.fetchImplementationCostOrdersAction
) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `implementationCosts/getImplementationCostOrders/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_SUCCEEDED,
      implementationCostOrders: response?.data?.orders || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchImplementationCostAttachments(
  action: actionTypes.fetchImplementationCostAttachmentsAction
) {
  try {
    const response = yield call(itemAttachmentsFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_ATTACHMENTS_SUCCEEDED,
      implementationCostAttachments: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_IMPLEMENTATION_COST_ATTACHMENTS_ERROR,
      error: error?.response,
    })
  }
}

export function* uploadImplementationCostAttachment(
  action: actionTypes.uploadImplementationCostAttachmentAction
) {
  try {
    const response = yield call(addSingleAttachmentCall, action.payload)

    yield put({
      type: actionTypes.UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED,
      attachment: {
        id: response.data.id,
        name: action.payload.uploadAttachment.name,
      },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* implementationCostsSaga() {
  yield takeLatest(actionTypes.FETCH_IMPLEMENTATION_COSTS_REQUESTED, fetchImplementationCosts)
  yield takeLatest(
    actionTypes.FETCH_IMPLEMENTATION_COST_BOMS_REQUESTED,
    fetchImplementationCostBoms
  )
  yield takeLatest(
    actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_REQUESTED,
    fetchImplementationCostOffers
  )
  yield takeLatest(
    actionTypes.FETCH_IMPLEMENTATION_COST_ORDERS_REQUESTED,
    fetchImplementationCostOrders
  )
  yield takeLatest(
    actionTypes.FETCH_IMPLEMENTATION_COST_ATTACHMENTS_REQUESTED,
    fetchImplementationCostAttachments
  )
  yield takeLatest(
    actionTypes.UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_REQUESTED,
    uploadImplementationCostAttachment
  )
  yield takeLatest(actionTypes.CREATE_IMPLEMENTATION_COST_REQUESTED, createImplementationCost)
  yield takeLatest(actionTypes.DELETE_IMPLEMENTATION_COST_REQUESTED, deleteImplementationCost)
  yield takeLatest(actionTypes.EDIT_IMPLEMENTATION_COST_REQUESTED, editImplementationCost)
}
