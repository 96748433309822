import { call, put, takeLatest } from 'redux-saga/effects'

import {
  currencyExchangeCallA,
  currencyExchangeCallB,
  deleteSingleAttachmentCall,
  getSingleAttachmentCall,
} from './api'
import * as actionTypes from '../action-types'

export function* fetchTodayCurrencyExchange() {
  try {
    const responseA = yield call(currencyExchangeCallA)
    const responseB = yield call(currencyExchangeCallB)

    // Creating same output as paid Fixer.io + Bigger amount of currencies than Currency API in EUR
    let todayCurrencyExchange = {
      base: 'PLN',
      date: responseA.data[0].effectiveDate || '',
      rates: { PLN: 1 },
    }

    responseA.data[0].rates.forEach((rate: actionTypes.TNBPExchangeRate) => {
      todayCurrencyExchange.rates[rate.code] = rate.mid
    })

    responseB.data[0].rates.forEach((rate: actionTypes.TNBPExchangeRate) => {
      todayCurrencyExchange.rates[rate.code] = rate.mid
    })

    yield put({ type: actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_SUCCEEDED, todayCurrencyExchange })
    yield localStorage.setItem('todayCurrencyExchange', JSON.stringify(todayCurrencyExchange))
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchSingleAttachment(action: actionTypes.fetchSingleAttachmentAction) {
  try {
    const response = yield call(getSingleAttachmentCall, action.payload, action.section)

    const file = response.data

    // IE doesn't allow using a blob object directly as a href
    const ieWindowNavigator: any = window.navigator

    if (ieWindowNavigator && ieWindowNavigator.msSaveOrOpenBlob) {
      ieWindowNavigator.msSaveOrOpenBlob(file)
    }

    // Other browsers:
    const fileURL = URL.createObjectURL(file)

    if (action.payload.download) {
      const fileLink = document.createElement('a')
      fileLink.href = fileURL

      fileLink.download = action?.payload?.fileName || 'plik'

      fileLink.click()
      fileLink.remove()
    } else {
      window.open(fileURL)
    }

    // For Firefox it is necessary to delay revoking the ObjectURL
    // setTimeout(function () {
    //   URL.revokeObjectURL(data)
    // }, 100)

    // const attachmentDownloadLink = document.createElement('a')
    // attachmentDownloadLink.href = data
    // attachmentDownloadLink.download = action.payload.fileName
    // attachmentDownloadLink.click()

    yield put({ type: actionTypes.FETCH_SINGLE_ATTACHMENT_SUCCEEDED })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_ATTACHMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteSingleAttachment(action: actionTypes.deleteSingleAttachmentAction) {
  try {
    yield call(deleteSingleAttachmentCall, action.payload, action.section)

    yield put({ type: actionTypes.DELETE_SINGLE_ATTACHMENT_SUCCEEDED })

    const deletedId = action.payload.id

    switch (action.section) {
      case 'bomElements':
        yield put({
          type: actionTypes.DELETE_BOM_ELEMENT_ATTACHMENT_SUCCEEDED,
          payload: { id: deletedId },
        })
        break
      case 'offers':
        yield put({
          type: actionTypes.DELETE_OFFER_ATTACHMENT_SUCCEEDED,
          payload: { id: deletedId },
        })
        break
      case 'orders':
        yield put({
          type: actionTypes.DELETE_ORDER_ATTACHMENT_SUCCEEDED,
          payload: { id: deletedId },
        })
        break
      case 'services':
        yield put({
          type: actionTypes.DELETE_SERVICE_ATTACHMENT_SUCCEEDED,
          payload: { id: deletedId },
        })
        break
      case 'implementationCosts':
        yield put({
          type: actionTypes.DELETE_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED,
          payload: { id: deletedId },
        })
        break
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_SINGLE_ATTACHMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* adminSaga() {
  yield takeLatest(actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED, fetchTodayCurrencyExchange)
  yield takeLatest(actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED, fetchSingleAttachment)
  yield takeLatest(actionTypes.DELETE_SINGLE_ATTACHMENT_REQUESTED, deleteSingleAttachment)
}
