import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Container, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CLabel, CButton, CPagination, CInput } from '@coreui/react'
import { Select, SelectProps } from '@material-ui/core'
import { cilListHighPriority } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumbersInTableRows,
  convertQuantityToNumericString,
  getEmptyListMessage,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  makeSearchBoxFixed,
  toDateInputValue,
  toDateTableValue,
} from '../../utils'
import { minimumWidthForWideDetailsView } from '../erp'
import { FormFixedActionsPanel } from '../../components'

export const initialDocumentsPageSize = 25

export const DocumentsOfWarehouses: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { tenant } = useParams<{ tenant: string }>()
  const today = toDateInputValue(new Date().toISOString())

  // A custom hook that builds on useLocation to parse the query string
  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const [documentsOfWarehousesFilterState, setDocumentsOfWarehousesFilterState] =
    useState<actionTypes.TFilterState>(undefined)
  const [documentsOfWarehousesSorterState, setDocumentsOfWarehousesSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const [currentlyBrowsedWarehouseId, setCurrentlyBrowsedWarehouseId] = useState<number | string>(
    Number(query.get('warehouseId')) || ''
  )
  const [currentlyBrowsedWarehouseTypeId, setCurrentlyBrowsedWarehouseTypeId] = useState<
    number | string
  >('')
  const [currentlyBrowsedWarehouseTypeName, setCurrentlyBrowsedWarehouseTypeName] = useState<
    number | string
  >('')
  const [searchTerm, setSearchTerm] = useState<string>(String(query.get('searchTerm') || ''))
  const [dateFrom, setDateFrom] = useState<string>(String(query.get('from') || ''))
  const [dateTo, setDateTo] = useState<string>(String(query.get('to') || today))
  const [pageSize, setPageSize] = useState<number>(
    Number(query.get('pageSize') || initialDocumentsPageSize)
  )
  const [chosenPage, setChosenPage] = useState<string>(String(query.get('page') || 1))

  const documentsPayload: actionTypes.IFetchDocumentsOfWarehousesData = {
    warehouseId: currentlyBrowsedWarehouseId || null,
    from: dateFrom,
    to: dateTo,
    searchTerm: searchTerm,
    pageSize: Number(pageSize),
    page: Number(chosenPage),
  }

  const memoizedTableItems = useMemo(() => {
    const documents: any[] | undefined = (
      warehousesState?.documentsOfWarehouses?.records || []
    )?.map((inventoryItem: actionTypes.IDocumentsOfWarehousesTableItem) => {
      return {
        documentType: inventoryItem.documentType || '',
        documentNumber: inventoryItem.documentNumber || '',
        invoices: inventoryItem?.invoices
          ? inventoryItem?.invoices.length > 1
            ? inventoryItem?.invoices.join('\n')
            : inventoryItem?.invoices
          : '',
        orderNumber: inventoryItem.orderNumber || '',
        warehouseName: inventoryItem.warehouseName || '',
        warehouseId: inventoryItem.warehouseId || '',
        supplierAndReceiver:
          inventoryItem?.supplierName && inventoryItem?.userName
            ? `${inventoryItem?.supplierName} \n ${inventoryItem?.userName}`
            : `${inventoryItem?.supplierName || inventoryItem?.userName || ''}`,
        quantity:
          inventoryItem?.bomElements
            ?.map((bomElement: any) => convertQuantityToNumericString(bomElement.quantity))
            ?.join('\n') || '',
        totalNetPrice: inventoryItem.totalNetPrice || '',
        totalNetPriceCurrency: inventoryItem.totalNetPriceCurrency || '',
        documentCreateDate: toDateTableValue(inventoryItem.documentCreateDate) || '',
      }
    })

    if (currentlyBrowsedWarehouseTypeName) {
      return documents?.filter((inventoryItem: actionTypes.IDocumentsOfWarehousesTableItem) =>
        currentlyBrowsedWarehouseTypeName
          ? inventoryItem.documentType === currentlyBrowsedWarehouseTypeName
          : true
      )
    } else {
      return documents
    }
  }, [
    warehousesState?.documentsOfWarehouses?.records,
    currentlyBrowsedWarehouseId,
    currentlyBrowsedWarehouseTypeName,
    pageSize,
    chosenPage,
  ])

  const closeDocumentsOfWarehouses = useCallback(() => {
    history.push(`/${tenant}/warehouse-management/${currentlyBrowsedWarehouseId}`)
  }, [history, tenant, currentlyBrowsedWarehouseId])

  // Change query params on page change
  useEffect(() => {
    query.set('page', chosenPage)

    history.push({
      pathname: location.pathname,
      search: query.toString(),
    })
  }, [history, chosenPage])

  // Change query params on page change
  useEffect(() => {
    query.set('pageSize', String(pageSize))

    history.replace({
      pathname: location.pathname,
      search: query.toString(),
    })
  }, [pageSize])

  // Fetch documents data
  useEffect(() => {
    if (
      location.pathname.includes(`documents-of-warehouses`) &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read')
    ) {
      dispatch({
        type: actionTypes.FETCH_DOCUMENTS_OF_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, ...documentsPayload },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
    currentlyBrowsedWarehouseId,
    dateFrom,
    dateTo,
    chosenPage,
  ])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    convertNumbersInTableRows(warehousesState?.documentsOfWarehouses?.records, [7], [6])
  }, [
    memoizedTableItems,
    documentsOfWarehousesFilterState,
    documentsOfWarehousesSorterState,
    chosenPage,
  ])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ maxWidth: '1660px' }}
    >
      <CCard className="documents-of-warehouses-container">
        <CCardBody className={`px-4 pt-4 pb-2`}>
          {authState?.authData?.roles?.includes('Warehouses_read') && (
            <FormFixedActionsPanel
              title={<></>}
              mode={'edit'}
              section="documents-of-warehouses"
              isSaving={false}
              isSaved={false}
              isDeleting={false}
              isDeleted={false}
              didFormValidationOccur={false}
              formErrorsBool={false}
              closeAction={''}
              deleteAction={''}
              deletePayload={null}
              closeFunction={closeDocumentsOfWarehouses}
              setDidFormValidationOccur={null}
              canDelete={false}
              isEditable={false}
              disabledDeleteButtonClassNames=""
              canSave={false}
              confirmDeleteMessageJSX={<></>}
            />
          )}

          <div className="d-flex justify-content-start documents-of-warehouses-filter-panel-wrapper">
            <div style={{ maxWidth: '210px' }}>
              <CLabel
                htmlFor="documents-warehouse-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Magazyn
              </CLabel>
              <InputGroup
                id="documents-warehouse-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-storage"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedWarehouseId}
                  className="item-selector warehouse-selector"
                  style={{ minWidth: '170px' }}
                  disabled={!warehousesState?.warehouses}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    const warehouseIdValue = event?.target?.value
                      ? Number(event?.target?.value)
                      : ''

                    setCurrentlyBrowsedWarehouseId(warehouseIdValue)

                    query.set('warehouseId', String(warehouseIdValue))

                    history.replace({
                      pathname: location.pathname,
                      search: query.toString(),
                    })

                    return event
                  }}
                >
                  <option value={''}>Wszystkie</option>
                  {warehousesState?.warehouses?.map(
                    (warehouse: actionTypes.TWarehouseLight, warehouseIndex: number) => {
                      return (
                        <option
                          key={`warehouse-select-option-${warehouse.id}-${warehouseIndex}`}
                          value={warehouse.id}
                        >
                          {warehouse?.name || '...'}
                        </option>
                      )
                    }
                  )}
                </Select>
              </InputGroup>
            </div>

            <div className="pl-4" style={{ minWidth: '200px' }}>
              <CLabel htmlFor="documents-search-term" className={`${inputLabelSpacingBottom}`}>
                Słowo kluczowe
              </CLabel>
              <InputGroup id="documents-search-term" className={`${inputFieldSpacingBottom}`}>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-notes"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <CInput
                  id="documents-search-term-input"
                  defaultValue={searchTerm}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    return event
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    if (setSearchTerm) {
                      setSearchTerm(event.target.value)

                      query.set('searchTerm', String(event.target.value))

                      history.replace({
                        pathname: location.pathname,
                        search: query.toString(),
                      })
                    }
                  }}
                  placeholder=""
                  type="search"
                  style={{ width: '150px' }}
                />
              </InputGroup>
            </div>

            <div className="pl-4" style={{ minWidth: '190px' }}>
              <CLabel htmlFor="documents-date-from" className={`${inputLabelSpacingBottom}`}>
                Data od
              </CLabel>
              <InputGroup id="documents-date-from" className={`${inputFieldSpacingBottom}`}>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-calendar"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  id="documents-date-from-input"
                  max={dateTo || ''}
                  defaultValue={dateFrom}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    return event
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    if (setDateFrom) {
                      setDateFrom(event.target.value)

                      query.set('from', String(event.target.value))

                      history.replace({
                        pathname: location.pathname,
                        search: query.toString(),
                      })
                    }
                  }}
                  placeholder="Wprowadź datę od"
                  type="date"
                  style={{ width: '140px' }}
                />
              </InputGroup>
            </div>

            <div className="pl-4" style={{ minWidth: '190px' }}>
              <CLabel htmlFor="documents-date-to" className={`${inputLabelSpacingBottom}`}>
                Data do
              </CLabel>
              <InputGroup id="documents-date-to" className={`${inputFieldSpacingBottom}`}>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-calendar"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  id="documents-date-to-input"
                  defaultValue={dateTo}
                  min={dateFrom || ''}
                  max={today}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    return event
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    if (setDateTo) {
                      const chosenDate = event?.target?.value || today

                      setDateTo(chosenDate)

                      query.set('to', String(chosenDate))

                      history.replace({
                        pathname: location.pathname,
                        search: query.toString(),
                      })
                    }
                  }}
                  placeholder="Wprowadź datę do"
                  type="date"
                  style={{ width: '140px' }}
                />
              </InputGroup>
            </div>

            <div className="pl-4" style={{ maxWidth: '220px' }}>
              <CLabel
                htmlFor="documents-page-size-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Ilość na stronę
              </CLabel>
              <InputGroup
                id="documents-page-size-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-chart"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={pageSize}
                  className="item-selector warehouse-selector"
                  style={{ minWidth: '70px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    if (setPageSize) {
                      setPageSize(Number(event?.target?.value))

                      query.set('pageSize', String(event.target.value))

                      history.replace({
                        pathname: location.pathname,
                        search: query.toString(),
                      })
                    }

                    return event
                  }}
                >
                  <option value={initialDocumentsPageSize}>{initialDocumentsPageSize}</option>
                  <option value={initialDocumentsPageSize * 2}>
                    {initialDocumentsPageSize * 2}
                  </option>
                  <option value={initialDocumentsPageSize * 4}>
                    {initialDocumentsPageSize * 4}
                  </option>
                  <option value={initialDocumentsPageSize * 8}>
                    {initialDocumentsPageSize * 8}
                  </option>
                  <option value={initialDocumentsPageSize * 20}>
                    {initialDocumentsPageSize * 20}
                  </option>
                </Select>
              </InputGroup>
            </div>

            <div className="pl-4" style={{ maxWidth: `${120 + 70}px` }}>
              <CLabel htmlFor="documents-type-id-selector" className={`${inputLabelSpacingBottom}`}>
                Rodzaj
              </CLabel>
              <InputGroup
                id="documents-type-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-inbox"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedWarehouseTypeId}
                  className="item-selector warehouse-selector"
                  style={{ minWidth: '120px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedWarehouseTypeId(
                      event?.target?.value ? Number(event?.target?.value) : ''
                    )

                    setCurrentlyBrowsedWarehouseTypeName(
                      event?.target?.value
                        ? String(
                            actionTypes.documentsOfWarehousesTypesObject[
                              Number(event?.target?.value)
                            ]
                          )
                        : ''
                    )

                    return event
                  }}
                >
                  <option value={''}>Wszystkie</option>
                  <option value={actionTypes.receiveOrderType}>
                    {actionTypes.documentsOfWarehousesTypesObject[actionTypes.receiveOrderType]}
                  </option>
                  <option value={actionTypes.warehouseShiftType}>
                    {actionTypes.documentsOfWarehousesTypesObject[actionTypes.warehouseShiftType]}
                  </option>
                </Select>
              </InputGroup>
            </div>

            <div className="documents-of-warehouses-reset-filters-button-wrapper">
              <CButton
                color="primary"
                variant="outline"
                className="ml-3 documents-of-warehouses-reset-filters-button"
                type="button"
                disabled={!currentlyBrowsedWarehouseTypeName && !documentsOfWarehousesFilterState}
                onClick={() => {
                  setCurrentlyBrowsedWarehouseTypeId('')
                  setCurrentlyBrowsedWarehouseTypeName('')
                  setDocumentsOfWarehousesFilterState(undefined)
                }}
              >
                <CIcon icon={cilListHighPriority} />
              </CButton>
            </div>
          </div>

          <CDataTable
            tableFilterValue={documentsOfWarehousesFilterState}
            sorterValue={documentsOfWarehousesSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              setDocumentsOfWarehousesSorterState(sorterState)
            }}
            onTableFilterChange={(tableFilterState: string) => {
              setDocumentsOfWarehousesFilterState(tableFilterState)
            }}
            loading={warehousesState?.areDocumentsOfWarehousesLoading}
            striped={!!warehousesState?.documentsOfWarehouses?.records?.length}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj na stronie...' }}
            addTableClasses="vertical-middle-list-table documents-of-warehouses-table mt-2 mb-4"
            cleaner
            onRowClick={undefined}
            itemsPerPage={pageSize}
            fields={[
              {
                key: 'documentType',
                label: 'Rodzaj dokumentu',
                _style: { width: '250px', textAlign: 'left' },
              },
              {
                key: 'documentNumber',
                label: 'Numer',
                _style: { width: '140px' },
              },
              {
                key: 'invoices',
                label: 'Faktury',
                _style: { width: '120px', textAlign: 'left' },
              },
              {
                key: 'orderNumber',
                label: 'Nr zamówienia',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'warehouseName',
                label: 'Magazyn',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'supplierAndReceiver',
                label: 'Dostawca \n/ Odbiorca',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'quantity',
                label: 'Ilość',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'totalNetPrice',
                label: 'Wartość netto',
                _style: { width: '130px', textAlign: 'left' },
              },
              {
                key: 'totalNetPriceCurrency',
                label: 'CCY',
                _style: { width: '60px', textAlign: 'left' },
              },
              {
                key: 'documentCreateDate',
                label: 'Utworzono',
                _style: { width: '90px', textAlign: 'left' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {currentlyBrowsedWarehouseId &&
                currentlyBrowsedWarehouseTypeName &&
                !documentsOfWarehousesFilterState &&
                !warehousesState.areDocumentsOfWarehousesLoading &&
                !warehousesState.fetchDocumentsOfWarehousesError
                  ? 'Brak wyników dla wybranego typu w wybranym magazynie!'
                  : currentlyBrowsedWarehouseId &&
                    !documentsOfWarehousesFilterState &&
                    !warehousesState.areDocumentsOfWarehousesLoading &&
                    !warehousesState.fetchDocumentsOfWarehousesError
                  ? 'Brak wyników w wybranym magazynie!'
                  : currentlyBrowsedWarehouseTypeName &&
                    !documentsOfWarehousesFilterState &&
                    !warehousesState.areDocumentsOfWarehousesLoading &&
                    !warehousesState.fetchDocumentsOfWarehousesError
                  ? 'Brak wyników dla wybranego typu!'
                  : getEmptyListMessage(
                      warehousesState.fetchDocumentsOfWarehousesError,
                      warehousesState.areDocumentsOfWarehousesLoading,
                      'dokumentów magazynowych',
                      warehousesState?.documentsOfWarehouses?.records?.length
                    )}
              </div>
            }
          />

          {!warehousesState.fetchDocumentsOfWarehousesError && (
            <CPagination
              activePage={Number(chosenPage)}
              pages={Math.ceil(
                Number(warehousesState?.documentsOfWarehouses?.totalCount) /
                  Number(warehousesState?.documentsOfWarehouses?.pageSize)
              )}
              onActivePageChange={(pageNumber: number) => {
                if (pageNumber && setChosenPage) {
                  setChosenPage(String(pageNumber))
                }
              }}
            />
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
