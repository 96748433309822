import {
  ISingleItemActionPayload,
  TAdminPayload,
  TFilterState,
  TNetworkError,
  TSorterState,
} from '.'

export const FETCH_USERS_REQUESTED = 'FETCH_USERS_REQUESTED'
export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'

export const SET_USERS_TABLE_FILTER_STATE = 'SET_USERS_TABLE_FILTER_STATE'
export const SET_USERS_TABLE_SORTER_STATE = 'SET_USERS_TABLE_SORTER_STATE'

export const CREATE_USER_REQUESTED = 'CREATE_USER_REQUESTED'
export const CREATE_USER_SUCCEEDED = 'CREATE_USER_SUCCEEDED'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const FETCH_SINGLE_USER_REQUESTED = 'FETCH_SINGLE_USER_REQUESTED'
export const FETCH_SINGLE_USER_SUCCEEDED = 'FETCH_SINGLE_USER_SUCCEEDED'
export const FETCH_SINGLE_USER_ERROR = 'FETCH_SINGLE_USER_ERROR'

export const EDIT_USER_REQUESTED = 'EDIT_USER_REQUESTED'
export const EDIT_USER_SUCCEEDED = 'EDIT_USER_SUCCEEDED'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'

export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED'
export const DELETE_USER_SUCCEEDED = 'DELETE_USER_SUCCEEDED'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export const USER_ACTIONS_UNLOCK = 'USER_ACTIONS_UNLOCK'

export const CLOSE_USER_DETAILS = 'CLOSE_USER_DETAILS'

export const FETCH_PROFILES_REQUESTED = 'FETCH_PROFILES_REQUESTED'
export const FETCH_PROFILES_SUCCEEDED = 'FETCH_PROFILES_SUCCEEDED'
export const FETCH_PROFILES_ERROR = 'FETCH_PROFILES_ERROR'

export const SAVE_PROFILES_REQUESTED = 'SAVE_PROFILES_REQUESTED'
export const SAVE_PROFILES_SUCCEEDED = 'SAVE_PROFILES_SUCCEEDED'
export const SAVE_PROFILES_ERROR = 'SAVE_PROFILES_ERROR'

export const PROFILES_ACTIONS_UNLOCK = 'PROFILES_ACTIONS_UNLOCK'

export type TUser = {
  userName: string
  firstName: string
  lastName: string
  isActive: boolean
  userProfiles: TLightUserProfile[] | []
  userId: string

  // types for delete icon
  icon?: string
  id?: string
  name?: string
  canDelete?: true
}

export type TUserLight = {
  userName: string
  userId: string
}

export enum EUserRoleName {
  Suppliers = 'Suppliers',
  BomElements = 'BomElements',
  Offers = 'Offers',
  Producers = 'Producers',
  ImplementationCosts = 'ImplementationCosts',
  Services = 'Services',
  Boms = 'Boms',
  PurchasePlans = 'PurchasePlans',
  Orders = 'Orders',
  Warehouses = 'Warehouses',
  Manufacturing = 'Manufacturing',
  AuditLogs = 'AuditLogs',
  Users = 'Users',
}

export type TUserRoleName = `${EUserRoleName}`

export type TEditableUserRole = { roleName: TUserRoleName; state: TUserRoleStateValue }

export type TUserProfile = {
  id: number
  name: string
  newName?: string
  roles: TEditableUserRole[]
  uuid: string
}

export type TLightUserProfile = {
  name: string
  id: number | string
  uuid: string
}

export type TUserRoleStateValue = 0 | 1 | 2

export interface ICreateUserPayload extends TAdminPayload {
  user: TUser
}

export interface ISaveProfilesPayload extends TAdminPayload {
  profiles: TUserProfile[]
}

// FETCH_USERS_REQUESTED

export interface fetchUsersAction {
  type: typeof FETCH_USERS_REQUESTED
  payload: TAdminPayload
}

export interface fetchUsersSucceeded {
  type: typeof FETCH_USERS_SUCCEEDED
  users: TUser[]
}

export interface fetchUsersError {
  type: typeof FETCH_USERS_ERROR
  error: TNetworkError
}

// SET_USERS_TABLE_FILTER_STATE

export interface setUsersTableFilterState {
  type: typeof SET_USERS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_USERS_TABLE_SORTER_STATE

export interface setUsersTableSorterState {
  type: typeof SET_USERS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_USER

export interface createUserAction {
  type: typeof CREATE_USER_REQUESTED
  payload: ICreateUserPayload
}

export interface createUserSucceeded {
  type: typeof CREATE_USER_SUCCEEDED
  user: TUser
}

export interface createUserError {
  type: typeof CREATE_USER_ERROR
  error: TNetworkError
}

// FETCH_SINGLE_USER

export interface fetchSingleUserAction {
  type: typeof FETCH_SINGLE_USER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleUserSucceeded {
  type: typeof FETCH_SINGLE_USER_SUCCEEDED
  user: TUser
}

export interface fetchSingleUserError {
  type: typeof FETCH_SINGLE_USER_ERROR
  error: TNetworkError
}

// EDIT_USER

export interface editUserAction {
  type: typeof EDIT_USER_REQUESTED
  payload: ICreateUserPayload
}

export interface editUserSucceeded {
  type: typeof EDIT_USER_SUCCEEDED
  user: TUser
}

export interface editUserError {
  type: typeof EDIT_USER_ERROR
  error: TNetworkError
}

// DELETE_USER

export interface deleteUserAction {
  type: typeof DELETE_USER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteUserSucceeded {
  type: typeof DELETE_USER_SUCCEEDED
  userId: string
}

export interface deleteUserError {
  type: typeof DELETE_USER_ERROR
  error: TNetworkError
}

// USER_ACTIONS_UNLOCK

export interface userActionsUnlockAction {
  type: typeof USER_ACTIONS_UNLOCK
}

// CLOSE_USER_DETAILS

export interface closeUserDetailsAction {
  type: typeof CLOSE_USER_DETAILS
}

// FETCH_PROFILES_REQUESTED

export interface fetchProfilesAction {
  type: typeof FETCH_PROFILES_REQUESTED
  payload: TAdminPayload
}

export interface fetchProfilesSucceeded {
  type: typeof FETCH_PROFILES_SUCCEEDED
  profiles: TUserProfile[]
}

export interface fetchProfilesError {
  type: typeof FETCH_PROFILES_ERROR
  error: TNetworkError
}

// SAVE_PROFILES

export interface saveProfilesAction {
  type: typeof SAVE_PROFILES_REQUESTED
  payload: ISaveProfilesPayload
}

export interface saveProfilesSucceeded {
  type: typeof SAVE_PROFILES_SUCCEEDED
  profiles: TUserProfile[]
}

export interface saveProfilesError {
  type: typeof SAVE_PROFILES_ERROR
  error: TNetworkError
}

// PROFILES_ACTIONS_UNLOCK

export interface profilesActionsUnlockAction {
  type: typeof PROFILES_ACTIONS_UNLOCK
}

export type UsersActionTypes =
  | fetchUsersAction
  | fetchUsersSucceeded
  | fetchUsersError
  | setUsersTableFilterState
  | setUsersTableSorterState
  | createUserAction
  | createUserSucceeded
  | createUserError
  | fetchSingleUserAction
  | fetchSingleUserSucceeded
  | fetchSingleUserError
  | editUserAction
  | editUserSucceeded
  | editUserError
  | deleteUserAction
  | deleteUserSucceeded
  | deleteUserError
  | userActionsUnlockAction
  | closeUserDetailsAction
  //
  | fetchProfilesAction
  | fetchProfilesSucceeded
  | fetchProfilesError
  | saveProfilesAction
  | saveProfilesSucceeded
  | saveProfilesError
  | profilesActionsUnlockAction
