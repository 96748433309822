import { legacy_createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers/index'
import {
  authSaga,
  adminSaga,
  suppliersSaga,
  producersSaga,
  bomElementsSaga,
  servicesSaga,
  settingsSaga,
  bomsSaga,
  ordersSaga,
  purchasePlansSaga,
  offersSaga,
  implementationCostsSaga,
  auditLogsSaga,
  usersSaga,
  warehousesSaga,
  manufacturingSaga,
  reportsSaga,
} from './sagas/index'

const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose

const sagaMiddleware = createSagaMiddleware()

const store = legacy_createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(authSaga)
sagaMiddleware.run(adminSaga)
sagaMiddleware.run(auditLogsSaga)
sagaMiddleware.run(usersSaga)

sagaMiddleware.run(suppliersSaga)
sagaMiddleware.run(settingsSaga)
sagaMiddleware.run(producersSaga)
sagaMiddleware.run(bomElementsSaga)
sagaMiddleware.run(servicesSaga)
sagaMiddleware.run(implementationCostsSaga)
sagaMiddleware.run(bomsSaga)
sagaMiddleware.run(offersSaga)
sagaMiddleware.run(purchasePlansSaga)
sagaMiddleware.run(ordersSaga)
sagaMiddleware.run(warehousesSaga)
sagaMiddleware.run(manufacturingSaga)
sagaMiddleware.run(reportsSaga)

export default store
