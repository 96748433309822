import { call, put, takeLatest } from 'redux-saga/effects'

import { sectionFetch } from './api'
import * as actionTypes from '../action-types'

// I did not decide to name it this way on the BE
export function* fetchAllUsersPossessions(
  action: actionTypes.fetchCurrentManufacturingReportAction
) {
  try {
    const response = yield call(sectionFetch, action.payload, `userPossessions`)

    yield put({
      type: actionTypes.FETCH_CURRENT_MANUFACTURING_REPORT_SUCCEEDED,
      currentManufacturingReport: response?.data?.bomElementUserPossessions || null,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_CURRENT_MANUFACTURING_REPORT_ERROR,
      error: error?.response,
    })
  }
}

export function* reportsSaga() {
  yield takeLatest(
    actionTypes.FETCH_CURRENT_MANUFACTURING_REPORT_REQUESTED,
    fetchAllUsersPossessions
  )
}
