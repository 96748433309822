import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { InputGroup, InputGroupAddon, InputGroupText, Table, Input } from 'reactstrap'
import { ErrorMessage, FastField, FieldArray, FormikErrors, FormikHandlers } from 'formik'
import { CLabel, CTabPane, CButton } from '@coreui/react'
import Select from '@material-ui/core/Select'
import { v4 as uuidv4 } from 'uuid'
import NumberFormat from 'react-number-format'
import { confirmAlert } from 'react-confirm-alert'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { BasicFormField, ConfirmActionModal } from '../../components'
import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  toDateInputValue,
  currencyExchanger,
  convertNumberToNumericString,
  globalThousandSeparator,
  globalDecimalSeparator,
  convertNumericStringToNumber,
  convertQuantityToNumericString,
  globalCurrencyFallback,
  longMaximumInputLength,
} from '../../utils'
import { useStylesForOrder } from './order-details'

// This is actually not a good result of child component structure in formik; try to avoid breaking large forms into other files
interface IOrderDeliveries {
  values: actionTypes.TOrderFormikValues
  errors: any
  handleChange: FormikHandlers['handleChange']
  exchangeRates: any
}

export const OrderDeliveries: React.FC<IOrderDeliveries> = ({
  values,
  errors,
  handleChange,
  exchangeRates,
}) => {
  const history = useHistory()
  const materialUISelectClasses = useStylesForOrder()
  const { tenant, id, tab } = useParams<{ tenant: string; id: string; tab: string }>()
  const paramsOrderId = id
  const paramsOrderTab = tab

  const authState = useSelector((state: TRootState) => state.auth)
  const ordersState = useSelector((state: TRootState) => state.orders)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const unchosenWarehouseValue = -1

  return (
    <FieldArray
      name="orderDeliveries"
      render={(arrayHelpers) => (
        <>
          {values?.orderDeliveries &&
            values?.orderDeliveries?.map(
              (delivery: actionTypes.TOrderDelivery, deliveryIndex: number) => (
                <CTabPane
                  key={`${delivery.id}-${deliveryIndex}-${delivery.uuid}`}
                  data-tab={`delivery-${deliveryIndex + 1}`}
                >
                  <div className="order-grid-fields order-grid-fields---delivery-first-row">
                    <div>
                      <CLabel
                        htmlFor={`order-delivery-${deliveryIndex}-${delivery.id}-status`}
                        className={`${inputLabelSpacingBottom}`}
                      >
                        Status
                      </CLabel>
                      <InputGroup
                        id={`order-delivery-${deliveryIndex}-${delivery.id}-status`}
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="cil-bell"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <FastField
                          as={Select}
                          variant="outlined"
                          native
                          classes={{
                            root: materialUISelectClasses.root,
                          }}
                          name={`orderDeliveries.${deliveryIndex}.status`}
                        >
                          <option value={1}>Niewysłane</option>
                          <option value={2}>Wysłane</option>
                          <option value={3}>Wysłane częściowo</option>
                          <option value={4}>Dostarczone</option>
                          <option value={5}>Dostarczone częściowo</option>
                          <option value={6}>Niedostarczone</option>
                          <option value={7}>Anulowane</option>
                        </FastField>
                      </InputGroup>
                    </div>

                    <div>
                      <CLabel
                        htmlFor={`order-delivery-${deliveryIndex}-${delivery.id}-payment`}
                        className={`${inputLabelSpacingBottom}`}
                      >
                        Płatność
                      </CLabel>
                      <InputGroup
                        id={`order-delivery-${deliveryIndex}-${delivery.id}-payment`}
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="cil-cash"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <FastField
                          as={Select}
                          variant="outlined"
                          native
                          classes={{
                            root: materialUISelectClasses.deliveryPayment,
                          }}
                          name={`orderDeliveries.${deliveryIndex}.paymentStatus`}
                        >
                          <option value={0}>Nieopłacone</option>
                          <option value={1}>Częściowo opłacone</option>
                          <option value={2}>Opłacone</option>
                        </FastField>
                      </InputGroup>
                    </div>

                    <BasicFormField
                      fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-delivery-create-date`}
                      fieldLabel="Data utworzenia"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.deliveryCreateDate`}
                      fieldValue={toDateInputValue(delivery.deliveryCreateDate)}
                      fieldError={null}
                      fieldIcon="cil-calendar"
                      fieldType="date"
                    />

                    <div>
                      <CLabel
                        htmlFor={`order-delivery-${deliveryIndex}-${delivery.id}-warehouse`}
                        className={`${inputLabelSpacingBottom}`}
                      >
                        Magazyn docelowy
                      </CLabel>
                      <InputGroup
                        id={`order-delivery-${deliveryIndex}-${delivery.id}-warehouse`}
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={
                              errors?.orderDeliveries &&
                              typeof errors?.orderDeliveries !== 'string' &&
                              errors?.orderDeliveries[deliveryIndex] &&
                              errors?.orderDeliveries[deliveryIndex]['warehouseId']
                                ? 'text-danger input-error-icon'
                                : ''
                            }
                          >
                            <i className="cil-storage"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <ErrorMessage
                          name={`orderDeliveries.${deliveryIndex}.warehouseId`}
                          component="span"
                          className="text-danger input-error-message"
                        />

                        <FastField
                          as={Select}
                          variant="outlined"
                          native
                          className={`${
                            errors?.orderDeliveries &&
                            typeof errors?.orderDeliveries !== 'string' &&
                            errors?.orderDeliveries[deliveryIndex] &&
                            errors?.orderDeliveries[deliveryIndex]['warehouseId']
                              ? 'invalid-native-selector'
                              : ''
                          }`}
                          classes={{
                            root: materialUISelectClasses.warehouse,
                          }}
                          name={`orderDeliveries.${deliveryIndex}.warehouseId`}
                          disabled={!!delivery?.arrivalDate}
                        >
                          <option value={unchosenWarehouseValue}>...</option>
                          {warehousesState?.warehouses?.map(
                            (warehouse: actionTypes.TWarehouseLight) => (
                              <option key={warehouse.id} value={warehouse.id}>
                                {warehouse?.name || ''}
                              </option>
                            )
                          )}
                        </FastField>
                      </InputGroup>
                    </div>

                    <BasicFormField
                      fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-arrivalDate`}
                      fieldLabel="Data przyjęcia"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.arrivalDate`}
                      fieldValue={
                        delivery.arrivalDate ? toDateInputValue(delivery.arrivalDate) : ''
                      }
                      fieldError={null}
                      fieldIcon="cil-calendar"
                      fieldType="date"
                      isDisabled
                    />
                  </div>

                  <div className="form-rows-separator">Transport</div>

                  <div className="order-grid-fields order-grid-fields--shipment">
                    <BasicFormField
                      fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-dispatch-date`}
                      fieldLabel="Data wysyłki"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.dispatchDate`}
                      fieldValue={toDateInputValue(delivery.dispatchDate)}
                      fieldError={
                        errors?.orderDeliveries &&
                        typeof errors?.orderDeliveries !== 'string' &&
                        errors?.orderDeliveries[deliveryIndex] &&
                        errors?.orderDeliveries[deliveryIndex]['dispatchDate']
                      }
                      fieldIcon="cil-calendar"
                      fieldType="date"
                    />

                    <BasicFormField
                      fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-expected-arrival-date=from`}
                      fieldLabel="Data dostarczenia od"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.expectedArrivalDateFrom`}
                      fieldValue={toDateInputValue(delivery.expectedArrivalDateFrom)}
                      fieldError={
                        errors?.orderDeliveries &&
                        typeof errors?.orderDeliveries !== 'string' &&
                        errors?.orderDeliveries[deliveryIndex] &&
                        errors?.orderDeliveries[deliveryIndex]['expectedArrivalDateFrom']
                      }
                      fieldIcon="cil-calendar-check"
                      fieldType="date"
                    />

                    <BasicFormField
                      fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-expected-arrival-date-to`}
                      fieldLabel="Data dostarczenia do"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.expectedArrivalDateTo`}
                      fieldValue={toDateInputValue(delivery.expectedArrivalDateTo)}
                      fieldError={
                        errors?.orderDeliveries &&
                        typeof errors?.orderDeliveries !== 'string' &&
                        errors?.orderDeliveries[deliveryIndex] &&
                        errors?.orderDeliveries[deliveryIndex]['expectedArrivalDateTo']
                      }
                      fieldIcon="cil-calendar-check"
                      fieldType="date"
                    />

                    <BasicFormField
                      fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-shipment-cost`}
                      fieldLabel="Koszt wysyłki"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.shipmentCost`}
                      // Do not add to value || '' because it removes the leading 0
                      fieldValue={delivery?.shipmentCost}
                      fieldError={null}
                      fieldIcon="cil-cash"
                      fieldType="number"
                      fieldCurrencyName={`orderDeliveries.${deliveryIndex}.shipmentCostCurrency`}
                      fieldCurrencyValue={delivery.shipmentCostCurrency}
                      handleChange={handleChange}
                    />
                  </div>

                  <div className="form-rows-separator">Faktury</div>

                  <FieldArray
                    name={`orderDeliveries.${deliveryIndex}.invoices`}
                    render={(arrayHelpers) => (
                      <>
                        <div className="pt-1 pb-2 mt-0 mb-2 d-flex justify-content-center">
                          <CButton
                            color="info"
                            type="button"
                            variant="outline"
                            onClick={() => {
                              const newInvoiceInDelivery: actionTypes.TDeliveryInvoice = {
                                invoiceNumber: '',
                                invoicePrice: '',
                                invoicePriceCurrency:
                                  settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                  globalCurrencyFallback,
                                invoiceCreateDate: '',

                                invoiceDeadline: null,
                                invoicePaymentFinalDate: '',
                                invoiceDescription: '',
                                uuid: uuidv4(),
                              }

                              arrayHelpers.push(newInvoiceInDelivery)
                            }}
                            className="px-3"
                            disabled={false}
                          >
                            Dodaj fakturę
                          </CButton>
                        </div>

                        {delivery?.invoices?.length > 0 && (
                          <Table
                            id={`order-delivery-${deliveryIndex}-invoices-table`}
                            className="order-delivery-invoices-table mt-0 mb-3"
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    width: '40px',
                                    textAlign: 'center',
                                  }}
                                >
                                  #
                                </th>
                                <th style={{ width: '250px' }}>Numer faktury</th>
                                <th style={{ width: '230px' }}>Wartość netto na fakturze</th>
                                <th style={{ width: '175px' }}>Data wystawienia</th>
                                <th style={{ width: '175px' }}>Finalny termin zapłaty</th>
                                <th style={{ width: '235px' }}>Opis</th>
                                <th style={{ width: '55px' }}></th>
                              </tr>
                            </thead>

                            <tbody id={`order-delivery-${deliveryIndex}-invoices-table-body`}>
                              {delivery?.invoices?.length > 0 &&
                                delivery?.invoices?.map(
                                  (invoice: actionTypes.TDeliveryInvoice, invoiceIndex: number) => {
                                    return (
                                      <tr key={`delivery-invoice-key-${invoiceIndex}`}>
                                        <td style={{ textAlign: 'center' }}>{invoiceIndex + 1}.</td>

                                        <td>
                                          <BasicFormField
                                            fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-${invoiceIndex}-invoice-number`}
                                            fieldLabel="Numer faktury"
                                            placeholder="Numer faktury"
                                            formikFieldName={`orderDeliveries.${deliveryIndex}.invoices.${invoiceIndex}.invoiceNumber`}
                                            fieldValue={invoice.invoiceNumber}
                                            fieldError={null}
                                            fieldIcon="cil-newspaper"
                                            fieldType="text"
                                            isFieldWithoutLabelAndIcon
                                          />
                                        </td>

                                        <td>
                                          <BasicFormField
                                            fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-${invoiceIndex}-invoice-price`}
                                            fieldLabel="Wartość netto na fakturze"
                                            placeholder="Wartość netto na fakturze"
                                            formikFieldName={`orderDeliveries.${deliveryIndex}.invoices.${invoiceIndex}.invoicePrice`}
                                            fieldValue={invoice.invoicePrice}
                                            fieldError={null}
                                            fieldIcon="cil-cash"
                                            fieldType="number"
                                            fieldCurrencyName={`orderDeliveries.${deliveryIndex}.invoices.${invoiceIndex}.invoicePriceCurrency`}
                                            fieldCurrencyValue={invoice.invoicePriceCurrency}
                                            handleChange={handleChange}
                                            isFieldWithoutLabelAndIcon
                                          />
                                        </td>

                                        <td>
                                          <BasicFormField
                                            fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-${invoiceIndex}-invoice-create-date`}
                                            fieldLabel="Data wystawienia"
                                            placeholder="Data wystawienia"
                                            formikFieldName={`orderDeliveries.${deliveryIndex}.invoices.${invoiceIndex}.invoiceCreateDate`}
                                            fieldValue={toDateInputValue(invoice.invoiceCreateDate)}
                                            fieldError={
                                              typeof errors?.orderDeliveries === 'object' &&
                                              errors?.orderDeliveries?.[deliveryIndex] &&
                                              typeof errors?.orderDeliveries?.[deliveryIndex] ===
                                                'object' &&
                                              (
                                                (
                                                  errors?.orderDeliveries?.[
                                                    deliveryIndex
                                                  ] as FormikErrors<actionTypes.TOrderDelivery>
                                                )?.invoices?.[
                                                  invoiceIndex
                                                ] as FormikErrors<actionTypes.TDeliveryInvoice>
                                              )?.invoiceCreateDate
                                            }
                                            fieldIcon="cil-calendar"
                                            fieldType="date"
                                            isFieldWithoutLabelAndIcon
                                          />
                                        </td>

                                        <td>
                                          <BasicFormField
                                            fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-${invoiceIndex}-invoice-payment-final-date`}
                                            fieldLabel="Ostateczny termin zapłaty"
                                            placeholder="Ostateczny termin zapłaty"
                                            formikFieldName={`orderDeliveries.${deliveryIndex}.invoices.${invoiceIndex}.invoicePaymentFinalDate`}
                                            fieldValue={toDateInputValue(
                                              invoice.invoicePaymentFinalDate
                                            )}
                                            fieldError={
                                              typeof errors?.orderDeliveries === 'object' &&
                                              errors?.orderDeliveries?.[deliveryIndex] &&
                                              typeof errors?.orderDeliveries?.[deliveryIndex] ===
                                                'object' &&
                                              (
                                                (
                                                  errors?.orderDeliveries?.[
                                                    deliveryIndex
                                                  ] as FormikErrors<actionTypes.TOrderDelivery>
                                                )?.invoices?.[
                                                  invoiceIndex
                                                ] as FormikErrors<actionTypes.TDeliveryInvoice>
                                              )?.invoicePaymentFinalDate
                                            }
                                            fieldIcon="cil-calendar-check"
                                            fieldType="date"
                                            isFieldWithoutLabelAndIcon
                                          />
                                        </td>

                                        <td>
                                          <FastField
                                            as={Input}
                                            placeholder="Opis"
                                            name={`orderDeliveries.${deliveryIndex}.invoices.${invoiceIndex}.invoiceDescription`}
                                            value={invoice.invoiceDescription}
                                            type="text"
                                            maxLength={longMaximumInputLength}
                                          />
                                        </td>

                                        <td>
                                          <CButton
                                            color="danger"
                                            variant="outline"
                                            className="select-option-remove-button"
                                            onClick={() => {
                                              invoice?.invoiceNumber ||
                                              invoice?.invoicePrice ||
                                              invoice?.invoiceCreateDate ||
                                              invoice?.invoiceDeadline ||
                                              invoice?.invoiceDescription
                                                ? confirmAlert({
                                                    closeOnEscape: true,
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                        <ConfirmActionModal
                                                          mode="delete"
                                                          onClose={onClose}
                                                          confirmMessageJSX={
                                                            <>
                                                              Czy na pewno chcesz usunąć
                                                              {!invoice?.invoiceNumber
                                                                ? ` ${invoiceIndex + 1}. `
                                                                : ' '}
                                                              fakturę?
                                                              {invoice?.invoiceNumber ? (
                                                                <>
                                                                  <br />
                                                                  <strong>
                                                                    {invoice?.invoiceNumber}
                                                                  </strong>
                                                                </>
                                                              ) : (
                                                                ''
                                                              )}
                                                            </>
                                                          }
                                                          onConfirmFunction={() => {
                                                            arrayHelpers.remove(invoiceIndex)
                                                          }}
                                                        />
                                                      )
                                                    },
                                                  })
                                                : arrayHelpers.remove(invoiceIndex)
                                            }}
                                          >
                                            <i className="cil-trash"></i>
                                          </CButton>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                            </tbody>
                          </Table>
                        )}
                      </>
                    )}
                  />

                  <div className="form-rows-separator">Opłaty</div>

                  <div className="order-grid-fields order-grid-fields--taxes mb-2">
                    <BasicFormField
                      fieldId={`orderDeliveries.${deliveryIndex}.customsCost`}
                      fieldLabel="Cło"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.customsCost`}
                      fieldValue={delivery?.customsCost}
                      fieldError={null}
                      fieldIcon="cil-institution"
                      fieldType="number"
                      fieldCurrencyName={`orderDeliveries.${deliveryIndex}.customsCostCurrency`}
                      fieldCurrencyValue={delivery.customsCostCurrency}
                      handleChange={handleChange}
                    />

                    <BasicFormField
                      fieldId={`orderDeliveries.${deliveryIndex}.tax`}
                      fieldLabel="VAT"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.tax`}
                      fieldValue={delivery?.tax}
                      fieldError={null}
                      fieldIcon="cil-institution"
                      fieldType="number"
                      fieldCurrencyName={`orderDeliveries.${deliveryIndex}.taxCurrency`}
                      fieldCurrencyValue={delivery.taxCurrency}
                      handleChange={handleChange}
                    />

                    <BasicFormField
                      fieldId={`orderDeliveries.${deliveryIndex}.remainingCosts`}
                      fieldLabel="Pozostałe koszty"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.remainingCosts`}
                      // Do not add to value || '' because it removes the leading 0
                      fieldValue={delivery?.remainingCosts}
                      fieldError={null}
                      fieldIcon="cil-money"
                      fieldType="number"
                      fieldCurrencyName={`orderDeliveries.${deliveryIndex}.remainingCostsCurrency`}
                      fieldCurrencyValue={delivery.remainingCostsCurrency}
                      handleChange={handleChange}
                    />

                    <BasicFormField
                      fieldId={`orderDeliveries.${deliveryIndex}.totalDeliveryCost`}
                      fieldLabel="Całkowity koszt dostawy"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.totalDeliveryCost`}
                      fieldValue={
                        Number(
                          currencyExchanger(
                            Number(convertNumericStringToNumber(delivery.shipmentCost)) || 0,
                            delivery?.shipmentCostCurrency as actionTypes.TCurrencyCode,
                            delivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode,
                            exchangeRates
                          ) +
                            currencyExchanger(
                              Number(convertNumericStringToNumber(delivery.tax)) || 0,
                              delivery?.taxCurrency as actionTypes.TCurrencyCode,
                              delivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode,
                              exchangeRates
                            ) +
                            currencyExchanger(
                              Number(convertNumericStringToNumber(delivery.customsCost)) || 0,
                              delivery?.customsCostCurrency as actionTypes.TCurrencyCode,
                              delivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode,
                              exchangeRates
                            ) +
                            currencyExchanger(
                              Number(convertNumericStringToNumber(delivery.remainingCosts)) || 0,
                              delivery?.remainingCostsCurrency as actionTypes.TCurrencyCode,
                              delivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode,
                              exchangeRates
                            )
                        ) || ''
                      }
                      fieldError={null}
                      fieldIcon="cil-money"
                      fieldType="number"
                      fieldCurrencyName={`orderDeliveries.${deliveryIndex}.totalDeliveryCostCurrency`}
                      fieldCurrencyValue={delivery.totalDeliveryCostCurrency}
                      placeholder="Brak"
                      handleChange={handleChange}
                      isDisabled
                    />
                  </div>

                  <div>
                    <BasicFormField
                      fieldId={`order-delivery-${deliveryIndex}-${delivery.id}-notes`}
                      fieldLabel="Uwagi"
                      formikFieldName={`orderDeliveries.${deliveryIndex}.notes`}
                      fieldValue={delivery.notes}
                      fieldError={null}
                      fieldIcon="cil-excerpt"
                      fieldType="textarea"
                    />
                  </div>

                  <h5 className="mb-2 mt-4" style={{ fontWeight: 400, fontSize: '0.95rem' }}>
                    Linie
                  </h5>
                  <Table
                    bordered
                    striped
                    className="item-inner-details-table mb-3"
                    id={`order-delivery-lines-${deliveryIndex}`}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '40px', textAlign: 'center' }}>#</th>
                        <th style={{ width: '1fr' }}>Element BOM</th>
                        <th style={{ width: '140px' }}>Cena jednostkowa</th>
                        <th style={{ width: '130px' }}>Ilość na zamówieniu</th>
                        <th style={{ width: '170px' }}>Ilość spodziewana</th>
                        <th style={{ width: '130px' }}>Ilość otrzymana</th>
                      </tr>
                    </thead>

                    {ordersState.editedOrder &&
                    ordersState.editedOrder.orderLines &&
                    ordersState.editedOrder.orderLines.length > 0 ? (
                      <tbody>
                        {delivery.deliveryLines?.map(
                          (deliveryLine: actionTypes.TDeliveryLine, indexOfLine: number) => (
                            <tr key={`offer-${deliveryLine.bomElementId}-${indexOfLine}`}>
                              <td style={{ textAlign: 'center' }}>{indexOfLine + 1}.</td>
                              <td>{deliveryLine.bomElementName}</td>
                              <td className="text-right">
                                {convertNumberToNumericString(deliveryLine?.orderLinePrice) +
                                  ' ' +
                                  deliveryLine.orderLinePriceCurrency}
                              </td>

                              <td className="text-right">
                                {(() => {
                                  const orderedAmount: any = values?.orderLines?.find(
                                    (orderLine: actionTypes.TOrderLine) =>
                                      orderLine.bomElementId === deliveryLine.bomElementId
                                  )?.orderedQuantity

                                  return orderedAmount !== null &&
                                    orderedAmount !== undefined &&
                                    orderedAmount !== ''
                                    ? convertQuantityToNumericString(orderedAmount)?.concat(' szt.')
                                    : ''
                                })()}
                              </td>
                              <td>
                                <InputGroup
                                  id={`orderDeliveries.${deliveryIndex}.deliveryLines.${indexOfLine}.expectedQuantity`}
                                >
                                  <ErrorMessage
                                    name={`orderDeliveries.${deliveryIndex}.deliveryLines.${indexOfLine}.expectedQuantity`}
                                    component="span"
                                    className="text-danger input-error-message"
                                  />
                                  <FastField
                                    as={NumberFormat}
                                    displayType="input"
                                    thousandSeparator={globalThousandSeparator}
                                    decimalSeparator={globalDecimalSeparator}
                                    decimalScale={0}
                                    fixedDecimalScale={false}
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                    name={`orderDeliveries.${deliveryIndex}.deliveryLines.${indexOfLine}.expectedQuantity`}
                                    placeholder="Spodziewana"
                                    value={deliveryLine?.expectedQuantity}
                                    onChange={handleChange}
                                    className="form-control text-right"
                                  />

                                  <InputGroupAddon addonType="append">
                                    <InputGroupText
                                      className="disabled-currency"
                                      style={{
                                        width: '45px',
                                        border: '1px solid #d8dbe0',
                                        borderLeft: 'none',
                                      }}
                                    >
                                      szt.
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                              </td>

                              <td className="text-right">
                                {deliveryLine?.receivedQuantity !== null
                                  ? convertQuantityToNumericString(
                                      deliveryLine?.receivedQuantity
                                    )?.concat(' szt.')
                                  : ''}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    ) : (
                      <caption className="item-inner-details-table-caption">
                        {ordersState.fetchOrdersError
                          ? 'Wystąpił błąd podczas pobierania linii!'
                          : 'To zamówienie nie posiada linii!'}
                      </caption>
                    )}
                  </Table>
                  <CButton
                    color="danger"
                    type="button"
                    variant="outline"
                    className="order__delete-delivery-button mt-3"
                    style={{
                      cursor:
                        deliveryIndex < Number(values?.orderDeliveries?.length) - 1
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                    onClick={() => {
                      confirmAlert({
                        closeOnEscape: true,
                        customUI: ({ onClose }) => {
                          return (
                            <ConfirmActionModal
                              mode="delete"
                              onClose={onClose}
                              confirmMessageJSX={
                                <>{`Czy na pewno chcesz usunąć dostawę ${deliveryIndex + 1}?`}</>
                              }
                              onConfirmFunction={() => {
                                history.push(
                                  `/${tenant}/order/${paramsOrderId}/${
                                    deliveryIndex > 0
                                      ? 'delivery-' + deliveryIndex
                                      : 'general-information'
                                  }`
                                )

                                arrayHelpers.remove(deliveryIndex)
                              }}
                            />
                          )
                        },
                      })
                    }}
                    disabled={
                      !authState?.authData?.roles?.includes('Orders_write') ||
                      deliveryIndex < Number(values?.orderDeliveries?.length) - 1
                    }
                  >
                    Usuń dostawę
                  </CButton>
                </CTabPane>
              )
            )}

          {paramsOrderTab !== 'attachments' && (
            <div className="d-flex justify-content-center pt-3">
              {' '}
              {}
              <CButton
                color="info"
                variant="outline"
                className="create-delivery-button"
                onClick={() => {
                  const today = new Date()
                  const newEmptyDelivery: actionTypes.TOrderDelivery = {
                    id: 0,
                    uuid: uuidv4(),
                    status: 0,
                    payment: 0,
                    deliveryCreateDate: toDateInputValue(today.toISOString()),
                    warehouseId: unchosenWarehouseValue,
                    arrivalDate: null,

                    //
                    dispatchDate: null,
                    expectedArrivalDateFrom: null,
                    expectedArrivalDateTo: null,
                    shipmentCost: null,
                    shipmentCostCurrency:
                      settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                      globalCurrencyFallback,
                    //
                    invoices: [
                      {
                        invoiceNumber: '',
                        invoicePrice: null,
                        invoicePriceCurrency:
                          settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                          globalCurrencyFallback,
                        invoiceCreateDate: '',
                        invoicePaymentFinalDate: null,
                        invoiceDeadline: null,
                        invoiceDescription: '',
                        uuid: uuidv4(),
                      },
                    ],

                    //
                    customsCost: null,
                    customsCostCurrency:
                      settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                      globalCurrencyFallback,
                    tax: null,
                    taxCurrency:
                      settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                      globalCurrencyFallback,
                    remainingCosts: null,
                    remainingCostsCurrency:
                      settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                      globalCurrencyFallback,
                    totalDeliveryCost: null,
                    totalDeliveryCostCurrency:
                      settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                      globalCurrencyFallback,
                    //
                    notes: '',

                    deliveryLines:
                      values?.orderLines?.map((orderLine: actionTypes.TOrderLine) => {
                        return {
                          bomElementName: orderLine?.bomElementName || '',
                          bomElementId: orderLine?.bomElementId,
                          orderLinePrice: orderLine?.orderLinePrice || 0,
                          orderLinePriceCurrency: orderLine?.orderLinePriceCurrency,
                          orderedQuantity: orderLine?.orderedQuantity || 0,
                          expectedQuantity: null,
                          receivedQuantity: null,
                          orderLineId: orderLine?.id,
                          id: 0,
                          uuid: uuidv4(),
                        }
                      }) || null,
                  }

                  arrayHelpers.push(newEmptyDelivery)

                  history.push(
                    `/${tenant}/order/${paramsOrderId}/delivery-${
                      Number(values?.orderDeliveries?.length) + 1
                    }`
                  )
                }}
                disabled={
                  !authState?.authData?.roles?.includes('Orders_write') ||
                  Boolean(
                    values?.orderLines?.reduce(
                      (accumulator: number, line: actionTypes.TOrderLine) => {
                        if (line.orderedQuantity) {
                          accumulator++
                        }

                        return accumulator
                      },
                      0
                    ) !== values?.orderLines?.length
                  )
                }
              >
                Stwórz dostawę
              </CButton>
            </div>
          )}
        </>
      )}
    />
  )
}
