import { currencyExchanger } from './currency-exchanger'
import {
  IBomFullElement,
  IBomImplementationCost,
  IBomService,
  ITotalBomCostCalculatorValues,
  TCurrencyCode,
} from '../store/action-types'
import { offlineCurrencyExchangeRates } from '../views/erp'
import { convertNumericStringToNumber } from '.'
import { IAdminState } from '../store/reducers/admin'

export const totalBomCostCalculator = (
  values: ITotalBomCostCalculatorValues,
  adminState: IAdminState,
  mode: 'estimated' | 'offer' | 'order',
  isTotalCostPerUnit: boolean
): number => {
  const exchangeRates = adminState.todayCurrencyExchange
    ? adminState.todayCurrencyExchange.rates
    : offlineCurrencyExchangeRates

  const totalCostCurrencyCode: TCurrencyCode =
    mode === 'estimated'
      ? values?.estimatedTotalBomCostCurrency
      : mode === 'offer'
      ? values?.offerTotalBomCostCurrency
      : values?.orderTotalBomCostCurrency

  return (
    Number(
      values?.bomElementDetails?.reduce((accumulator: number, bomElement: IBomFullElement) => {
        const quantity = bomElement?.bomElementCentimetersInPiece
          ? Number(bomElement?.quantity) / bomElement?.bomElementCentimetersInPiece
          : bomElement?.quantity

        const price =
          mode === 'estimated'
            ? bomElement?.estimatedPrice
            : mode === 'offer'
            ? bomElement.offer?.bomElementPrice || bomElement?.offer?.bomElementPrice
            : bomElement?.lastOrderPrice

        const bomElementCurrency =
          mode === 'estimated'
            ? bomElement?.estimatedPriceCurrency
            : mode === 'offer'
            ? bomElement?.offer?.bomElementPriceCurrency
            : bomElement?.lastOrderPriceCurrency

        const convertedFinalPrice = currencyExchanger(
          Number(convertNumericStringToNumber(price)),
          bomElementCurrency,
          totalCostCurrencyCode,
          exchangeRates
        )

        if (quantity && convertedFinalPrice) {
          return (
            accumulator +
            (isTotalCostPerUnit
              ? 1
              : Number(convertNumericStringToNumber(values?.estimatedQuantity))) *
              Number(convertNumericStringToNumber(quantity)) *
              convertedFinalPrice
            // This price is already converted from currencyExchanger
          )
        } else {
          return accumulator
        }
      }, 0)
    ) +
    /*
     * Here implementation costs are added
     */

    Number(
      values?.bomImplementationCostDetails?.reduce(
        (accumulator: number, implementationCost: IBomImplementationCost) => {
          const implementationCostPrice =
            mode === 'estimated'
              ? implementationCost?.estimatedPrice
              : mode === 'offer'
              ? implementationCost?.offer?.implementationCostPrice
              : implementationCost?.lastOrderPrice

          const implementationCostPriceCurrency =
            mode === 'estimated'
              ? implementationCost?.estimatedPriceCurrency
              : mode === 'offer'
              ? implementationCost?.offer?.implementationCostPriceCurrency
              : implementationCost?.lastOrderPriceCurrency

          const convertedFinalPrice = currencyExchanger(
            Number(convertNumericStringToNumber(implementationCostPrice)),
            implementationCostPriceCurrency,
            totalCostCurrencyCode,
            exchangeRates
          )

          if (
            implementationCost?.isChecked &&
            implementationCost?.quantity &&
            convertedFinalPrice
          ) {
            return (
              accumulator +
              Number(convertNumericStringToNumber(implementationCost.quantity)) *
                convertedFinalPrice
            )
          } else {
            return accumulator
          }
        },
        0
      )
    ) /
      (isTotalCostPerUnit ? Number(convertNumericStringToNumber(values?.estimatedQuantity)) : 1) +
    /*
     * Here services are added
     */
    Number(
      values?.bomServiceDetails?.reduce((accumulator: number, service: IBomService) => {
        const servicePrice =
          mode === 'estimated'
            ? service?.estimatedPrice
            : mode === 'offer'
            ? service?.offer?.servicePrice
            : service?.lastOrderPrice

        const servicePriceCurrency =
          mode === 'estimated'
            ? service?.estimatedPriceCurrency
            : mode === 'offer'
            ? service?.offer?.servicePriceCurrency
            : service?.lastOrderPriceCurrency

        const convertedFinalPrice = currencyExchanger(
          Number(convertNumericStringToNumber(servicePrice)),
          servicePriceCurrency,
          totalCostCurrencyCode,
          exchangeRates
        )

        if (service?.isChecked && service?.quantity && convertedFinalPrice) {
          return (
            accumulator +
            (isTotalCostPerUnit
              ? 1
              : Number(convertNumericStringToNumber(values?.estimatedQuantity))) *
              Number(convertNumericStringToNumber(service.quantity)) *
              convertedFinalPrice
          )
        } else {
          return accumulator
        }
      }, 0)
    )
  )
}
