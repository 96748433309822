import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Container, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CCard, CCardBody, CButton, CLabel, CModal, CModalHeader } from '@coreui/react'
import { ErrorMessage, FastField, Field, FieldArray, Form, Formik } from 'formik'
import { Select } from '@material-ui/core'
import { confirmAlert } from 'react-confirm-alert'
import { cilChevronBottom, cilChevronRight, cilFork, cilObjectGroup } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumericStringToNumber,
  getErrorMessageFromStatus,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  preventNavigationChange,
  successMessageDuration,
} from '../../utils'
import { minimumWidthForWideDetailsView } from '../erp'
import {
  ConfirmActionModal,
  CustomErrorMessage,
  FormActionCancelButton,
  FormActionDeleteButton,
  FormActionSaveButton,
  ThreeDots,
} from '../../components'
import { ManufacturingStepsDetailsBomIntermediateModal } from './manufacturing-steps-details-bom-intermediate-modal'

export const ManufacturingStepsDetails: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { tenant, mode, id } = useParams<{
    tenant: string
    mode: actionTypes.TFormMode
    id: string
  }>()
  const paramsManufacturingStepsDetailsId = id

  const authState = useSelector((state: TRootState) => state.auth)
  const adminState = useSelector((state: TRootState) => state.admin)
  const bomsState = useSelector((state: TRootState) => state.boms)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)

  const [manufacturingStepsDetailsBomIdInState, setManufacturingStepsDetailsBomIdInState] =
    useState<undefined | number>(
      mode === 'create' ? undefined : manufacturingState?.manufacturingStepsDetails?.bomId
    )

  const [bomIntermediateInStepModalVisibility, setBomIntermediateInStepModalVisibility] =
    useState<boolean>(false)
  const [bomIntermediateInStepModalUuid, setBomIntermediateInStepModalUuid] = useState<
    string | null
  >(null)

  const [bomIntermediateModalMode, setBomIntermediateModalMode] = useState<'create' | 'edit'>(
    'create'
  )

  const [addExistingIntermediateModalVisibility, setAddExistingIntermediateModalVisibility] =
    useState<boolean>(false)
  const [addExistingIntermediateModalScopeUuid, setAddExistingIntermediateModalScopeUuid] =
    useState<string | null>(null)
  const [
    addExistingIntermediateToIntermediateFormikPath,
    setAddExistingIntermediateToIntermediateFormikPath,
  ] = useState<string>('')
  const [addExistingIntermediateModalMode, setAddExistingIntermediateModalMode] = useState<
    'step' | 'intermediate'
  >('step')
  const [
    intermediateOrStepNameInAddExistingIntermediateModal,
    setIntermediateOrStepNameInAddExistingIntermediateModal,
  ] = useState<string>('...')
  const [alreadyExistingIntermediateToAdd, setAlreadyExistingIntermediateToAdd] =
    useState<actionTypes.IBomIntermediate | null>(null)

  const [addManufacturingActivityModalVisibility, setAddManufacturingActivityModalVisibility] =
    useState<boolean>(false)
  const [addManufacturingActivityModalScopeUuid, setAddManufacturingActivityModalScopeUuid] =
    useState<string | null>(null)
  const [
    addManufacturingActivityToIntermediateFormikPath,
    setAddManufacturingActivityToIntermediateFormikPath,
  ] = useState<string>('')
  const [addManufacturingActivityModalMode, setAddManufacturingActivityModalMode] = useState<
    'step' | 'intermediate'
  >('step')
  const [
    intermediateOrStepNameInAddManufacturingActivityModal,
    setIntermediateOrStepNameInAddManufacturingActivityModal,
  ] = useState<string>('...')
  const [chosenManufacturingActivityToAdd, setChosenManufacturingActivityToAdd] =
    useState<actionTypes.IManufacturingActivity | null>(null)

  const [initialUnsavedEditedBomIntermediate, setInitialUnsavedEditedBomIntermediate] =
    useState<actionTypes.IBomIntermediate | null>(null)
  const [
    areManufacturingStepsDetailsModifiedAndUnsaved,
    setAreManufacturingStepsDetailsModifiedAndUnsaved,
  ] = useState<boolean>(false)

  const calculateRemainingQuantity = (
    bomElementFromEditableBomTableInModal: actionTypes.IBomElementFromEditableBomTableInModal,
    manufacturingSteps: actionTypes.IManufacturingStep[]
  ) => {
    return (
      Number(
        convertNumericStringToNumber(bomElementFromEditableBomTableInModal.initialQuantityInBom)
      ) -
      manufacturingSteps
        .map((manufacturingStep: actionTypes.IManufacturingStep) =>
          manufacturingStep?.bomIntermediates?.map(
            (bomIntermediate: actionTypes.IBomIntermediate) =>
              Number(
                convertNumericStringToNumber(
                  bomIntermediate?.bomElements?.find(
                    (bomElement: actionTypes.TBomElementInManufacturingStep) =>
                      bomElement?.bomElementId ===
                        bomElementFromEditableBomTableInModal?.bomElementId &&
                      convertNumericStringToNumber(bomElement?.quantity)
                  )?.quantity
                )
              )
          )
        )
        .flat()
        .reduce(
          (previousSum, nextQuantity: number | null) => Number(previousSum) + Number(nextQuantity),
          0
        )
    )
  }

  const nonExistentIntermediateName = 'Półprodukt ...'

  const getNameOfIntermediateFromOriginalSource = (
    bomIntermediate: actionTypes.IBomIntermediate,
    manufacturingSteps: actionTypes.IManufacturingStep[]
  ) =>
    (
      manufacturingSteps
        ?.map((manufacturingStepSearch: actionTypes.IManufacturingStep) =>
          manufacturingStepSearch?.bomIntermediates?.find(
            (bomIntermediateSearch: actionTypes.IBomIntermediate) =>
              bomIntermediate.uuid === bomIntermediateSearch.uuid &&
              !bomIntermediateSearch?.isAddedFromExisting
          )
        )
        .flat()
        .filter((manufacturingStepFilter: any) => !!manufacturingStepFilter)[0] as any
    )?.name || nonExistentIntermediateName

  const areManufacturingStepsDetailsEditable =
    authState?.authData?.roles?.includes('Manufacturing_write') &&
    !manufacturingState?.fetchManufacturingStepsDetailsError

  const closeManufacturingStepsDetails = useCallback(() => {
    history.push(`/${tenant}/manufacturing-steps`)
  }, [history, tenant, paramsManufacturingStepsDetailsId])

  useEffect(() => {
    if (
      areManufacturingStepsDetailsModifiedAndUnsaved &&
      manufacturingState.areManufacturingStepsDetailsEditSaved
    ) {
      setAreManufacturingStepsDetailsModifiedAndUnsaved(false)
    }
  }, [manufacturingState.areManufacturingStepsDetailsEditSaved])

  // Fetch data for Manufacturing Steps Details edit and clean store for Manufacturing Steps Details create
  useEffect(() => {
    if (tenant && authState.authData && paramsManufacturingStepsDetailsId && mode === 'edit') {
      dispatch({
        type: actionTypes.FETCH_MANUFACTURING_STEPS_DETAILS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          id: paramsManufacturingStepsDetailsId,
        },
      })
    } else if (mode === 'create') {
      dispatch({
        type: actionTypes.CLOSE_MANUFACTURING_STEPS_DETAILS,
      })
    }
  }, [dispatch, tenant, authState, paramsManufacturingStepsDetailsId, mode])

  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      paramsManufacturingStepsDetailsId &&
      mode === 'edit' &&
      manufacturingState.manufacturingStepsDetails?.bomId
    ) {
      dispatch({
        type: actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          id: manufacturingState.manufacturingStepsDetails?.bomId,
        },
      })
    }
  }, [
    dispatch,
    tenant,
    authState,
    paramsManufacturingStepsDetailsId,
    mode,
    manufacturingState.manufacturingStepsDetails?.bomId,
  ])

  // Redirect on not found editable Manufacturing Steps Details
  // useEffect(() => {
  //   if (
  //     manufacturingState.fetchManufacturingStepsDetailsError &&
  //     manufacturingState.fetchManufacturingStepsDetailsError.status === 404
  //   ) {
  //     history.replace(`/${tenant}/manufacturing-steps`)
  //   }
  // }, [dispatch, manufacturingState.fetchManufacturingStepsDetailsError, history, tenant])

  // Close details on a successful delete
  useEffect(() => {
    if (
      !manufacturingState.areManufacturingStepsDeleting &&
      !manufacturingState.manufacturingStepsDeleteError &&
      manufacturingState.areManufacturingStepsDeleted
    ) {
      setTimeout(() => {
        closeManufacturingStepsDetails()
      }, successMessageDuration)
    }
  }, [
    manufacturingState.areManufacturingStepsDeleting,
    manufacturingState.manufacturingStepsDeleteError,
    manufacturingState.areManufacturingStepsDeleted,
    closeManufacturingStepsDetails,
  ])

  // Close details on a successful create
  useEffect(() => {
    if (
      !manufacturingState.areManufacturingStepsCreating &&
      !manufacturingState.manufacturingStepsCreateError &&
      manufacturingState.areManufacturingStepsCreated
    ) {
      setTimeout(() => {
        closeManufacturingStepsDetails()
      }, successMessageDuration)
    }
  }, [
    manufacturingState.areManufacturingStepsCreating,
    manufacturingState.manufacturingStepsCreateError,
    manufacturingState.areManufacturingStepsCreated,
    closeManufacturingStepsDetails,
  ])

  // Track the changes again
  useEffect(() => {
    if (
      manufacturingState.areManufacturingStepsDetailsEditSaving ||
      manufacturingState.areManufacturingStepsCreating
    ) {
      setAreManufacturingStepsDetailsModifiedAndUnsaved(false)
    }

    if (
      manufacturingState.areManufacturingStepsDetailsEditSaved ||
      manufacturingState.areManufacturingStepsCreated ||
      manufacturingState.areManufacturingStepsDeleted
    ) {
      setTimeout(() => {
        setAreManufacturingStepsDetailsModifiedAndUnsaved(false)
      }, successMessageDuration)
    }

    if (
      manufacturingState.manufacturingStepsDetailsEditSaveError ||
      manufacturingState.manufacturingStepsCreateError
    ) {
      setAreManufacturingStepsDetailsModifiedAndUnsaved(true)
    }
  }, [
    manufacturingState.areManufacturingStepsDetailsEditSaving,
    manufacturingState.areManufacturingStepsCreating,
    manufacturingState.areManufacturingStepsDetailsEditSaved,
    manufacturingState.areManufacturingStepsCreated,
    manufacturingState.areManufacturingStepsDeleted,
    manufacturingState.manufacturingStepsDetailsEditSaveError,
    manufacturingState.manufacturingStepsCreateError,
  ])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      areManufacturingStepsDetailsModifiedAndUnsaved,
      areManufacturingStepsDetailsEditable,
      'manufacturing-steps/',
      paramsManufacturingStepsDetailsId,
      ''
    )
    // Without pathname in location there is no tab change detection
  }, [
    location.pathname,
    history,
    areManufacturingStepsDetailsModifiedAndUnsaved,
    areManufacturingStepsDetailsEditable,
    paramsManufacturingStepsDetailsId,
  ])

  // Unmount component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_MANUFACTURING_STEPS_DETAILS,
      })
    }
  }, [])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard className="manufacturing-steps-details">
        <CCardBody className="p-4" style={{ maxWidth: '595px' }}>
          <Formik
            initialValues={{
              manufacturingStepsDetailsBomId:
                mode === 'create'
                  ? manufacturingStepsDetailsBomIdInState
                  : manufacturingState?.manufacturingStepsDetails?.bomId,
              editableBomForManufacturingSteps: manufacturingState?.bomForManufacturingSteps
                ? {
                    ...manufacturingState.bomForManufacturingSteps,
                    bomElementDetails:
                      manufacturingState?.bomForManufacturingSteps?.bomElementDetails.map(
                        (bomElement: actionTypes.IBomFullElement) => ({
                          ...bomElement,
                          category: actionTypes.OBomElementCategory[bomElement.category],
                          initialQuantityInBom: bomElement.quantity,
                          checkbox: false,
                        })
                      ),
                  }
                : undefined,
              manufacturingSteps:
                mode === 'create'
                  ? []
                  : manufacturingState?.manufacturingStepsDetails?.manufacturingSteps || [],
              radioInputInAddExistingIntermediateModal: null,
              radioInputInAddManufacturingActivityModal: null,
            }}
            onSubmit={(values) => {
              if (areManufacturingStepsDetailsEditable) {
                const {
                  manufacturingStepsDetailsBomId,
                  editableBomForManufacturingSteps,
                  radioInputInAddExistingIntermediateModal,
                  radioInputInAddManufacturingActivityModal,
                  ...modifiedValues
                } = values

                dispatch({
                  type:
                    mode === 'create'
                      ? actionTypes.CREATE_MANUFACTURING_STEPS_REQUESTED
                      : actionTypes.EDIT_MANUFACTURING_STEPS_DETAILS_REQUESTED,
                  payload: {
                    tenant: tenant,
                    token: authState.authData?.token,
                    manufacturingSteps: {
                      ...modifiedValues,
                      bomId: values.manufacturingStepsDetailsBomId,
                      uuid:
                        mode === 'create'
                          ? uuidv4()
                          : manufacturingState?.manufacturingStepsDetails?.uuid,
                      manufacturingSteps: modifiedValues?.manufacturingSteps?.map(
                        (manufacturingStep: actionTypes.IManufacturingStep) => {
                          return {
                            ...manufacturingStep,
                            bomIntermediates: manufacturingStep.bomIntermediates.map(
                              (bomIntermediate: actionTypes.IBomIntermediate) => {
                                return {
                                  ...bomIntermediate,
                                  bomElements: bomIntermediate.bomElements
                                    .map(
                                      (
                                        bomElementInBomIntermediate: actionTypes.TBomElementInManufacturingStep
                                      ) => {
                                        if (bomElementInBomIntermediate.quantity) {
                                          return {
                                            ...bomElementInBomIntermediate,
                                            quantity: convertNumericStringToNumber(
                                              bomElementInBomIntermediate.quantity
                                            ),
                                          }
                                        } else {
                                          return null
                                        }
                                      }
                                    )
                                    .filter(
                                      (bomElementInBomIntermediate: any) =>
                                        !!bomElementInBomIntermediate
                                    ),
                                }
                              }
                            ),
                          }
                        }
                      ),
                    },
                  },
                })
              }
            }}
            enableReinitialize={true}
          >
            {({ values, errors, resetForm, handleChange, setFieldValue, setErrors }) => (
              <Form
                onChange={() => {
                  setAreManufacturingStepsDetailsModifiedAndUnsaved(true)
                }}
              >
                {authState?.authData?.roles?.includes('Manufacturing_write') && (
                  <div
                    className={`form-fixed-panel-in-header form-fixed-panel-in-header--manufacturing-steps-details 
                    ${
                      adminState.sidebarVisibility === 'responsive' && !adminState.sidebarMinimize
                        ? 'form-fixed-panel-in-header--manufacturing-steps-details-smaller-screen-with-sidebar'
                        : ''
                    } ${
                      adminState.sidebarMinimize && adminState.sidebarVisibility
                        ? 'form-fixed-panel-in-header--manufacturing-steps-details-smaller-screen-with-sidebar-minimized'
                        : ''
                    }
                    `}
                    // Two classes aboove are not yet implemented, maybe not needed
                  >
                    <h4 className="form-fixed-panel-in-header-title text-center font-weight-light mb-0">
                      <strong>{mode === 'edit' ? '' : 'Nowe'} Etapy produkcji</strong>
                    </h4>

                    <div>
                      {mode === 'edit' && (
                        <FormActionDeleteButton
                          isSaving={
                            manufacturingState.areManufacturingStepsDetailsLoading ||
                            manufacturingState.areManufacturingStepsDetailsEditSaving
                          }
                          isSaved={manufacturingState.areManufacturingStepsDetailsEditSaved}
                          isDeleting={manufacturingState.areManufacturingStepsDeleting}
                          isDeleted={manufacturingState.areManufacturingStepsDeleted}
                          deleteAction={actionTypes.DELETE_MANUFACTURING_STEPS_REQUESTED}
                          deletePayload={{
                            tenant: tenant,
                            token: authState.authData?.token,
                            id: Number(paramsManufacturingStepsDetailsId),
                          }}
                          phrasesInDelete={undefined}
                          setIsOneFieldChanged={undefined}
                          canDelete={
                            !manufacturingState.manufacturingStepsDetails
                              ? false
                              : manufacturingState?.manufacturingStepsDetails?.canDelete
                          }
                          disabledDeleteButtonClassNames="delete-details-button"
                          confirmDeleteMessageJSX={
                            <>
                              Czy na pewno chcesz usunąć etapy produkcji?
                              <>
                                <br />
                                <strong>
                                  {manufacturingState?.manufacturingStepsDetails?.bomName || '...'}
                                </strong>
                              </>
                            </>
                          }
                        />
                      )}

                      <FormActionCancelButton
                        closeFunction={() => {
                          resetForm()
                          closeManufacturingStepsDetails()
                        }}
                        closeAction={''}
                      />

                      {areManufacturingStepsDetailsEditable && (
                        <FormActionSaveButton
                          mode={mode}
                          isSaving={
                            mode === 'edit'
                              ? manufacturingState.areManufacturingStepsDetailsEditSaving
                              : manufacturingState.areManufacturingStepsCreating
                          }
                          isSaved={
                            mode === 'edit'
                              ? manufacturingState.areManufacturingStepsDetailsEditSaved
                              : manufacturingState.areManufacturingStepsCreated
                          }
                          isDeleting={manufacturingState.areManufacturingStepsDeleting}
                          isDeleted={manufacturingState.areManufacturingStepsDeleted}
                          formErrorsBool={false}
                          setDidFormValidationOccur={null}
                          didFormValidationOccur={false}
                          canSave={areManufacturingStepsDetailsEditable}
                        />
                      )}
                    </div>
                  </div>
                )}

                {/*
                 * Display Network Error Messages
                 */}

                {manufacturingState?.fetchBomForManufacturingStepsError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'fetch',
                      manufacturingState?.fetchBomForManufacturingStepsError?.status,
                      'produktu'
                    )}
                  />
                )}

                {manufacturingState?.fetchManufacturingActivitiesError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'fetch',
                      manufacturingState?.fetchManufacturingActivitiesError?.status,
                      'czynności'
                    )}
                  />
                )}

                {manufacturingState?.fetchAllManufacturingStepsError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'fetch',
                      manufacturingState?.fetchAllManufacturingStepsError?.status,
                      'listy etapów produkcji'
                    )}
                  />
                )}

                {manufacturingState?.manufacturingStepsCreateError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'create',
                      manufacturingState?.manufacturingStepsCreateError?.status,
                      'etapów produkcji'
                    )}
                  />
                )}

                {manufacturingState?.manufacturingStepsDetailsEditSaveError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'edit',
                      manufacturingState?.manufacturingStepsDetailsEditSaveError?.status,
                      'etapów produkcji'
                    )}
                  />
                )}

                {manufacturingState?.manufacturingStepsDeleteError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'delete',
                      manufacturingState?.manufacturingStepsDeleteError?.status,
                      'etapów produkcji'
                    )}
                  />
                )}

                {mode === 'edit' && manufacturingState?.fetchManufacturingStepsDetailsError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'fetch',
                      manufacturingState?.fetchManufacturingStepsDetailsError?.status,
                      'etapów produkcji'
                    )}
                  />
                )}

                {/*
                 * Choose BOM/Product
                 */}

                <div>
                  <div className="d-flex justify-content-evenly align-items-center">
                    <div style={{ maxWidth: '250px' }}>
                      <CLabel
                        htmlFor="manufacturing-steps-bom-product-id-selector"
                        className={`${inputLabelSpacingBottom}`}
                      >
                        Produkt
                      </CLabel>
                      <InputGroup
                        id="manufacturing-steps-bom-product-id-selector"
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={
                              errors.manufacturingStepsDetailsBomId &&
                              'text-danger input-error-icon'
                            }
                          >
                            <i className="cil-storage"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <ErrorMessage
                          name="manufacturingStepsDetailsBomId"
                          component="span"
                          className="text-danger input-error-message"
                        />
                        <Field
                          as={Select}
                          name="manufacturingStepsDetailsBomId"
                          variant="outlined"
                          native
                          value={String(values.manufacturingStepsDetailsBomId)}
                          className={`item-selector product-selector ${
                            values?.manufacturingStepsDetailsBomId !== undefined
                              ? ''
                              : 'element-selector-placeholder'
                          }`}
                          style={{ minWidth: '200px' }}
                          disabled={mode === 'edit'}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            if (event?.target?.value) {
                              const changeManufacturingStepsDetailsBomId = (
                                selectedBomId: number
                              ) => {
                                handleChange(event)

                                setManufacturingStepsDetailsBomIdInState(selectedBomId)

                                setAreManufacturingStepsDetailsModifiedAndUnsaved(false)

                                resetForm()

                                if (errors) {
                                  setErrors({})
                                }

                                // This is important to change the displayed value in select
                                event.target.value = String(selectedBomId)

                                dispatch({
                                  type: actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_REQUESTED,
                                  payload: {
                                    tenant: tenant,
                                    token: authState.authData?.token,
                                    id: selectedBomId,
                                  },
                                })
                              }

                              if (
                                areManufacturingStepsDetailsModifiedAndUnsaved &&
                                values.manufacturingSteps.length
                              ) {
                                confirmAlert({
                                  closeOnEscape: true,
                                  // This is a hack, message is the new selected fromBomId passed down to scope
                                  message: event?.target?.value,
                                  customUI: ({ onClose, message }) => {
                                    return (
                                      <ConfirmActionModal
                                        mode="selectOther"
                                        onClose={onClose}
                                        confirmMessageJSX={
                                          <>
                                            <strong>Masz niezapisane zmiany!</strong>
                                            <br />
                                            Czy na pewno chcesz zmienić produkt na <br />
                                            <strong>
                                              {bomsState?.boms
                                                ?.find(
                                                  (bom: actionTypes.TBomLight) =>
                                                    Number(message) === bom.id
                                                )
                                                ?.name?.substring(0, 40) || 'produkt bez nazwy'}
                                            </strong>
                                            ?
                                          </>
                                        }
                                        onConfirmFunction={() => {
                                          changeManufacturingStepsDetailsBomId(Number(message))
                                        }}
                                      />
                                    )
                                  },
                                })
                              } else {
                                changeManufacturingStepsDetailsBomId(Number(event?.target?.value))
                              }
                            } else {
                              handleChange(event)
                              event.target.value = ''
                              setFieldValue('manufacturingStepsDetailsBomId', '')
                              setFieldValue('editableBomForManufacturingSteps', null)
                              resetForm()
                            }
                          }}
                        >
                          <option
                            key={`bom-select-option-placeholder`}
                            value={undefined}
                            className="select-option-placeholder"
                            disabled={!!values?.manufacturingStepsDetailsBomId}
                          >
                            {bomsState.areBomsLoading
                              ? () => 'Ładowanie...'
                              : mode === 'edit'
                              ? 'Produkt'
                              : 'Wybierz produkt'}
                          </option>

                          {bomsState?.boms?.map((bom: actionTypes.TBomLight, bomIndex: number) => {
                            return (
                              <option
                                key={`bom-select-option-${bom.id}-${bomIndex}`}
                                value={bom.id}
                                disabled={
                                  !!manufacturingState.allManufacturingSteps.find(
                                    (singleManufacturingSteps: any) =>
                                      singleManufacturingSteps.bomId === bom.id
                                  )
                                }
                                className="manufacturing-steps-product-select-list-option"
                                style={{ cursor: 'not-allowed' }}
                              >
                                {bom?.name || '...'}
                              </option>
                            )
                          })}
                        </Field>
                      </InputGroup>
                    </div>
                    {manufacturingState?.isBomForManufacturingStepsLoading && (
                      <div className="ml-2 mt-1 pt-1">
                        <Spinner color="#d8dbe0" size="sm" />
                      </div>
                    )}
                  </div>

                  <hr />
                  {mode === 'edit' &&
                  (manufacturingState.isBomForManufacturingStepsLoading ||
                    manufacturingState.areManufacturingStepsDetailsLoading) ? (
                    <div
                      style={{
                        height: '360px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <FieldArray
                      name="manufacturingSteps"
                      render={(manufacturingStepsListArrayHelpers) => (
                        <>
                          <div>
                            {manufacturingState?.isBomForManufacturingStepsLoading && (
                              <div className="d-flex justify-content-center">
                                Wczytywanie wybranego produktu
                                <ThreeDots />
                              </div>
                            )}

                            {!values?.editableBomForManufacturingSteps &&
                              !manufacturingState?.isBomForManufacturingStepsLoading &&
                              !manufacturingState?.fetchManufacturingStepsDetailsError && (
                                <div className="d-flex justify-content-center">
                                  {mode === 'edit' ||
                                  manufacturingState.manufacturingStepsDetails?.manufacturingSteps
                                    ? 'Brak etapów w bazie!'
                                    : 'Najpierw wybierz produkt!'}
                                </div>
                              )}

                            {!values?.manufacturingSteps.length &&
                              values?.editableBomForManufacturingSteps &&
                              !manufacturingState?.isBomForManufacturingStepsLoading && (
                                <div className="d-flex justify-content-center">
                                  Dodaj pierwszy etap
                                </div>
                              )}

                            {manufacturingState?.fetchManufacturingStepsDetailsError && (
                              <div className="d-flex justify-content-center">
                                Wystąpił błąd podczas pobierania etapów!
                              </div>
                            )}

                            <ol className="manufacturing-steps-list">
                              {values?.manufacturingSteps?.map(
                                (
                                  manufacturingStep: actionTypes.IManufacturingStep,
                                  indexOfManufacturingStep: number
                                ) => (
                                  <li
                                    key={manufacturingStep.uuid}
                                    className="manufacturing-steps-list-step-item"
                                    id={`manufacturing-step-id-${manufacturingStep.uuid}`}
                                  >
                                    <div className="manufacturing-steps-list-step-item-header">
                                      <div className="d-flex align-items-center">
                                        <CIcon
                                          className={`chevron-icon ${
                                            manufacturingStep.bomIntermediates.length ||
                                            manufacturingStep.manufacturingActivities.length
                                              ? 'chevron-icon-with-elements'
                                              : 'chevron-icon-without-elements'
                                          }`}
                                          style={{
                                            cursor:
                                              manufacturingStep.bomIntermediates.length ||
                                              manufacturingStep.manufacturingActivities.length
                                                ? 'pointer'
                                                : 'default',
                                          }}
                                          icon={
                                            manufacturingStep.bomIntermediates.length ||
                                            manufacturingStep.manufacturingActivities.length
                                              ? cilChevronBottom
                                              : cilChevronRight
                                          }
                                          onClick={() => {
                                            if (
                                              manufacturingStep.bomIntermediates.length ||
                                              manufacturingStep.manufacturingActivities.length
                                            ) {
                                              const manufacturingStepElement =
                                                document.getElementById(
                                                  `manufacturing-step-id-${manufacturingStep.uuid}`
                                                )
                                              const nameOfHiddenClass = 'hidden-items-in-step'

                                              if (manufacturingStepElement) {
                                                if (
                                                  !manufacturingStepElement?.classList?.contains(
                                                    nameOfHiddenClass
                                                  )
                                                ) {
                                                  manufacturingStepElement?.classList?.add(
                                                    nameOfHiddenClass
                                                  )
                                                } else {
                                                  manufacturingStepElement?.classList?.remove(
                                                    nameOfHiddenClass
                                                  )
                                                }
                                              }
                                            } else {
                                              return null
                                            }
                                          }}
                                        />

                                        <FastField
                                          as={Input}
                                          name={`manufacturingSteps.${indexOfManufacturingStep}.name`}
                                          value={manufacturingStep.name}
                                          type="text"
                                          autoComplete={null}
                                          maxLength={125}
                                          className="manufacturing-steps-list-step-item-title remove-focus-on-input"
                                        />
                                      </div>

                                      {/*
                                       * Step Button Icons Begin Here
                                       */}

                                      <div className="manufacturing-step-title-header-icons-wrapper">
                                        {/*
                                         * Add Another Step Below the Chosen One
                                         */}

                                        <CButton
                                          color="info"
                                          type="button"
                                          variant="outline"
                                          onClick={() => {
                                            let newManufacturingStep: actionTypes.IManufacturingStep =
                                              {
                                                name: `Etap ${indexOfManufacturingStep + 2}`,
                                                bomIntermediates: [],
                                                manufacturingActivities: [],
                                                uuid: uuidv4(),
                                              }

                                            manufacturingStepsListArrayHelpers.insert(
                                              indexOfManufacturingStep + 1,
                                              newManufacturingStep
                                            )
                                          }}
                                          className="list-action-button manufacturing-step-add-next-step-list-item-button"
                                        >
                                          <i className="cil-list-low-priority"></i>
                                        </CButton>

                                        {/*
                                         * Prepare Bom Intermediate with Elements
                                         */}

                                        <CButton
                                          color="info"
                                          type="button"
                                          variant="outline"
                                          onClick={() => {
                                            const manufacturingStepElement =
                                              document.getElementById(
                                                `manufacturing-step-id-${manufacturingStep.uuid}`
                                              )

                                            const nameOfHiddenClass = 'hidden-items-in-step'

                                            if (manufacturingStepElement) {
                                              if (
                                                manufacturingStepElement?.classList?.contains(
                                                  nameOfHiddenClass
                                                )
                                              ) {
                                                manufacturingStepElement?.classList?.remove(
                                                  nameOfHiddenClass
                                                )
                                              }
                                            }

                                            setFieldValue(
                                              `editableBomForManufacturingSteps.bomElementDetails`,
                                              values?.editableBomForManufacturingSteps?.bomElementDetails?.map(
                                                (
                                                  bomElement: actionTypes.IBomElementFromEditableBomTableInModal
                                                ) => ({
                                                  ...bomElement,
                                                  checkbox: false,
                                                })
                                              )
                                            )

                                            setBomIntermediateModalMode('create')

                                            setBomIntermediateInStepModalVisibility(true)

                                            const bomElementsForIntermediate:
                                              | actionTypes.TBomElementInManufacturingStep[]
                                              | undefined =
                                              values?.editableBomForManufacturingSteps?.bomElementDetails?.map(
                                                (
                                                  bomElement: actionTypes.IBomFullElement,
                                                  bomElementIndex: number
                                                ) => {
                                                  return {
                                                    bomElementName: bomElement.bomElementName,
                                                    bomElementId: bomElement.bomElementId,
                                                    quantity: '',
                                                    index: bomElementIndex,
                                                  }
                                                }
                                              )

                                            const newUuid = uuidv4()

                                            setFieldValue(
                                              `manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates`,
                                              [
                                                ...manufacturingStep.bomIntermediates,
                                                {
                                                  name: `Półprodukt ${
                                                    manufacturingStep.bomIntermediates.length + 1
                                                  }`,

                                                  bomElements: bomElementsForIntermediate,
                                                  bomIntermediates: [],

                                                  index: manufacturingStep.bomIntermediates.length,
                                                  uuid: newUuid,
                                                },
                                              ]
                                            )

                                            setBomIntermediateInStepModalUuid(newUuid)
                                          }}
                                          className="list-action-button manufacturing-step-prepare-intermediate-list-item-button ml-2"
                                        >
                                          <i className="cil-library-add"></i>
                                        </CButton>

                                        {/*
                                         * Add Existing Intermediate to Step
                                         */}

                                        <CButton
                                          color="info"
                                          type="button"
                                          variant="outline"
                                          onClick={() => {
                                            setAddExistingIntermediateModalVisibility(true)

                                            setAddExistingIntermediateModalScopeUuid(
                                              manufacturingStep.uuid
                                            )

                                            setAddExistingIntermediateModalMode('step')

                                            setIntermediateOrStepNameInAddExistingIntermediateModal(
                                              manufacturingStep.name
                                            )
                                          }}
                                          className="list-action-button manufacturing-step-add-existing-intermediate-list-item-button mx-2"
                                          disabled={
                                            !values?.manufacturingSteps?.some(
                                              (manufacturingStep: actionTypes.IManufacturingStep) =>
                                                manufacturingStep.bomIntermediates.length > 0
                                            )
                                          }
                                        >
                                          <i className="cil-object-group"></i>
                                        </CButton>

                                        {/*
                                         * Add Manufacturing Activity to Step
                                         */}

                                        <CButton
                                          color="info"
                                          type="button"
                                          variant="outline"
                                          onClick={() => {
                                            setAddManufacturingActivityModalVisibility(true)

                                            setAddManufacturingActivityModalScopeUuid(
                                              manufacturingStep.uuid
                                            )

                                            setAddManufacturingActivityModalMode('step')

                                            setIntermediateOrStepNameInAddManufacturingActivityModal(
                                              manufacturingStep.name
                                            )
                                          }}
                                          className={`list-action-button mr-2 ${
                                            manufacturingState?.manufacturingActivities.length
                                              ? 'manufacturing-step-add-manufacturing-activity-list-item-button'
                                              : 'manufacturing-step-add-manufacturing-activity-list-item-button--no-activities'
                                          }`}
                                          disabled={
                                            !manufacturingState?.manufacturingActivities.length
                                          }
                                        >
                                          <i className="cil-fork"></i>
                                        </CButton>

                                        {/*
                                         * Delete Whole Step
                                         */}

                                        <CButton
                                          color="danger"
                                          type="button"
                                          variant="outline"
                                          onClick={() => {
                                            if (manufacturingStep?.bomIntermediates.length) {
                                              confirmAlert({
                                                closeOnEscape: true,
                                                customUI: ({ onClose }) => {
                                                  return (
                                                    <ConfirmActionModal
                                                      mode="delete"
                                                      onClose={onClose}
                                                      confirmMessageJSX={
                                                        <>
                                                          Czy na pewno chcesz usunąć{' '}
                                                          <strong>
                                                            {manufacturingStep?.name || ''}
                                                          </strong>
                                                          ?
                                                          <br />
                                                          Etap zawiera półprodukty i odniesienia,
                                                          które zostaną wraz z nim usunięte.
                                                        </>
                                                      }
                                                      onConfirmFunction={() => {
                                                        const removedSameIntermediatesFromExistingInManufacturingSteps =
                                                          values?.manufacturingSteps?.map(
                                                            (
                                                              manufacturingStepExistingIntermediateRemove: actionTypes.IManufacturingStep
                                                            ) => {
                                                              return {
                                                                ...manufacturingStepExistingIntermediateRemove,
                                                                bomIntermediates: (
                                                                  manufacturingStepExistingIntermediateRemove?.bomIntermediates?.map(
                                                                    (
                                                                      bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                    ) => {
                                                                      if (
                                                                        manufacturingStep?.bomIntermediates?.some(
                                                                          (
                                                                            bomIntermediateToRemoveBasedOnRemovedStep: actionTypes.IBomIntermediate
                                                                          ) => {
                                                                            return (
                                                                              bomIntermediateToRemove.uuid !==
                                                                                bomIntermediateToRemoveBasedOnRemovedStep.uuid &&
                                                                              !bomIntermediateToRemove?.isAddedFromExisting
                                                                            )
                                                                          }
                                                                        )
                                                                      ) {
                                                                        return {
                                                                          ...bomIntermediateToRemove,
                                                                          bomIntermediates:
                                                                            // Second layer nesting of bomIntermediate in bomIntermediate
                                                                            (
                                                                              bomIntermediateToRemove?.bomIntermediates?.map(
                                                                                (
                                                                                  bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                                ) =>
                                                                                  manufacturingStep?.bomIntermediates?.some(
                                                                                    (
                                                                                      bomIntermediateToRemoveBasedOnRemovedStep: actionTypes.IBomIntermediate
                                                                                    ) =>
                                                                                      bomIntermediateToRemove.uuid !==
                                                                                        bomIntermediateToRemoveBasedOnRemovedStep.uuid &&
                                                                                      !bomIntermediateToRemove?.isAddedFromExisting
                                                                                  )
                                                                                    ? bomIntermediateToRemove
                                                                                    : null
                                                                              ) as any
                                                                            )?.filter(
                                                                              (
                                                                                bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                              ) =>
                                                                                !!bomIntermediateToRemove
                                                                            ),
                                                                        }
                                                                      } else {
                                                                        return null
                                                                      }
                                                                    }
                                                                  ) as any
                                                                )?.filter(
                                                                  (
                                                                    bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                  ) => !!bomIntermediateToRemove
                                                                ),
                                                              }
                                                            }
                                                          )

                                                        setFieldValue(
                                                          'manufacturingSteps',
                                                          removedSameIntermediatesFromExistingInManufacturingSteps
                                                        )

                                                        manufacturingStepsListArrayHelpers.remove(
                                                          indexOfManufacturingStep
                                                        )
                                                      }}
                                                    />
                                                  )
                                                },
                                              })
                                            } else {
                                              manufacturingStepsListArrayHelpers.remove(
                                                indexOfManufacturingStep
                                              )
                                            }
                                          }}
                                          className="select-option-remove-button"
                                        >
                                          <i className="cil-trash"></i>
                                        </CButton>
                                      </div>
                                    </div>

                                    {/*
                                     * List of Manufacturing Activities in Step
                                     */}

                                    {manufacturingStep?.manufacturingActivities.length > 0 && (
                                      <FieldArray
                                        name={`manufacturingSteps.${indexOfManufacturingStep}.manufacturingActivities`}
                                        render={(
                                          manufacturingStepBomIntermediatesListArrayHelpers
                                        ) => (
                                          <div className="manufacturing-step-manufacturing-activities-list-wrapper">
                                            <ol className="manufacturing-step-manufacturing-activities-list">
                                              {manufacturingStep?.manufacturingActivities?.map(
                                                (
                                                  manufacturingActivityInStep: actionTypes.IManufacturingActivity,
                                                  indexOfManufacturingActivityInStep: number
                                                ) => (
                                                  <li
                                                    key={`${manufacturingStep?.uuid}-${manufacturingActivityInStep?.id}-${indexOfManufacturingActivityInStep}-${manufacturingActivityInStep?.name}`}
                                                    id={`manufacturing-activity-id-${manufacturingStep?.uuid}-${manufacturingActivityInStep?.id}-${indexOfManufacturingActivityInStep}-${manufacturingActivityInStep?.name}`}
                                                    className="manufacturing-step-manufacturing-activity-list-item"
                                                  >
                                                    <div className="manufacturing-steps-list-manufacturing-activity-item-header">
                                                      <div className="d-flex align-items-center">
                                                        <CIcon
                                                          className="chevron-icon"
                                                          icon={cilFork}
                                                          style={{
                                                            marginBottom: 0,
                                                            cursor: 'default',
                                                          }}
                                                          onClick={() => null}
                                                        />

                                                        <div className="manufacturing-steps-list-manufacturing-activity-item-title">
                                                          <Field
                                                            as={Input}
                                                            name={`manufacturingSteps.${indexOfManufacturingStep}.manufacturingActivities.${indexOfManufacturingActivityInStep}.name`}
                                                            value={manufacturingActivityInStep.name}
                                                            type="text"
                                                            autoComplete={null}
                                                            maxLength={125}
                                                            className="manufacturing-steps-list-manufacturing-activity-item-title remove-focus-on-input"
                                                            onClick={null}
                                                            onChange={() => null}
                                                          />
                                                        </div>
                                                      </div>

                                                      {/*
                                                       * Icons in Intermediate Begin Here | use bomIntermediate here as list item
                                                       */}

                                                      <div className="manufacturing-step-manufacturing-activity-icons-wrapper">
                                                        {/*
                                                  
                                                      {/*
                                                       * Delete Whole Intermediate
                                                       */}

                                                        <CButton
                                                          color="danger"
                                                          type="button"
                                                          variant="outline"
                                                          onClick={() => {
                                                            confirmAlert({
                                                              closeOnEscape: true,
                                                              customUI: ({ onClose }) => {
                                                                return (
                                                                  <ConfirmActionModal
                                                                    mode="delete"
                                                                    onClose={onClose}
                                                                    confirmMessageJSX={
                                                                      <>
                                                                        <div className="mb-2">
                                                                          (
                                                                          {manufacturingStep?.name ||
                                                                            'Etap ...'}
                                                                          )
                                                                        </div>
                                                                        Czy na pewno chcesz usunąć
                                                                        czynność <br />
                                                                        <strong>
                                                                          {manufacturingActivityInStep?.name ||
                                                                            '...'}
                                                                        </strong>
                                                                        ?
                                                                      </>
                                                                    }
                                                                    onConfirmFunction={() => {
                                                                      manufacturingStepBomIntermediatesListArrayHelpers.remove(
                                                                        indexOfManufacturingActivityInStep
                                                                      )
                                                                    }}
                                                                  />
                                                                )
                                                              },
                                                            })
                                                          }}
                                                          className="select-option-remove-button"
                                                        >
                                                          <i className="cil-trash"></i>
                                                        </CButton>
                                                      </div>
                                                    </div>
                                                  </li>
                                                )
                                              )}
                                            </ol>
                                          </div>
                                        )}
                                      />
                                    )}

                                    {/*
                                     * List of Intermediates in Step Begins Here
                                     */}

                                    {manufacturingStep?.bomIntermediates.length > 0 && (
                                      <FieldArray
                                        name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates`}
                                        render={(
                                          manufacturingStepBomIntermediatesListArrayHelpers
                                        ) => (
                                          <div className="manufacturing-step-intermediates-list-wrapper">
                                            <ol className="manufacturing-step-intermediates-list">
                                              {manufacturingStep?.bomIntermediates?.map(
                                                (
                                                  bomIntermediate: actionTypes.IBomIntermediate,
                                                  indexOfBomIntermediate: number
                                                ) => (
                                                  <li
                                                    key={`${manufacturingStep?.uuid}-${bomIntermediate?.uuid}-${indexOfBomIntermediate}-${bomIntermediate?.isAddedFromExisting}`}
                                                    id={`intermediate-id-${manufacturingStep?.uuid}-${bomIntermediate?.uuid}-${indexOfBomIntermediate}-${bomIntermediate?.isAddedFromExisting}`}
                                                    className="manufacturing-step-intermediate-list-item"
                                                  >
                                                    <div className="manufacturing-steps-list-intermediate-item-header">
                                                      <div className="d-flex align-items-center">
                                                        <CIcon
                                                          className="chevron-icon"
                                                          icon={
                                                            bomIntermediate?.isAddedFromExisting
                                                              ? cilObjectGroup
                                                              : cilChevronBottom
                                                          }
                                                          style={{
                                                            marginBottom:
                                                              bomIntermediate?.isAddedFromExisting
                                                                ? '2px'
                                                                : '0',
                                                            cursor:
                                                              !bomIntermediate?.isAddedFromExisting
                                                                ? 'pointer'
                                                                : 'default',
                                                          }}
                                                          onClick={() => {
                                                            if (
                                                              !bomIntermediate?.isAddedFromExisting
                                                            ) {
                                                              const manufacturingStepElement =
                                                                document.getElementById(
                                                                  `intermediate-id-${manufacturingStep?.uuid}-${bomIntermediate?.uuid}-${indexOfBomIntermediate}-${bomIntermediate?.isAddedFromExisting}`
                                                                )
                                                              const nameOfHiddenClass =
                                                                'hidden-elements-in-intermediate'

                                                              if (manufacturingStepElement) {
                                                                if (
                                                                  !manufacturingStepElement?.classList?.contains(
                                                                    nameOfHiddenClass
                                                                  )
                                                                ) {
                                                                  manufacturingStepElement?.classList?.add(
                                                                    nameOfHiddenClass
                                                                  )
                                                                } else {
                                                                  manufacturingStepElement?.classList?.remove(
                                                                    nameOfHiddenClass
                                                                  )
                                                                }
                                                              }
                                                            } else {
                                                              return null
                                                            }
                                                          }}
                                                        />

                                                        {bomIntermediate?.isAddedFromExisting ? (
                                                          <div className="manufacturing-steps-list-intermediate-item-title">
                                                            <Field
                                                              as={Input}
                                                              name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.name`}
                                                              value={getNameOfIntermediateFromOriginalSource(
                                                                bomIntermediate,
                                                                values.manufacturingSteps
                                                              )}
                                                              type="text"
                                                              autoComplete={null}
                                                              maxLength={125}
                                                              className="manufacturing-steps-list-intermediate-item-title remove-focus-on-input"
                                                              onClick={null}
                                                              onChange={() => null}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <Field
                                                            as={Input}
                                                            name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.name`}
                                                            value={bomIntermediate?.name}
                                                            type="text"
                                                            autoComplete={null}
                                                            maxLength={125}
                                                            className="manufacturing-steps-list-intermediate-item-title remove-focus-on-input"
                                                            onChange={handleChange}
                                                          />
                                                        )}
                                                      </div>

                                                      {/*
                                                       * Icons in Intermediate Begin Here | use bomIntermediate here as list item
                                                       */}

                                                      <div className="manufacturing-step-intermediate-icons-wrapper">
                                                        {/*
                                                         * Edit Intermediate Button
                                                         */}
                                                        {!bomIntermediate?.isAddedFromExisting && (
                                                          <CButton
                                                            color="primary"
                                                            type="button"
                                                            variant="outline"
                                                            onClick={() => {
                                                              const bomElementsForIntermediate:
                                                                | actionTypes.TBomElementInManufacturingStep[]
                                                                | undefined =
                                                                values?.editableBomForManufacturingSteps?.bomElementDetails?.map(
                                                                  (
                                                                    bomElement: actionTypes.IBomFullElement,
                                                                    bomElementIndex: number
                                                                  ) => {
                                                                    return {
                                                                      bomElementName:
                                                                        bomElement.bomElementName,
                                                                      bomElementId:
                                                                        bomElement.bomElementId,
                                                                      quantity:
                                                                        bomIntermediate.bomElements.find(
                                                                          (
                                                                            bomElementInIntermediate: actionTypes.TBomElementInManufacturingStep
                                                                          ) =>
                                                                            bomElement.bomElementId ===
                                                                            bomElementInIntermediate.bomElementId
                                                                        )?.quantity || '',
                                                                      index: bomElementIndex,
                                                                    }
                                                                  }
                                                                )

                                                              setFieldValue(
                                                                `manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}`,
                                                                {
                                                                  ...bomIntermediate,
                                                                  bomElements:
                                                                    bomElementsForIntermediate,
                                                                }
                                                              )

                                                              setFieldValue(
                                                                `editableBomForManufacturingSteps.bomElementDetails`,
                                                                values?.editableBomForManufacturingSteps?.bomElementDetails?.map(
                                                                  (
                                                                    bomElement: actionTypes.IBomElementFromEditableBomTableInModal
                                                                  ) => ({
                                                                    ...bomElement,
                                                                    checkbox:
                                                                      !!bomIntermediate?.bomElements?.some(
                                                                        (
                                                                          bomElementInBomIntermediate: actionTypes.TBomElementInManufacturingStep
                                                                        ) =>
                                                                          bomElementInBomIntermediate?.bomElementId ===
                                                                            bomElement?.bomElementId &&
                                                                          convertNumericStringToNumber(
                                                                            bomElementInBomIntermediate?.quantity
                                                                          )
                                                                      ),
                                                                  })
                                                                )
                                                              )

                                                              setBomIntermediateInStepModalUuid(
                                                                bomIntermediate.uuid
                                                              )

                                                              setBomIntermediateModalMode('edit')

                                                              setBomIntermediateInStepModalVisibility(
                                                                true
                                                              )

                                                              setInitialUnsavedEditedBomIntermediate(
                                                                bomIntermediate
                                                              )
                                                            }}
                                                            className="list-action-button edit-list-item-button"
                                                            disabled={
                                                              !values?.manufacturingSteps?.some(
                                                                (
                                                                  manufacturingStep: actionTypes.IManufacturingStep
                                                                ) =>
                                                                  manufacturingStep.bomIntermediates
                                                                    .length > 0
                                                              )
                                                            }
                                                          >
                                                            <i className="cil-pencil"></i>
                                                          </CButton>
                                                        )}

                                                        {/*
                                                         * Add Existing Intermediate to Intermediate Button
                                                         */}

                                                        {!bomIntermediate?.isAddedFromExisting && (
                                                          <CButton
                                                            color="info"
                                                            type="button"
                                                            variant="outline"
                                                            onClick={() => {
                                                              setAddExistingIntermediateModalVisibility(
                                                                true
                                                              )

                                                              setAddExistingIntermediateModalScopeUuid(
                                                                bomIntermediate.uuid
                                                              )

                                                              setAddExistingIntermediateModalMode(
                                                                'intermediate'
                                                              )

                                                              setIntermediateOrStepNameInAddExistingIntermediateModal(
                                                                bomIntermediate.name
                                                              )

                                                              setAddExistingIntermediateToIntermediateFormikPath(
                                                                `manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.bomIntermediates.${
                                                                  (
                                                                    values.manufacturingSteps[
                                                                      indexOfManufacturingStep
                                                                    ] as actionTypes.IManufacturingStep
                                                                  ).bomIntermediates[
                                                                    indexOfBomIntermediate
                                                                  ].bomIntermediates.length
                                                                }`
                                                              )
                                                            }}
                                                            className="list-action-button manufacturing-step-add-existing-intermediate-list-item-button mx-2"
                                                            disabled={
                                                              !values?.manufacturingSteps?.some(
                                                                (
                                                                  manufacturingStep: actionTypes.IManufacturingStep
                                                                ) =>
                                                                  manufacturingStep.bomIntermediates
                                                                    .length > 0
                                                              )
                                                            }
                                                          >
                                                            <i className="cil-object-group"></i>
                                                          </CButton>
                                                        )}

                                                        {/*
                                                         * Add Manufacturing Activity to Intermediate
                                                         */}
                                                        {!bomIntermediate?.isAddedFromExisting && (
                                                          <CButton
                                                            color="info"
                                                            type="button"
                                                            variant="outline"
                                                            onClick={() => {
                                                              setAddManufacturingActivityModalVisibility(
                                                                true
                                                              )

                                                              setAddManufacturingActivityModalScopeUuid(
                                                                manufacturingStep.uuid
                                                              )

                                                              setAddManufacturingActivityModalMode(
                                                                'intermediate'
                                                              )

                                                              setIntermediateOrStepNameInAddManufacturingActivityModal(
                                                                manufacturingStep.name
                                                              )

                                                              setAddManufacturingActivityToIntermediateFormikPath(
                                                                `manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.manufacturingActivities.${
                                                                  (
                                                                    values.manufacturingSteps[
                                                                      indexOfManufacturingStep
                                                                    ] as actionTypes.IManufacturingStep
                                                                  ).bomIntermediates[
                                                                    indexOfBomIntermediate
                                                                  ]?.manufacturingActivities
                                                                    ?.length || 0
                                                                }`
                                                              )
                                                            }}
                                                            className={`list-action-button mr-2 ${
                                                              manufacturingState
                                                                ?.manufacturingActivities.length
                                                                ? 'manufacturing-step-add-manufacturing-activity-list-item-button'
                                                                : 'manufacturing-step-add-manufacturing-activity-list-item-button--no-activities'
                                                            }`}
                                                            disabled={
                                                              !manufacturingState
                                                                .manufacturingActivities.length
                                                            }
                                                          >
                                                            <i className="cil-fork"></i>
                                                          </CButton>
                                                        )}

                                                        {/*
                                                         * Delete Whole Intermediate
                                                         */}

                                                        <CButton
                                                          color="danger"
                                                          type="button"
                                                          variant="outline"
                                                          onClick={() => {
                                                            confirmAlert({
                                                              closeOnEscape: true,
                                                              customUI: ({ onClose }) => {
                                                                return (
                                                                  <ConfirmActionModal
                                                                    mode="delete"
                                                                    onClose={onClose}
                                                                    confirmMessageJSX={
                                                                      <>
                                                                        <div className="mb-2">
                                                                          (
                                                                          {manufacturingStep?.name ||
                                                                            'Etap ...'}
                                                                          )
                                                                        </div>
                                                                        Czy na pewno chcesz usunąć{' '}
                                                                        {bomIntermediate?.isAddedFromExisting
                                                                          ? 'odnośnik do '
                                                                          : ''}
                                                                        <br />
                                                                        <strong>
                                                                          {!bomIntermediate?.isAddedFromExisting
                                                                            ? bomIntermediate?.name ||
                                                                              ''
                                                                            : getNameOfIntermediateFromOriginalSource(
                                                                                bomIntermediate,
                                                                                values.manufacturingSteps
                                                                              )}
                                                                        </strong>
                                                                        ?
                                                                        {/*
                                                                         * Check if intermediates are used in other places
                                                                         */}
                                                                        {!bomIntermediate?.isAddedFromExisting &&
                                                                          values?.manufacturingSteps?.some(
                                                                            (
                                                                              manufacturingStepInSome: actionTypes.IManufacturingStep
                                                                            ) =>
                                                                              manufacturingStepInSome?.bomIntermediates?.some(
                                                                                (
                                                                                  bomIntermediateInSome: actionTypes.IBomIntermediate
                                                                                ) =>
                                                                                  (bomIntermediateInSome.uuid ===
                                                                                    bomIntermediate.uuid &&
                                                                                    bomIntermediateInSome?.isAddedFromExisting) ||
                                                                                  bomIntermediateInSome.bomIntermediates.some(
                                                                                    (
                                                                                      bomIntermediateFromSomeInSome: actionTypes.IBomIntermediate
                                                                                    ) =>
                                                                                      bomIntermediateFromSomeInSome.uuid ===
                                                                                        bomIntermediate.uuid &&
                                                                                      bomIntermediateFromSomeInSome?.isAddedFromExisting
                                                                                  )
                                                                              )
                                                                          ) && (
                                                                            <>
                                                                              <br />
                                                                              <br />
                                                                              Półprodukt zawiera
                                                                              odniesienia w innych
                                                                              etapach, które również
                                                                              zostaną usunięte!
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    }
                                                                    onConfirmFunction={() => {
                                                                      manufacturingStepBomIntermediatesListArrayHelpers.remove(
                                                                        indexOfBomIntermediate
                                                                      )

                                                                      if (
                                                                        !bomIntermediate?.isAddedFromExisting
                                                                      ) {
                                                                        const removedSameIntermediatesFromExistingInManufacturingSteps =
                                                                          values?.manufacturingSteps?.map(
                                                                            (
                                                                              manufacturingStepExistingIntermediateRemove: actionTypes.IManufacturingStep
                                                                            ) => ({
                                                                              ...manufacturingStepExistingIntermediateRemove,
                                                                              bomIntermediates:
                                                                                manufacturingStepExistingIntermediateRemove?.bomIntermediates
                                                                                  ?.map(
                                                                                    (
                                                                                      bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                                    ) => ({
                                                                                      ...bomIntermediateToRemove,
                                                                                      bomIntermediates:
                                                                                        // Second layer nesting of bomIntermediate in bomIntermediate
                                                                                        bomIntermediateToRemove?.bomIntermediates
                                                                                          ?.map(
                                                                                            (
                                                                                              bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                                            ) =>
                                                                                              bomIntermediateToRemove
                                                                                          )
                                                                                          ?.filter(
                                                                                            (
                                                                                              bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                                            ) => {
                                                                                              return (
                                                                                                bomIntermediateToRemove.uuid !==
                                                                                                  bomIntermediate.uuid &&
                                                                                                !bomIntermediateToRemove?.isAddedFromExisting
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                    })
                                                                                  )
                                                                                  ?.filter(
                                                                                    (
                                                                                      bomIntermediateToRemove: actionTypes.IBomIntermediate
                                                                                    ) => {
                                                                                      return (
                                                                                        bomIntermediateToRemove.uuid !==
                                                                                          bomIntermediate.uuid &&
                                                                                        !bomIntermediateToRemove?.isAddedFromExisting
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                            })
                                                                          )

                                                                        setFieldValue(
                                                                          'manufacturingSteps',
                                                                          removedSameIntermediatesFromExistingInManufacturingSteps
                                                                        )
                                                                      }
                                                                    }}
                                                                  />
                                                                )
                                                              },
                                                            })
                                                          }}
                                                          className="select-option-remove-button"
                                                        >
                                                          <i className="cil-trash"></i>
                                                        </CButton>
                                                      </div>
                                                    </div>

                                                    {/*
                                                     * List of Elements/Intermediates/Activities in Intermediate
                                                     */}

                                                    {!bomIntermediate?.isAddedFromExisting && (
                                                      <ol
                                                        className="manufacturing-step-intermediate-elements-list"
                                                        style={{ listStyle: 'disc' }}
                                                      >
                                                        {/*
                                                         * List of Manufacturing Activities in Intermediate
                                                         */}
                                                        <FieldArray
                                                          name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.manufacturingActivities`}
                                                          render={(
                                                            manufacturingActivitiesInIntermediateListArrayHelpers
                                                          ) => (
                                                            <>
                                                              {bomIntermediate?.manufacturingActivities?.map(
                                                                (
                                                                  manufacturingActivityinIntermediate: actionTypes.IManufacturingActivity,
                                                                  indexOfManufacturingActivityInIntermediate: number
                                                                ) => {
                                                                  return (
                                                                    <li
                                                                      key={`${manufacturingStep.uuid}-${bomIntermediate.uuid}-${manufacturingActivityinIntermediate.id}-${indexOfManufacturingActivityInIntermediate}-${manufacturingActivityinIntermediate.name}`}
                                                                      style={{
                                                                        listStyle: 'square',
                                                                        color: '#39f',
                                                                      }}
                                                                    >
                                                                      {manufacturingActivityinIntermediate?.name ||
                                                                        '...'}{' '}
                                                                      &nbsp;|{' '}
                                                                      <div
                                                                        className="text-danger remove-intermediate-from-intermediate-cross"
                                                                        onClick={() => {
                                                                          confirmAlert({
                                                                            closeOnEscape: true,
                                                                            customUI: ({
                                                                              onClose,
                                                                            }) => {
                                                                              return (
                                                                                <ConfirmActionModal
                                                                                  mode="delete"
                                                                                  onClose={onClose}
                                                                                  confirmMessageJSX={
                                                                                    <>
                                                                                      Czy na pewno
                                                                                      chcesz usunąć
                                                                                      czynność{' '}
                                                                                      <strong>
                                                                                        {manufacturingActivityinIntermediate?.name ||
                                                                                          '...'}
                                                                                      </strong>{' '}
                                                                                      z{' '}
                                                                                      {bomIntermediate?.name ||
                                                                                        ''}
                                                                                      ?
                                                                                    </>
                                                                                  }
                                                                                  onConfirmFunction={() => {
                                                                                    manufacturingActivitiesInIntermediateListArrayHelpers.remove(
                                                                                      indexOfManufacturingActivityInIntermediate
                                                                                    )
                                                                                  }}
                                                                                />
                                                                              )
                                                                            },
                                                                          })
                                                                        }}
                                                                      >
                                                                        X
                                                                      </div>
                                                                    </li>
                                                                  )
                                                                }
                                                              )}
                                                            </>
                                                          )}
                                                        />

                                                        {/*
                                                         *  Intermediates from Already Existing
                                                         */}

                                                        <FieldArray
                                                          name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.bomIntermediates`}
                                                          render={(
                                                            intermediatesInIntermediateListArrayHelpers
                                                          ) => (
                                                            <>
                                                              {bomIntermediate?.bomIntermediates?.map(
                                                                (
                                                                  intermediateInIntermediate: actionTypes.IBomIntermediate,
                                                                  indexOfIntermediateInIntermediate: number
                                                                ) => {
                                                                  return (
                                                                    <li
                                                                      key={`${manufacturingStep.uuid}-${bomIntermediate.uuid}-${intermediateInIntermediate.uuid}-${indexOfIntermediateInIntermediate}`}
                                                                      style={{
                                                                        listStyle: 'circle',
                                                                      }}
                                                                    >
                                                                      {getNameOfIntermediateFromOriginalSource(
                                                                        intermediateInIntermediate,
                                                                        values.manufacturingSteps
                                                                      )}{' '}
                                                                      &nbsp;|{' '}
                                                                      <div
                                                                        className="text-danger remove-intermediate-from-intermediate-cross"
                                                                        onClick={() => {
                                                                          confirmAlert({
                                                                            closeOnEscape: true,
                                                                            customUI: ({
                                                                              onClose,
                                                                            }) => {
                                                                              return (
                                                                                <ConfirmActionModal
                                                                                  mode="delete"
                                                                                  onClose={onClose}
                                                                                  confirmMessageJSX={
                                                                                    <>
                                                                                      Czy na pewno
                                                                                      chcesz usunąć{' '}
                                                                                      <strong>
                                                                                        {getNameOfIntermediateFromOriginalSource(
                                                                                          intermediateInIntermediate,
                                                                                          values.manufacturingSteps
                                                                                        )}
                                                                                      </strong>{' '}
                                                                                      z{' '}
                                                                                      {bomIntermediate?.name ||
                                                                                        ''}
                                                                                      ?
                                                                                    </>
                                                                                  }
                                                                                  onConfirmFunction={() => {
                                                                                    intermediatesInIntermediateListArrayHelpers.remove(
                                                                                      indexOfIntermediateInIntermediate
                                                                                    )
                                                                                  }}
                                                                                />
                                                                              )
                                                                            },
                                                                          })
                                                                        }}
                                                                      >
                                                                        X
                                                                      </div>
                                                                    </li>
                                                                  )
                                                                }
                                                              )}
                                                            </>
                                                          )}
                                                        />

                                                        {/*
                                                         * Elements from Modal
                                                         */}

                                                        {bomIntermediate?.bomElements?.map(
                                                          (
                                                            bomElementInIntermediate: actionTypes.TBomElementInManufacturingStep,
                                                            indexOfBomElementInIntermediate: number
                                                          ) =>
                                                            // Do not show any element if quantity was not filled yet in modal
                                                            bomElementInIntermediate?.quantity &&
                                                            String(
                                                              bomElementInIntermediate?.quantity
                                                            ) !== '0' ? (
                                                              <li
                                                                key={`${manufacturingStep.uuid}-${bomIntermediate.uuid}-${bomElementInIntermediate.bomElementId}-${indexOfBomElementInIntermediate}`}
                                                              >
                                                                {
                                                                  bomElementInIntermediate?.bomElementName
                                                                }{' '}
                                                                &nbsp;|&nbsp;{' '}
                                                                <strong>
                                                                  {convertNumericStringToNumber(
                                                                    bomElementInIntermediate?.quantity
                                                                  )}
                                                                </strong>
                                                              </li>
                                                            ) : null
                                                        )}
                                                      </ol>
                                                    )}

                                                    {/*
                                                     * BOM Intermediate Create/Edit Modal
                                                     */}

                                                    {bomIntermediateInStepModalVisibility &&
                                                      bomIntermediateInStepModalUuid ===
                                                        bomIntermediate.uuid && (
                                                        <ManufacturingStepsDetailsBomIntermediateModal
                                                          setBomIntermediateInStepModalVisibility={
                                                            setBomIntermediateInStepModalVisibility
                                                          }
                                                          setBomIntermediateInStepModalUuid={
                                                            setBomIntermediateInStepModalUuid
                                                          }
                                                          setInitialUnsavedEditedBomIntermediate={
                                                            setInitialUnsavedEditedBomIntermediate
                                                          }
                                                          setFieldValue={setFieldValue}
                                                          handleChange={handleChange}
                                                          calculateRemainingQuantity={
                                                            calculateRemainingQuantity
                                                          }
                                                          bomIntermediateModalMode={
                                                            bomIntermediateModalMode
                                                          }
                                                          manufacturingStepBomIntermediatesListArrayHelpers={
                                                            manufacturingStepBomIntermediatesListArrayHelpers
                                                          }
                                                          indexOfBomIntermediate={
                                                            indexOfBomIntermediate
                                                          }
                                                          indexOfManufacturingStep={
                                                            indexOfManufacturingStep
                                                          }
                                                          initialUnsavedEditedBomIntermediate={
                                                            initialUnsavedEditedBomIntermediate
                                                          }
                                                          bomIntermediate={bomIntermediate}
                                                          manufacturingStep={manufacturingStep}
                                                          editableBomForManufacturingSteps={
                                                            values?.editableBomForManufacturingSteps
                                                          }
                                                          manufacturingSteps={
                                                            values?.manufacturingSteps
                                                          }
                                                          manufacturingState={manufacturingState}
                                                          bomsState={bomsState}
                                                        />
                                                      )}
                                                  </li>
                                                )
                                              )}
                                            </ol>
                                          </div>
                                        )}
                                      />
                                    )}
                                  </li>
                                )
                              )}
                            </ol>

                            <hr />

                            {/*
                             * Add Existing Intermediate Modal
                             */}

                            {addExistingIntermediateModalVisibility && (
                              <CModal
                                onClose={() => {
                                  setAddExistingIntermediateModalVisibility(false)

                                  setAlreadyExistingIntermediateToAdd(null)

                                  setAddExistingIntermediateModalScopeUuid(null)

                                  setFieldValue('radioInputInAddExistingIntermediateModal', null)
                                }}
                                size="sm"
                                show={addExistingIntermediateModalVisibility}
                                centered
                                backdrop
                                closeOnBackdrop={false}
                                addContentClass="manufacturing-steps-details-add-existing-intermediate-modal py-4 px-5"
                              >
                                <CModalHeader className="c-modal-header-grid mb-1" closeButton>
                                  <h3 className={`text-center pb-3`}>
                                    Dodaj Gotowy Półprodukt
                                    <br />({intermediateOrStepNameInAddExistingIntermediateModal})
                                  </h3>
                                </CModalHeader>

                                <div className="ml-3 my-3 d-flex flex-column">
                                  {values?.manufacturingSteps
                                    .map(
                                      (
                                        manufacturingStep: actionTypes.IManufacturingStep,
                                        indexOfManufacturingStep: number
                                      ) =>
                                        manufacturingStep?.bomIntermediates.map(
                                          (
                                            bomIntermediateInStepForRadioButton: actionTypes.IBomIntermediate,
                                            indexOfBomIntermediate: number
                                          ) =>
                                            !bomIntermediateInStepForRadioButton?.isAddedFromExisting ? (
                                              <div
                                                role="group"
                                                className={`form-check ${
                                                  (addExistingIntermediateModalMode === 'step'
                                                    ? !!(
                                                        values?.manufacturingSteps?.find(
                                                          (
                                                            manufacturingStepToFind: actionTypes.IManufacturingStep
                                                          ) =>
                                                            manufacturingStepToFind.uuid ===
                                                            addExistingIntermediateModalScopeUuid
                                                        ) as
                                                          | actionTypes.IManufacturingStep
                                                          | undefined
                                                      )?.bomIntermediates?.find(
                                                        (
                                                          bomIntermediateToFind: actionTypes.IBomIntermediate
                                                        ) =>
                                                          bomIntermediateToFind.uuid ===
                                                          bomIntermediateInStepForRadioButton.uuid
                                                      )
                                                    : bomIntermediateInStepForRadioButton.uuid ===
                                                      addExistingIntermediateModalScopeUuid) &&
                                                  'disabled-radio-label'
                                                }`}
                                                key={`${indexOfManufacturingStep}-${indexOfBomIntermediate}`}
                                              >
                                                <label>
                                                  <Field
                                                    type="radio"
                                                    name="radioInputInAddExistingIntermediateModal"
                                                    value={
                                                      bomIntermediateInStepForRadioButton?.uuid
                                                    }
                                                    className="form-check-input"
                                                    onClick={() => {
                                                      setAlreadyExistingIntermediateToAdd({
                                                        ...bomIntermediateInStepForRadioButton,
                                                        isAddedFromExisting: true,
                                                        bomElements: [],
                                                      })
                                                    }}
                                                    disabled={
                                                      addExistingIntermediateModalMode === 'step'
                                                        ? !!(
                                                            values?.manufacturingSteps?.find(
                                                              (
                                                                manufacturingStepToFind: actionTypes.IManufacturingStep
                                                              ) =>
                                                                manufacturingStepToFind.uuid ===
                                                                addExistingIntermediateModalScopeUuid
                                                            ) as
                                                              | actionTypes.IManufacturingStep
                                                              | undefined
                                                          )?.bomIntermediates?.find(
                                                            (
                                                              bomIntermediateToFind: actionTypes.IBomIntermediate
                                                            ) =>
                                                              bomIntermediateToFind.uuid ===
                                                              bomIntermediateInStepForRadioButton.uuid
                                                          )
                                                        : bomIntermediateInStepForRadioButton.uuid ===
                                                          addExistingIntermediateModalScopeUuid
                                                    }
                                                  />
                                                  {`${bomIntermediateInStepForRadioButton.name} (${manufacturingStep.name})`}
                                                </label>
                                              </div>
                                            ) : null
                                        )
                                    )
                                    .flat()}
                                </div>

                                <div className="p-0 mt-4 d-flex justify-content-center">
                                  <CButton
                                    color="primary"
                                    type="button"
                                    variant="outline"
                                    className="px-4 mr-4"
                                    onClick={() => {
                                      setAddExistingIntermediateModalVisibility(false)

                                      setAlreadyExistingIntermediateToAdd(null)

                                      setAddExistingIntermediateModalScopeUuid(null)

                                      setFieldValue(
                                        'radioInputInAddExistingIntermediateModal',
                                        null
                                      )
                                    }}
                                    disabled={manufacturingState.isBomForManufacturingStepsLoading}
                                  >
                                    Anuluj
                                  </CButton>

                                  <CButton
                                    color="info"
                                    type="button"
                                    variant="outline"
                                    className="add-intermediate-modal-button px-4 ml-2"
                                    onClick={() => {
                                      setAddExistingIntermediateModalVisibility(false)

                                      if (addExistingIntermediateModalMode === 'step') {
                                        const indexOfStep = values.manufacturingSteps.findIndex(
                                          (manufacturingStep: actionTypes.IManufacturingStep) =>
                                            manufacturingStep.uuid ===
                                            addExistingIntermediateModalScopeUuid
                                        )

                                        setFieldValue(
                                          `manufacturingSteps.${
                                            indexOfStep || 0
                                          }.bomIntermediates.${
                                            (
                                              values.manufacturingSteps[
                                                indexOfStep || 0
                                              ] as actionTypes.IManufacturingStep
                                            ).bomIntermediates.length
                                          }`,
                                          alreadyExistingIntermediateToAdd
                                        )
                                      } else {
                                        setFieldValue(
                                          addExistingIntermediateToIntermediateFormikPath,
                                          alreadyExistingIntermediateToAdd
                                        )
                                      }

                                      setFieldValue(
                                        'radioInputInAddExistingIntermediateModal',
                                        null
                                      )
                                    }}
                                    disabled={!alreadyExistingIntermediateToAdd}
                                  >
                                    Dodaj
                                  </CButton>
                                </div>
                              </CModal>
                            )}

                            {/*
                             * Add Manufacturing Activity Modal
                             */}

                            {addManufacturingActivityModalVisibility && (
                              <CModal
                                onClose={() => {
                                  setAddManufacturingActivityModalVisibility(false)

                                  setChosenManufacturingActivityToAdd(null)

                                  setAddManufacturingActivityModalScopeUuid(null)

                                  setFieldValue('radioInputInAddManufacturingActivityModal', null)
                                }}
                                size="sm"
                                show={addManufacturingActivityModalVisibility}
                                centered
                                backdrop
                                closeOnBackdrop={false}
                                addContentClass="manufacturing-steps-details-add-existing-intermediate-modal py-4 px-5"
                              >
                                <CModalHeader className="c-modal-header-grid mb-1" closeButton>
                                  <h3 className="text-center pb-3">
                                    Dodaj Czynność
                                    <br />({intermediateOrStepNameInAddManufacturingActivityModal})
                                  </h3>
                                </CModalHeader>

                                <div className="ml-3 my-3 pl-3 d-flex flex-column">
                                  {manufacturingState?.manufacturingActivities?.map(
                                    (
                                      manufacturingActivity: actionTypes.IManufacturingActivity,
                                      indexOfManufacturingActivity: number
                                    ) => (
                                      <div
                                        role="group"
                                        key={`${manufacturingActivity?.id}-${indexOfManufacturingActivity}-${manufacturingActivity?.name}`}
                                      >
                                        <label>
                                          <Field
                                            type="radio"
                                            name="radioInputInAddManufacturingActivityModal"
                                            value={manufacturingActivity.name}
                                            className="form-check-input"
                                            onClick={(event: ChangeEvent) => {
                                              setChosenManufacturingActivityToAdd(
                                                manufacturingActivity
                                              )

                                              handleChange(event)
                                            }}
                                          />
                                          {manufacturingActivity?.name || '...'}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>

                                <div className="p-0 mt-4 d-flex justify-content-center">
                                  <CButton
                                    color="primary"
                                    type="button"
                                    variant="outline"
                                    className="px-4 mr-4"
                                    onClick={() => {
                                      setAddManufacturingActivityModalVisibility(false)

                                      setChosenManufacturingActivityToAdd(null)

                                      setAddManufacturingActivityModalScopeUuid(null)

                                      setFieldValue(
                                        'radioInputInAddManufacturingActivityModal',
                                        null
                                      )
                                    }}
                                    disabled={manufacturingState.isBomForManufacturingStepsLoading}
                                  >
                                    Anuluj
                                  </CButton>

                                  <CButton
                                    color="info"
                                    type="button"
                                    variant="outline"
                                    className="add-intermediate-modal-button px-4 ml-2"
                                    onClick={() => {
                                      setAddManufacturingActivityModalVisibility(false)

                                      if (addManufacturingActivityModalMode === 'step') {
                                        const indexOfStep = values.manufacturingSteps.findIndex(
                                          (manufacturingStep: actionTypes.IManufacturingStep) =>
                                            manufacturingStep.uuid ===
                                            addManufacturingActivityModalScopeUuid
                                        )

                                        setFieldValue(
                                          `manufacturingSteps.${
                                            indexOfStep || 0
                                          }.manufacturingActivities.${
                                            (
                                              values.manufacturingSteps[
                                                indexOfStep || 0
                                              ] as actionTypes.IManufacturingStep
                                            ).manufacturingActivities.length
                                          }`,
                                          chosenManufacturingActivityToAdd
                                        )
                                      } else {
                                        setFieldValue(
                                          addManufacturingActivityToIntermediateFormikPath,
                                          chosenManufacturingActivityToAdd
                                        )
                                      }

                                      setFieldValue(
                                        'radioInputInAddManufacturingActivityModal',
                                        null
                                      )
                                    }}
                                    disabled={!values.radioInputInAddManufacturingActivityModal}
                                  >
                                    Dodaj
                                  </CButton>
                                </div>
                              </CModal>
                            )}

                            <div className="p-0 mt-4 d-flex justify-content-center">
                              <CButton
                                color="info"
                                type="button"
                                variant="outline"
                                className="px-4 not-allowed-if-disabled"
                                onClick={() => {
                                  let newManufacturingStep: actionTypes.IManufacturingStep = {
                                    name: `Etap ${values?.manufacturingSteps?.length + 1}`,
                                    manufacturingActivities: [],
                                    bomIntermediates: [],
                                    uuid: uuidv4(),
                                  }

                                  manufacturingStepsListArrayHelpers.push(newManufacturingStep)
                                }}
                                disabled={
                                  manufacturingState.isBomForManufacturingStepsLoading ||
                                  !values?.editableBomForManufacturingSteps
                                }
                              >
                                Dodaj Etap
                              </CButton>
                            </div>
                          </div>
                        </>
                      )}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </CCardBody>
      </CCard>
    </Container>
  )
}
