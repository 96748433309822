import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

import { TNetworkError } from '../store/action-types'
import { inputLabelSpacingBottom } from '../utils'
import CIcon from '@coreui/icons-react'
import { CLabel } from '@coreui/react'
import { cilZoom } from '@coreui/icons'

interface ISimpleConnectedBom {
  id: number
  name: string | null
}

interface IConnectedBomsTable {
  mode: 'bom-element' | 'implementation-cost' | 'service'
  editedItemBoms: ISimpleConnectedBom[] | null
  fetchItemBomsError: TNetworkError
  tenant: string
}

export const ConnectedBomsTable: React.FC<IConnectedBomsTable> = ({
  mode,
  editedItemBoms,
  fetchItemBomsError,
  tenant,
}) => {
  return (
    <>
      <CLabel htmlFor={`${mode}-boms`} className={`${inputLabelSpacingBottom}`}>
        Zastosowanie
      </CLabel>
      <Table bordered striped className="item-inner-details-table mb-3" id={`${mode}-boms`}>
        <thead>
          <tr>
            <th style={{ width: '100px' }}>Podgląd</th>
            <th style={{ width: '1fr' }}>BOM / Produkt</th>
          </tr>
        </thead>

        {editedItemBoms && editedItemBoms?.length > 0 ? (
          <tbody>
            {editedItemBoms?.map((bom: ISimpleConnectedBom, indexOfBom: number) => (
              <tr key={`bom-${bom?.name || indexOfBom}-${bom.id}`}>
                <td style={{ padding: 0 }}>
                  <Link
                    to={`/${tenant}/boms/edit/${bom.id}/elements`}
                    className="button info d-flex justify-content-center"
                    style={{ padding: '0.4rem 0.75rem' }}
                  >
                    <CIcon icon={cilZoom} />
                  </Link>
                </td>
                <td>{bom?.name || ''}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <caption className="item-inner-details-table-caption">
            {fetchItemBomsError
              ? `Wystąpił błąd ${fetchItemBomsError?.status} podczas pobierania BOMów ${
                  mode === 'bom-element'
                    ? 'tego elementu'
                    : mode === 'implementation-cost'
                    ? 'tego toolingu'
                    : 'tej usługi'
                }!`
              : `${
                  mode === 'bom-element'
                    ? 'Ten element'
                    : mode === 'implementation-cost'
                    ? 'Ten tooling'
                    : 'Ta usługa'
                } nie jest obecnie w żadnym BOM!`}
          </caption>
        )}
      </Table>
    </>
  )
}
