import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Container, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CLabel, CButton } from '@coreui/react'
import { Select, SelectProps } from '@material-ui/core'
import { cilListHighPriority } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumbersInTableRows,
  getEmptyListMessage,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  makeSearchBoxFixed,
} from '../../utils'
import { minimumWidthForWideDetailsView } from '../erp'
import { FormFixedActionsPanel } from '../../components'

export const CurrentManufacturingReport: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)
  const reportsState = useSelector((state: TRootState) => state.reports)

  const [currentlyBrowsedManufacturingErrandId, setCurrentlyBrowsedManufacturingErrandId] =
    useState<null | number>(null)
  const [currentlyBrowsedAssemblyErrandId, setCurrentlyBrowsedAssemblyErrandId] = useState<
    null | number
  >(null)
  const [currentlyBrowsedTypeId, setCurrentlyBrowsedTypeId] = useState<null | number>(null)
  const [currentlyBrowsedBomId, setCurrentlyBrowsedBomId] = useState<null | number>(null)
  const [currentlyBrowsedUserId, setCurrentlyBrowsedUserId] = useState<null | string>(null)

  const [currentManufacturingReportFilterState, setCurrentManufacturingReportFilterState] =
    useState<actionTypes.TFilterState>(undefined)
  const [currentManufacturingReportSorterState, setCurrentManufacturingReportSorterState] =
    useState<actionTypes.TSorterState>({
      column: '',
      asc: true,
    })

  const memoizedTableItems = useMemo(() => {
    const currentManufacturingReport = reportsState?.currentManufacturingReport?.map(
      (reportItem: actionTypes.TCurrentManufacturingReportTableItem) => {
        return {
          ...reportItem,
          manufacturingErrandNumber: reportItem.manufacturingErrandNumber || '',
          assemblyErrandNumber: reportItem.assemblyErrandNumber || '',
          bomName: reportItem.bomName || '',
          userName: reportItem.userName || '',
          bomIntermediateName: reportItem.bomIntermediateName || '',
          bomElementName: reportItem.bomElementName || '',
          quantity: reportItem.quantity || '',
        }
      }
    )

    if (
      currentlyBrowsedManufacturingErrandId ||
      currentlyBrowsedAssemblyErrandId ||
      currentlyBrowsedBomId ||
      currentlyBrowsedUserId
    ) {
      return currentManufacturingReport?.filter(
        (reportItem: actionTypes.TCurrentManufacturingReportTableItem) =>
          (currentlyBrowsedManufacturingErrandId
            ? reportItem.manufacturingErrandId === currentlyBrowsedManufacturingErrandId
            : true) &&
          (currentlyBrowsedAssemblyErrandId
            ? reportItem.assemblyErrandId === currentlyBrowsedAssemblyErrandId
            : true) &&
          // (currentlyBrowsedTypeId
          //   ? reportItem.type === currentlyBrowsedTypeId
          //   : true)
          //   &&
          (currentlyBrowsedBomId ? reportItem.bomId === currentlyBrowsedBomId : true) &&
          (currentlyBrowsedUserId ? reportItem.userId === currentlyBrowsedUserId : true)
      )
    } else {
      return currentManufacturingReport
    }
  }, [
    reportsState?.currentManufacturingReport,
    currentlyBrowsedManufacturingErrandId,
    currentlyBrowsedAssemblyErrandId,
    currentlyBrowsedBomId,
    currentlyBrowsedUserId,
    currentManufacturingReportFilterState,
    currentManufacturingReportSorterState,
  ])

  const generateDropdownOptionsList = useCallback(
    (
      propertyIdName: keyof actionTypes.TCurrentManufacturingReportTableItem,
      propertyFullName: keyof actionTypes.TCurrentManufacturingReportTableItem,
      propertyKeyName: string
    ) => {
      return Array.from(
        new Map(
          reportsState?.currentManufacturingReport?.map(
            (reportItem: actionTypes.TCurrentManufacturingReportTableItem) => [
              reportItem[propertyIdName],
              reportItem,
            ]
          )
        ).values()
      )
        ?.map((reportItem: any, reportItemIndex: number) => {
          if (reportItem[propertyIdName]) {
            return (
              <option
                key={`${propertyKeyName}-option-${reportItem.userId}-${reportItemIndex}-${reportItem[propertyIdName]}`}
                value={reportItem[propertyIdName]}
              >
                {reportItem[propertyFullName] || '...'}
              </option>
            )
          }
        })
        ?.filter((reportItem) => reportItem)
    },
    [reportsState.currentManufacturingReport]
  )

  const closeCurrentManuafacturingReport = useCallback(() => {
    if (history.length) {
      history.goBack()
    } else {
      history.push(`/${tenant}/reports`)
    }
  }, [history, tenant])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    convertNumbersInTableRows(reportsState.currentManufacturingReport, [], [6])
  }, [
    memoizedTableItems,
    currentManufacturingReportFilterState,
    currentManufacturingReportSorterState,
  ])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ maxWidth: '1600px' }}
    >
      <CCard style={{ maxWidth: '1250px' }}>
        <CCardBody className="p-4">
          {authState?.authData?.roles?.includes('Users_read') && (
            <FormFixedActionsPanel
              title={<></>}
              mode={'edit'}
              section="current-manufacturing-report"
              isSaving={false}
              isSaved={false}
              isDeleting={false}
              isDeleted={false}
              didFormValidationOccur={false}
              formErrorsBool={false}
              closeAction={''}
              deleteAction={''}
              deletePayload={null}
              closeFunction={closeCurrentManuafacturingReport}
              setDidFormValidationOccur={null}
              canDelete={false}
              isEditable={false}
              disabledDeleteButtonClassNames=""
              canSave={false}
              confirmDeleteMessageJSX={<></>}
            />
          )}

          <div className="d-flex justify-content-start">
            <div style={{ maxWidth: '260px' }}>
              <CLabel
                htmlFor="current-manufacturing-report-manufacturing-errand-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Zlecenie produkcyjne
              </CLabel>
              <InputGroup
                id="current-manufacturing-report-manufacturing-errand-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-factory"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedManufacturingErrandId || ''}
                  className="item-selector black-options-selector"
                  style={{ minWidth: '220px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedManufacturingErrandId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )
                    return event
                  }}
                  disabled={
                    !generateDropdownOptionsList(
                      'manufacturingErrandId',
                      'manufacturingErrandNumber',
                      'manufacturing-errand'
                    )?.length
                  }
                >
                  <option value={''}>Wszystkie</option>

                  {generateDropdownOptionsList(
                    'manufacturingErrandId',
                    'manufacturingErrandNumber',
                    'manufacturing-errand'
                  )}
                </Select>
              </InputGroup>
            </div>

            <div className="pl-4">
              <CLabel
                htmlFor="current-manufacturing-report-assembly-errand-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Zlecenie montażu
              </CLabel>
              <InputGroup
                id="current-manufacturing-report-assembly-errand-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-fork"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedAssemblyErrandId || ''}
                  className="item-selector black-options-selector"
                  style={{ minWidth: '220px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedAssemblyErrandId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )
                    return event
                  }}
                  disabled={
                    !generateDropdownOptionsList(
                      'assemblyErrandId',
                      'assemblyErrandNumber',
                      'assembly-errand'
                    )?.length
                  }
                >
                  <option value={''}>Wszystkie</option>

                  {generateDropdownOptionsList(
                    'assemblyErrandId',
                    'assemblyErrandNumber',
                    'assembly-errand'
                  )}
                </Select>
              </InputGroup>
            </div>

            <div className="pl-4" style={{ maxWidth: '260px' }}>
              <CLabel
                htmlFor="current-manufacturing-report-type-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Rodzaj
              </CLabel>
              <InputGroup
                id="current-manufacturing-report-type-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-list"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedTypeId || ''}
                  className="item-selector black-options-selector"
                  style={{ minWidth: '220px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedTypeId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )
                    return event
                  }}
                  disabled={true}
                >
                  <option value={''}>RW</option>
                </Select>
              </InputGroup>
            </div>

            <div className="current-manufacturing-report-reset-filters-button-wrapper">
              <CButton
                color="primary"
                variant="outline"
                className="ml-3 current-manufacturing-report-reset-filters-button"
                type="button"
                disabled={
                  !currentlyBrowsedManufacturingErrandId &&
                  !currentlyBrowsedAssemblyErrandId &&
                  !currentlyBrowsedTypeId &&
                  !currentlyBrowsedBomId &&
                  !currentlyBrowsedUserId
                }
                onClick={() => {
                  setCurrentlyBrowsedManufacturingErrandId(null)
                  setCurrentlyBrowsedAssemblyErrandId(null)
                  setCurrentlyBrowsedTypeId(null)
                  setCurrentlyBrowsedBomId(null)
                  setCurrentlyBrowsedUserId(null)

                  setCurrentManufacturingReportFilterState(undefined)
                }}
              >
                <CIcon icon={cilListHighPriority} />
              </CButton>
            </div>
          </div>

          <div className="d-flex justify-content-start mb-2">
            <div style={{ maxWidth: '260px' }}>
              <CLabel
                htmlFor="current-manufacturing-report-bom-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Produkt
              </CLabel>
              <InputGroup
                id="current-manufacturing-report-bom-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-fax"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedBomId || ''}
                  className="item-selector black-options-selector"
                  style={{ minWidth: '220px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedBomId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )
                    return event
                  }}
                  disabled={!generateDropdownOptionsList('bomId', 'bomName', 'bom')?.length}
                >
                  <option value={''}>Wszystkie</option>

                  {generateDropdownOptionsList('bomId', 'bomName', 'bom')}
                </Select>
              </InputGroup>
            </div>

            <div className="pl-4" style={{ maxWidth: '260px' }}>
              <CLabel
                htmlFor="current-manufacturing-report-user-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Pracownik
              </CLabel>
              <InputGroup
                id="current-manufacturing-report-user-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-user"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedUserId || ''}
                  className="item-selector black-options-selector"
                  style={{ minWidth: '220px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedUserId(
                      event?.target?.value ? String(event?.target?.value) : null
                    )
                    return event
                  }}
                  disabled={!generateDropdownOptionsList('userId', 'userName', 'user')?.length}
                >
                  <option value={''}>Wszyscy</option>

                  {generateDropdownOptionsList('userId', 'userName', 'user')}
                </Select>
              </InputGroup>
            </div>
          </div>

          <CDataTable
            tableFilterValue={currentManufacturingReportFilterState}
            sorterValue={currentManufacturingReportSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              setCurrentManufacturingReportSorterState(sorterState)
            }}
            onTableFilterChange={(tableFilterState: string) => {
              setCurrentManufacturingReportFilterState(tableFilterState)
            }}
            loading={reportsState?.isCurrentManufacturingReportLoading}
            striped={!!reportsState?.currentManufacturingReport?.length}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
            addTableClasses="vertical-middle-list-table current-manufacturing-report-table my-1"
            cleaner
            onRowClick={undefined}
            fields={[
              {
                key: 'manufacturingErrandNumber',
                label: 'Zlecenie produkcyjne',
                _style: { width: '120px' },
              },
              {
                key: 'assemblyErrandNumber',
                label: 'Zlecenie montażu',
                _style: { width: '120px' },
              },
              {
                key: 'bomName',
                label: 'Produkt',
                _style: { width: '160px' },
              },
              {
                key: 'userName',
                label: 'Pracownik',
                _style: { width: '200px' },
              },
              {
                key: 'bomIntermediateName',
                label: 'Półprodukt',
                _style: { width: '200px' },
              },
              {
                key: 'bomElementName',
                label: 'Element',
                _style: { width: '300px' },
              },
              {
                key: 'quantity',
                label: 'Ilość',
                _style: { width: '100px' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {currentlyBrowsedManufacturingErrandId || currentlyBrowsedAssemblyErrandId
                  ? 'Brak wyników dla wybranego filtrowania!'
                  : getEmptyListMessage(
                      reportsState.fetchCurrentManuafcturingReportError,
                      reportsState.isCurrentManufacturingReportLoading,
                      'raportu produkcji w toku',
                      reportsState?.currentManufacturingReport?.length
                    )}
              </div>
            }
          />
        </CCardBody>
      </CCard>
    </Container>
  )
}
