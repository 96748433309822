import * as actionTypes from '../action-types'

export interface IManfuacturingState {
  // MANUFACUTRING_STEPS

  allManufacturingSteps: actionTypes.IManufacturingSteps[] | []
  areAllManufacturingStepsLoading: boolean
  fetchAllManufacturingStepsError: actionTypes.TNetworkError

  bomForManufacturingSteps: actionTypes.IBomFull | null
  isBomForManufacturingStepsLoading: boolean
  isBomForManufacturingStepsLoaded: boolean
  fetchBomForManufacturingStepsError: actionTypes.TNetworkError

  areManufacturingStepsCreating: boolean
  areManufacturingStepsCreated: boolean
  manufacturingStepsCreateError: actionTypes.TNetworkError

  areManufacturingStepsDetailsLoading: boolean
  manufacturingStepsDetails: actionTypes.IManufacturingSteps | null
  fetchManufacturingStepsDetailsError: actionTypes.TNetworkError

  areManufacturingStepsDetailsEditSaving: boolean
  areManufacturingStepsDetailsEditSaved: boolean
  manufacturingStepsDetailsEditSaveError: actionTypes.TNetworkError

  currentlyManagedManufacturingStepsId: number | null

  areManufacturingStepsDeleting: boolean
  areManufacturingStepsDeleted: boolean
  manufacturingStepsDeleteError: actionTypes.TNetworkError

  // MANUFACUTRING_ACTIVITIES

  manufacturingActivities: actionTypes.IManufacturingActivity[] | any
  areManufacturingActivitiesLoading: boolean
  fetchManufacturingActivitiesError: actionTypes.TNetworkError

  manufacturingActivitiesTableSorterState: actionTypes.TSorterState | undefined
  manufacturingActivitiesTableFilterState: actionTypes.TFilterState
  manufacturingActivityModalVisibility: boolean
  editedManufacturingActivity: actionTypes.IManufacturingActivity | null

  isManufacturingActivityCreating: boolean
  manufacturingActivityCreateError: actionTypes.TNetworkError
  isManufacturingActivityCreated: boolean

  isManufacturingActivityDeleting: boolean
  manufacturingActivityDeleteError: actionTypes.TNetworkError
  isManufacturingActivityDeleted: boolean

  currentlyManagedManufacturingActivityId: number | null

  isManufacturingActivityEditSaving: boolean
  manufacturingActivityEditSaveError: actionTypes.TNetworkError
  isManufacturingActivityEditSaved: boolean

  // TEAM_MEMBERS

  teamMembers: actionTypes.ITeamMemberGeneralInformationAndLight[]
  areTeamMembersLoading: boolean
  fetchTeamMembersError: actionTypes.TNetworkError

  teamMembersTableSorterState: actionTypes.TSorterState | undefined
  teamMembersTableFilterState: actionTypes.TFilterState
  editedTeamMember: actionTypes.ITeamMember | null

  isTeamMemberEditSaving: boolean
  teamMemberEditSaveError: actionTypes.TNetworkError
  isTeamMemberEditSaved: boolean

  isSingleTeamMemberLoading: boolean
  singleTeamMemberFetchError: actionTypes.TNetworkError

  // MANUFACUTRING_ERRANDS

  manufacturingErrands: actionTypes.TManufacturingErrand[]
  areManufacturingErrandsLoading: boolean
  fetchManufacturingErrandsError: actionTypes.TNetworkError

  manufacturingErrandsTableSorterState: actionTypes.TSorterState | undefined
  manufacturingErrandsTableFilterState: actionTypes.TFilterState
  manufacturingErrandModalVisibility: boolean
  editedManufacturingErrand: actionTypes.TManufacturingErrand | null

  isManufacturingErrandCreating: boolean
  manufacturingErrandCreateError: actionTypes.TNetworkError
  isManufacturingErrandCreated: boolean

  isManufacturingErrandDeleting: boolean
  manufacturingErrandDeleteError: actionTypes.TNetworkError
  isManufacturingErrandDeleted: boolean

  currentlyManagedManufacturingErrandId: number | null

  isManufacturingErrandEditSaving: boolean
  manufacturingErrandEditSaveError: actionTypes.TNetworkError
  isManufacturingErrandEditSaved: boolean
}

const initialState: IManfuacturingState = {
  // MANUFACUTRING_STEPS

  allManufacturingSteps: [],
  areAllManufacturingStepsLoading: false,
  fetchAllManufacturingStepsError: null,

  bomForManufacturingSteps: null,
  isBomForManufacturingStepsLoading: false,
  isBomForManufacturingStepsLoaded: false,
  fetchBomForManufacturingStepsError: null,

  areManufacturingStepsCreating: false,
  areManufacturingStepsCreated: false,
  manufacturingStepsCreateError: null,

  areManufacturingStepsDetailsLoading: false,
  manufacturingStepsDetails: null,
  fetchManufacturingStepsDetailsError: null,

  areManufacturingStepsDetailsEditSaving: false,
  areManufacturingStepsDetailsEditSaved: false,
  manufacturingStepsDetailsEditSaveError: null,

  currentlyManagedManufacturingStepsId: null,

  areManufacturingStepsDeleting: false,
  areManufacturingStepsDeleted: false,
  manufacturingStepsDeleteError: null,

  // MANUFACUTRING_ACTIVITIES

  manufacturingActivities: [],
  areManufacturingActivitiesLoading: false,
  fetchManufacturingActivitiesError: null,

  manufacturingActivitiesTableSorterState: undefined,
  manufacturingActivitiesTableFilterState: undefined,
  manufacturingActivityModalVisibility: false,
  editedManufacturingActivity: null,

  isManufacturingActivityCreating: false,
  manufacturingActivityCreateError: null,
  isManufacturingActivityCreated: false,

  isManufacturingActivityDeleting: false,
  manufacturingActivityDeleteError: null,
  isManufacturingActivityDeleted: false,

  currentlyManagedManufacturingActivityId: null,

  isManufacturingActivityEditSaving: false,
  manufacturingActivityEditSaveError: null,
  isManufacturingActivityEditSaved: false,

  // TEAM_MEMBERS

  teamMembers: [],
  areTeamMembersLoading: false,
  fetchTeamMembersError: null,

  teamMembersTableSorterState: undefined,
  teamMembersTableFilterState: undefined,
  editedTeamMember: null,

  isTeamMemberEditSaving: false,
  teamMemberEditSaveError: null,
  isTeamMemberEditSaved: false,

  isSingleTeamMemberLoading: false,
  singleTeamMemberFetchError: null,

  // MANUFACUTRING_ERRANDS

  manufacturingErrands: [],
  areManufacturingErrandsLoading: false,
  fetchManufacturingErrandsError: null,

  manufacturingErrandsTableSorterState: undefined,
  manufacturingErrandsTableFilterState: undefined,
  manufacturingErrandModalVisibility: false,
  editedManufacturingErrand: null,

  isManufacturingErrandCreating: false,
  manufacturingErrandCreateError: null,
  isManufacturingErrandCreated: false,

  isManufacturingErrandDeleting: false,
  manufacturingErrandDeleteError: null,
  isManufacturingErrandDeleted: false,

  currentlyManagedManufacturingErrandId: null,

  isManufacturingErrandEditSaving: false,
  manufacturingErrandEditSaveError: null,
  isManufacturingErrandEditSaved: false,
}

export default function manufacturingReducer(
  state = initialState,
  action: actionTypes.ManufacturingActionTypes
): IManfuacturingState {
  switch (action.type) {
    case actionTypes.FETCH_ALL_MANUFACTURING_STEPS_REQUESTED: {
      return {
        ...state,
        areAllManufacturingStepsLoading: true,
        fetchAllManufacturingStepsError: null,
      }
    }

    case actionTypes.FETCH_ALL_MANUFACTURING_STEPS_SUCCEEDED: {
      return {
        ...state,
        areAllManufacturingStepsLoading: false,
        allManufacturingSteps: action.allManufacturingSteps,
      }
    }

    case actionTypes.FETCH_ALL_MANUFACTURING_STEPS_ERROR: {
      return {
        ...state,
        areAllManufacturingStepsLoading: false,
        fetchAllManufacturingStepsError: action.error,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_REQUESTED: {
      return {
        ...state,
        isBomForManufacturingStepsLoading: true,
        isBomForManufacturingStepsLoaded: false,
        fetchBomForManufacturingStepsError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_SUCCEEDED: {
      return {
        ...state,
        isBomForManufacturingStepsLoading: false,
        isBomForManufacturingStepsLoaded: true,
        bomForManufacturingSteps: action.bomForManufacturingSteps,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_ERROR: {
      return {
        ...state,
        isBomForManufacturingStepsLoading: false,
        isBomForManufacturingStepsLoaded: false,
        fetchBomForManufacturingStepsError: action.error,
      }
    }

    case actionTypes.CREATE_MANUFACTURING_STEPS_REQUESTED: {
      return {
        ...state,
        areManufacturingStepsCreating: true,
        areManufacturingStepsCreated: false,
        manufacturingStepsCreateError: null,
      }
    }

    case actionTypes.CREATE_MANUFACTURING_STEPS_SUCCEEDED: {
      return {
        ...state,
        areManufacturingStepsCreating: false,
        areManufacturingStepsCreated: true,
        allManufacturingSteps: [...state.allManufacturingSteps, action.manufacturingStepsDetails],
      }
    }

    case actionTypes.CREATE_MANUFACTURING_STEPS_ERROR: {
      return {
        ...state,
        areManufacturingStepsCreating: false,
        areManufacturingStepsCreated: false,
        manufacturingStepsCreateError: action.error,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_STEPS_DETAILS_REQUESTED: {
      return {
        ...state,
        manufacturingStepsDetails: null,
        fetchManufacturingStepsDetailsError: null,
        areManufacturingStepsDetailsLoading: true,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_STEPS_DETAILS_SUCCEEDED: {
      return {
        ...state,
        manufacturingStepsDetails: action.manufacturingStepsDetails,
        areManufacturingStepsDetailsLoading: false,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_STEPS_DETAILS_ERROR: {
      return {
        ...state,
        manufacturingStepsDetails: null,
        fetchManufacturingStepsDetailsError: action.error,
        areManufacturingStepsDetailsLoading: false,
      }
    }

    case actionTypes.EDIT_MANUFACTURING_STEPS_DETAILS_REQUESTED: {
      return {
        ...state,
        areManufacturingStepsDetailsEditSaving: true,
        areManufacturingStepsDetailsEditSaved: false,
        manufacturingStepsDetailsEditSaveError: null,
      }
    }

    case actionTypes.EDIT_MANUFACTURING_STEPS_DETAILS_SUCCEEDED: {
      return {
        ...state,
        areManufacturingStepsDetailsEditSaving: false,
        areManufacturingStepsDetailsEditSaved: true,
      }
    }

    case actionTypes.EDIT_MANUFACTURING_STEPS_DETAILS_ERROR: {
      return {
        ...state,
        areManufacturingStepsDetailsEditSaving: false,
        areManufacturingStepsDetailsEditSaved: false,
        manufacturingStepsDetailsEditSaveError: action.error,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_STEPS_REQUESTED: {
      return {
        ...state,
        areManufacturingStepsDeleting: true,
        areManufacturingStepsDeleted: false,
        manufacturingStepsDeleteError: null,

        currentlyManagedManufacturingStepsId: action.payload.id,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_STEPS_SUCCEEDED: {
      return {
        ...state,
        areManufacturingStepsDeleting: false,
        areManufacturingStepsDeleted: true,
        allManufacturingSteps: state.allManufacturingSteps.filter(
          (manufacturingSteps: actionTypes.IManufacturingSteps) =>
            manufacturingSteps.id !== action.manufacturingStepsDetails.id
        ),

        currentlyManagedManufacturingStepsId: null,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_STEPS_ERROR: {
      return {
        ...state,
        areManufacturingStepsDeleting: false,
        areManufacturingStepsDeleted: false,
        manufacturingStepsDeleteError: action.error,

        currentlyManagedManufacturingStepsId: null,
      }
    }

    case actionTypes.OPEN_MANUFACTURING_STEPS_DETAILS: {
      return {
        ...state,
        manufacturingStepsCreateError: null,
        fetchManufacturingStepsDetailsError: null,
        manufacturingStepsDeleteError: null,
      }
    }

    case actionTypes.CLOSE_MANUFACTURING_STEPS_DETAILS: {
      return {
        ...state,
        areManufacturingStepsCreating: false,
        areManufacturingStepsCreated: false,
        manufacturingStepsCreateError: null,

        areManufacturingStepsDetailsLoading: false,
        manufacturingStepsDetails: null,
        fetchManufacturingStepsDetailsError: null,

        areManufacturingStepsDetailsEditSaving: false,
        areManufacturingStepsDetailsEditSaved: false,
        manufacturingStepsDetailsEditSaveError: null,

        areManufacturingStepsDeleting: false,
        areManufacturingStepsDeleted: false,
        manufacturingStepsDeleteError: null,
      }
    }

    case actionTypes.MANUFACTURING_STEPS_DETAILS_ACTIONS_UNLOCK: {
      return {
        ...state,
        areManufacturingStepsDetailsEditSaved: false,
        areManufacturingStepsCreated: false,
        areManufacturingStepsDeleted: false,
        areManufacturingStepsDetailsEditSaving: false,
        areManufacturingStepsCreating: false,
        areManufacturingStepsDeleting: false,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_ACTIVITIES_REQUESTED: {
      return {
        ...state,
        fetchManufacturingActivitiesError: null,
        areManufacturingActivitiesLoading: true,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_ACTIVITIES_SUCCEEDED: {
      return {
        ...state,
        manufacturingActivities: action.manufacturingActivities,
        areManufacturingActivitiesLoading: false,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_ACTIVITIES_ERROR: {
      return {
        ...state,
        fetchManufacturingActivitiesError: action.error,
        areManufacturingActivitiesLoading: false,
      }
    }

    case actionTypes.SET_MANUFACTURING_ACTIVITIES_TABLE_FILTER_STATE: {
      return {
        ...state,
        manufacturingActivitiesTableFilterState: action.manufacturingActivitiesTableFilterState,
      }
    }

    case actionTypes.SET_MANUFACTURING_ACTIVITIES_TABLE_SORTER_STATE: {
      return {
        ...state,
        manufacturingActivitiesTableSorterState: action.manufacturingActivitiesTableSorterState,
      }
    }

    case actionTypes.CREATE_MANUFACTURING_ACTIVITY_REQUESTED: {
      return {
        ...state,
        manufacturingActivityCreateError: null,
        isManufacturingActivityCreating: true,
        isManufacturingActivityCreated: false,
      }
    }

    case actionTypes.CREATE_MANUFACTURING_ACTIVITY_SUCCEEDED: {
      return {
        ...state,
        isManufacturingActivityCreating: false,
        isManufacturingActivityCreated: true,
        manufacturingActivities: [...state.manufacturingActivities, action.manufacturingActivity],
      }
    }

    case actionTypes.CREATE_MANUFACTURING_ACTIVITY_ERROR: {
      return {
        ...state,
        manufacturingActivityCreateError: action.error,
        isManufacturingActivityCreating: false,
        isManufacturingActivityCreated: false,
      }
    }

    case actionTypes.EDIT_MANUFACTURING_ACTIVITY_REQUESTED: {
      return {
        ...state,
        manufacturingActivityEditSaveError: null,
        isManufacturingActivityEditSaving: true,
        isManufacturingActivityEditSaved: false,
      }
    }

    case actionTypes.EDIT_MANUFACTURING_ACTIVITY_SUCCEEDED: {
      return {
        ...state,
        isManufacturingActivityEditSaving: false,
        isManufacturingActivityEditSaved: true,
        manufacturingActivities: state.manufacturingActivities?.map(
          (manufacturingActivity: actionTypes.IManufacturingActivity) => {
            if (manufacturingActivity.id === action.manufacturingActivity.id) {
              return action.manufacturingActivity
            } else {
              return manufacturingActivity
            }
          }
        ),
      }
    }

    case actionTypes.EDIT_MANUFACTURING_ACTIVITY_ERROR: {
      return {
        ...state,
        manufacturingActivityEditSaveError: action.error,
        isManufacturingActivityEditSaving: false,
        isManufacturingActivityEditSaved: false,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_ACTIVITY_REQUESTED: {
      return {
        ...state,
        manufacturingActivityDeleteError: null,
        isManufacturingActivityDeleting: true,
        isManufacturingActivityDeleted: false,
        currentlyManagedManufacturingActivityId: action.payload.id,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_ACTIVITY_SUCCEEDED: {
      return {
        ...state,
        isManufacturingActivityDeleting: false,
        isManufacturingActivityDeleted: true,
        manufacturingActivities: state.manufacturingActivities.filter(
          (manufacturingActivity: actionTypes.IManufacturingActivity) =>
            manufacturingActivity.id !== action.id
        ),
        currentlyManagedManufacturingActivityId: null,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_ACTIVITY_ERROR: {
      return {
        ...state,
        manufacturingActivityDeleteError: action.error,
        isManufacturingActivityDeleting: false,
        isManufacturingActivityDeleted: false,
        currentlyManagedManufacturingActivityId: null,
      }
    }

    case actionTypes.OPEN_MANUFACTURING_ACTIVITY_MODAL: {
      return {
        ...state,
        manufacturingActivityModalVisibility: true,
        editedManufacturingActivity: action.editedManufacturingActivity,
      }
    }

    case actionTypes.CLOSE_MANUFACTURING_ACTIVITY_MODAL: {
      return {
        ...state,
        manufacturingActivityCreateError: null,
        manufacturingActivityEditSaveError: null,
        manufacturingActivityDeleteError: null,

        isManufacturingActivityCreating: false,
        isManufacturingActivityEditSaving: false,
        isManufacturingActivityDeleting: false,

        isManufacturingActivityCreated: false,
        isManufacturingActivityEditSaved: false,
        isManufacturingActivityDeleted: false,

        manufacturingActivityModalVisibility: false,
        editedManufacturingActivity: null,
      }
    }

    case actionTypes.FETCH_TEAM_MEMBERS_REQUESTED: {
      return {
        ...state,
        fetchTeamMembersError: null,
        areTeamMembersLoading: true,
      }
    }

    case actionTypes.FETCH_TEAM_MEMBERS_SUCCEEDED: {
      return {
        ...state,
        teamMembers: action.teamMembers,
        areTeamMembersLoading: false,
      }
    }

    case actionTypes.FETCH_TEAM_MEMBERS_ERROR: {
      return {
        ...state,
        fetchTeamMembersError: action.error,
        areTeamMembersLoading: false,
      }
    }

    case actionTypes.SET_TEAM_MEMBERS_TABLE_FILTER_STATE: {
      return {
        ...state,
        teamMembersTableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_TEAM_MEMBERS_TABLE_SORTER_STATE: {
      return {
        ...state,
        teamMembersTableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.EDIT_TEAM_MEMBER_REQUESTED: {
      return {
        ...state,
        teamMemberEditSaveError: null,
        isTeamMemberEditSaving: true,
        isTeamMemberEditSaved: false,
      }
    }

    case actionTypes.EDIT_TEAM_MEMBER_SUCCEEDED: {
      return {
        ...state,
        isTeamMemberEditSaving: false,
        isTeamMemberEditSaved: true,
        teamMembers: state.teamMembers?.map((teamMember: any) => {
          if (teamMember.id === action.teamMember.id) {
            return action.teamMember
          } else {
            return teamMember
          }
        }),
      }
    }

    case actionTypes.EDIT_TEAM_MEMBER_ERROR: {
      return {
        ...state,
        teamMemberEditSaveError: action.error,
        isTeamMemberEditSaving: false,
        isTeamMemberEditSaved: false,
      }
    }

    // MANUFACTURING_ERRANDS

    case actionTypes.FETCH_MANUFACTURING_ERRANDS_REQUESTED: {
      return {
        ...state,
        fetchManufacturingErrandsError: null,
        areManufacturingErrandsLoading: true,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_ERRANDS_SUCCEEDED: {
      return {
        ...state,
        manufacturingErrands: action.manufacturingErrands,
        areManufacturingErrandsLoading: false,
      }
    }

    case actionTypes.FETCH_MANUFACTURING_ERRANDS_ERROR: {
      return {
        ...state,
        fetchManufacturingErrandsError: action.error,
        areManufacturingErrandsLoading: false,
      }
    }

    case actionTypes.SET_MANUFACTURING_ERRANDS_TABLE_FILTER_STATE: {
      return {
        ...state,
        manufacturingErrandsTableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_MANUFACTURING_ERRANDS_TABLE_SORTER_STATE: {
      return {
        ...state,
        manufacturingErrandsTableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_MANUFACTURING_ERRAND_REQUESTED: {
      return {
        ...state,
        manufacturingErrandCreateError: null,
        isManufacturingErrandCreating: true,
        isManufacturingErrandCreated: false,
      }
    }

    case actionTypes.CREATE_MANUFACTURING_ERRAND_SUCCEEDED: {
      return {
        ...state,
        isManufacturingErrandCreating: false,
        isManufacturingErrandCreated: true,
        manufacturingErrands: [...state.manufacturingErrands, action.manufacturingErrand],
      }
    }

    case actionTypes.CREATE_MANUFACTURING_ERRAND_ERROR: {
      return {
        ...state,
        manufacturingErrandCreateError: action.error,
        isManufacturingErrandCreating: false,
        isManufacturingErrandCreated: false,
      }
    }

    case actionTypes.EDIT_MANUFACTURING_ERRAND_REQUESTED: {
      return {
        ...state,
        manufacturingErrandEditSaveError: null,
        isManufacturingErrandEditSaving: true,
        isManufacturingErrandEditSaved: false,
      }
    }

    case actionTypes.EDIT_MANUFACTURING_ERRAND_SUCCEEDED: {
      return {
        ...state,
        isManufacturingErrandEditSaving: false,
        isManufacturingErrandEditSaved: true,
        manufacturingErrands: state.manufacturingErrands?.map(
          (manufacturingErrand: actionTypes.TManufacturingErrand) => {
            if (manufacturingErrand.id === action.manufacturingErrand.id) {
              return action.manufacturingErrand
            } else {
              return manufacturingErrand
            }
          }
        ),
      }
    }

    case actionTypes.EDIT_MANUFACTURING_ERRAND_ERROR: {
      return {
        ...state,
        manufacturingErrandEditSaveError: action.error,
        isManufacturingErrandEditSaving: false,
        isManufacturingErrandEditSaved: false,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_ERRAND_REQUESTED: {
      return {
        ...state,
        manufacturingErrandDeleteError: null,
        isManufacturingErrandDeleting: true,
        isManufacturingErrandDeleted: false,
        currentlyManagedManufacturingErrandId: action.payload.id,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_ERRAND_SUCCEEDED: {
      return {
        ...state,
        isManufacturingErrandDeleting: false,
        isManufacturingErrandDeleted: true,
        manufacturingErrands: state.manufacturingErrands.filter(
          (manufacturingErrand: actionTypes.TManufacturingErrand) =>
            manufacturingErrand.id !== action.id
        ),
        currentlyManagedManufacturingErrandId: null,
      }
    }

    case actionTypes.DELETE_MANUFACTURING_ERRAND_ERROR: {
      return {
        ...state,
        manufacturingErrandDeleteError: action.error,
        isManufacturingErrandDeleting: false,
        isManufacturingErrandDeleted: false,
        currentlyManagedManufacturingErrandId: null,
      }
    }

    case actionTypes.OPEN_MANUFACTURING_ERRAND_MODAL: {
      return {
        ...state,
        manufacturingErrandModalVisibility: true,
        editedManufacturingErrand: action.editedManufacturingErrand,
      }
    }

    case actionTypes.CLOSE_MANUFACTURING_ERRAND_MODAL: {
      return {
        ...state,
        manufacturingErrandCreateError: null,
        manufacturingErrandEditSaveError: null,
        manufacturingErrandDeleteError: null,

        isManufacturingErrandCreating: false,
        isManufacturingErrandEditSaving: false,
        isManufacturingErrandDeleting: false,

        isManufacturingErrandCreated: false,
        isManufacturingErrandEditSaved: false,
        isManufacturingErrandDeleted: false,

        manufacturingErrandModalVisibility: false,
        editedManufacturingErrand: null,
      }
    }

    default:
      return state
  }
}
