import { call, delay, put, takeLatest } from 'redux-saga/effects'

import { deleteItemCall, profilesCall, sectionFetch, singleItemFetch, userCall } from './api'
import * as actionTypes from '../action-types'
import { editSuccessMessageDuration, successMessageDuration } from '../../utils'

export function* fetchUsers(action: actionTypes.fetchUsersAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'users')

    yield put({ type: actionTypes.FETCH_USERS_SUCCEEDED, users: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_USERS_ERROR,
      error: error?.response,
    })
  }
}

export function* createUser(action: actionTypes.createUserAction) {
  try {
    const response = yield call(userCall, action.payload)

    yield put({
      type: actionTypes.CREATE_BOM_ELEMENT_SUCCEEDED,
      user: action.payload.user,
    })

    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.USER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_USER_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.USER_ACTIONS_UNLOCK,
    })
  }
}

export function* fetchSingleUser(action: actionTypes.fetchSingleUserAction) {
  try {
    const response = yield call(singleItemFetch, action.payload, `users/edit/${action.payload.id}`)

    yield put({
      type: actionTypes.FETCH_SINGLE_USER_SUCCEEDED,
      user: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_USER_ERROR,
      error: error?.response,
    })
  }
}

export function* editUser(action: actionTypes.editUserAction) {
  try {
    yield call(userCall, action.payload)

    yield put({
      type: actionTypes.EDIT_USER_SUCCEEDED,
      user: action.payload.user,
    })

    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.USER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_USER_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.USER_ACTIONS_UNLOCK,
    })
  }
}

export function* deleteUser(action: actionTypes.deleteUserAction) {
  try {
    yield call(deleteItemCall, action.payload, 'users')
    yield put({
      type: actionTypes.DELETE_USER_SUCCEEDED,
      id: action.payload.id,
    })

    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.USER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_USER_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.USER_ACTIONS_UNLOCK,
    })
  }
}

export function* fetchProfiles(action: actionTypes.fetchProfilesAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'usersProfiles')

    yield put({ type: actionTypes.FETCH_PROFILES_SUCCEEDED, profiles: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_PROFILES_ERROR,
      error: error?.response,
    })
  }
}

export function* saveProfiles(action: actionTypes.saveProfilesAction) {
  try {
    let response = yield call(profilesCall, action.payload)

    yield put({
      type: actionTypes.SAVE_PROFILES_SUCCEEDED,
      profiles: action.payload.profiles,
    })

    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.PROFILES_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.SAVE_PROFILES_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.PROFILES_ACTIONS_UNLOCK,
    })
  }
}

export function* usersSaga() {
  yield takeLatest(actionTypes.FETCH_USERS_REQUESTED, fetchUsers)
  yield takeLatest(actionTypes.CREATE_USER_REQUESTED, createUser)
  yield takeLatest(actionTypes.FETCH_SINGLE_USER_REQUESTED, fetchSingleUser)
  yield takeLatest(actionTypes.EDIT_USER_REQUESTED, editUser)
  yield takeLatest(actionTypes.DELETE_USER_REQUESTED, deleteUser)

  yield takeLatest(actionTypes.FETCH_PROFILES_REQUESTED, fetchProfiles)
  yield takeLatest(actionTypes.SAVE_PROFILES_REQUESTED, saveProfiles)
}
