import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  bomElementCall,
  sectionFetch,
  deleteItemCall,
  copyItemCall,
  archiveItemCall,
  addSingleAttachmentCall,
  itemAttachmentsFetch,
  singleItemFetch,
} from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration, editSuccessMessageDuration } from '../../utils'

export function* fetchBomElements(action: actionTypes.fetchBomElementsAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'bomElements')

    yield put({ type: actionTypes.FETCH_BOM_ELEMENTS_SUCCEEDED, bomElements: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_BOM_ELEMENTS_ERROR,
      error: error?.response,
    })
  }
}

export function* createBomElement(action: actionTypes.createBomElementAction) {
  try {
    const response = yield call(bomElementCall, action.payload)

    yield put({
      type: actionTypes.CREATE_BOM_ELEMENT_SUCCEEDED,
      bomElement: { ...action.payload.bomElement, canDelete: true, id: response.data.id },
    })

    if (response?.data?.producerId && action?.payload?.bomElement?.producerId === -1) {
      yield put({
        type: actionTypes.ADD_PRODUCER_TO_STORE,
        producer: {
          name: action.payload?.bomElement?.producerName || '',
          id: response?.data?.producerId || null,
          code: '',
          canDelete: false,
        },
      })
    }
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_BOM_ELEMENT_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
    })
  }
}

export function* fetchSingleBomElement(action: actionTypes.fetchSingleBomElementAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `bomElements/edit/${action.payload.id}`
    )
    yield put({
      type: actionTypes.FETCH_SINGLE_BOM_ELEMENT_SUCCEEDED,
      bomElement: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_BOM_ELEMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* editBomElement(action: actionTypes.editBomElementAction) {
  try {
    let response = yield call(bomElementCall, action.payload)
    yield put({
      type: actionTypes.EDIT_BOM_ELEMENT_SUCCEEDED,
      bomElement: action.payload.bomElement,
    })

    if (response?.data?.producerId && action?.payload?.bomElement?.producerId === -1) {
      yield put({
        type: actionTypes.ADD_PRODUCER_TO_STORE,
        producer: {
          name: action.payload?.bomElement?.producerName || '',
          id: response?.data?.producerId || null,
          code: '',
          canDelete: false,
        },
      })
    }
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_BOM_ELEMENT_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
    })
  }
}

export function* copyBomElement(action: actionTypes.copyBomElementAction) {
  try {
    const response = yield call(copyItemCall, action.payload, 'bomElements')

    if (response.data) {
      yield put({
        type: actionTypes.COPY_BOM_ELEMENT_SUCCEEDED,
        bomElement: { ...response.data },
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.COPY_BOM_ERROR,
      error: error?.response,
    })
  }
}

export function* archiveBomElement(action: actionTypes.archiveBomElementAction) {
  try {
    yield call(archiveItemCall, action.payload, 'bomElements')
    yield put({
      type: actionTypes.ARCHIVE_BOM_ELEMENT_SUCCEEDED,
      bomElement: { id: action.payload.id },
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.ARCHIVE_BOM_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteBomElement(action: actionTypes.deleteBomElementAction) {
  try {
    yield call(deleteItemCall, action.payload, 'bomElements')
    yield put({
      type: actionTypes.DELETE_BOM_ELEMENT_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_BOM_ELEMENT_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
    })
  }
}

export function* fetchBomElementBoms(action: actionTypes.fetchBomElementBomsAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `bomElements/getBomElementBoms/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_BOM_ELEMENT_BOMS_SUCCEEDED,
      bomElementBoms: response?.data?.boms || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_BOM_ELEMENT_BOMS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchBomElementOffers(action: actionTypes.fetchBomElementOffersAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `bomElements/getBomElementOffers/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_BOM_ELEMENT_OFFERS_SUCCEEDED,
      bomElementOffers: response?.data?.offers || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_BOM_ELEMENT_OFFERS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchBomElementAttachments(action: actionTypes.fetchBomElementOffersAction) {
  try {
    const response = yield call(itemAttachmentsFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_BOM_ELEMENT_ATTACHMENTS_SUCCEEDED,
      bomElementAttachments: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_BOM_ELEMENT_ATTACHMENTS_ERROR,
      error: error?.response,
    })
  }
}

export function* uploadBomElementAttachment(action: actionTypes.uploadBomElementAttachmentAction) {
  try {
    const response = yield call(addSingleAttachmentCall, action.payload)

    yield put({
      type: actionTypes.UPLOAD_BOM_ELEMENT_ATTACHMENT_SUCCEEDED,
      attachment: {
        id: response.data?.id,
        name: response.data?.name || '',
      },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.UPLOAD_BOM_ELEMENT_ATTACHMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* bomElementsSaga() {
  yield takeLatest(actionTypes.FETCH_BOM_ELEMENTS_REQUESTED, fetchBomElements)
  yield takeLatest(actionTypes.FETCH_SINGLE_BOM_ELEMENT_REQUESTED, fetchSingleBomElement)
  yield takeLatest(actionTypes.FETCH_BOM_ELEMENT_BOMS_REQUESTED, fetchBomElementBoms)
  yield takeLatest(actionTypes.FETCH_BOM_ELEMENT_OFFERS_REQUESTED, fetchBomElementOffers)
  yield takeLatest(actionTypes.FETCH_BOM_ELEMENT_ATTACHMENTS_REQUESTED, fetchBomElementAttachments)
  yield takeLatest(actionTypes.UPLOAD_BOM_ELEMENT_ATTACHMENT_REQUESTED, uploadBomElementAttachment)

  yield takeLatest(actionTypes.CREATE_BOM_ELEMENT_REQUESTED, createBomElement)
  yield takeLatest(actionTypes.EDIT_BOM_ELEMENT_REQUESTED, editBomElement)

  yield takeLatest(actionTypes.COPY_BOM_ELEMENT_REQUESTED, copyBomElement)
  yield takeLatest(actionTypes.ARCHIVE_BOM_ELEMENT_REQUESTED, archiveBomElement)
  yield takeLatest(actionTypes.DELETE_BOM_ELEMENT_REQUESTED, deleteBomElement)
}
