import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { CModal, CModalHeader } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { BasicFormField, FormActionsPanel, CustomErrorMessage } from '../../components'
import { getErrorMessageFromStatus, successMessageDuration } from '../../utils'

interface IManufacturingActivityModal {
  mode: actionTypes.TFormMode
}

export const ManufacturingActivityModal: React.FC<IManufacturingActivityModal> = ({ mode }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)

  const isManufacturingActivityEditable =
    authState?.authData?.roles?.includes('Manufacturing_write') &&
    (mode === 'edit' ? !!manufacturingState.editedManufacturingActivity : true)

  const closeManufacturingActivityModal = useCallback(() => {
    history.push(`/${tenant}/manufacturing-activities`)
  }, [dispatch, history, tenant])

  // Close modal on a successful create or delete
  useEffect(() => {
    if (
      (!manufacturingState.isManufacturingActivityCreating &&
        !manufacturingState.manufacturingActivityCreateError &&
        manufacturingState.isManufacturingActivityCreated) ||
      (!manufacturingState.isManufacturingActivityDeleting &&
        !manufacturingState.manufacturingActivityDeleteError &&
        manufacturingState.isManufacturingActivityDeleted)
    ) {
      setTimeout(() => {
        closeManufacturingActivityModal()
      }, successMessageDuration)
    }
  }, [
    manufacturingState.isManufacturingActivityCreated,
    manufacturingState.isManufacturingActivityDeleted,
    closeManufacturingActivityModal,
  ])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_MANUFACTURING_ACTIVITY_MODAL,
      })
    }
  }, [])

  const ManufacturingActivitySchema = Yup.object().shape({
    name: Yup.string()
      .required('To pole jest wymagane!')
      .test('name', 'Wpisana czynność już istnieje!', (manufacturingActivityNameInInput) =>
        manufacturingState.editedManufacturingActivity?.name.toLowerCase() !==
        String(manufacturingActivityNameInInput).toLowerCase()
          ? !manufacturingState.manufacturingActivities?.find(
              (manufacturingActivityInList: actionTypes.IManufacturingActivity) =>
                manufacturingActivityInList?.name.toLowerCase() ===
                String(manufacturingActivityNameInInput).toLowerCase()
            )
          : true
      ),
  })

  return (
    <CModal
      onClose={() => {
        closeManufacturingActivityModal()
      }}
      size=""
      show={manufacturingState.manufacturingActivityModalVisibility}
      centered
      backdrop
      addContentClass="producer-modal py-4 px-5"
    >
      <CModalHeader className="c-modal-header-grid" closeButton>
        <h4 className="mb-3 pb-1 text-center">
          {mode === 'edit'
            ? !authState?.authData?.roles?.includes('Manufacturing_write')
              ? 'Przeglądaj Czynność'
              : 'Edytuj Czynność'
            : 'Nowa Czynność'}
        </h4>
      </CModalHeader>
      <Formik
        initialValues={{
          name: manufacturingState.editedManufacturingActivity?.name || '',
        }}
        validationSchema={ManufacturingActivitySchema}
        onSubmit={(values) => {
          if (isManufacturingActivityEditable && values?.name) {
            dispatch({
              type:
                mode === 'create'
                  ? actionTypes.CREATE_MANUFACTURING_ACTIVITY_REQUESTED
                  : actionTypes.EDIT_MANUFACTURING_ACTIVITY_REQUESTED,
              payload: {
                tenant: tenant,
                token: authState.authData?.token,
                manufacturingActivity: {
                  name: values.name,
                  canDelete: true,
                  ...(manufacturingState.editedManufacturingActivity?.id && {
                    id: manufacturingState.editedManufacturingActivity?.id,
                  }),
                },
              },
            })
          }
        }}
        validateOnBlur={false}
        validateOnChange={didFormValidationOccur}
      >
        {({ initialValues, values, errors }) => (
          <Form>
            {/*
             * Display Network Error Message
             */}

            {!errors.name && manufacturingState?.manufacturingActivityCreateError && (
              <CustomErrorMessage
                wrapperClassNames="mb-3 mt-0"
                customErrorMessageText={getErrorMessageFromStatus(
                  'create',
                  manufacturingState?.manufacturingActivityCreateError?.status
                )}
              />
            )}

            {!errors.name && manufacturingState?.manufacturingActivityEditSaveError && (
              <CustomErrorMessage
                wrapperClassNames="mb-3 mt-0"
                customErrorMessageText={getErrorMessageFromStatus(
                  'edit',
                  manufacturingState?.manufacturingActivityEditSaveError?.status,
                  'czynności'
                )}
              />
            )}

            {manufacturingState?.manufacturingActivityDeleteError && (
              <CustomErrorMessage
                wrapperClassNames="mb-3 mt-0"
                customErrorMessageText={getErrorMessageFromStatus(
                  'delete',
                  manufacturingState?.manufacturingActivityDeleteError?.status,
                  'czynności'
                )}
              />
            )}

            <BasicFormField
              fieldId="manufacturing-activity-name"
              fieldLabel="Nazwa"
              fieldIcon="cil-short-text"
              formikFieldName="name"
              fieldValue={values.name}
              fieldError={errors?.name}
              fieldType="text"
              placeholder="Wprowadź nazwę czynności"
            />

            <FormActionsPanel
              mode={mode}
              padding="pt-4 pb-1"
              isSaving={
                manufacturingState.isManufacturingActivityCreating ||
                manufacturingState.isManufacturingActivityEditSaving
              }
              isSaved={
                mode === 'create'
                  ? manufacturingState.isManufacturingActivityCreated
                  : manufacturingState.isManufacturingActivityEditSaved
              }
              isDeleting={manufacturingState.isManufacturingActivityDeleting}
              isDeleted={manufacturingState.isManufacturingActivityDeleted}
              setDidFormValidationOccur={setDidFormValidationOccur}
              didFormValidationOccur={didFormValidationOccur}
              formErrorsBool={Boolean(errors.name)}
              closeAction={actionTypes.CLOSE_MANUFACTURING_ACTIVITY_MODAL}
              deleteAction={actionTypes.DELETE_MANUFACTURING_ACTIVITY_REQUESTED}
              deletePayload={{
                tenant: tenant,
                token: authState.authData?.token,
                id: manufacturingState.editedManufacturingActivity?.id,
              }}
              closeFunction={closeManufacturingActivityModal}
              canDelete={Boolean(manufacturingState.editedManufacturingActivity?.canDelete)}
              isEditable={isManufacturingActivityEditable}
              disabledDeleteButtonClassNames="delete-details-button"
              confirmDeleteMessageJSX={
                <>
                  Czy na pewno chcesz usunąć czynność?
                  {initialValues?.name ? (
                    <>
                      <br />
                      <strong>{initialValues?.name}</strong>
                    </>
                  ) : (
                    ''
                  )}
                </>
              }
            />
          </Form>
        )}
      </Formik>
    </CModal>
  )
}
