import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  sectionFetch,
  warehouseCall,
  deleteItemCall,
  singleItemFetch,
  waresReplenishmentCall,
  moveReplenishmentCall,
  saveWarehouseInventoryControlCall,
  manufacturingReplenishmentCall,
  deliveryMaterialsReplenishmentCall,
  manufacturingErrandMaterialsReplenishmentCall,
  finishedProductsReplenishmentCall,
  documentsOfWarehousesFetch,
} from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration, editSuccessMessageDuration } from '../../utils'

export function* fetchWarehouses(action: actionTypes.fetchWarehousesAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'warehouses')
    yield put({ type: actionTypes.FETCH_WAREHOUSES_SUCCEEDED, warehouses: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_WAREHOUSES_ERROR,
      error: error?.response,
    })
  }
}

export function* createWarehouse(action: actionTypes.createWarehouseAction) {
  try {
    const response = yield call(warehouseCall, action.payload)
    yield put({
      type: actionTypes.CREATE_WAREHOUSE_SUCCEEDED,
      warehouse: { name: action.payload.warehouse.name, id: response.data.id, canDelete: true },
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_WAREHOUSE_ERROR,
      error: error?.response,
    })

    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* editWarehouse(action: actionTypes.editWarehouseAction) {
  try {
    yield call(warehouseCall, action.payload)
    yield put({
      type: actionTypes.EDIT_WAREHOUSE_SUCCEEDED,
      warehouse: {
        name: action.payload.warehouse.name,
        id: action.payload.warehouse.id,
        canDelete: action.payload.canDelete,
      },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.WAREHOUSE_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_WAREHOUSE_ERROR,
      error: error?.response,
    })

    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* deleteWarehouse(action: actionTypes.deleteWarehouseAction) {
  try {
    yield call(deleteItemCall, action.payload, 'warehouses')
    yield put({
      type: actionTypes.DELETE_WAREHOUSE_SUCCEEDED,
      warehouse: { id: action.payload.id },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.CLOSE_WAREHOUSE_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_WAREHOUSE_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchWarehouseManagementDeliveries(
  action: actionTypes.fetchWarehouseManagementDeliveriesAction
) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `warehouses/deliveries/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_SUCCEEDED,
      managedWarehouseDeliveries: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchWarehouseInventoryControlData(
  action: actionTypes.fetchWarehouseManagementDeliveriesAction
) {
  try {
    const response = yield call(
      sectionFetch,
      action.payload,
      `warehouses/inventoryControl/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED,
      warehouseInventoryControlData: {
        inventoryControlTotalPrice: response?.data?.inventoryControlTotalPrice,
        inventoryControlTotalPriceCurrency: response?.data?.inventoryControlTotalPriceCurrency,
        details: response?.data?.details,
      },
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR,
      error: error?.response,
    })
  }
}

export function* saveWarehouseInventoryControlData(
  action: actionTypes.saveWarehouseInventoryControlDataAction
) {
  try {
    yield call(saveWarehouseInventoryControlCall, action.payload)

    yield put({
      type: actionTypes.SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED,
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.UNLOCK_WAREHOUSE_INVENTORY_CONTROL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchInventoriesOfWarehouses(
  action: actionTypes.fetchInventoriesOfWarehousesAction
) {
  try {
    const response = yield call(sectionFetch, action.payload, 'warehouses/inventory')
    yield put({
      type: actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_SUCCEEDED,
      inventoriesOfWarehouses: response?.data?.details || null,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchDocumentsOfWarehouses(action: actionTypes.fetchDocumentsOfWarehousesAction) {
  try {
    const response = yield call(documentsOfWarehousesFetch, action.payload)

    yield put({
      type: actionTypes.FETCH_DOCUMENTS_OF_WAREHOUSES_SUCCEEDED,
      documentsOfWarehouses: response?.data || null,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_DOCUMENTS_OF_WAREHOUSES_ERROR,
      error: error?.response,
    })
  }
}

export function* generateDeliveryMaterialsReplenishmentDocument(
  action: actionTypes.generateDeliveryMaterialsReplenishmentDocumentAction
) {
  try {
    yield call(deliveryMaterialsReplenishmentCall, action.payload)
    yield put({
      type: actionTypes.GENERATE_DELIVERY_MATERIALS_REPLENISHMENT_DOCUMENT_SUCCEEDED,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_DELIVERY_MATERIALS_REPLENISHMENT_DOCUMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* generateManufacturingErrandMaterialsReplenishmentDocument(
  action: actionTypes.generateManufacturingErrandMaterialsReplenishmentDocumentAction
) {
  try {
    yield call(manufacturingErrandMaterialsReplenishmentCall, action.payload)
    yield put({
      type: actionTypes.GENERATE_MANUFACTURING_ERRAND_MATERIALS_REPLENISHMENT_DOCUMENT_SUCCEEDED,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_MANUFACTURING_ERRAND_MATERIALS_REPLENISHMENT_DOCUMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* generateWaresReplenishmentDocument(
  action: actionTypes.generateWaresReplenishmentDocumentAction
) {
  try {
    yield call(waresReplenishmentCall, action.payload)
    yield put({
      type: actionTypes.GENERATE_WARES_REPLENISHMENT_DOCUMENT_SUCCEEDED,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_WARES_REPLENISHMENT_DOCUMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* generateFinishedProductsReplenishmentDocument(
  action: actionTypes.generateFinishedProductsReplenishmentDocumentAction
) {
  try {
    yield call(finishedProductsReplenishmentCall, action.payload)
    yield put({
      type: actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_SUCCEEDED,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* generateMoveReplenishmentDocument(
  action: actionTypes.generateMoveReplenishmentDocumentAction
) {
  try {
    yield call(moveReplenishmentCall, action.payload)
    yield put({
      type: actionTypes.GENERATE_MOVE_REPLENISHMENT_DOCUMENT_SUCCEEDED,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_MOVE_REPLENISHMENT_DOCUMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* generateManufacturingReplenishmentDocument(
  action: actionTypes.generateManufacturingReplenishmentDocumentAction
) {
  try {
    yield call(manufacturingReplenishmentCall, action.payload)
    yield put({
      type: actionTypes.GENERATE_MANUFACTURING_REPLENISHMENT_DOCUMENT_SUCCEEDED,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_MANUFACTURING_REPLENISHMENT_DOCUMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchUserPossessions(action: actionTypes.fetchUserPossessionsAction) {
  try {
    const response = yield call(
      sectionFetch,
      action.payload,
      `userPossessions/${action.payload.userId}`
    )

    yield put({
      type: actionTypes.FETCH_USER_POSSESSIONS_SUCCEEDED,
      userPossessions: response?.data || response?.data?.details || null,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_USER_POSSESSIONS_ERROR,
      error: error?.response,
    })
  }
}

export function* warehousesSaga() {
  yield takeLatest(actionTypes.FETCH_WAREHOUSES_REQUESTED, fetchWarehouses)

  yield takeLatest(actionTypes.CREATE_WAREHOUSE_REQUESTED, createWarehouse)
  yield takeLatest(actionTypes.EDIT_WAREHOUSE_REQUESTED, editWarehouse)
  yield takeLatest(actionTypes.DELETE_WAREHOUSE_REQUESTED, deleteWarehouse)

  yield takeLatest(
    actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED,
    fetchWarehouseManagementDeliveries
  )

  yield takeLatest(
    actionTypes.FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED,
    fetchWarehouseInventoryControlData
  )

  yield takeLatest(
    actionTypes.SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED,
    saveWarehouseInventoryControlData
  )

  yield takeLatest(
    actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED,
    fetchInventoriesOfWarehouses
  )

  yield takeLatest(
    actionTypes.GENERATE_DELIVERY_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED,
    generateDeliveryMaterialsReplenishmentDocument
  )

  yield takeLatest(
    actionTypes.GENERATE_MANUFACTURING_ERRAND_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED,
    generateManufacturingErrandMaterialsReplenishmentDocument
  )

  yield takeLatest(
    actionTypes.GENERATE_WARES_REPLENISHMENT_DOCUMENT_REQUESTED,
    generateWaresReplenishmentDocument
  )

  yield takeLatest(
    actionTypes.GENERATE_MANUFACTURING_REPLENISHMENT_DOCUMENT_REQUESTED,
    generateManufacturingReplenishmentDocument
  )

  yield takeLatest(
    actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_REQUESTED,
    generateFinishedProductsReplenishmentDocument
  )

  yield takeLatest(
    actionTypes.GENERATE_MOVE_REPLENISHMENT_DOCUMENT_REQUESTED,
    generateMoveReplenishmentDocument
  )

  yield takeLatest(actionTypes.FETCH_DOCUMENTS_OF_WAREHOUSES_REQUESTED, fetchDocumentsOfWarehouses)

  yield takeLatest(actionTypes.FETCH_USER_POSSESSIONS_REQUESTED, fetchUserPossessions)
}
