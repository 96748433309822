import React, { useState, useCallback, useEffect, RefObject, useRef, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Container, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, ErrorMessage, FastField, FieldArray, Field } from 'formik'
import { makeStyles, Select } from '@material-ui/core'
import CIcon from '@coreui/icons-react'
import NumberFormat from 'react-number-format'
import * as Yup from 'yup'
import {
  CLabel,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CCard,
  CCardBody,
  CButton,
  CInput,
} from '@coreui/react'
import { cilMinus, cilPlus } from '@coreui/icons'
import deepEqual from 'deep-equal'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  FormActionsPanel,
  BasicFormField,
  ThreeDots,
  CountrySelectField,
  CustomErrorMessage,
} from '../../components'
import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  handleNumberFormatPaste,
  convertNumericStringToNumber,
  successMessageDuration,
  globalThousandSeparator,
  globalDecimalSeparator,
  countries,
  TCountry,
  getErrorMessageFromStatus,
  preventNavigationChange,
} from '../../utils'
import { TFormTab, topLabelsOffsetFromNavigationTabs } from '../erp'

const useStylesForSupplier = makeStyles({
  root: {
    background: 'transparent',
    borderColor: '#fff',
    height: '35px',
    maxHeight: '35px',
    padding: '1px 14px',
    width: '100px',
    fontSize: '0.875rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
})

export const supplierNavTabs: TFormTab[] = [
  { name: 'main', title: 'Główne', isEditOnly: false },
  { name: 'trade', title: 'Handlowe', isEditOnly: false },
  { name: 'address', title: 'Adres', isEditOnly: false },
]

export const SupplierDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, mode, id, tab } = useParams<{
    tenant: string
    mode: actionTypes.TFormMode
    id: string
    tab: 'main' | 'trade' | 'address'
  }>()
  const paramsSupplierId = id
  const paramsSupplierTab = tab

  const materialUISelectClasses = useStylesForSupplier()

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isSupplierModifiedAndUnsaved, setIsSupplierModifiedAndUnsaved] = useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const suppliersState = useSelector((state: TRootState) => state.suppliers)

  const refCountryTypeahead: RefObject<any> = useRef()

  const isSupplierEditable =
    authState?.authData?.roles?.includes('Suppliers_write') &&
    !suppliersState?.singleSupplierFetchError

  const isThereNetworkError =
    suppliersState?.supplierCreateError?.status ||
    suppliersState?.supplierEditSaveError?.status ||
    suppliersState?.supplierDeleteError?.status ||
    suppliersState?.singleSupplierFetchError?.status

  const closeSupplierDetails = useCallback(() => {
    history.push(`/${tenant}/suppliers`)
  }, [dispatch, history, tenant])

  // Set data for editedSupplier in details view when clicked on row or opened in new window
  useEffect(() => {
    if (mode === 'edit') {
      dispatch({
        type: actionTypes.FETCH_SINGLE_SUPPLIER_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsSupplierId },
      })
    }

    if (mode === 'create') {
      dispatch({ type: actionTypes.SET_EDITED_SUPPLIER, supplier: null })
    }
  }, [dispatch, tenant, authState, paramsSupplierId, mode])

  // Fix the path in edit
  useEffect(() => {
    if (tenant && authState.authData) {
      const tabsArray = supplierNavTabs?.map((navTab: TFormTab) =>
        mode === 'edit' ? navTab.name : !navTab.isEditOnly ? navTab.name : undefined
      )

      if (paramsSupplierTab === undefined || !tabsArray.includes(paramsSupplierTab)) {
        history.replace(
          `/${tenant}/suppliers/${mode === 'create' ? 'create' : `edit/${paramsSupplierId}`}/${
            supplierNavTabs[0].name
          }`
        )
      }
    }
  }, [tenant, authState, history, paramsSupplierId, paramsSupplierTab, mode])

  // Close the view on a successful supplier create, save, delete
  useEffect(() => {
    if (
      (!suppliersState.isSupplierCreating &&
        !suppliersState.supplierCreateError &&
        suppliersState.isSupplierCreated) ||
      (!suppliersState.isSupplierDeleting &&
        !suppliersState.supplierDeleteError &&
        suppliersState.isSupplierDeleted)
    ) {
      setTimeout(() => {
        closeSupplierDetails()
      }, successMessageDuration)
    }
  }, [suppliersState.isSupplierCreated, suppliersState.isSupplierDeleted, closeSupplierDetails])

  // Track the changes again
  useEffect(() => {
    if (
      suppliersState.isSupplierEditSaved ||
      suppliersState.isSupplierCreated ||
      suppliersState.isSupplierDeleted
    ) {
      setIsSupplierModifiedAndUnsaved(false)
    }
  }, [
    suppliersState.isSupplierEditSaved,
    suppliersState.isSupplierCreated,
    suppliersState.isSupplierDeleted,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isSupplierModifiedAndUnsaved,
      isSupplierEditable,
      'suppliers',
      paramsSupplierId,
      mode
    )
  }, [location.pathname, history, isSupplierModifiedAndUnsaved, isSupplierEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.SET_EDITED_SUPPLIER, supplier: null })
      dispatch({ type: actionTypes.SUPPLIER_ACTIONS_UNLOCK })
    }
  }, [])

  const DiscountSchema = Yup.object().shape({
    prepaidPaymentInfos: Yup.array()
      .of(
        Yup.object().shape({
          percent: Yup.string(),
        })
      )
      .test('isMoreThan100Percent', 'Suma zniżek przekracza 100%', (prepaidPaymentInfos) =>
        Number(
          prepaidPaymentInfos?.reduce(
            (accumulator: number, prepaidInfo: any) =>
              Number(convertNumericStringToNumber(prepaidInfo.percent)) + accumulator,
            0
          )
        ) > 100
          ? false
          : true
      ),
  })

  const SupplierSchema = Yup.object().shape({
    mainInfo: Yup.object().shape({
      name: Yup.string().required('To pole jest wymagane!'),
      contacts: Yup.array().of(
        Yup.object().shape({
          contactPerson: Yup.string().required('To pole jest wymagane!'),
          email: Yup.string()
            .email('Nieprawidłowy format adresu e-mail!')
            .required('To pole jest wymagane!'),
        })
      ),
    }),

    tradeInfo: Yup.lazy((tradeInfo: any) =>
      String(tradeInfo?.paymentType) === '1' ? DiscountSchema : Yup.object()
    ),

    address: Yup.object().shape({
      country: Yup.string()
        .required('To pole jest wymagane!')
        .test('wrongCountryName', 'Wpisano nazwę kraju spoza listy!', (countryName) => {
          return countryName
            ? countries?.map((country: TCountry) => country?.polishName).includes(countryName)
            : false
        }),
    }),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="supplier-details">
          <h4 className={`text-center ${isThereNetworkError ? 'mb-2' : 'mb-4'}`}>
            {!suppliersState.isSingleSupplierLoading &&
              (mode === 'edit'
                ? !authState?.authData?.roles?.includes('Suppliers_write')
                  ? 'Przeglądaj Dostawcę'
                  : 'Edytuj Dostawcę'
                : 'Nowy Dostawca')}

            {suppliersState.isSingleSupplierLoading && 'Dostawca'}
            {suppliersState.isSingleSupplierLoading && <ThreeDots />}
          </h4>
          {suppliersState.isSingleSupplierLoading ? (
            <div
              style={{
                height: '420px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                mainInfo: {
                  name: suppliersState.editedSupplier?.mainInfo?.name || '',
                  contacts: suppliersState.editedSupplier?.mainInfo?.contacts || [
                    { contactPerson: '', email: '', phone: '', info: null, id: 0, uuid: uuidv4() },
                  ],
                },
                tradeInfo: {
                  status: suppliersState.editedSupplier?.tradeInfo?.status || 1,
                  producerIsAlsoSupplier:
                    suppliersState.editedSupplier?.tradeInfo?.producerIsAlsoSupplier || false,
                  paymentType: suppliersState.editedSupplier?.tradeInfo?.paymentType || 1,
                  days: suppliersState.editedSupplier?.tradeInfo?.days || '',
                  prepaidPaymentInfos: suppliersState.editedSupplier?.tradeInfo?.prepaidPaymentInfos
                    .length
                    ? suppliersState.editedSupplier?.tradeInfo?.prepaidPaymentInfos
                    : [{ id: 0, percent: '', description: '' }],
                  iban: suppliersState.editedSupplier?.tradeInfo?.iban || '',
                  bicSwift: suppliersState.editedSupplier?.tradeInfo?.bicSwift || '',
                  bankAddress: suppliersState.editedSupplier?.tradeInfo?.bankAddress || '',
                  taxNumber1: suppliersState.editedSupplier?.tradeInfo?.taxNumber1 || '',
                  taxNumber2: suppliersState.editedSupplier?.tradeInfo?.taxNumber2 || '',
                },
                address: {
                  id: suppliersState.editedSupplier?.address?.id || 0,
                  address1: suppliersState.editedSupplier?.address?.address1 || '',
                  address2: suppliersState.editedSupplier?.address?.address2 || '',
                  address3: suppliersState.editedSupplier?.address?.address3 || '',
                  postCode: suppliersState.editedSupplier?.address?.postCode || '',
                  city: suppliersState.editedSupplier?.address?.city || '',
                  country: suppliersState.editedSupplier?.address?.country || '',
                },
              }}
              validationSchema={SupplierSchema}
              onSubmit={(values) => {
                if (isSupplierEditable) {
                  setDidFormValidationOccur(true)

                  dispatch({
                    type:
                      mode === 'create'
                        ? actionTypes.CREATE_SUPPLIER_REQUESTED
                        : actionTypes.EDIT_SUPPLIER_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      supplier: {
                        ...values,
                        tradeInfo: {
                          ...values.tradeInfo,
                          status: Number(values.tradeInfo.status),
                          days: convertNumericStringToNumber(values?.tradeInfo?.days),
                          prepaidPaymentInfos: values?.tradeInfo?.prepaidPaymentInfos?.map(
                            (prepaidPaymentInfo) => ({
                              ...prepaidPaymentInfo,
                              percent: convertNumericStringToNumber(prepaidPaymentInfo?.percent),
                            })
                          ),
                        },
                        ...(suppliersState.editedSupplier?.id && {
                          id: Number(suppliersState.editedSupplier?.id),
                        }),
                      },
                    },
                  })
                }
              }}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ initialValues, values, errors, resetForm, setFieldValue }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsSupplierModifiedAndUnsaved(true)
                    } else {
                      setIsSupplierModifiedAndUnsaved(false)
                    }

                    if (
                      errors.mainInfo?.name ||
                      errors.mainInfo?.contacts ||
                      errors.address?.country ||
                      errors?.tradeInfo?.prepaidPaymentInfos
                    ) {
                      setDidFormValidationOccur(true)
                    }
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {suppliersState?.supplierCreateError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'create',
                        suppliersState?.supplierCreateError?.status
                      )}
                    />
                  )}

                  {suppliersState?.singleSupplierFetchError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        suppliersState?.singleSupplierFetchError?.status,
                        'dostawcy'
                      )}
                    />
                  )}

                  {suppliersState?.supplierEditSaveError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'edit',
                        suppliersState?.supplierEditSaveError?.status,
                        'dostawcy'
                      )}
                    />
                  )}

                  {suppliersState?.supplierDeleteError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'delete',
                        suppliersState?.supplierDeleteError?.status,
                        'dostawcy'
                      )}
                    />
                  )}

                  {/*
                   * Form Tabs
                   */}

                  <CTabs activeTab={paramsSupplierTab}>
                    <CNav variant="tabs" className="mb-4">
                      {supplierNavTabs?.map((modalTab: TFormTab) => {
                        if (modalTab.isEditOnly && mode === 'create') {
                          return null
                        } else {
                          return (
                            <CNavItem key={modalTab.name}>
                              <CNavLink
                                className={`${
                                  (modalTab.name === 'main' &&
                                    (errors.mainInfo?.name || errors.mainInfo?.contacts)) ||
                                  (modalTab.name === 'address' && errors.address?.country) ||
                                  (modalTab.name === 'trade' &&
                                    errors?.tradeInfo?.prepaidPaymentInfos &&
                                    String(values?.tradeInfo?.paymentType) === '1')
                                    ? 'text-danger is-invalid'
                                    : ''
                                }`}
                                data-tab={modalTab.name}
                                onClick={() => {
                                  history.replace(
                                    `/${tenant}/suppliers/${
                                      mode === 'edit' ? `edit/${paramsSupplierId}` : 'create'
                                    }/${modalTab.name}`
                                  )
                                }}
                              >
                                {modalTab.title}
                              </CNavLink>
                            </CNavItem>
                          )
                        }
                      })}
                    </CNav>
                    <CTabContent>
                      {/*
                       * Main Information Tab
                       */}

                      <CTabPane
                        data-tab="main"
                        style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                      >
                        <BasicFormField
                          fieldId="supplier-name"
                          fieldLabel="Nazwa"
                          fieldIcon="cil-short-text"
                          formikFieldName="mainInfo.name"
                          fieldError={errors.mainInfo?.name}
                          fieldValue={values.mainInfo?.name}
                          fieldType="text"
                          placeholder="Wprowadź nazwę dostawcy"
                        />

                        <FieldArray
                          name="mainInfo.contacts"
                          render={(arrayHelpers) => (
                            <div>
                              {values?.mainInfo?.contacts?.map(
                                (
                                  supplierContactItem: actionTypes.TSupplierContact,
                                  supplierContactItemIndex: number
                                ) => {
                                  return (
                                    <div
                                      key={`supplier-contacts-key-${supplierContactItemIndex}-${supplierContactItem.uuid}`}
                                    >
                                      <p
                                        className={`${
                                          supplierContactItemIndex === 0 ? 'mt-4 pt-1' : 'mt-3 pt-1'
                                        }`}
                                      >
                                        <strong>
                                          {supplierContactItemIndex === 0
                                            ? `Kontakt główny`
                                            : `Kontakt dodatkowy`}
                                        </strong>
                                      </p>
                                      <div className="supplier-contacts-double-grid my-3">
                                        <div className="">
                                          <BasicFormField
                                            fieldId={`supplier-contacts-${supplierContactItemIndex}-contact-person`}
                                            fieldLabel={`Imię i nazwisko`}
                                            fieldIcon="cil-contact"
                                            formikFieldName={`mainInfo.contacts.${supplierContactItemIndex}.contactPerson`}
                                            // prettier-ignore
                                            // @ts-ignore
                                            fieldError={errors?.mainInfo?.contacts[supplierContactItemIndex]?.contactPerson}
                                            fieldValue={
                                              values.mainInfo?.contacts[supplierContactItemIndex]
                                                .contactPerson
                                            }
                                            fieldType="text"
                                            placeholder="Wprowadź imię i nazwisko osoby do kontaktu"
                                          />

                                          <BasicFormField
                                            fieldId={`supplier-contacts-${supplierContactItemIndex}-email`}
                                            fieldLabel="E-mail"
                                            fieldIcon="cil-envelope-closed"
                                            formikFieldName={`mainInfo.contacts.${supplierContactItemIndex}.email`}
                                            // prettier-ignore
                                            // @ts-ignore
                                            fieldError={errors.mainInfo?.contacts[supplierContactItemIndex]?.email}
                                            fieldValue={
                                              values.mainInfo?.contacts[supplierContactItemIndex]
                                                .email
                                            }
                                            fieldType="text"
                                            placeholder="Wprowadź e-mail kontaktu"
                                          />

                                          <BasicFormField
                                            fieldId={`supplier-contacts-${supplierContactItemIndex}-phone`}
                                            fieldLabel="Telefon"
                                            fieldIcon="cil-phone"
                                            formikFieldName={`mainInfo.contacts.${supplierContactItemIndex}.phone`}
                                            fieldError={''}
                                            fieldValue={
                                              values.mainInfo?.contacts[supplierContactItemIndex]
                                                .phone || ''
                                            }
                                            fieldType="text"
                                            placeholder="Opcjonalnie wprowadź telefon kontaktu"
                                          />

                                          <BasicFormField
                                            fieldId={`supplier-contacts-${supplierContactItemIndex}-info`}
                                            fieldLabel="Opis"
                                            fieldIcon="cil-short-text"
                                            formikFieldName={`mainInfo.contacts.${supplierContactItemIndex}.info`}
                                            fieldError={''}
                                            fieldValue={
                                              values.mainInfo?.contacts[supplierContactItemIndex]
                                                .info || ''
                                            }
                                            fieldType="text"
                                            placeholder="Opcjonalnie wprowadź opis kontaktu"
                                          />
                                        </div>

                                        <div>
                                          <CButton
                                            color="danger"
                                            type="button"
                                            variant="outline"
                                            onClick={() => {
                                              arrayHelpers.remove(supplierContactItemIndex)
                                            }}
                                            className="select-option-remove-button"
                                            style={{
                                              display:
                                                supplierContactItemIndex === 0 &&
                                                values?.mainInfo?.contacts?.length < 2
                                                  ? 'none'
                                                  : 'initial',
                                            }}
                                            disabled={
                                              supplierContactItemIndex === 0 &&
                                              values?.mainInfo?.contacts?.length < 2
                                            }
                                          >
                                            <i className="cil-trash"></i>
                                          </CButton>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              )}

                              <div className="d-flex justify-content-center my-3">
                                <CButton
                                  color="info"
                                  type="button"
                                  variant="outline"
                                  onClick={() => {
                                    let newSupplierContact: actionTypes.TSupplierContact = {
                                      contactPerson: '',
                                      email: '',
                                      phone: '',
                                      info: '',
                                      uuid: uuidv4(),
                                      id: 0,
                                    }

                                    arrayHelpers.push(newSupplierContact)
                                  }}
                                  disabled={
                                    suppliersState.isSingleSupplierLoading ||
                                    suppliersState.isSupplierCreating ||
                                    suppliersState.isSupplierDeleting ||
                                    suppliersState.isSupplierEditSaving
                                  }
                                  className="px-4"
                                >
                                  Dodaj kontakt
                                </CButton>
                              </div>
                            </div>
                          )}
                        />
                      </CTabPane>

                      {/*
                       * Trade Tab
                       */}

                      <CTabPane
                        data-tab="trade"
                        style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                      >
                        <div className="d-flex justify-content-start">
                          <div>
                            <CLabel
                              htmlFor={`supplier-trade-info-status`}
                              className={`${inputLabelSpacingBottom}`}
                            >
                              Status
                            </CLabel>
                            <InputGroup
                              id={`supplier-trade-info-status`}
                              className={`${inputFieldSpacingBottom} dropdown-selector`}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="cil-bell"></i>
                                </InputGroupText>
                              </InputGroupAddon>

                              <FastField
                                as={Select}
                                variant="outlined"
                                native
                                classes={{
                                  root: materialUISelectClasses.root,
                                }}
                                name={`tradeInfo.status`}
                              >
                                <option value={1}>Nowy</option>
                                <option value={2}>Oczekujący</option>
                                <option value={3}>Aktywny</option>
                                <option value={4}>Nieaktywny</option>
                              </FastField>
                            </InputGroup>
                          </div>

                          <div className="d-flex justify-content-start align-items-center mt-2">
                            <Field
                              as={CInput}
                              name={`tradeInfo.producerIsAlsoSupplier`}
                              type="checkbox"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '35px',
                                cursor: 'default',
                              }}
                              checked={values.tradeInfo.producerIsAlsoSupplier}
                            />
                            <span
                              onClick={() =>
                                setFieldValue(
                                  `tradeInfo.producerIsAlsoSupplier`,
                                  !values.tradeInfo.producerIsAlsoSupplier
                                )
                              }
                              className="ml-2"
                              style={{ fontSize: '14px', cursor: 'default' }}
                            >
                              Dostawca = Producent
                            </span>
                          </div>
                        </div>

                        <div className="supplier-payment-option-wrapper">
                          <div>
                            <CLabel
                              htmlFor="supplier-payment-type"
                              className={`${inputLabelSpacingBottom}`}
                            >
                              Płatność
                            </CLabel>
                            <InputGroup
                              id="supplier-payment-type"
                              className={`${inputFieldSpacingBottom} dropdown-selector`}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText
                                  className={
                                    errors.tradeInfo?.paymentType && 'text-danger input-error-icon'
                                  }
                                >
                                  <i className="cil-cash"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <ErrorMessage
                                name="tradeInfo.paymentType"
                                component="span"
                                className="text-danger input-error-message"
                              />
                              <FastField
                                as={Select}
                                variant="outlined"
                                native
                                classes={{
                                  root: materialUISelectClasses.root,
                                }}
                                name="tradeInfo.paymentType"
                              >
                                <option value={1}>Przedpłata</option>
                                <option value={2}>Przelew</option>
                              </FastField>
                            </InputGroup>
                          </div>

                          {/* The field here from dropdown always renders the value as String so it has to be compared to '2' */}
                          {String(values.tradeInfo.paymentType) === '2' && (
                            <BasicFormField
                              fieldId="supplier-prepaid-days"
                              fieldLabel="Termin płatności [dni]"
                              fieldIcon="cil-calendar"
                              formikFieldName="tradeInfo.days"
                              fieldError={errors.tradeInfo?.days}
                              fieldValue={values.tradeInfo?.days}
                              fieldType="number"
                              placeholder="Wprowadź ilość dni"
                              amountMeasurementType={1}
                            />
                          )}
                        </div>

                        {String(values.tradeInfo.paymentType) === '1' && (
                          <div className="mb-4">
                            <FieldArray
                              name="tradeInfo.prepaidPaymentInfos"
                              render={(arrayHelpers) =>
                                values?.tradeInfo?.prepaidPaymentInfos?.map(
                                  (
                                    prepaidPaymentInfo: actionTypes.TPaymentInfo,
                                    prepaidPaymentInfoIndex: number
                                  ) => (
                                    <div
                                      className="d-flex justify-content-left align-items-center mb-2"
                                      key={`${prepaidPaymentInfoIndex}-${prepaidPaymentInfo.id}`}
                                    >
                                      {Number(prepaidPaymentInfoIndex + 1) + '.'}{' '}
                                      <InputGroup
                                        id={`tradeInfo.prepaidPaymentInfos.${prepaidPaymentInfoIndex}.percent.group`}
                                        style={{ width: 'initial' }}
                                      >
                                        <Field
                                          as={NumberFormat}
                                          name={`tradeInfo.prepaidPaymentInfos.${prepaidPaymentInfoIndex}.percent`}
                                          id={`tradeInfo.prepaidPaymentInfos.${prepaidPaymentInfoIndex}.percent`}
                                          value={prepaidPaymentInfo?.percent || ''}
                                          placeholder=""
                                          displayType="input"
                                          thousandSeparator={globalThousandSeparator}
                                          decimalSeparator={globalDecimalSeparator}
                                          decimalScale={2}
                                          fixedDecimalScale={false}
                                          allowNegative={false}
                                          allowLeadingZeros={true}
                                          onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                                            handleNumberFormatPaste(event)
                                          }}
                                          className={`form-control text-right ml-3 ${
                                            Boolean(errors?.tradeInfo?.prepaidPaymentInfos) &&
                                            'border-danger'
                                          }`}
                                          style={{ width: '60px' }}
                                        />
                                        <InputGroupAddon addonType="append">
                                          <InputGroupText
                                            className={
                                              errors?.tradeInfo?.prepaidPaymentInfos &&
                                              'text-danger input-error-icon'
                                            }
                                          >
                                            <i>%</i>
                                          </InputGroupText>
                                        </InputGroupAddon>
                                      </InputGroup>
                                      {/**
                                       *  Prepaid Discount Description Field
                                       */}
                                      <FastField
                                        as={Input}
                                        name={`tradeInfo.prepaidPaymentInfos.${prepaidPaymentInfoIndex}.description`}
                                        value={prepaidPaymentInfo.description}
                                        placeholder="Opis"
                                        type="text"
                                        className="form-control ml-3"
                                        style={{ width: '200px' }}
                                      />
                                      {prepaidPaymentInfoIndex + 1 ===
                                      values.tradeInfo?.prepaidPaymentInfos.length ? (
                                        <>
                                          <CButton
                                            color="primary"
                                            variant="outline"
                                            className="supplier-payment-array-button add-percent-discount-button ml-3"
                                            disabled={Boolean(errors.tradeInfo)}
                                            onClick={() => {
                                              arrayHelpers.push({
                                                id: 0,
                                                percent: '',
                                                description: '',
                                              })
                                            }}
                                          >
                                            <CIcon icon={cilPlus} />
                                          </CButton>
                                          {prepaidPaymentInfoIndex !== 0 && (
                                            <CButton
                                              color="primary"
                                              variant="outline"
                                              className="supplier-payment-array-button ml-2"
                                              onClick={() => {
                                                arrayHelpers.remove(prepaidPaymentInfoIndex)
                                              }}
                                            >
                                              <CIcon icon={cilMinus} />
                                            </CButton>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  )
                                )
                              }
                            />
                          </div>
                        )}

                        {errors.tradeInfo ? (
                          <div className="text-danger d-flex justify-content-center my-2">
                            Sumaryczna zniżka przekracza 100%
                          </div>
                        ) : null}

                        <BasicFormField
                          fieldId="supplier-account-number"
                          fieldLabel="IBAN / Numer rachunku"
                          fieldIcon="cil-bank"
                          formikFieldName="tradeInfo.iban"
                          fieldError={errors.tradeInfo?.iban}
                          fieldValue={values.tradeInfo?.iban}
                          fieldType="text"
                          placeholder="Wprowadź numer rachunku bankowego"
                        />

                        <BasicFormField
                          fieldId="supplier-bic-swift"
                          fieldLabel="BIC / SWIFT"
                          fieldIcon="cil-bank"
                          formikFieldName="tradeInfo.bicSwift"
                          fieldError={errors.tradeInfo?.bicSwift}
                          fieldValue={values.tradeInfo?.bicSwift}
                          fieldType="text"
                          placeholder="Wprowadź BIC / SWIFT"
                        />

                        <BasicFormField
                          fieldId="supplier-bank-address"
                          fieldLabel="Adres banku"
                          fieldIcon="cil-bank"
                          formikFieldName="tradeInfo.bankAddress"
                          fieldError={errors.tradeInfo?.bankAddress}
                          fieldValue={values.tradeInfo?.bankAddress}
                          fieldType="text"
                          placeholder="Wprowadź adres banku"
                        />

                        <BasicFormField
                          fieldId="supplier-tax-number-1"
                          fieldLabel="NIP / EU VAT"
                          fieldIcon="cil-balance-scale"
                          formikFieldName="tradeInfo.taxNumber1"
                          fieldError={errors.tradeInfo?.taxNumber1}
                          fieldValue={values.tradeInfo?.taxNumber1}
                          fieldType="text"
                          placeholder="Wprowadź NIP / EU VAT"
                        />
                      </CTabPane>

                      {/*
                       * Address Tab
                       */}

                      <CTabPane
                        data-tab="address"
                        style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                      >
                        <BasicFormField
                          fieldId="supplier-address-first-line"
                          fieldLabel="Adres"
                          fieldIcon="cil-home"
                          formikFieldName="address.address1"
                          fieldError={errors.address?.address1}
                          fieldValue={values.address?.address1}
                          fieldType="textarea"
                          placeholder="Wprowadź adres"
                        />

                        <BasicFormField
                          fieldId="supplier-address-second-line"
                          fieldLabel="Adres linia 2 (opcjonalnie)"
                          fieldIcon="cil-home"
                          formikFieldName="address.address2"
                          fieldError={errors.address?.address2}
                          fieldValue={values.address?.address2}
                          fieldType="text"
                          placeholder="Wprowadź 2 linię adresu"
                        />

                        <BasicFormField
                          fieldId="supplier-address-third-line"
                          fieldLabel="Adres linia 3 (opcjonalnie)"
                          fieldIcon="cil-home"
                          formikFieldName="address.address3"
                          fieldError={errors.address?.address3}
                          fieldValue={values.address?.address3}
                          fieldType="text"
                          placeholder="Wprowadź 3 linię adresu"
                        />

                        <BasicFormField
                          fieldId="supplier-postal-code"
                          fieldLabel="Kod pocztowy"
                          fieldIcon="cil-envelope-closed"
                          formikFieldName="address.postCode"
                          fieldError={errors.address?.postCode}
                          fieldValue={values.address?.postCode}
                          fieldType="text"
                          placeholder="Wprowadź kod pocztowy dostawcy"
                          isShortLengthInput
                        />

                        <BasicFormField
                          fieldId="supplier-city"
                          fieldLabel="Miasto"
                          fieldIcon="cil-building"
                          formikFieldName="address.city"
                          fieldError={errors.address?.city}
                          fieldValue={values.address?.city}
                          fieldType="text"
                          placeholder="Wprowadź miasto dostawcy"
                          isShortLengthInput
                        />

                        <CountrySelectField
                          fieldId="supplier-country"
                          fieldLabel="Kraj"
                          fieldIcon="cil-globe-alt"
                          formikFieldName="address.country"
                          fieldError={errors.address?.country}
                          fieldValue={values.address?.country}
                          placeholder="Wybierz z listy lub wpisz nazwę kraju"
                          setFieldValue={setFieldValue}
                          refCountryTypeahead={refCountryTypeahead}
                        />
                      </CTabPane>
                    </CTabContent>
                  </CTabs>
                  <FormActionsPanel
                    mode={mode}
                    padding="pt-4 pb-1"
                    isSaving={
                      mode === 'create'
                        ? suppliersState.isSupplierCreating
                        : suppliersState.isSupplierEditSaving
                    }
                    isSaved={
                      mode === 'create'
                        ? suppliersState.isSupplierCreated
                        : suppliersState.isSupplierEditSaved
                    }
                    isDeleting={suppliersState.isSupplierDeleting}
                    isDeleted={suppliersState.isSupplierDeleted}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    formErrorsBool={Boolean(
                      errors.mainInfo?.name || errors.mainInfo?.contacts || errors.address?.country
                    )}
                    closeFunction={closeSupplierDetails}
                    closeAction={''}
                    isEditable={isSupplierEditable}
                    deleteAction={actionTypes.DELETE_SUPPLIER_REQUESTED}
                    deletePayload={{
                      tenant: tenant,
                      token: authState.authData?.token,
                      id: suppliersState?.editedSupplier?.id,
                    }}
                    canDelete={suppliersState?.editedSupplier?.canDelete}
                    disabledDeleteButtonClassNames="delete-details-button"
                    confirmDeleteMessageJSX={
                      <>
                        Czy na pewno chcesz usunąć całego dostawcę?
                        {initialValues?.mainInfo?.name ? (
                          <>
                            <br />
                            <strong>{initialValues?.mainInfo?.name}</strong>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    }
                  />
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
