import { TNetworkError } from './auth'
import { TAdminPayload, ISingleItemActionPayload, TFilterState, TSorterState } from './admin'
import { TOffer } from './offers'
import { TOrder } from './orders'
import { TCurrencyCode } from './boms'

export const FETCH_PURCHASE_PLANS_REQUESTED = 'FETCH_PURCHASE_PLANS_REQUESTED'
export const FETCH_PURCHASE_PLANS_ERROR = 'FETCH_PURCHASE_PLANS_ERROR'
export const FETCH_PURCHASE_PLANS_SUCCEEDED = 'FETCH_PURCHASE_PLANS_SUCCEEDED'

export const SET_PURCHASE_PLANS_TABLE_FILTER_STATE = 'SET_PURCHASE_PLANS_TABLE_FILTER_STATE'
export const SET_PURCHASE_PLANS_TABLE_SORTER_STATE = 'SET_PURCHASE_PLANS_TABLE_SORTER_STATE'

export const CREATE_PURCHASE_PLAN_FROM_BOMS_REQUESTED = 'CREATE_PURCHASE_PLAN_FROM_BOMS_REQUESTED'
export const CREATE_PURCHASE_PLAN_FROM_BOMS_SUCCEEDED = 'CREATE_PURCHASE_PLAN_FROM_BOMS_SUCCEEDED'
export const CREATE_PURCHASE_PLAN_FROM_BOMS_ERROR = 'CREATE_PURCHASE_PLAN_FROM_BOMS_ERROR'

export const FETCH_SINGLE_PURCHASE_PLAN_REQUESTED = 'FETCH_SINGLE_PURCHASE_PLAN_REQUESTED'
export const FETCH_SINGLE_PURCHASE_PLAN_ERROR = 'FETCH_SINGLE_PURCHASE_PLAN_ERROR'
export const FETCH_SINGLE_PURCHASE_PLAN_SUCCEEDED = 'FETCH_SINGLE_PURCHASE_PLAN_SUCCEEDED'

export const EDIT_PURCHASE_PLAN_REQUESTED = 'EDIT_PURCHASE_PLAN_REQUESTED'
export const EDIT_PURCHASE_PLAN_SUCCEEDED = 'EDIT_PURCHASE_PLAN_SUCCEEDED'
export const EDIT_PURCHASE_PLAN_ERROR = 'EDIT_PURCHASE_PLAN_ERROR'

export const DELETE_PURCHASE_PLAN_REQUESTED = 'DELETE_PURCHASE_PLAN_REQUESTED'
export const DELETE_PURCHASE_PLAN_SUCCEEDED = 'DELETE_PURCHASE_PLAN_SUCCEEDED'
export const DELETE_PURCHASE_PLAN_ERROR = 'DELETE_PURCHASE_PLAN_ERROR'

export const SET_PURCHASE_PLAN_DETAILS_BOM_ELEMENTS_TABLE_SORTER_STATE =
  'SET_PURCHASE_PLAN_DETAILS_BOM_ELEMENTS_TABLE_SORTER_STATE'
export const SET_PURCHASE_PLAN_DETAILS_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE =
  'SET_PURCHASE_PLAN_DETAILS_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE'
export const SET_PURCHASE_PLAN_DETAILS_SERVICES_TABLE_SORTER_STATE =
  'SET_PURCHASE_PLAN_DETAILS_SERVICES_TABLE_SORTER_STATE'

export const FETCH_PURCHASE_PLAN_BOM_ELEMENT_REQUESTED = 'FETCH_PURCHASE_PLAN_BOM_ELEMENT_REQUESTED'
export const FETCH_PURCHASE_PLAN_BOM_ELEMENT_SUCCEEDED = 'FETCH_PURCHASE_PLAN_BOM_ELEMENT_SUCCEEDED'
export const FETCH_PURCHASE_PLAN_BOM_ELEMENT_ERROR = 'FETCH_PURCHASE_PLAN_BOM_ELEMENT_ERROR'

export const FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_REQUESTED =
  'FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_REQUESTED'
export const FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_SUCCEEDED =
  'FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_SUCCEEDED'
export const FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_ERROR =
  'FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_ERROR'

export const FETCH_PURCHASE_PLAN_SERVICE_REQUESTED = 'FETCH_PURCHASE_PLAN_SERVICE_REQUESTED'
export const FETCH_PURCHASE_PLAN_SERVICE_SUCCEEDED = 'FETCH_PURCHASE_PLAN_SERVICE_SUCCEEDED'
export const FETCH_PURCHASE_PLAN_SERVICE_ERROR = 'FETCH_PURCHASE_PLAN_SERVICE_ERROR'

export const OPEN_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL = 'OPEN_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL'
export const CLOSE_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL =
  'CLOSE_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL'

export const OPEN_PURCHASE_PLAN_BOM_ELEMENT_MODAL = 'OPEN_PURCHASE_PLAN_BOM_ELEMENT_MODAL'
export const CLOSE_PURCHASE_PLAN_BOM_ELEMENT_MODAL = 'CLOSE_PURCHASE_PLAN_BOM_ELEMENT_MODAL'

export const OPEN_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL =
  'OPEN_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL'
export const CLOSE_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL =
  'CLOSE_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL'

export const OPEN_PURCHASE_PLAN_SERVICE_MODAL = 'OPEN_PURCHASE_PLAN_SERVICE_MODAL'
export const CLOSE_PURCHASE_PLAN_SERVICE_MODAL = 'CLOSE_PURCHASE_PLAN_SERVICE_MODAL'

export const UNLOCK_PURCHASE_PLAN_ACTIONS = 'UNLOCK_PURCHASE_PLAN_ACTIONS'

export const GENERATE_ORDER_REQUESTED = 'GENERATE_ORDER_REQUESTED'
export const GENERATE_ORDER_SUCCEEDED = 'GENERATE_ORDER_SUCCEEDED'
export const GENERATE_ORDER_ERROR = 'GENERATE_ORDER_ERROR'
export const UNLOCK_GENERATED_ORDER = 'UNLOCK_GENERATED_ORDER'

export type TBomElementInPurchasePlan = {
  name: string
  bomNames: string[]
  plannedQuantity: number
  purchasePlanId: number

  bomElementId: number
  bomElementName: string
  producerCode: string
  bomIds: number[]
  estimatedQuantity: number
  estimatedPrice: number
  estimatedPriceCurrency: TCurrencyCode | null
  isPending: boolean

  offer: TOffer
  offerId: number
  offerPrice: number | null
  offerPriceCurrency: TCurrencyCode | null
  offers: TOffer[]
  offerQuantity: number
  finalOfferId: number
  finalOffer: TOffer

  order: TOrder | null
  orderId: number | null
  orderPrice: number | null
  orderPriceCurrency: TCurrencyCode | null
  orderedQuantity: number | null

  supplierId: number | null
  supplierName: string | null

  status: string | null
  warehouseState: number | string | null

  generateOrderCheckbox?: boolean
  uuid: string
}

export type TImplementationCostInPurchasePlan = {
  implementationCostId: number
  implementationCostName: string
  supplierName: string

  bomIds: number[]
  bomNames: string
  purchasePlanId: number

  offerId: number
  bomElementId: number
  bomElementName: string

  estimatedPrice: number
  estimatedPriceCurrency: TCurrencyCode
  quantity: string
}

export type TServiceInPurchasePlan = {
  serviceId: number
  serviceName: string

  bomIds: number[]
  bomNames: string
  purchasePlanId: number
  supplierName: string

  offerId: number
  offer: null
  offers: null

  estimatedPrice: number
  estimatedPriceCurrency: TCurrencyCode
  estimatedQuantity: number | null
}

export interface IPurchasePlanDetailsInModal {
  bomElementId?: number
  bomElementName?: string
  implementationCostId?: number
  implementationCostName?: string
  serviceId?: number
  serviceName?: string

  bomId: number
  bomName: string
  estimatedQuantity: number
  estimatedPrice: number
  estimatedPriceCurrency: TCurrencyCode
  isPending: boolean
  offer: TOffer
  offerId: number | string | null
  offerPrice: number
  offerQuantity: number
  offers: TOffer[] | null
  order: TOrder | null
  orderId: number | null
  orderPrice: number | null
  orderedQuantity: number | null
  plannedQuantity: number
  purchasePlanId: number
  supplierId: number | null
  supplierName: string | null
}

export type TBomPurchasePlan = {
  purchasePlanId: number
  bomId: number
  quantity: number
  bomName: string
}

export type TBomWithQuantity = {
  checkbox: boolean
  bomId: number
  quantity: number
  name: string
}

export type TPurchasePlan = {
  id: number
  number: string
  createdByLogin: string
  createDate: string
  productionStartDate: string | null
  bomNames: TBomPurchasePlan[] | null
  estimatedMaxLeadTime: number
  firstPossibleProductionStartDate: string
  lastPossibleOrderDate: string
  maxLeadTime: number
  offersMaxLeadTime: number | null
  canDelete: boolean

  purchasePlanBomInfos?: TBomPurchasePlan[] | null
  purchasePlanBomElements: TBomElementInPurchasePlan[] | null
  purchasePlanImplementationCosts: TImplementationCostInPurchasePlan[] | null
  purchasePlanServices: TServiceInPurchasePlan[] | null
}

export type TPurchasePlanLight = {
  id: number

  bomIds: number[]
  bomNames: string[]
  canDelete: boolean
  createDate: string
  createdByLogin: string
  customNumber: string | null
  estimatedMaxLeadTime: string | null
  firstPossibleProductionStartDate: string
  lastPossibleOrderDate: string
  maxLeadTime: string | null
  number: string
  offersMaxLeadTime: number | null
  productionStartDate: string | null
}

export type TBomElementWithOffer = {
  bomElementId: number
  offerId: number
  supplierId: number | null
  uuid: string

  bomElementName: string
  offerQuantity: number
  plannedQuantity: number
  warehouseState: string | number | null
}

export type TGenerateOrderPayload = {
  purchasePlanId: number
  bomElementsWithOffer: TBomElementWithOffer[]
}

export type TCreatePurchasePlanFromBom = {
  bomId: number
  quantity: number
}

export interface ICreatePurchasePlanFromBomsPayload {
  purchasePlanBomInfos: TCreatePurchasePlanFromBom[]
}

export interface ICreateNewPurchasePlanPayload extends TAdminPayload {
  items: ICreatePurchasePlanFromBomsPayload
}

export interface IEditPurchasePlanPayload extends TAdminPayload {
  purchasePlan: TPurchasePlan
}

export interface IGetPurchasePlanBomElementPayload extends TAdminPayload {
  purchasePlanId: number
  bomElementId: number
  bomIds: number[]
}

export interface IEditPurchasePlanBomElementPayload extends TAdminPayload {
  purchasePlanBomElementModalTabs: TBomElementInPurchasePlan
}

export interface IGetPurchasePlanImplementationCostPayload extends TAdminPayload {
  purchasePlanId: number
  implementationCostId: number
  bomIds: number[]
}

export interface IEditPurchasePlanImplementationCostPayload extends TAdminPayload {
  purchasePlanImplementationCostModalTabs: TImplementationCostInPurchasePlan
}

export interface IGetPurchasePlanServicePayload extends TAdminPayload {
  purchasePlanId: number
  serviceId: number
  bomIds: number[]
}

export interface IEditPurchasePlanServicePayload extends TAdminPayload {
  purchasePlanServiceModalTabs: TServiceInPurchasePlan
}

export interface IGenerateOrderPayload extends TAdminPayload {
  orderInfo: TGenerateOrderPayload
}

// FETCH_PURCHASE_PLANS

export interface fetchPurchasePlansAction {
  type: typeof FETCH_PURCHASE_PLANS_REQUESTED
  payload: TAdminPayload
}

export interface fetchPurchasePlansSucceeded {
  type: typeof FETCH_PURCHASE_PLANS_SUCCEEDED
  purchasePlans: TBomElementInPurchasePlan[]
}

export interface fetchPurchasePlansError {
  type: typeof FETCH_PURCHASE_PLANS_ERROR
  error: TNetworkError
}

// SET_PURCHASE_PLANS_TABLE_FILTER_STATE

export interface setPurchasePlansTableFilterState {
  type: typeof SET_PURCHASE_PLANS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_PURCHASE_PLANS_TABLE_SORTER_STATE

export interface setPurchasePlansTableSorterState {
  type: typeof SET_PURCHASE_PLANS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// FETCH_SINGLE_PURCHASE_PLAN

export interface fetchSinglePurchasePlanAction {
  type: typeof FETCH_SINGLE_PURCHASE_PLAN_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSinglePurchasePlanSucceeded {
  type: typeof FETCH_SINGLE_PURCHASE_PLAN_SUCCEEDED
  singlePurchasePlan: TPurchasePlan
}

export interface singlePurchasePlanFetchError {
  type: typeof FETCH_SINGLE_PURCHASE_PLAN_ERROR
  error: TNetworkError
}

// CREATE_PURCHASE_PLAN_FROM_BOMS

export interface createPurchasePlanFromBomsAction {
  type: typeof CREATE_PURCHASE_PLAN_FROM_BOMS_REQUESTED
  payload: ICreateNewPurchasePlanPayload
}

export interface createPurchasePlanFromBomsSucceeded {
  type: typeof CREATE_PURCHASE_PLAN_FROM_BOMS_SUCCEEDED
}

export interface createPurchasePlanFromBomsError {
  type: typeof CREATE_PURCHASE_PLAN_FROM_BOMS_ERROR
  error: TNetworkError
}

// EDIT_PURCHASE_PLAN

export interface editPurchasePlanAction {
  type: typeof EDIT_PURCHASE_PLAN_REQUESTED
  payload: IEditPurchasePlanPayload
}

export interface editPurchasePlanSucceeded {
  type: typeof EDIT_PURCHASE_PLAN_SUCCEEDED
  purchasePlan: TBomElementInPurchasePlan
}

export interface editPurchasePlanError {
  type: typeof EDIT_PURCHASE_PLAN_ERROR
  error: TNetworkError
}

// DELETE_PURCHASE_PLAN

export interface deletePurchasePlanAction {
  type: typeof DELETE_PURCHASE_PLAN_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deletePurchasePlanSucceeded {
  type: typeof DELETE_PURCHASE_PLAN_SUCCEEDED
  id: number
}

export interface deletePurchasePlanError {
  type: typeof DELETE_PURCHASE_PLAN_ERROR
  error: TNetworkError
}

// SET_PURCHASE_PLAN_DETAILS_BOM_ELEMENTS_TABLE_SORTER_STATE

export interface setPurchasePlanDetailsBomElementsTableSorterState {
  type: typeof SET_PURCHASE_PLAN_DETAILS_BOM_ELEMENTS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// SET_PURCHASE_PLAN_DETAILS_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE

export interface setPurchasePlanDetailsImplementationCostsTableSorterState {
  type: typeof SET_PURCHASE_PLAN_DETAILS_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// SET_PURCHASE_PLAN_DETAILS_SERVICES_TABLE_SORTER_STATE

export interface setPurchasePlanDetailsServicesTableSorterState {
  type: typeof SET_PURCHASE_PLAN_DETAILS_SERVICES_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// FETCH_PURCHASE_PLAN_BOM_ELEMENT

export interface fetchPurchasePlanBomElementAction {
  type: typeof FETCH_PURCHASE_PLAN_BOM_ELEMENT_REQUESTED
  payload: IGetPurchasePlanBomElementPayload
}

export interface fetchPurchasePlanBomElementSucceeded {
  type: typeof FETCH_PURCHASE_PLAN_BOM_ELEMENT_SUCCEEDED
  purchasePlanBomElementModalTabs: IPurchasePlanDetailsInModal[]
}

export interface fetchPurchasePlanBomElementError {
  type: typeof FETCH_PURCHASE_PLAN_BOM_ELEMENT_ERROR
  error: TNetworkError
}

// FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST

export interface fetchPurchasePlanImplementationCostAction {
  type: typeof FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_REQUESTED
  payload: IGetPurchasePlanImplementationCostPayload
}

export interface fetchPurchasePlanImplementationCostSucceeded {
  type: typeof FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_SUCCEEDED
  purchasePlanImplementationCostModalTabs: IPurchasePlanDetailsInModal[]
}

export interface fetchPurchasePlanImplementationCostError {
  type: typeof FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_ERROR
  error: TNetworkError
}

// FETCH_PURCHASE_PLAN_SERVICE

export interface fetchPurchasePlanServiceAction {
  type: typeof FETCH_PURCHASE_PLAN_SERVICE_REQUESTED
  payload: IGetPurchasePlanServicePayload
}

export interface fetchPurchasePlanServiceSucceeded {
  type: typeof FETCH_PURCHASE_PLAN_SERVICE_SUCCEEDED
  purchasePlanServiceModalTabs: IPurchasePlanDetailsInModal[]
}

export interface fetchPurchasePlanServiceError {
  type: typeof FETCH_PURCHASE_PLAN_SERVICE_ERROR
  error: TNetworkError
}

// PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL

export interface openPurchasePlanCreateFromBomsModalAction {
  type: typeof OPEN_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL
}

export interface closePurchasePlansCreateFromBomsModalAction {
  type: typeof CLOSE_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL
}

// OPEN_PURCHASE_PLAN_BOM_ELEMENT_MODAL

export interface openPurchasePlanBomElementModalAction {
  type: typeof OPEN_PURCHASE_PLAN_BOM_ELEMENT_MODAL
}

export interface closePurchasePlanBomElementModalAction {
  type: typeof CLOSE_PURCHASE_PLAN_BOM_ELEMENT_MODAL
}

// OPEN_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL

export interface openPurchasePlanImplementationCostModalAction {
  type: typeof OPEN_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL
}

export interface closePurchasePlanImplementationCostModalAction {
  type: typeof CLOSE_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL
}

// OPEN_PURCHASE_PLAN_SERVICE_MODAL

export interface openPurchasePlanServiceModalAction {
  type: typeof OPEN_PURCHASE_PLAN_SERVICE_MODAL
}

export interface closePurchasePlanServiceModalAction {
  type: typeof CLOSE_PURCHASE_PLAN_SERVICE_MODAL
}

// UNLOCK_PURCHASE_PLAN_ACTIONS

export interface unlockPurchasePlanActions {
  type: typeof UNLOCK_PURCHASE_PLAN_ACTIONS
}

// GENERATE_ORDER

export interface generateOrderAction {
  type: typeof GENERATE_ORDER_REQUESTED
  payload: IGenerateOrderPayload
}

export interface generateOrderSucceeded {
  type: typeof GENERATE_ORDER_SUCCEEDED
  generatedOrderId: number
}

export interface generateOrderError {
  type: typeof GENERATE_ORDER_ERROR
  error: TNetworkError
}

export interface unlockGeneratedOrderAction {
  type: typeof UNLOCK_GENERATED_ORDER
}

export type PurchasePlansActionTypes =
  | fetchPurchasePlansAction
  | fetchPurchasePlansError
  | fetchPurchasePlansSucceeded
  | setPurchasePlansTableFilterState
  | setPurchasePlansTableSorterState
  | fetchSinglePurchasePlanAction
  | singlePurchasePlanFetchError
  | fetchSinglePurchasePlanSucceeded
  | createPurchasePlanFromBomsAction
  | createPurchasePlanFromBomsSucceeded
  | createPurchasePlanFromBomsError
  | editPurchasePlanAction
  | editPurchasePlanSucceeded
  | editPurchasePlanError
  | deletePurchasePlanAction
  | deletePurchasePlanSucceeded
  | deletePurchasePlanError
  | setPurchasePlanDetailsBomElementsTableSorterState
  | setPurchasePlanDetailsImplementationCostsTableSorterState
  | setPurchasePlanDetailsServicesTableSorterState
  | fetchPurchasePlanBomElementAction
  | fetchPurchasePlanBomElementSucceeded
  | fetchPurchasePlanBomElementError
  | fetchPurchasePlanImplementationCostAction
  | fetchPurchasePlanImplementationCostSucceeded
  | fetchPurchasePlanImplementationCostError
  | fetchPurchasePlanServiceAction
  | fetchPurchasePlanServiceSucceeded
  | fetchPurchasePlanServiceError
  | openPurchasePlanCreateFromBomsModalAction
  | closePurchasePlansCreateFromBomsModalAction
  | openPurchasePlanBomElementModalAction
  | closePurchasePlanBomElementModalAction
  | openPurchasePlanImplementationCostModalAction
  | closePurchasePlanImplementationCostModalAction
  | openPurchasePlanServiceModalAction
  | closePurchasePlanServiceModalAction
  | unlockPurchasePlanActions
  | generateOrderAction
  | generateOrderSucceeded
  | generateOrderError
  | unlockGeneratedOrderAction
