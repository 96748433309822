import React, { useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'

const TeamMembers: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string; id: string }>()

  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      manufacturingState?.teamMembers?.map(
        (teamMember: actionTypes.ITeamMemberGeneralInformationAndLight) => {
          return {
            ...teamMember,
            name: teamMember?.name || '',
            surname: teamMember?.surname || '',
            position: teamMember?.position || '',
            email: teamMember?.email || '',
            phone: teamMember?.phone || '',
            icon: '🖊️',
          }
        }
      ),
    [manufacturingState.teamMembers]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="7">
        <CCard>
          <CCardBody className="pb-2">
            <CDataTable
              tableFilterValue={manufacturingState?.teamMembersTableFilterState}
              sorterValue={manufacturingState?.teamMembersTableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_TEAM_MEMBERS_TABLE_SORTER_STATE,
                  teamMembersTableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_TEAM_MEMBERS_TABLE_FILTER_STATE,
                  teamMembersTableFilterState: tableFilterState,
                })
              }
              loading={manufacturingState.areTeamMembersLoading}
              striped={!!manufacturingState.teamMembers.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table"
              onRowClick={(teamMember: actionTypes.ITeamMemberGeneralInformationAndLight) => {
                history.push(`/${tenant}/team-member/edit/${teamMember.id}`)
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '40px',
                    display: !manufacturingState.teamMembers.length ? 'none' : '',
                  },
                },
                {
                  key: 'surname',
                  label: 'Nazwisko',
                  _style: { width: '100px', textAlign: 'left' },
                },
                { key: 'name', label: 'Imię', _style: { width: '100px', textAlign: 'left' } },
                {
                  key: 'position',
                  label: 'Stanowisko',
                  _style: { width: '100px', textAlign: 'left' },
                },
                {
                  key: 'email',
                  label: 'Polyend ID',
                  _style: { width: '100px', textAlign: 'left' },
                },
                {
                  key: 'phone',
                  label: 'Nr telefonu',
                  _style: { width: '100px', textAlign: 'left' },
                },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    manufacturingState.fetchTeamMembersError,
                    manufacturingState.areTeamMembersLoading,
                    'zespołu',
                    manufacturingState?.teamMembers?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default TeamMembers
