import React from 'react'
import { CFooter } from '@coreui/react'

export const Footer: React.FC<{}> = () => {
  return (
    <CFooter fixed={false}>
      <div className="mfs-auto">Polyend System™ &nbsp;{new Date().getFullYear()}</div>
    </CFooter>
  )
}
