import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  Table,
} from 'reactstrap'
import { Formik, Form, FastField, Field, FieldArray } from 'formik'
import {
  CTabs,
  CTabContent,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CCard,
  CCardBody,
  CLabel,
} from '@coreui/react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import deepEqual from 'deep-equal'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  BasicFormField,
  FormActionSaveButton,
  ThreeDots,
  CustomErrorMessage,
  FormActionCancelButton,
} from '../../components'
import {
  getErrorMessageFromStatus,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
} from '../../utils'
import { TFormTab, topLabelsOffsetFromNavigationTabs } from '../erp'
import CIcon from '@coreui/icons-react'
import { cilChevronRight, cilFork, cilObjectGroup } from '@coreui/icons'
import { Select } from '@material-ui/core'

export const teamMemberNavTabs: TFormTab[] = [
  { name: 'general-information', title: 'Informacje ogólne', isEditOnly: false },
  { name: 'skills', title: 'Umiejętności', isEditOnly: false },
  { name: 'schedule', title: 'Harmonogram', isEditOnly: true },
  { name: 'vacations', title: 'Urlopy', isEditOnly: true },
]

export const TeamMemberDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, mode, id, tab } = useParams<{
    tenant: string
    mode: actionTypes.TFormMode
    id: string
    tab: string
  }>()
  const paramsTeamMemberId = id
  const paramsTeamMemberDetailsTab = tab

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isTeamMemberModifiedAndUnsaved, setIsTeamMemberModifiedAndUnsaved] =
    useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)

  const isThereNetworkError =
    manufacturingState?.teamMemberEditSaveError?.status ||
    manufacturingState?.singleTeamMemberFetchError?.status

  const isTeamMemberEditable =
    authState?.authData?.roles?.includes('Manufacturing_write') &&
    !manufacturingState?.singleTeamMemberFetchError

  const closeTeamMemberDetails = useCallback(() => {
    history.push(`/${tenant}/team-members`)
  }, [dispatch, history, tenant])

  // Fetch data
  useEffect(() => {
    if (tenant && authState.authData && paramsTeamMemberId) {
      dispatch({
        type: actionTypes.FETCH_SINGLE_TEAM_MEMBER_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsTeamMemberId },
      })
    }
  }, [dispatch, tenant, authState, paramsTeamMemberId])

  // Fix the path in edit
  useEffect(() => {
    if (tenant && authState.authData) {
      const tabsArray = teamMemberNavTabs?.map((navTab: TFormTab) => navTab.name)

      if (
        paramsTeamMemberDetailsTab === undefined ||
        !tabsArray.includes(paramsTeamMemberDetailsTab)
      ) {
        history.replace(
          `/${tenant}/team-members/edit/${paramsTeamMemberId}/${teamMemberNavTabs[0].name}`
        )
      }
    }
  }, [tenant, authState, paramsTeamMemberId, history, paramsTeamMemberDetailsTab, mode])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <>
        <CCard>
          <CCardBody className="team-member-details">
            {manufacturingState.isSingleTeamMemberLoading ? (
              <>
                <h4 className={`text-center ${isThereNetworkError ? 'mb-2' : 'mb-3'} pb-2`}>
                  Jan Kowalski
                  <ThreeDots />
                </h4>
                <div
                  style={{
                    height: '360px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spinner />
                </div>
              </>
            ) : (
              <Formik
                initialValues={{
                  generalInformation:
                    manufacturingState?.editedTeamMember?.generalInformation || null,
                  skills: [
                    {
                      bomId: 1,
                      bomName: 'Tracker',
                      name: 'Tracker',

                      manufacturingSteps: [
                        {
                          name: 'Jeden',
                          uuid: '1',
                          bomIntermediatesWithSkills: [],
                          manufacturingActivitiesWithSkills: [
                            { uuid: '1', index: 1, name: 'Flashowanie', skillValue: 0 },
                            { uuid: '2', index: 2, name: 'Skręcenie', skillValue: 22 },
                          ],
                          skillValue: 3,
                        },
                        {
                          name: 'Dwa',
                          uuid: '2',
                          bomIntermediatesWithSkills: [
                            { uuid: '1', index: 1, name: 'Płytka', skillValue: 2 },
                            { uuid: '2', index: 2, name: 'Wyświetlacz', skillValue: 5 },
                          ],
                          skillValue: 1,
                        },
                        {
                          name: 'Trzy',
                          uuid: '3',
                          bomIntermediatesWithSkills: [
                            { uuid: '1', index: 1, name: 'Płytka', skillValue: 2 },
                            { uuid: '2', index: 2, name: 'Wyświetlacz', skillValue: 5 },
                          ],
                        },
                      ],
                    },
                    {
                      bomId: 2,
                      bomName: 'Play',
                      name: 'Play',

                      manufacturingSteps: [
                        {
                          name: 'Pięć',
                          uuid: '1',
                          bomIntermediatesWithSkills: [],
                          manufacturingActivitiesWithSkills: [
                            { uuid: '1', index: 1, name: 'Wysłanie', skillValue: 21 },
                            { uuid: '2', index: 2, name: 'ABC', skillValue: 1 },
                          ],
                        },
                        {
                          name: 'Dwa',
                          uuid: '2',
                          bomIntermediatesWithSkills: [
                            { uuid: '1', index: 1, name: 'Płytka', skillValue: 2 },
                            { uuid: '2', index: 2, name: 'Wyświetlacz', skillValue: 5 },
                          ],
                        },
                        {
                          name: 'Sześć',
                          uuid: '3',
                          bomIntermediatesWithSkills: [
                            { uuid: '1', index: 1, name: 'CDFB', skillValue: 2 },
                            { uuid: '2', index: 2, name: 'KLJF', skillValue: 1 },
                          ],
                        },
                      ],
                    },
                  ],
                  currentlyChosenProductSkills: undefined,
                  schedule: null,
                  vacations: null,
                }}
                onSubmit={(values) => {
                  if (isTeamMemberEditable) {
                    setDidFormValidationOccur(true)

                    dispatch({
                      type: actionTypes.EDIT_TEAM_MEMBER_REQUESTED,
                      payload: {
                        tenant: tenant,
                        token: authState.authData?.token,
                      },
                    })
                  }
                }}
                validateOnBlur={false}
                validateOnChange={didFormValidationOccur}
                enableReinitialize={true}
              >
                {({ initialValues, values, errors, setFieldValue, handleChange }) => (
                  <Form
                    onChange={() => {
                      // Values here are always 1 step behind
                      let isModified = !deepEqual(values, initialValues, { strict: false })

                      if (isModified) {
                        /* If form is brought to its initial state then it is not modified */
                        setIsTeamMemberModifiedAndUnsaved(true)
                      } else {
                        setIsTeamMemberModifiedAndUnsaved(false)
                      }

                      if (errors) {
                        setDidFormValidationOccur(true)
                      }
                    }}
                  >
                    <h4 className={`text-center ${isThereNetworkError ? 'mb-2' : 'mb-3'} pb-2`}>
                      {manufacturingState?.editedTeamMember?.generalInformation?.name ||
                        manufacturingState?.teamMembers?.find(
                          (teamMember: actionTypes.ITeamMemberGeneralInformationAndLight) =>
                            teamMember.id === Number(paramsTeamMemberId)
                        )?.name ||
                        'Nieznany'}
                    </h4>

                    {/*
                     * Display Network Error Message
                     */}

                    {manufacturingState?.singleTeamMemberFetchError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'fetch',
                          manufacturingState?.singleTeamMemberFetchError?.status,
                          'członka zespołu'
                        )}
                      />
                    )}

                    {manufacturingState?.teamMemberEditSaveError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'edit',
                          manufacturingState?.teamMemberEditSaveError?.status,
                          'członka zespołu'
                        )}
                      />
                    )}

                    {/*
                     * Form Tabs
                     */}

                    <CTabs activeTab={paramsTeamMemberDetailsTab}>
                      <CNav variant="tabs" className="mb-4">
                        {teamMemberNavTabs?.map((modalTab: TFormTab) => {
                          return (
                            <CNavItem key={modalTab.name}>
                              <CNavLink
                                // className={`${
                                //   modalTab.name === 'general-information' && errors
                                //     ? 'text-danger is-invalid'
                                //     : ''
                                // }`}
                                data-tab={modalTab.name}
                                onClick={() => {
                                  history.replace(
                                    `/${tenant}/team-members/edit/${Number(paramsTeamMemberId)}/${
                                      modalTab.name
                                    }`
                                  )
                                }}
                              >
                                {modalTab.title}
                              </CNavLink>
                            </CNavItem>
                          )
                        })}
                      </CNav>
                      <CTabContent>
                        {/*
                         * General Information Tab
                         */}

                        <CTabPane
                          data-tab="general-information"
                          style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                        >
                          <div className="team-member-grid-fields mb-1">
                            <BasicFormField
                              fieldId="team-member-surname"
                              fieldLabel="Nazwisko"
                              fieldIcon="cil-short-text"
                              formikFieldName="surname"
                              fieldValue={values.generalInformation?.surname}
                              fieldError={null}
                              fieldType="text"
                              placeholder="Brak nazwiska"
                              isDisabled
                            />
                            <BasicFormField
                              fieldId="team-member-name"
                              fieldLabel="Imię"
                              fieldIcon="cil-short-text"
                              formikFieldName="name"
                              fieldValue={values.generalInformation?.name}
                              fieldError={null}
                              fieldType="text"
                              placeholder="Brak imienia"
                              isDisabled
                            />
                          </div>

                          <div className="team-member-grid-fields">
                            <BasicFormField
                              fieldId="team-member-email"
                              fieldLabel="Poylend ID"
                              fieldIcon="cil-barcode"
                              formikFieldName="email"
                              fieldValue={values.generalInformation?.email}
                              fieldError={null}
                              fieldType="text"
                              placeholder="Brak Polyend ID"
                              isShortLengthInput
                              isDisabled
                            />

                            <BasicFormField
                              fieldId="team-member-phone"
                              fieldLabel="Nr telefonu"
                              fieldIcon="cil-tag"
                              formikFieldName="phone"
                              fieldValue={values.generalInformation?.phone}
                              fieldError={null}
                              fieldType="text"
                              placeholder="Wprowadź numer telefonu"
                              isShortLengthInput
                            />
                          </div>

                          <div className="team-member-grid-fields">
                            <BasicFormField
                              fieldId="team-member-position"
                              fieldLabel="Stanowisko"
                              fieldIcon="cil-barcode"
                              formikFieldName="position"
                              fieldValue={values.generalInformation?.position}
                              fieldError={null}
                              fieldType="text"
                              isShortLengthInput
                            />
                          </div>
                        </CTabPane>

                        {/*
                         * Skills Tab
                         */}

                        <CTabPane
                          data-tab="skills"
                          style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                          className="px-2 skills-tab-wrapper"
                        >
                          <div style={{ maxWidth: '250px' }} className="pb-3">
                            <CLabel
                              htmlFor="skills-tab-manufacturing-steps-bom-product-id-selector"
                              className={`${inputLabelSpacingBottom}`}
                            >
                              Produkt
                            </CLabel>
                            <InputGroup
                              id="skills-tab-manufacturing-steps-bom-product-id-selector"
                              className={`${inputFieldSpacingBottom} dropdown-selector`}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="cil-storage"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Field
                                as={Select}
                                name="manufacturingStepsDetailsBomId"
                                variant="outlined"
                                native
                                style={{ minWidth: '200px' }}
                                disabled={!values?.skills?.length}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                  if (event?.target?.value) {
                                    setFieldValue(
                                      'currentlyChosenProductSkills',
                                      Number(event.target.value)
                                    )

                                    return handleChange(event)
                                  }
                                }}
                              >
                                {values.skills?.map((skill: any, skillIndex: number) => {
                                  return (
                                    <option
                                      key={`bom-select-option-${skill.id}-${skillIndex}`}
                                      value={skillIndex}
                                      className="manufacturing-steps-product-select-list-option"
                                      style={{ cursor: 'not-allowed' }}
                                    >
                                      {skill?.name || '...'}
                                    </option>
                                  )
                                })}
                              </Field>
                            </InputGroup>
                          </div>

                          {values?.skills.length && (
                            <ol className="manufacturing-steps-list">
                              {values?.skills[
                                values.currentlyChosenProductSkills || 0
                              ]?.manufacturingSteps?.map(
                                (
                                  manufacturingStep: actionTypes.IManufacturingStepWithSkills,
                                  indexOfManufacturingStep: number
                                ) => (
                                  <li
                                    key={manufacturingStep.uuid}
                                    className="manufacturing-steps-list-step-item"
                                    id={`manufacturing-step-id-${manufacturingStep.uuid}`}
                                  >
                                    <div className="manufacturing-steps-list-step-item-header">
                                      <div className="d-flex align-items-center">
                                        <CIcon
                                          className={`chevron-icon ${
                                            manufacturingStep?.bomIntermediatesWithSkills?.length
                                              ? 'chevron-icon-with-elements'
                                              : 'chevron-icon-without-elements'
                                          }`}
                                          icon={cilChevronRight}
                                          onClick={() => {
                                            if (
                                              manufacturingStep?.bomIntermediatesWithSkills?.length
                                            ) {
                                              const manufacturingStepElement =
                                                document.getElementById(
                                                  `manufacturing-step-id-${manufacturingStep.uuid}`
                                                )
                                              const nameOfHiddenClass = 'hidden-items-in-step'

                                              if (manufacturingStepElement) {
                                                if (
                                                  !manufacturingStepElement?.classList?.contains(
                                                    nameOfHiddenClass
                                                  )
                                                ) {
                                                  manufacturingStepElement?.classList?.add(
                                                    nameOfHiddenClass
                                                  )
                                                } else {
                                                  manufacturingStepElement?.classList?.remove(
                                                    nameOfHiddenClass
                                                  )
                                                }
                                              }
                                            } else {
                                              return null
                                            }
                                          }}
                                        />

                                        <FastField
                                          as={Input}
                                          name={`manufacturingSteps.${indexOfManufacturingStep}.name`}
                                          value={manufacturingStep.name}
                                          type="text"
                                          autoComplete={null}
                                          maxLength={125}
                                          className="manufacturing-steps-list-step-item-title remove-focus-on-input"
                                          disabled
                                        />
                                      </div>

                                      <div className="manufacturing-step-title-header-icons-wrapper">
                                        SKILL VALUE STEP
                                      </div>
                                    </div>

                                    {/*
                                     * List of Manufacturing Activities in Step
                                     */}

                                    {manufacturingStep?.manufacturingActivitiesWithSkills &&
                                      manufacturingStep?.manufacturingActivitiesWithSkills?.length >
                                        0 && (
                                        <FieldArray
                                          name={`manufacturingSteps.${indexOfManufacturingStep}.manufacturingActivities`}
                                          render={(
                                            manufacturingStepBomIntermediatesListArrayHelpers
                                          ) => (
                                            <div className="manufacturing-step-manufacturing-activities-list-wrapper">
                                              <ol className="manufacturing-step-manufacturing-activities-list">
                                                {manufacturingStep.manufacturingActivitiesWithSkills?.map(
                                                  (
                                                    manufacturingActivityWithSkillsInStep: actionTypes.IManufacturingActivityWithSkills,
                                                    indexOfManufacturingActivityInStep: number
                                                  ) => (
                                                    <li
                                                      key={`${manufacturingStep.uuid}-${manufacturingActivityWithSkillsInStep?.id}-${indexOfManufacturingActivityInStep}-${manufacturingActivityWithSkillsInStep?.name}`}
                                                      id={`manufacturing-activity-id-${manufacturingStep.uuid}-${manufacturingActivityWithSkillsInStep?.id}-${indexOfManufacturingActivityInStep}-${manufacturingActivityWithSkillsInStep?.name}`}
                                                      className="manufacturing-step-manufacturing-activity-list-item"
                                                    >
                                                      <div className="manufacturing-steps-list-manufacturing-activity-item-header">
                                                        <div className="d-flex align-items-center">
                                                          <CIcon
                                                            className="chevron-icon"
                                                            icon={cilFork}
                                                            style={{
                                                              marginBottom: 0,
                                                              cursor: 'default',
                                                            }}
                                                            onClick={() => null}
                                                          />

                                                          <div className="manufacturing-steps-list-manufacturing-activity-item-title">
                                                            <Field
                                                              as={Input}
                                                              name={`manufacturingSteps.${indexOfManufacturingStep}.manufacturingActivities.${indexOfManufacturingActivityInStep}.name`}
                                                              value={
                                                                manufacturingActivityWithSkillsInStep.name
                                                              }
                                                              type="text"
                                                              autoComplete={null}
                                                              maxLength={125}
                                                              className="manufacturing-steps-list-manufacturing-activity-item-title remove-focus-on-input"
                                                              onClick={null}
                                                              onChange={() => null}
                                                              disabled
                                                            />
                                                          </div>
                                                        </div>

                                                        <div className="manufacturing-step-manufacturing-activity-icons-wrapper">
                                                          {/* SKILL VALUE ACTIVITY */}
                                                          {
                                                            manufacturingActivityWithSkillsInStep.skillValue
                                                          }
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                )}
                                              </ol>
                                            </div>
                                          )}
                                        />
                                      )}

                                    {/*
                                     * List of Intermediates in Step Begins Here
                                     */}

                                    {manufacturingStep?.bomIntermediatesWithSkills &&
                                      manufacturingStep?.bomIntermediatesWithSkills?.length > 0 && (
                                        <FieldArray
                                          name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediatesWithSkills`}
                                          render={(
                                            manufacturingStepBomIntermediatesListArrayHelpers
                                          ) => (
                                            <div className="manufacturing-step-intermediates-list-wrapper">
                                              <ol className="manufacturing-step-intermediates-list">
                                                {manufacturingStep?.bomIntermediatesWithSkills?.map(
                                                  (
                                                    bomIntermediateWithSkills: actionTypes.IBomIntermediateWithSkills,
                                                    indexOfBomIntermediate: number
                                                  ) => (
                                                    <li
                                                      key={`${manufacturingStep.uuid}-${bomIntermediateWithSkills?.uuid}-${indexOfBomIntermediate}`}
                                                      id={`intermediate-id-${manufacturingStep.uuid}-${bomIntermediateWithSkills?.uuid}-${indexOfBomIntermediate}`}
                                                      className="manufacturing-step-intermediate-list-item"
                                                    >
                                                      <div className="manufacturing-steps-list-intermediate-item-header">
                                                        <div className="d-flex align-items-center">
                                                          <CIcon
                                                            className="chevron-icon"
                                                            icon={cilObjectGroup}
                                                            style={{
                                                              marginBottom: 0,
                                                              cursor: 'default',
                                                            }}
                                                          />
                                                          <Field
                                                            as={Input}
                                                            name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediateWithSkills.${indexOfBomIntermediate}.name`}
                                                            value={bomIntermediateWithSkills?.name}
                                                            type="text"
                                                            autoComplete={null}
                                                            maxLength={125}
                                                            className="manufacturing-steps-list-intermediate-item-title remove-focus-on-input"
                                                            onClick={null}
                                                            onChange={() => null}
                                                          />
                                                        </div>

                                                        <div className="manufacturing-step-intermediate-icons-wrapper">
                                                          {/* SKILL VALUE INTERMEDIATE */}
                                                          {bomIntermediateWithSkills.skillValue}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                )}
                                              </ol>
                                            </div>
                                          )}
                                        />
                                      )}
                                  </li>
                                )
                              )}
                            </ol>
                          )}
                        </CTabPane>

                        {/*
                         * Schedule Tab
                         */}

                        <CTabPane
                          data-tab="schedule"
                          style={{
                            marginTop: topLabelsOffsetFromNavigationTabs,
                            minHeight: '500px',
                          }}
                        >
                          <div className="pt-3">
                            <FullCalendar
                              plugins={[dayGridPlugin]}
                              initialView="dayGridMonth"
                              weekends={true}
                              events={[
                                { title: 'event 1', date: '2023-04-01' },
                                { title: 'event 2', date: '2019-04-02' },
                                {
                                  id: '64rjv5muo12ha2ih4al3caajue',
                                  title: 'Test all day event',
                                  backgroundColor: '#8f83fb',
                                  allDay: true,
                                  start: '2023-04-28',
                                  end: '2023-04-30',
                                },
                              ]}
                            />
                          </div>
                        </CTabPane>

                        {/*
                         * Vacations Tab
                         */}

                        <CTabPane
                          data-tab="vacations"
                          style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                        >
                          <Table className="bom-details-elements-table p-2">
                            <thead className="bom-elements-table-sticky-thead">
                              {/*
                               * When changing width values below remember to also change them in .dragged-bom-element-row-in-table class
                               * Especially width of name Typeahead in fixed pixels! last value was: 394px
                               */}
                              <tr>
                                <th style={{ width: '1fr' }}>Nazwa</th>
                                <th style={{ width: '100px' }}>Dni</th>{' '}
                                <th style={{ width: '200px' }}>Okres</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>Limit bieżący</td>
                                <td>1</td>
                                <td>2022 r.</td>
                              </tr>
                              <tr>
                                <td>Limit za poprzedni okres</td>
                                <td>17</td>
                                <td>2022 r.</td>
                              </tr>
                              <tr>
                                <td>Pozostało do wykorzystania</td>
                                <td>12</td>
                                <td>1-5/02\r10-14/07</td>
                              </tr>
                              <tr>
                                <td>Urlop zaplanowany</td>
                                <td>1</td>
                                <td>2022 r.</td>
                              </tr>
                              <tr>
                                <td>Urlop niezaplanowany</td>
                                <td>7</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Pozostałe (u. okolicznościowy)</td>
                                <td>2</td>
                                <td>5-6/12</td>
                              </tr>
                            </tbody>
                          </Table>
                        </CTabPane>
                      </CTabContent>
                    </CTabs>

                    {/*
                     * Do not replace this with FormActionsPanel
                     */}

                    <div
                      className={`pt-3 pb-1 d-flex ${
                        mode === 'edit' && isTeamMemberEditable
                          ? 'justify-content-between'
                          : 'justify-content-end'
                      }`}
                    >
                      <div>
                        <FormActionCancelButton
                          closeFunction={closeTeamMemberDetails}
                          closeAction={''}
                        />

                        {isTeamMemberEditable && (
                          <FormActionSaveButton
                            mode={mode}
                            isSaving={manufacturingState.isTeamMemberEditSaving}
                            isSaved={manufacturingState.isTeamMemberEditSaved}
                            isDeleting={false}
                            isDeleted={false}
                            formErrorsBool={false}
                            didFormValidationOccur={didFormValidationOccur}
                            setDidFormValidationOccur={setDidFormValidationOccur}
                          />
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </CCardBody>
        </CCard>
      </>
    </Container>
  )
}
