import React, { Suspense } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'
import { useSelector } from 'react-redux'

import erpRoutes, { TErpRoute } from '../routes/erp-routes'
import { TRootState } from '../store/reducers'
import { Admin404Page } from '.'

const loading = (
  <div className="d-flex justify-content-center align-items-center">
    <div className="spinner-border spinner-border-xl text-muted" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

const Content: React.FC<{}> = () => {
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)

  // Here you can change if wrapped card content of section begins at top or is centered

  return (
    <main className={`c-main d-flex flex-column align-items-center justify-content-start`}>
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {erpRoutes?.map((route: TErpRoute) => {
              return (
                route?.component &&
                (route?.roleReadKeyword
                  ? authState?.authData?.roles?.includes(route?.roleReadKeyword)
                  : true) && (
                  <Route
                    key={route.name}
                    path={route.path}
                    exact={route.exact}
                    render={() => (
                      <CFade>
                        <route.component />
                      </CFade>
                    )}
                  />
                )
              )
            })}
            <Route
              key={'Boms'}
              path={'/:tenant/boms/edit'}
              exact={true}
              render={() => {
                history.replace(`/${tenant}/boms`)
                return null
              }}
            />
            <Route component={Admin404Page} />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(Content)
