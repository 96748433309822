import React, { useEffect, useState, useMemo, SyntheticEvent } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumbersInTableRows,
  getEmptyListMessage,
  makeSearchBoxFixed,
  toDateTableValue,
} from '../../utils'
import { ManufacturingErrandsModal } from './manufacturing-errands-modal'
import { ListActionButton, ListStickyErrorMessage } from '../../components'

const ManufacturingErrands: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{ tenant: string; id: string }>()
  const paramsManufacturingErrandId = id

  const authState = useSelector((state: TRootState) => state.auth)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)

  const [manufacturingErrandModalMode, setManufacturingErrandModalMode] =
    useState<actionTypes.TFormMode>('create')

  // Open create modal
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      location.pathname.includes(`/${tenant}/manufacturing-errands/create`) &&
      !manufacturingState.manufacturingErrandModalVisibility
    ) {
      dispatch({
        type: actionTypes.OPEN_MANUFACTURING_ERRAND_MODAL,
        editedManufacturingErrand: null,
      })
    }
  }, [
    dispatch,
    tenant,
    authState,
    manufacturingState.manufacturingErrands,
    history,
    location.pathname,
    manufacturingErrandModalMode,
    manufacturingState.manufacturingErrandModalVisibility,
  ])

  // Set data for editedManufacturingErrand in modal when clicked on row or opened in new window
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      paramsManufacturingErrandId &&
      manufacturingState.manufacturingErrands
    ) {
      setManufacturingErrandModalMode('edit')

      if (manufacturingState?.manufacturingErrands?.length) {
        const foundManufacturingErrand = manufacturingState.manufacturingErrands.find(
          (prod: actionTypes.TManufacturingErrand) =>
            Number(prod.id) === Number(paramsManufacturingErrandId)
        )

        if (foundManufacturingErrand) {
          dispatch({
            type: actionTypes.OPEN_MANUFACTURING_ERRAND_MODAL,
            editedManufacturingErrand: foundManufacturingErrand,
          })
        } else if (!manufacturingState.isManufacturingErrandDeleted) {
          history.replace(`/${tenant}/manufacturing-errands`)
        }
      }
    }
  }, [
    dispatch,
    tenant,
    authState,
    paramsManufacturingErrandId,
    manufacturingState.manufacturingErrands,
    history,
  ])

  // Close ManufacturingErrand Modal when going back in browser history
  useEffect(() => {
    if (
      !paramsManufacturingErrandId &&
      manufacturingState.manufacturingErrandModalVisibility &&
      !location.pathname.includes('manufacturing-errands/create') &&
      !location.pathname.includes('manufacturing-errands/edit')
    ) {
      dispatch({
        type: actionTypes.CLOSE_MANUFACTURING_ERRAND_MODAL,
      })
    }
  }, [
    dispatch,
    paramsManufacturingErrandId,
    manufacturingState.manufacturingErrandModalVisibility,
    location.pathname,
  ])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      manufacturingState?.manufacturingErrands?.map(
        (manufacturingErrand: actionTypes.TManufacturingErrand) => {
          return {
            ...manufacturingErrand,
            manufacturingErrandNumber: manufacturingErrand?.manufacturingErrandNumber || '',
            bomName: manufacturingErrand?.bomName || '',
            name: manufacturingErrand?.bomName || '',
            startDate: toDateTableValue(manufacturingErrand?.startDate) || '',
            endDate: toDateTableValue(manufacturingErrand?.endDate) || '',
            plannedQuantity: manufacturingErrand?.plannedQuantity || '',
            readyQuantity: manufacturingErrand?.readyQuantity || '',
            sentQuantity: manufacturingErrand?.sentQuantity || '',
            icon: '🖊️',
          }
        }
      ),
    [manufacturingState.manufacturingErrands]
  )

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    convertNumbersInTableRows(manufacturingState.manufacturingErrands, [], [5, 6, 7])
  }, [
    memoizedTableItems,
    manufacturingState.manufacturingErrandsTableFilterState,
    manufacturingState.manufacturingErrandsTableSorterState,
  ])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="9">
        <CCard>
          <CCardBody className="pb-2">
            {authState?.authData?.roles?.includes('Manufacturing_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/manufacturing-errands/create`)
                    setManufacturingErrandModalMode('create')
                    dispatch({ type: actionTypes.OPEN_MANUFACTURING_ERRAND_MODAL })
                  }}
                >
                  Nowe Zlecenie
                </CButton>
              </div>
            )}

            {manufacturingState?.manufacturingErrandDeleteError && (
              <ListStickyErrorMessage
                listErrorMessage={`Wystąpił ${
                  manufacturingState?.manufacturingErrandDeleteError?.status
                    ? `błąd ${manufacturingState?.manufacturingErrandDeleteError?.status}`
                    : 'nieznany błąd'
                } podczas usuwania zlecenia produkcyjnego!`}
              />
            )}

            <CDataTable
              tableFilterValue={manufacturingState?.manufacturingErrandsTableFilterState}
              sorterValue={manufacturingState?.manufacturingErrandsTableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_MANUFACTURING_ERRANDS_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_MANUFACTURING_ERRANDS_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={manufacturingState.areManufacturingErrandsLoading}
              striped={!!manufacturingState?.manufacturingErrands?.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table manufacturing-errands-table"
              onRowClick={(manufacturingErrand: actionTypes.TManufacturingErrand) => {
                history.push(`/${tenant}/manufacturing-errands/edit/${manufacturingErrand.id}`)
                setManufacturingErrandModalMode('edit')
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '45px',
                    display: !manufacturingState?.manufacturingErrands?.length ? 'none' : '',
                  },
                },
                {
                  key: 'manufacturingErrandNumber',
                  label: 'Zlecenie produkcyjne',
                  _style: { width: '100px', textAlign: 'left' },
                },
                { key: 'bomName', label: 'Produkt', _style: { width: '200px', textAlign: 'left' } },
                {
                  key: 'startDate',
                  label: 'Termin rozpoczęcia',
                  _style: { width: '100px', textAlign: 'left' },
                },
                {
                  key: 'endDate',
                  label: 'Termin zakończenia',
                  _style: { width: '100px', textAlign: 'left' },
                },
                {
                  key: 'plannedQuantity',
                  label: 'Ilość planowana',
                  _style: { width: '100px', textAlign: 'left' },
                },
                {
                  key: 'readyQuantity',
                  label: 'Ilość gotowa',
                  _style: { width: '100px', textAlign: 'left' },
                },
                {
                  key: 'sentQuantity',
                  label: 'Ilość wysłana',
                  _style: { width: '100px', textAlign: 'left' },
                },
                {
                  key: 'delete',
                  label: '',
                  _style: { width: '45px' },
                },
              ]}
              scopedSlots={{
                delete: (itemFromList: actionTypes.TManufacturingErrand) => (
                  <td
                    className="text-center"
                    style={{ pointerEvents: 'none' }}
                    onClick={(event: SyntheticEvent) => {
                      if (!itemFromList.canDelete) {
                        event.preventDefault()
                        event.stopPropagation()
                        return null
                      } else {
                        return undefined
                      }
                    }}
                  >
                    <ListActionButton
                      mainActionName="delete"
                      buttonColor="danger"
                      classNames="delete-list-item-button"
                      typeOfAction={actionTypes.DELETE_MANUFACTURING_ERRAND_REQUESTED}
                      iconName="cil-trash"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={
                        manufacturingState.currentlyManagedManufacturingErrandId
                      }
                      isItemCopying={false}
                      isItemArchiving={false}
                      isItemDeleting={manufacturingState.isManufacturingErrandDeleting}
                    />
                  </td>
                ),
              }}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    manufacturingState.fetchManufacturingErrandsError,
                    manufacturingState.areManufacturingErrandsLoading,
                    'zleceń produkcyjnych',
                    manufacturingState?.manufacturingErrands?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
      {manufacturingState.manufacturingErrandModalVisibility && (
        <ManufacturingErrandsModal mode={manufacturingErrandModalMode} />
      )}
    </Container>
  )
}

export default ManufacturingErrands
