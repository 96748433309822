import { TCurrencyCode } from '../store/action-types'

export const inputFieldSpacingBottom: string = 'mb-3'
export const inputLabelSpacingBottom: string = 'mb-1'
export const successMessageDuration: number = 1000
export const editSuccessMessageDuration: number = 750

export const globalDecimalSeparator: string = ','
export const globalThousandSeparator: string = ' '
export const globalCurrencyFallback: TCurrencyCode = 'PLN'
export const allowedImageFormats: string[] = [
  'png',
  'jpg',
  'jpeg',
  'webp',
  'tif',
  'tiff',
  'bmp',
  'heic',
  'avif',
]
export const allowedFileFormats: string[] = ['pdf', 'step', 'stp', 'dxf']

export const longMaximumInputLength: number = 500
export const shortMaximumInputLength: number = 100
export const maximumDescriptionLength: number = 4000

export const noSearchResultsText: string = 'Brak wyników pasujących do frazy szukanej w tabeli!'
