import { confirmAlert } from 'react-confirm-alert'
import * as H from 'history'

import { CancelFormMessagePrompt, ConfirmActionModal } from '../components'

export const preventNavigationChange = (
  history: H.History<unknown>,
  isFormModifiedAndUnsaved: boolean | undefined,
  isFormEditable: boolean | undefined,
  sectionName:
    | 'suppliers'
    | 'bom-elements'
    | 'offers'
    | 'boms'
    | 'order'
    | 'implementation-costs'
    | 'services'
    | 'warehouses'
    | 'inventory-control'
    | 'users'
    | 'profiles'
    | 'settings'
    | 'replenishment'
    | 'manufacturing-steps/'
    | 'team-members/',
  elementId: string | number,
  mode: string
): void => {
  if (isFormModifiedAndUnsaved && isFormEditable) {
    // Warn on browser refresh or before closing a tab
    // The language used in this modal is the same as your browser's language and it can not be changed to a local language
    window.onbeforeunload = function () {
      return 'You have unsaved changes!'
    }
  } else {
    window.onbeforeunload = function () {
      return null
    }
  }

  let unblock = history.block((targetLocation) => {
    if (
      isFormModifiedAndUnsaved &&
      isFormEditable &&
      (sectionName === 'order'
        ? !targetLocation.pathname.includes(`/${sectionName}/${elementId}`)
        : sectionName === 'inventory-control'
        ? !targetLocation.pathname.includes(`warehouse-management/${elementId}/${sectionName}`)
        : sectionName === 'replenishment'
        ? !targetLocation.pathname.includes(`replenishment`)
        : mode === 'create'
        ? !targetLocation.pathname.includes(`${sectionName}/create`)
        : mode === 'edit'
        ? !targetLocation.pathname.includes(`${sectionName}/edit/${elementId}`)
        : !targetLocation.pathname.includes(`/${sectionName}/`))
    ) {
      confirmAlert({
        closeOnEscape: true,
        customUI: ({ onClose }) => {
          return (
            <ConfirmActionModal
              mode="cancel"
              onClose={onClose}
              confirmMessageJSX={<CancelFormMessagePrompt />}
              onConfirmFunction={() => {
                window.onbeforeunload = function () {
                  return null
                }

                unblock()

                return history.push(targetLocation)
              }}
            />
          )
        },
      })
    } else {
      window.onbeforeunload = function () {
        return null
      }

      unblock()

      return history.push(targetLocation)
    }

    return false
  })
}
