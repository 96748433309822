import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CButton, CInput } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { makeSearchBoxFixed } from '../../utils'
import { minimumWidthForWideDetailsView } from '../erp'

const Reports: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)

  const [reportsFilterState, setReportsFilterState] = useState<actionTypes.TFilterState>(undefined)
  const [reportsSorterState, setReportsSorterState] = useState<actionTypes.TSorterState>({
    column: '',
    asc: true,
  })

  const reportsList = [
    {
      name: 'Produkcja w toku',
      description: 'Elementy/Półprodukty na produkcji',
      author: 'Czarek',
      date: '2023-07-14',
      uuid: 'cbfabfce-ae54-4879-8e1a-f7d90039ad34',
      path: 'current-manufacturing-report',
    },
  ]

  const [favouriteReports, setFavouriteReports] = useState<actionTypes.IReportTableItem[]>(
    localStorage?.getItem('favouriteReports')
      ? JSON?.parse(localStorage?.getItem('favouriteReports') || '') || []
      : [reportsList[0]]
  )

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ maxWidth: '1600px' }}
    >
      <CCard style={{ maxWidth: '890px' }}>
        <CCardBody className="p-4">
          {authState?.authData?.roles?.includes('Users_write') && (
            <div className="reports-buttons-wrapper pb-2">
              {favouriteReports?.map((favouriteReport: actionTypes.IReportTableItem) => {
                return (
                  <CButton
                    key={`favourite-report-button-${favouriteReport.uuid}`}
                    color="primary"
                    type="button"
                    className="w-50 py-4 mt-2 mb-4"
                    onClick={() => {
                      history.push(`/${tenant}/reports/${favouriteReport.path}`)
                    }}
                  >
                    {favouriteReport?.name || '...'}
                  </CButton>
                )
              })}
            </div>
          )}

          <CDataTable
            tableFilterValue={reportsFilterState}
            sorterValue={reportsSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              setReportsSorterState(sorterState)
            }}
            onTableFilterChange={(tableFilterState: string) => {
              setReportsFilterState(tableFilterState)
            }}
            loading={false}
            striped={true}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
            addTableClasses="vertical-middle-list-table reports-table mb-2"
            cleaner
            onRowClick={undefined}
            fields={[
              {
                key: 'name',
                label: 'Nazwa raportu',
                _style: { width: '220px' },
              },
              {
                key: 'description',
                label: 'Opis',
                _style: { width: '300px' },
              },
              {
                key: 'author',
                label: 'Autor',
                _style: { width: '120px' },
              },
              {
                key: 'date',
                label: 'Data',
                _style: { width: '120px' },
              },
              {
                key: 'isFavourite',
                label: 'Ulubione',
                _style: { width: '45px', textAlign: 'center' },
              },
              {
                key: 'open',
                label: '',
                _style: { width: '45px' },
              },
            ]}
            items={reportsList}
            noItemsViewSlot={
              <div className="no-items-in-table">Brak raportów do wyświetlenia!</div>
            }
            scopedSlots={{
              isFavourite: (reportFromList: actionTypes.IReportTableItem) => (
                <td
                  className="text-center"
                  style={{ pointerEvents: 'none' }}
                  onClick={(event: SyntheticEvent) => {
                    event.preventDefault()
                    event.stopPropagation()
                    return null
                  }}
                >
                  <CInput
                    type="checkbox"
                    className="table-row-checkbox"
                    onClick={(event: SyntheticEvent) => {
                      // This line blocks the opening of report
                      event.stopPropagation()
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (
                        event.currentTarget.checked &&
                        !favouriteReports.includes(reportFromList)
                      ) {
                        const changedFavouriteReports = [...favouriteReports, reportFromList]

                        setFavouriteReports(changedFavouriteReports)

                        localStorage.setItem(
                          'favouriteReports',
                          JSON.stringify(changedFavouriteReports)
                        )
                      } else {
                        const changedFavouriteReports = favouriteReports.filter(
                          (favouriteReport: actionTypes.IReportTableItem) =>
                            favouriteReport.uuid !== reportFromList.uuid
                        )

                        setFavouriteReports(changedFavouriteReports)

                        localStorage.setItem(
                          'favouriteReports',
                          JSON.stringify(changedFavouriteReports)
                        )
                      }

                      // This line blocks the opening of item on checkbox/row click
                      event.stopPropagation()
                    }}
                    style={{ pointerEvents: 'all' }}
                    checked={
                      !!favouriteReports?.find(
                        (favouriteReport: actionTypes.IReportTableItem) =>
                          favouriteReport.uuid === reportFromList.uuid
                      )
                    }
                    disabled={reportFromList.name === 'Produkcja w toku'}
                  />
                </td>
              ),
              open: (reportFromList: actionTypes.IReportTableItem) => (
                <td className="text-center" style={{ pointerEvents: 'none' }}>
                  <CButton
                    color="dark"
                    variant="outline"
                    size="50"
                    className="list-action-button open-list-item-button"
                    style={{ pointerEvents: 'all' }}
                    onClick={() => {
                      history.push(`/${tenant}/reports/${reportFromList.path}`)
                    }}
                  >
                    <i className="cil-zoom"></i>
                  </CButton>
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </Container>
  )
}

export default Reports
