import React, { ChangeEvent } from 'react'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { ErrorMessage, FastField, Field } from 'formik'
import { CLabel } from '@coreui/react'
import NumberFormat from 'react-number-format'

import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  handleNumberFormatPaste,
  longMaximumInputLength,
  shortMaximumInputLength,
  globalCurrencyFallback,
  globalThousandSeparator,
  globalDecimalSeparator,
} from '../utils'
import { CurrencyPicker } from '.'
import { TCurrencyCode } from '../store/action-types'

interface IBasicFormField {
  fieldId: string
  fieldLabel: string
  formikFieldName: string
  fieldError: any
  fieldValue: any
  fieldIcon: string
  fieldType: HTMLInputElement['type']
  amountMeasurementType?: 1 | 2
  isDisabled?: boolean | undefined | null
  fieldCurrencyValue?: TCurrencyCode | null
  fieldCurrencyName?: string
  handleChange?: any
  placeholder?: string
  disabledCurrency?: boolean
  isShortLengthInput?: boolean
  inputGroupCustomClasses?: string
  autoCompleteValue?:
    | string
    | 'one-time-code'
    | 'current-password'
    | 'new-password'
    | 'email'
    | 'country'
    | 'on'
    | 'off'

  isFieldWithoutLabelAndIcon?: boolean
}

export const BasicFormField: React.FC<IBasicFormField> = ({
  fieldId,
  fieldLabel,
  formikFieldName,
  fieldError,
  fieldValue,
  fieldIcon,
  fieldType,
  isDisabled = false,
  amountMeasurementType,
  fieldCurrencyName = '',
  fieldCurrencyValue = null,
  handleChange = () => null,
  placeholder,
  disabledCurrency,
  isShortLengthInput = false,
  inputGroupCustomClasses = '',
  autoCompleteValue = 'off',
  isFieldWithoutLabelAndIcon = false,
}) => (
  <div className="basic-form-field-wrapper">
    {!isFieldWithoutLabelAndIcon && (
      <CLabel htmlFor={fieldId} className={`${inputLabelSpacingBottom}`}>
        {fieldLabel}
      </CLabel>
    )}

    <InputGroup
      id={fieldId}
      className={`${
        inputGroupCustomClasses || (!isFieldWithoutLabelAndIcon ? inputFieldSpacingBottom : '')
      }`}
    >
      {!isFieldWithoutLabelAndIcon && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText className={fieldError && 'text-danger input-error-icon'}>
            <i className={fieldIcon}></i>
          </InputGroupText>
        </InputGroupAddon>
      )}

      <ErrorMessage
        name={formikFieldName}
        component="span"
        className="text-danger input-error-message"
      />

      {fieldCurrencyName || amountMeasurementType ? (
        <Field
          as={NumberFormat}
          name={formikFieldName}
          value={fieldValue}
          displayType="input"
          thousandSeparator={globalThousandSeparator}
          decimalSeparator={globalDecimalSeparator}
          decimalScale={fieldCurrencyName || amountMeasurementType === 2 ? 2 : 0}
          fixedDecimalScale={fieldCurrencyName ? true : false}
          allowNegative={false}
          allowLeadingZeros={true}
          placeholder={placeholder || ` `}
          className={`${
            fieldError
              ? 'is-invalid'
              : fieldCurrencyName && !disabledCurrency
              ? 'currency-input-field'
              : ''
          } form-control text-right`}
          disabled={isDisabled}
          noValidate
          onPaste={(event: ChangeEvent<any>) => {
            handleNumberFormatPaste(event)
          }}
        />
      ) : (
        <FastField
          as={Input}
          name={formikFieldName}
          value={fieldValue}
          placeholder={placeholder || ` `}
          type={fieldType}
          autoComplete={autoCompleteValue}
          maxLength={isShortLengthInput ? shortMaximumInputLength : longMaximumInputLength}
          className={
            fieldError &&
            `${
              fieldType === 'date' || fieldType === 'datetime-local'
                ? 'invalid-field-without-exclamation'
                : 'is-invalid'
            }`
          }
          disabled={isDisabled}
        />
      )}

      {fieldCurrencyName && !disabledCurrency && (
        <InputGroupAddon addonType="append">
          <CurrencyPicker
            handleChange={handleChange}
            fieldName={fieldCurrencyName}
            dynamicCurrency={fieldCurrencyValue}
          />
        </InputGroupAddon>
      )}

      {fieldCurrencyName && disabledCurrency && (
        <InputGroupAddon addonType="append">
          <InputGroupText className="disabled-currency">
            {fieldCurrencyValue || globalCurrencyFallback}
          </InputGroupText>
        </InputGroupAddon>
      )}
    </InputGroup>
  </div>
)
