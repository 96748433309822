import React from 'react'
import { CButton, CModal, CModalBody, CModalHeader } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilChartLine,
  cilExitToApp,
  cilNewspaper,
  cilSend,
  cilStorage,
  cilSwapHorizontal,
  cilTrash,
  cilTruck,
  cilWarning,
} from '@coreui/icons'

interface IConfirmActionModal {
  mode:
    | 'delete'
    | 'archive'
    | 'cancel'
    | 'save'
    | 'generateOrder'
    | 'replenishment'
    | 'changeDelivery'
    | 'generateOrderPDF'
    | 'selectOther'
    | 'materialsFromUserReplenishment'
  onClose: () => void
  onConfirmFunction: () => void
  confirmMessageJSX: JSX.Element
}

export const ConfirmActionModal: React.FC<IConfirmActionModal> = ({
  mode,
  onClose,
  onConfirmFunction,
  confirmMessageJSX,
}) => (
  <CModal
    onClose={() => {
      onClose()
    }}
    closeOnBackdrop={false}
    show={true}
    backdrop
    addContentClass="global-confirm-modal py-3 px-5"
    centered={true}
  >
    <CModalHeader className="c-modal-header-grid mt-2" closeButton>
      <div
        style={{ borderBottom: '1px solid #bbb' }}
        className="text-center d-flex flex-column align-items-center mt-1"
      >
        <h2>
          {mode === 'materialsFromUserReplenishment' || mode === 'generateOrder'
            ? 'UWAGA'
            : 'Potwierdź akcję'}
        </h2>
        <div className="d-flex mt-3 mb-4">
          <CIcon
            icon={
              mode === 'delete'
                ? cilTrash
                : mode === 'archive'
                ? cilStorage
                : mode === 'cancel'
                ? cilExitToApp
                : mode === 'generateOrderPDF'
                ? cilNewspaper
                : mode === 'generateOrder'
                ? cilWarning
                : mode === 'changeDelivery'
                ? cilTruck
                : mode === 'selectOther'
                ? cilSwapHorizontal
                : mode === 'replenishment' || mode === 'materialsFromUserReplenishment'
                ? cilChartLine
                : cilSend
            }
            customClassName="request-password-reset-success-modal-icon"
          />
        </div>
      </div>
    </CModalHeader>
    <CModalBody style={{ padding: '1rem 0' }}>
      {mode === 'generateOrder' ? (
        <div>{confirmMessageJSX}</div>
      ) : (
        <p style={{ lineHeight: '1.5', fontSize: '17px' }} className="mt-2 mb-3 text-center">
          {confirmMessageJSX}
        </p>
      )}

      <div className="pt-4 d-flex justify-content-between w-100">
        <CButton
          color={
            mode === 'delete'
              ? 'danger'
              : mode === 'save' || mode === 'generateOrderPDF'
              ? 'success'
              : 'info'
          }
          className="btn ml-3"
          style={{ width: mode === 'generateOrder' ? '170px' : '150px' }}
          type="button"
          onClick={() => {
            onConfirmFunction()

            mode !== 'replenishment' && mode !== 'materialsFromUserReplenishment' ? onClose() : null
          }}
        >
          {mode === 'materialsFromUserReplenishment'
            ? 'Zatwierdź'
            : mode === 'generateOrder'
            ? 'Utwórz Zamówienie'
            : 'Tak'}
        </CButton>

        <CButton
          color="primary"
          className="btn mr-3"
          style={{ width: '150px' }}
          type="button"
          onClick={onClose}
        >
          {mode === 'materialsFromUserReplenishment'
            ? 'Odrzuć'
            : mode === 'generateOrder'
            ? 'Anuluj'
            : 'Nie'}
        </CButton>
      </div>
    </CModalBody>
  </CModal>
)
