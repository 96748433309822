import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  sectionFetch,
  deleteItemCall,
  singleItemFetch,
  manufacturingStepsCall,
  manufacturingActivityCall,
  manufacturingErrandCall,
  teamMemberCall,
} from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration, editSuccessMessageDuration } from '../../utils'

export function* fetchAllManufacturingSteps(action: actionTypes.fetchAllManufacturingStepsAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'manufacturingSteps')

    yield put({
      type: actionTypes.FETCH_ALL_MANUFACTURING_STEPS_SUCCEEDED,
      allManufacturingSteps: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_ALL_MANUFACTURING_STEPS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchManufacturingStepsDetails(
  action: actionTypes.fetchManufacturingStepsDetailsAction
) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `manufacturingSteps/edit/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_MANUFACTURING_STEPS_DETAILS_SUCCEEDED,
      manufacturingStepsDetails: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_MANUFACTURING_STEPS_DETAILS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchSingleBomForManufacuringStepsDetails(
  action: actionTypes.fetchSingleBomAction
) {
  try {
    const response = yield call(singleItemFetch, action.payload, `boms/edit/${action.payload.id}`)

    yield put({
      type: actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_SUCCEEDED,
      bomForManufacturingSteps: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_ERROR,
      error: error?.response,
    })
  }
}

export function* createManufacturingSteps(action: actionTypes.createManufacturingStepsAction) {
  try {
    const response = yield call(manufacturingStepsCall, action.payload)

    yield put({
      type: actionTypes.CREATE_MANUFACTURING_STEPS_SUCCEEDED,
      manufacturingStepsDetails: {
        bomName: 'abc',
        name: 'abcde',
        id: response.data.id,
        canDelete: true,
      },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.MANUFACTURING_STEPS_DETAILS_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_MANUFACTURING_STEPS_ERROR,
      error: error?.response,
    })
    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* editManufacturingSteps(action: actionTypes.editManufacturingStepsDetailsAction) {
  try {
    yield call(manufacturingStepsCall, action.payload)

    yield put({
      type: actionTypes.EDIT_MANUFACTURING_STEPS_DETAILS_SUCCEEDED,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.MANUFACTURING_STEPS_DETAILS_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_MANUFACTURING_STEPS_DETAILS_ERROR,
      error: error?.response,
    })
    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* deleteManufacturingSteps(action: actionTypes.deleteManufacturingStepsAction) {
  try {
    yield call(deleteItemCall, action.payload, 'manufacturingSteps')
    yield put({
      type: actionTypes.DELETE_MANUFACTURING_STEPS_SUCCEEDED,
      manufacturingSteps: { id: action.payload.id },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.CLOSE_MANUFACTURING_STEPS_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_MANUFACTURING_STEPS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchManufacturingActivities(
  action: actionTypes.fetchManufacturingActivitiesAction
) {
  try {
    const response = yield call(sectionFetch, action.payload, 'manufacturingActivities')
    yield put({
      type: actionTypes.FETCH_MANUFACTURING_ACTIVITIES_SUCCEEDED,
      manufacturingActivities: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_MANUFACTURING_ACTIVITIES_ERROR,
      error: error?.response,
    })
  }
}

export function* createManufacturingActivity(
  action: actionTypes.createManufacturingActivityAction
) {
  try {
    const response = yield call(manufacturingActivityCall, action.payload)
    yield put({
      type: actionTypes.CREATE_MANUFACTURING_ACTIVITY_SUCCEEDED,
      manufacturingActivity: { ...action.payload.manufacturingActivity, id: response.data },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_MANUFACTURING_ACTIVITY_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_MANUFACTURING_ACTIVITY_ERROR,
      error: error?.response,
    })
  }
}

export function* editManufacturingActivity(action: actionTypes.editManufacturingActivityAction) {
  try {
    yield call(manufacturingActivityCall, action.payload)
    yield put({
      type: actionTypes.EDIT_MANUFACTURING_ACTIVITY_SUCCEEDED,
      manufacturingActivity: action.payload.manufacturingActivity,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_MANUFACTURING_ACTIVITY_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_MANUFACTURING_ACTIVITY_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteManufacturingActivity(
  action: actionTypes.deleteManufacturingActivityAction
) {
  try {
    yield call(deleteItemCall, action.payload, 'manufacturingActivities')
    yield put({
      type: actionTypes.DELETE_MANUFACTURING_ACTIVITY_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_MANUFACTURING_ACTIVITY_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_MANUFACTURING_ACTIVITY_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchTeamMembers(action: actionTypes.fetchTeamMembersAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'teamMembers')
    yield put({
      type: actionTypes.FETCH_TEAM_MEMBERS_REQUESTED,
      teamMembers: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_TEAM_MEMBERS_ERROR,
      error: error?.response,
    })
  }
}

export function* editTeamMember(action: actionTypes.editTeamMemberAction) {
  try {
    yield call(teamMemberCall, action.payload)
    yield put({
      type: actionTypes.EDIT_TEAM_MEMBER_SUCCEEDED,
      teamMember: action.payload.teamMember,
    })
    yield delay(successMessageDuration)
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_TEAM_MEMBER_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchManufacturingErrands(action: actionTypes.fetchManufacturingErrandsAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'manufacturingErrands')
    yield put({
      type: actionTypes.FETCH_MANUFACTURING_ERRANDS_SUCCEEDED,
      manufacturingErrands: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_MANUFACTURING_ERRANDS_ERROR,
      error: error?.response,
    })
  }
}

export function* createManufacturingErrand(action: actionTypes.createManufacturingErrandAction) {
  try {
    const response = yield call(manufacturingErrandCall, action.payload)
    yield put({
      type: actionTypes.CREATE_MANUFACTURING_ERRAND_SUCCEEDED,
      manufacturingErrand: { ...action.payload.manufacturingErrand, id: response.data },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_MANUFACTURING_ERRAND_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_MANUFACTURING_ERRAND_ERROR,
      error: error?.response,
    })
  }
}

export function* editManufacturingErrand(action: actionTypes.editManufacturingErrandAction) {
  try {
    yield call(manufacturingErrandCall, action.payload)
    yield put({
      type: actionTypes.EDIT_MANUFACTURING_ERRAND_SUCCEEDED,
      manufacturingErrand: action.payload.manufacturingErrand,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_MANUFACTURING_ERRAND_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_MANUFACTURING_ERRAND_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteManufacturingErrand(action: actionTypes.deleteManufacturingErrandAction) {
  try {
    yield call(deleteItemCall, action.payload, 'manufacturingErrands')
    yield put({
      type: actionTypes.DELETE_MANUFACTURING_ERRAND_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_MANUFACTURING_ERRAND_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_MANUFACTURING_ERRAND_ERROR,
      error: error?.response,
    })
  }
}

export function* manufacturingSaga() {
  yield takeLatest(actionTypes.FETCH_ALL_MANUFACTURING_STEPS_REQUESTED, fetchAllManufacturingSteps)

  yield takeLatest(
    actionTypes.FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_REQUESTED,
    fetchSingleBomForManufacuringStepsDetails
  )
  yield takeLatest(actionTypes.CREATE_MANUFACTURING_STEPS_REQUESTED, createManufacturingSteps)
  yield takeLatest(
    actionTypes.FETCH_MANUFACTURING_STEPS_DETAILS_REQUESTED,
    fetchManufacturingStepsDetails
  )
  yield takeLatest(actionTypes.EDIT_MANUFACTURING_STEPS_DETAILS_REQUESTED, editManufacturingSteps)
  yield takeLatest(actionTypes.DELETE_MANUFACTURING_STEPS_REQUESTED, deleteManufacturingSteps)

  yield takeLatest(
    actionTypes.FETCH_MANUFACTURING_ACTIVITIES_REQUESTED,
    fetchManufacturingActivities
  )
  yield takeLatest(actionTypes.CREATE_MANUFACTURING_ACTIVITY_REQUESTED, createManufacturingActivity)
  yield takeLatest(actionTypes.DELETE_MANUFACTURING_ACTIVITY_REQUESTED, deleteManufacturingActivity)
  yield takeLatest(actionTypes.EDIT_MANUFACTURING_ACTIVITY_REQUESTED, editManufacturingActivity)

  yield takeLatest(actionTypes.FETCH_TEAM_MEMBERS_REQUESTED, fetchTeamMembers)
  yield takeLatest(actionTypes.EDIT_TEAM_MEMBER_REQUESTED, editTeamMember)

  yield takeLatest(actionTypes.FETCH_MANUFACTURING_ERRANDS_REQUESTED, fetchManufacturingErrands)
  yield takeLatest(actionTypes.CREATE_MANUFACTURING_ERRAND_REQUESTED, createManufacturingErrand)
  yield takeLatest(actionTypes.DELETE_MANUFACTURING_ERRAND_REQUESTED, deleteManufacturingErrand)
  yield takeLatest(actionTypes.EDIT_MANUFACTURING_ERRAND_REQUESTED, editManufacturingErrand)
}
