import { useEffect, useState } from 'react'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'

import * as actionTypes from '../../store/action-types'
import {
  convertNumericStringToNumber,
  currencyExchanger,
  globalCurrencyFallback,
  totalBomCostCalculator,
} from '../../utils'
import { useSelector } from 'react-redux'
import { TRootState } from '../../store/reducers'
import { offlineCurrencyExchangeRates } from '../erp'
import { isString } from 'formik'

type TPieChartData = {
  name: string
  quantity: number | null | undefined
  estimatedPrice: number | null | undefined
  color: string
  calculatedPercentageOfTotalCost?: number
}

export const BomDetailsGraphs = ({ values }) => {
  const adminState = useSelector((state: TRootState) => state.admin)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const displayedCurrency =
    settingsState?.settings?.otherSettings?.defaultSystemCurrency || globalCurrencyFallback

  const exchangeRates = adminState.todayCurrencyExchange
    ? adminState.todayCurrencyExchange.rates
    : offlineCurrencyExchangeRates

  const estimatedTotalBomCost = totalBomCostCalculator(values, adminState, 'estimated', true)

  const minimumPercentageOfTotalCost = 0.5

  let [estimatedPriceOfElementsPieChart, setEstimatedPriceOfElementsPieChart] = useState<
    Array<TPieChartData>
  >([])

  useEffect(() => {
    const estimatedPriceOfElementsData: TPieChartData[] = values?.bomElementDetails?.map(
      (bomElement: actionTypes.IBomFullElement) => {
        const calculatedBomElementPrice = currencyExchanger(
          bomElement.bomElementMeasurementType === 2
            ? (Number(convertNumericStringToNumber(bomElement?.quantity)) /
                Number(convertNumericStringToNumber(bomElement?.bomElementCentimetersInPiece))) *
                Number(convertNumericStringToNumber(bomElement?.estimatedPrice))
            : Number(convertNumericStringToNumber(bomElement?.estimatedPrice)),
          bomElement.estimatedPriceCurrency,
          displayedCurrency,
          exchangeRates
        )

        const calculatedPercentageOfTotalCost =
          (calculatedBomElementPrice / estimatedTotalBomCost) * 100

        // Remove values with a cost below 5% of total BOM cost
        if (calculatedPercentageOfTotalCost < minimumPercentageOfTotalCost) {
          return null
        } else {
          return {
            name: bomElement.bomElementName,
            quantity: bomElement.quantity,
            estimatedPrice: calculatedBomElementPrice,
            color: `rgb(${Math.floor(Math.random() * (200 + 1 - 50)) + 50}, ${
              Math.floor(Math.random() * (200 + 1 - 50)) + 50
            }, ${Math.floor(Math.random() * (255 + 1 - 50)) + 50}
            )`,
            calculatedPercentageOfTotalCost: calculatedPercentageOfTotalCost,
          }
        }
      }
    )

    setEstimatedPriceOfElementsPieChart(estimatedPriceOfElementsData)
    // The values can not be updated each time because the graph changes unexpectedly, it should only react to the bomElementDetails
    // eslint-disable-next-line
  }, [values.bomElementDetails, exchangeRates])

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-4">
        <h5 className="my-3">
          Wykres elementów o szacunkowej cenie w {displayedCurrency} powyżej{' '}
          {minimumPercentageOfTotalCost}% kosztu całkowitego
        </h5>

        {estimatedPriceOfElementsPieChart &&
        estimatedPriceOfElementsPieChart.some(
          (data: TPieChartData) =>
            data && data?.name && data?.quantity && data?.estimatedPrice && data?.color
        ) ? (
          <PieChart width={1300} height={700}>
            <Pie
              data={estimatedPriceOfElementsPieChart}
              dataKey="estimatedPrice"
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              outerRadius={220}
              fill="#5f708c"
              fillRule="evenodd"
              label={(dataEntry: TPieChartData) =>
                isString(dataEntry?.name)
                  ? `${dataEntry?.name} : : ${Math.round(
                      Number(dataEntry?.calculatedPercentageOfTotalCost)
                    )}%`
                  : ''
              }
            >
              {estimatedPriceOfElementsPieChart?.map((data: TPieChartData, index: number) => (
                <Cell key={`cell-${index}`} fill={data?.color} />
              ))}
            </Pie>

            <Tooltip
              formatter={(value: number) =>
                `: : ${new Intl.NumberFormat('pl').format(value)} ${displayedCurrency}`
              }
            />
          </PieChart>
        ) : (
          <p className="mt-0">Brak elementów z wpisaną ilością i ceną szacunkową!</p>
        )}
      </div>
    </>
  )
}
