import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, FastField } from 'formik'
import {
  CModal,
  CLabel,
  CModalHeader,
  CButton,
  CTabs,
  CNav,
  CTabContent,
  CTabPane,
  CNavItem,
  CNavLink,
  CInput,
} from '@coreui/react'

import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  convertNumberToNumericString,
  getErrorMessageFromStatus,
} from '../../utils'
import { BasicFormField, CustomErrorMessage, ThreeDots } from '../../components'
import { TNetworkError, IPurchasePlanDetailsInModal } from '../../store/action-types'

interface IPurchasePlanDetailsModal {
  section: 'bom-elements' | 'services' | 'implementation-costs'
  closeAction: string
  loadingState: boolean
  modalError: TNetworkError
  modalTabs: IPurchasePlanDetailsInModal[] | null
}

export const PurchasePlanDetailsModal: React.FC<IPurchasePlanDetailsModal> = ({
  section,
  closeAction,
  loadingState,
  modalError,
  modalTabs,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { tenant, id, bomIdTab } = useParams<{ tenant: string; id: string; bomIdTab: string }>()
  const purchasePlanId = id

  const closePurchasePlanDetailsModal = useCallback(() => {
    history.replace(`/${tenant}/purchase-plan/${purchasePlanId}/${section}`)

    dispatch({
      type: closeAction,
    })
  }, [dispatch, history, tenant, purchasePlanId])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({ type: closeAction })
    }
  }, [])

  useEffect(() => {
    const firstElement = modalTabs && modalTabs[0]

    if (firstElement) {
      history.replace(
        `/${tenant}/purchase-plan/${purchasePlanId}/${section}/${
          section === 'bom-elements'
            ? firstElement.bomElementId
            : section === 'services'
            ? firstElement.serviceId
            : firstElement.implementationCostId
        }/${firstElement?.bomId ? String(firstElement?.bomId) : null}`
      )
    }
  }, [modalTabs])

  return (
    <CModal
      onClose={() => {
        closePurchasePlanDetailsModal()
      }}
      size=""
      show={true}
      centered
      backdrop
      closeOnBackdrop
      addContentClass="purchase-plan-details-modal py-4 px-5"
      style={{ minHeight: '538px' }}
    >
      <CModalHeader className="c-modal-header-grid" closeButton>
        <h4 className={`text-center mb-2`}>
          Szczegóły zapotrzebowania: <br />
          <i style={{ display: 'block', marginTop: '3px' }}>
            {loadingState ? (
              <ThreeDots />
            ) : section === 'bom-elements' ? (
              (modalTabs !== null && modalTabs[0]?.bomElementName) || 'Element'
            ) : section === 'services' ? (
              (modalTabs !== null && modalTabs[0]?.serviceName) || 'Usługa'
            ) : (
              (modalTabs !== null && modalTabs[0]?.implementationCostName) || 'Tooling'
            )}
          </i>
        </h4>
      </CModalHeader>
      {loadingState ? (
        <div
          style={{
            height: '360px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <Formik
          initialValues={{
            modalTabs: modalTabs || [],
          }}
          validationSchema={null}
          onSubmit={() => {
            return undefined
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values }) => (
            <Form>
              {modalError && (
                <CustomErrorMessage
                  wrapperClassNames="my-3"
                  customErrorMessageText={getErrorMessageFromStatus('fetch', modalError?.status)}
                />
              )}

              <CTabs activeTab={bomIdTab}>
                <CNav variant="tabs" className="mb-4 mt-3">
                  {values?.modalTabs?.map(
                    (detailModalTab: IPurchasePlanDetailsInModal, indexOfNavTab: number) => {
                      return (
                        <CNavItem key={detailModalTab.bomId}>
                          <CNavLink
                            data-tab={detailModalTab.bomId}
                            onClick={() => {
                              history.replace(
                                `/${tenant}/purchase-plan/${purchasePlanId}/${section}/${
                                  section === 'bom-elements'
                                    ? detailModalTab.bomElementId
                                    : section === 'services'
                                    ? detailModalTab.serviceId
                                    : detailModalTab.implementationCostId
                                }/${detailModalTab.bomId}`
                              )
                            }}
                          >
                            {detailModalTab.bomName || `BOM ${indexOfNavTab + 1}`}
                          </CNavLink>
                        </CNavItem>
                      )
                    }
                  )}
                </CNav>
                <CTabContent>
                  {values.modalTabs?.map(
                    (
                      detailModalTab: IPurchasePlanDetailsInModal,
                      indexOfDetailModalTab: number
                    ) => (
                      <CTabPane
                        data-tab={detailModalTab.bomId}
                        key={`${detailModalTab.bomId}-tab-pane`}
                      >
                        <div className="purchase-plan-details-modal-prices-grid-row">
                          <BasicFormField
                            fieldId={`purchase-plan-${section}-estimated-quantity-${detailModalTab.bomId}`}
                            fieldLabel="Planowana ilość"
                            fieldIcon="cil-asterisk"
                            formikFieldName={`modalTabs.${indexOfDetailModalTab}.estimatedQuantity`}
                            fieldError={null}
                            fieldValue={detailModalTab?.estimatedQuantity || ''}
                            fieldType="number"
                            placeholder="Planowana ilość"
                            amountMeasurementType={1}
                            isDisabled
                          />

                          <BasicFormField
                            fieldId={`purchase-plan-${section}-estimated-price-${detailModalTab.bomId}`}
                            fieldLabel="Cena szacunkowa"
                            fieldIcon="cil-cash"
                            formikFieldName={`modalTabs.${indexOfDetailModalTab}.estimatedPrice`}
                            fieldError={null}
                            fieldValue={detailModalTab?.estimatedPrice}
                            fieldType="number"
                            placeholder="Cena szacunkowa"
                            fieldCurrencyName={`modalTabs.${indexOfDetailModalTab}.estimatedPriceCurrency`}
                            fieldCurrencyValue={detailModalTab.estimatedPriceCurrency}
                            isDisabled
                            disabledCurrency
                          />
                        </div>

                        <div className="d-flex flex-column justify-content-center purchase-plan-details-offer mb-1">
                          <CLabel
                            htmlFor={`purchase-plan-${section}-offer-${detailModalTab.bomId}`}
                            className={`${inputLabelSpacingBottom} mt-1`}
                          >
                            Oferta
                          </CLabel>
                          <InputGroup
                            id={`purchase-plan-${section}-offer-${detailModalTab.bomId}`}
                            className={`${inputFieldSpacingBottom}`}
                          >
                            <FastField
                              as={CInput}
                              className={
                                detailModalTab && detailModalTab?.offer
                                  ? ''
                                  : 'placeholder-text-color'
                              }
                              value={
                                (detailModalTab &&
                                  detailModalTab?.offer &&
                                  `${detailModalTab?.offer?.supplierName || 'dostawca'}  |  ${
                                    section === 'bom-elements'
                                      ? `${detailModalTab?.offer?.quantity || 'ilość'} szt.  |  ${
                                          convertNumberToNumericString(
                                            detailModalTab?.offer?.bomElementPrice
                                          ) || 'cena'
                                        } ${detailModalTab?.offer?.bomElementPriceCurrency || ''}`
                                      : section === 'services'
                                      ? `${
                                          convertNumberToNumericString(
                                            detailModalTab?.offer?.servicePrice
                                          ) &&
                                          `Usługa: ${detailModalTab?.offer?.servicePrice} ${
                                            detailModalTab?.offer?.servicePriceCurrency || ''
                                          }`
                                        }`
                                      : `${
                                          detailModalTab?.offer?.implementationCostPrice &&
                                          `Tooling: ${
                                            detailModalTab?.offer?.implementationCostPrice?.toFixed(
                                              2
                                            ) || ''
                                          } ${
                                            detailModalTab?.offer
                                              ?.implementationCostPriceCurrency || ''
                                          }`
                                        }`
                                  }`) ||
                                'Brak przypisanej oferty'
                              }
                              variant="outlined"
                              name={`modalTabs.${indexOfDetailModalTab}.offerId`}
                              disabled
                            />

                            <InputGroupAddon addonType="append">
                              {detailModalTab?.offerId ? (
                                <span className="offer-link-checkout">
                                  <InputGroupText
                                    onClick={() =>
                                      history.push(
                                        `/${tenant}/offers/edit/${detailModalTab?.offerId}/information`
                                      )
                                    }
                                  >
                                    <i className="cil-magnifying-glass"></i>
                                  </InputGroupText>
                                </span>
                              ) : (
                                <InputGroupText>
                                  <i className="cil-x-circle"></i>
                                </InputGroupText>
                              )}
                            </InputGroupAddon>
                          </InputGroup>
                        </div>

                        <div className="purchase-plan-details-modal-prices-grid-row">
                          <BasicFormField
                            fieldId={`purchase-plan-${section}-ordered-quantity-${detailModalTab.bomId}`}
                            fieldLabel="Ilość na zamówieniu"
                            fieldIcon="cil-cash"
                            formikFieldName={`modalTabs.${indexOfDetailModalTab}.orderedQuantity`}
                            fieldError={null}
                            fieldValue={detailModalTab?.orderedQuantity || ''}
                            fieldType="number"
                            placeholder="Ilość na zamówieniu"
                            amountMeasurementType={1}
                            isDisabled
                          />

                          <BasicFormField
                            fieldId={`purchase-plan-${section}-order-price-${detailModalTab.bomId}`}
                            fieldLabel="Cena z zamówienia"
                            fieldIcon="cil-cash"
                            formikFieldName={`modalTabs.${indexOfDetailModalTab}.orderPrice`}
                            fieldError={null}
                            fieldValue={detailModalTab?.orderPrice}
                            fieldType="number"
                            placeholder="Cena z zamówienia"
                            fieldCurrencyName={`modalTabs.${indexOfDetailModalTab}.order.bomElementsTotalPrice`}
                            fieldCurrencyValue={
                              detailModalTab?.order?.bomElementsTotalPriceCurrency
                            }
                            isDisabled
                            disabledCurrency
                          />
                        </div>

                        <div className="d-flex justify-content-center mt-3 pb-1">
                          <CButton
                            color="info"
                            variant="outline"
                            className="button--go-to d-flex justify-content-center align-items-center"
                            onClick={() =>
                              history.push(
                                `/${tenant}/boms/edit/${detailModalTab?.bomId}/${
                                  section === 'bom-elements' ? 'elements' : section
                                }`
                              )
                            }
                          >
                            Otwórz BOM &nbsp; <span className="small-paper-icon">📰</span>
                          </CButton>

                          {detailModalTab?.orderId && (
                            <CButton
                              color="info"
                              variant="outline"
                              className="button--go-to "
                              style={{ marginLeft: '54px' }}
                              onClick={() =>
                                history.push(
                                  `/${tenant}/order${
                                    detailModalTab?.orderId
                                      ? `/${detailModalTab?.orderId}/general-information`
                                      : ''
                                  }`
                                )
                              }
                            >
                              Otwórz Zamówienie &nbsp;
                              <span className="small-truck-icon">&#128451;</span>
                            </CButton>
                          )}
                        </div>
                      </CTabPane>
                    )
                  )}
                </CTabContent>
              </CTabs>

              <div className="d-flex justify-content-end mt-4 pt-1">
                <CButton
                  color="primary"
                  className="cancel-button"
                  variant="outline"
                  type="button"
                  onClick={() => {
                    closePurchasePlanDetailsModal()
                  }}
                >
                  Wyjdź
                </CButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </CModal>
  )
}
