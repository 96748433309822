import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { ListActionButton } from '../../components'
import { ListStickyErrorMessage } from '../../components/list-sticky-error-message'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'

const ManufacturingSteps: React.FC<{}> = () => {
  const { tenant } = useParams<{ tenant: string }>()
  const history = useHistory()
  let dispatch = useDispatch()

  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)
  const authState = useSelector((state: TRootState) => state.auth)

  const memoizedTableItems = useMemo(
    () =>
      manufacturingState?.allManufacturingSteps?.map(
        (singleManufacturingSteps: actionTypes.IManufacturingSteps) => {
          return {
            ...singleManufacturingSteps,
            bomName: singleManufacturingSteps?.bomName || '',
            icon: '🖊️',
          }
        }
      ),
    [manufacturingState.allManufacturingSteps]
  )

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()

    // Remove all errors on refresh or path change
    dispatch({
      type: actionTypes.CLOSE_MANUFACTURING_STEPS_DETAILS,
    })
  }, [dispatch, makeSearchBoxFixed])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="7">
        <CCard>
          <CCardBody className="pb-2">
            {authState?.authData?.roles?.includes('Boms_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/manufacturing-steps/create`)
                  }}
                >
                  Nowe Etapy
                </CButton>
              </div>
            )}

            {manufacturingState?.manufacturingStepsDeleteError && (
              <ListStickyErrorMessage
                listErrorMessage={`Wystąpił błąd ${
                  manufacturingState?.manufacturingStepsDeleteError?.status || 'nieznany'
                } podczas ${
                  (manufacturingState?.manufacturingStepsDeleteError && 'usuwania') || 'zmiany'
                } etapów produkcji!`}
              />
            )}

            <CDataTable
              loading={manufacturingState.areAllManufacturingStepsLoading}
              striped={!!manufacturingState.allManufacturingSteps.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              onRowClick={(singleManufacturingSteps: actionTypes.IManufacturingSteps) => {
                history.push(`/${tenant}/manufacturing-steps/edit/${singleManufacturingSteps.id}`)
              }}
              addTableClasses="item-boms-table"
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '45px',
                    display: !manufacturingState.allManufacturingSteps.length ? 'none' : '',
                  },
                },
                {
                  key: 'bomName',
                  label: 'Produkt',
                  _style: { width: '1fr', textAlign: 'left' },
                },
                {
                  key: 'delete',
                  label: '',
                  _style: { width: '45px' },
                },
              ]}
              scopedSlots={{
                delete: (itemFromList: actionTypes.IManufacturingSteps) => (
                  <td
                    className="text-center"
                    style={{ pointerEvents: 'none' }}
                    onClick={(event: SyntheticEvent) => {
                      if (!itemFromList.canDelete) {
                        event.preventDefault()
                        event.stopPropagation()
                        return null
                      } else {
                        return undefined
                      }
                    }}
                  >
                    <ListActionButton
                      mainActionName="delete"
                      buttonColor="danger"
                      classNames="delete-list-item-button"
                      typeOfAction={actionTypes.DELETE_MANUFACTURING_STEPS_REQUESTED}
                      iconName="cil-trash"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={
                        manufacturingState.currentlyManagedManufacturingStepsId
                      }
                      isItemCopying={false}
                      isItemArchiving={false}
                      isItemDeleting={manufacturingState.areManufacturingStepsDeleting}
                    />
                  </td>
                ),
              }}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    manufacturingState.fetchAllManufacturingStepsError,
                    manufacturingState.areAllManufacturingStepsLoading,
                    'etapów produkcji',
                    manufacturingState?.allManufacturingSteps?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default ManufacturingSteps
