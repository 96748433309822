import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, ErrorMessage, Field, FastField } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton, CDataTable, CLabel } from '@coreui/react'
import { Select } from '@material-ui/core'
import NumberFormat from 'react-number-format'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  BasicFormField,
  CustomErrorMessage,
  ThreeDots,
  FormActionCancelButton,
} from '../../../components'
import {
  getErrorMessageFromStatus,
  globalThousandSeparator,
  globalDecimalSeparator,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  toDateInputValue,
  toDateTimeInputValue,
  successMessageDuration,
} from '../../../utils'
import { Typeahead } from 'react-bootstrap-typeahead'

export const FinishedProductsReplenishment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{
    tenant: string
    id: string
  }>()
  const paramsManagedWarehouseId = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [
    isFinishedProductsReplenishmentModifiedAndUnsaved,
    setIsFinishedProductsReplenishmentModifiedAndUnsaved,
  ] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)
  const usersState = useSelector((state: TRootState) => state.users)

  const [finishedProductsReplenishmentFilterState, setFinishedProductsReplenishmentFilterState] =
    useState<actionTypes.TFilterState>(undefined)

  const [finishedProductsReplenishmentSorterState, setFinishedProductsReplenishmentSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const closeFinishedProductsReplenishment = useCallback(() => {
    history.push(`/${tenant}/warehouse-management/${paramsManagedWarehouseId}`)
  }, [dispatch, history, tenant, paramsManagedWarehouseId])

  const generateFinishedProductsListFromManufacturingErrand = (
    finishedProductsInManufacturingErrand: actionTypes.TManufacturingErrand
  ) => [
    {
      bomId: finishedProductsInManufacturingErrand?.bomId || '',
      bomName: finishedProductsInManufacturingErrand?.bomName || '',
      quantity: '',
      position: `1.`,
    },
  ]

  const isWarehouseEditable = authState?.authData?.roles?.includes('Warehouses_write')

  const emptyManufacturingErrandItemsInForm = []
  const selectManufacturingErrandPlaceholderValue = ''

  const managedWarehouseTypes: actionTypes.TWarehouseType[] | undefined = useMemo(
    () =>
      warehousesState?.warehouses?.find(
        (warehouse: actionTypes.TWarehouseLight) =>
          warehouse.id === Number(paramsManagedWarehouseId)
      )?.types,
    [warehousesState.warehouses, paramsManagedWarehouseId]
  )

  useEffect(() => {
    if (!managedWarehouseTypes?.includes(actionTypes.finishedProductsTypeNumber)) {
      closeFinishedProductsReplenishment()
    }
  }, [managedWarehouseTypes, history])

  // Close finished products replenishment if document was generated
  useEffect(() => {
    if (warehousesState.isFinishedProductsReplenishmentDocumentGenerated) {
      setTimeout(() => {
        closeFinishedProductsReplenishment()
      }, successMessageDuration)
    }
  }, [warehousesState.isFinishedProductsReplenishmentDocumentGenerated])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_FINISHED_PRODUCTS_REPLENISHMENT,
      })
    }
  }, [])

  const FinishedProductsReplenishmentSchema = Yup.object().shape({
    date: Yup.string().required('To pole jest wymagane!'),
    referenceNumber: Yup.string().required('To pole jest wymagane!'),
    userId: Yup.string().required('To pole jest wymagane!'),
    selectedManufacturingErrand: Yup.string().required('Wybierz zlecenie!'),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="warehouse-details">
          <h4 className="text-center mb-4">
            {authState?.authData?.roles?.includes('Warehouses_write')
              ? 'Przyjęcie wyrobów gotowych'
              : ''}
          </h4>

          <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
            <FormActionCancelButton
              closeFunction={closeFinishedProductsReplenishment}
              closeAction={''}
            />
          </div>
          {manufacturingState?.areManufacturingErrandsLoading ? (
            <div
              style={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                date: toDateTimeInputValue(new Date().toISOString()),
                referenceNumber: '',
                userId: '',
                selectedManufacturingErrand: '',
                replenishmentDetails: emptyManufacturingErrandItemsInForm,
              }}
              validationSchema={FinishedProductsReplenishmentSchema}
              onSubmit={(values) => {
                if (isWarehouseEditable) {
                  setDidFormValidationOccur(true)
                  setIsFinishedProductsReplenishmentModifiedAndUnsaved(false)

                  const replenishmentDetails:
                    | actionTypes.TFromToManufacturingReplenishmentDetailsItem[]
                    | undefined = values?.replenishmentDetails?.map(
                    (
                      replenishmentItem: actionTypes.TFromToManufacturingReplenishmentDetailsItem
                    ) => {
                      return {
                        bomId: replenishmentItem?.bomId || undefined,
                        quantity: Number(replenishmentItem?.quantity),
                      }
                    }
                  )

                  const selectedManufacturingErrand: any = values?.selectedManufacturingErrand
                    ? JSON.parse(values?.selectedManufacturingErrand)
                    : undefined

                  dispatch({
                    type: actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      documentData: {
                        warehouseId: Number(paramsManagedWarehouseId),
                        date: values?.date || '',
                        referenceNumber: values.referenceNumber || '',
                        userId: values?.userId || '',
                        manufacturingErrandId: selectedManufacturingErrand?.id || 0,
                        replenishmentDetails: replenishmentDetails,
                      },
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ values, errors, setFieldValue, handleChange }) => (
                <Form
                  onChange={() => {
                    setIsFinishedProductsReplenishmentModifiedAndUnsaved(true)
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {!isFinishedProductsReplenishmentModifiedAndUnsaved &&
                    warehousesState?.finishedProductsReplenishmentDocumentGenerateError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'create',
                          warehousesState?.finishedProductsReplenishmentDocumentGenerateError
                            ?.status,
                          'danych przyjęcia wyrobów gotowych'
                        )}
                      />
                    )}

                  {usersState?.fetchUsersError && (
                    <CustomErrorMessage
                      wrapperClassNames="mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        usersState?.fetchUsersError?.status,
                        'listy użytkowników'
                      )}
                    />
                  )}

                  {manufacturingState?.fetchManufacturingErrandsError && (
                    <CustomErrorMessage
                      wrapperClassNames="mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        manufacturingState?.fetchManufacturingErrandsError?.status,
                        'zleceń produkcyjnych'
                      )}
                    />
                  )}

                  <div className="settings-view-double-grid-fields">
                    <BasicFormField
                      fieldId="warehouse-management-finished-products-replenishment-manufacturing-errand-date"
                      fieldLabel="Data i godzina przyjęcia"
                      fieldIcon="cil-calendar"
                      formikFieldName="date"
                      fieldValue={values.date}
                      fieldError={errors.date}
                      fieldType="datetime-local"
                      placeholder=""
                    />

                    <BasicFormField
                      fieldId="warehouse-management-finished-products-replenishment-reference-number"
                      fieldLabel="Dokument przyjęcia"
                      fieldIcon="cil-short-text"
                      formikFieldName="referenceNumber"
                      fieldValue={values.referenceNumber}
                      fieldError={errors.referenceNumber}
                      fieldType="text"
                      placeholder="Wpisz numer PW"
                    />
                  </div>

                  <div className="warehouse-management-finished-products-replenishment-giver-wrapper">
                    <CLabel
                      htmlFor="warehouse-management-finished-products-replenishment-giver"
                      className={`${inputLabelSpacingBottom}`}
                    >
                      Zdający
                    </CLabel>
                    <InputGroup
                      id="warehouse-management-finished-products-replenishment-giver"
                      className={`${inputFieldSpacingBottom} flex-nowrap`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className={errors.userId && 'text-danger input-error-icon'}>
                          <i className="cil-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>

                      <ErrorMessage
                        name="userId"
                        component="span"
                        className="text-danger input-error-message"
                      />

                      <Typeahead
                        placeholder="Wybierz z listy lub wpisz e-mail zdającego"
                        isInvalid={!!errors.userId}
                        id="warehouse-management-finished-products-replenishment-giver-typeahead"
                        onChange={(selected: actionTypes.TUser[]) => {
                          if (selected.length > 0) {
                            setFieldValue('userId', selected[0].userId)
                          }
                        }}
                        onInputChange={(text) => {
                          if (text.length > 0) {
                            const matchedUser = usersState.users.find(
                              (user: actionTypes.TUser) =>
                                user.userName.toLowerCase() === text.toLowerCase()
                            )

                            if (matchedUser) {
                              setFieldValue('userId', matchedUser.userId)
                            } else {
                              setFieldValue('userId', -1)
                            }
                          } else {
                            setFieldValue('userId', undefined)
                          }
                        }}
                        defaultInputValue={''}
                        options={usersState.users}
                        labelKey="userName"
                        emptyLabel="Nie znaleziono użytkowników"
                      />
                    </InputGroup>
                  </div>

                  <div className="mb-4">
                    <CLabel
                      htmlFor="warehouse-management-finished-products-replenishment-manufacturing-errand-id"
                      className={inputLabelSpacingBottom}
                    >
                      Zlecenie produkcyjne
                    </CLabel>
                    <InputGroup
                      id="warehouse-management-finished-products-replenishment-manufacturing-errand-id"
                      className={`${inputFieldSpacingBottom} dropdown-selector`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          className={
                            errors.selectedManufacturingErrand && 'text-danger input-error-icon'
                          }
                        >
                          <i className="cil-factory"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <ErrorMessage
                        name="selectedManufacturingErrand"
                        component="span"
                        className="text-danger input-error-message"
                      />
                      <Field
                        as={Select}
                        variant="outlined"
                        native
                        className={`item-selector element-selector w-100 text-center ${
                          String(values.selectedManufacturingErrand) ===
                            selectManufacturingErrandPlaceholderValue &&
                          'element-selector-placeholder'
                        } ${errors.selectedManufacturingErrand && 'invalid-native-selector'}`}
                        name="selectedManufacturingErrand"
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                          const selectElement: HTMLSelectElement = event.currentTarget

                          if (selectElement?.value) {
                            const selectedManufacturingErrand: actionTypes.TManufacturingErrand =
                              JSON.parse(selectElement?.value)

                            if (selectedManufacturingErrand) {
                              const finishedProductsInManufacturingErrand =
                                generateFinishedProductsListFromManufacturingErrand(
                                  selectedManufacturingErrand
                                )

                              setFieldValue(
                                'replenishmentDetails',
                                finishedProductsInManufacturingErrand
                              )
                            }
                          } else {
                            setFieldValue(
                              'replenishmentDetails',
                              emptyManufacturingErrandItemsInForm
                            )
                          }

                          return handleChange(event)
                        }}
                        disabled={!manufacturingState?.manufacturingErrands}
                      >
                        <option
                          className="select-option-placeholder text-center"
                          value={selectManufacturingErrandPlaceholderValue}
                          disabled={false}
                        >
                          {manufacturingState?.manufacturingErrands
                            ? 'Wybierz zlecenie'
                            : 'Brak zleceń do wyboru!'}
                        </option>
                        {manufacturingState?.manufacturingErrands?.map(
                          (manufacturingErrand: actionTypes.TManufacturingErrand) => {
                            return (
                              <option
                                key={`warehouse-finished-product-replenishment-manufacturing-errand-option-${manufacturingErrand.id}`}
                                value={JSON.stringify(manufacturingErrand)}
                                className="text-center"
                              >
                                Zlecenie:{' '}
                                {manufacturingErrand?.manufacturingErrandNumber || 'brak numeru'} |
                                Produkt: {manufacturingErrand?.bomName || 'brak nazwy'}
                              </option>
                            )
                          }
                        )}
                      </Field>
                    </InputGroup>
                  </div>

                  <CDataTable
                    tableFilterValue={finishedProductsReplenishmentFilterState}
                    sorterValue={finishedProductsReplenishmentSorterState}
                    onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                      setFinishedProductsReplenishmentSorterState(sorterState)
                    }}
                    onTableFilterChange={(tableFilterState: string) => {
                      setFinishedProductsReplenishmentFilterState(tableFilterState)
                    }}
                    loading={false}
                    striped={true}
                    border
                    sorter
                    tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                    addTableClasses="vertical-middle-list-table warehouse-replenishment-table"
                    cleaner
                    onRowClick={undefined}
                    fields={[
                      {
                        key: 'position',
                        label: '#',
                        _style: { width: '45px', textAlign: 'center' },
                      },
                      {
                        key: 'bomName',
                        label: 'Nazwa produktu',
                        _style: { width: '250px' },
                      },
                      {
                        key: 'quantity',
                        label: 'Ilość',
                        _style: { width: '120px', textAlign: 'right' },
                      },
                    ]}
                    items={values?.replenishmentDetails || undefined}
                    noItemsViewSlot={
                      <div className="no-items-in-table">
                        {values?.replenishmentDetails && values?.selectedManufacturingErrand
                          ? 'Brak produktów w zleceniu produkcyjnym!'
                          : 'Nie wybrano zlecenia produkcyjnego!'}
                      </div>
                    }
                    scopedSlots={{
                      quantity: (
                        itemFromManufacturingErrand: actionTypes.TFromToManufacturingReplenishmentDetailsItem,
                        indexOfItemFromManufacturingErrand: number
                      ) => {
                        const indexOfStabilizedItem = values?.replenishmentDetails?.findIndex(
                          (
                            replenishmentDetailsItem: actionTypes.TFromToManufacturingReplenishmentDetailsItem
                          ) => replenishmentDetailsItem.bomId === itemFromManufacturingErrand.bomId
                        )

                        return (
                          <td>
                            <InputGroup
                              id={`${
                                values?.selectedManufacturingErrand
                                  ? JSON.parse(values?.selectedManufacturingErrand)?.id
                                  : 'empty'
                              }-${
                                itemFromManufacturingErrand.bomId
                              }-finished-products-replenishment-manufacturing-errand-item-group-id`}
                              style={{ pointerEvents: 'all' }}
                            >
                              <ErrorMessage
                                name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                component="span"
                                className="text-danger input-error-message"
                              />
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                placeholder={''}
                                name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                value={itemFromManufacturingErrand.quantity || ''}
                                className="form-control text-right"
                                // Might be useful:
                                required
                              />
                            </InputGroup>
                          </td>
                        )
                      },
                    }}
                  />

                  <CButton
                    color="success"
                    className="save-button w-100 mt-3 mb-2"
                    type="submit"
                    onClick={() => {
                      setDidFormValidationOccur && setDidFormValidationOccur(true)
                    }}
                    disabled={
                      warehousesState.isFinishedProductsReplenishmentDocumentGenerating ||
                      (didFormValidationOccur
                        ? Boolean(errors.replenishmentDetails && errors.referenceNumber)
                        : false)
                    }
                  >
                    {warehousesState?.isFinishedProductsReplenishmentDocumentGenerating ? (
                      <>
                        Generowanie PW
                        <ThreeDots />
                      </>
                    ) : warehousesState?.isFinishedProductsReplenishmentDocumentGenerated ? (
                      <>Wygenerowano PW!</>
                    ) : (
                      'Generuj PW'
                    )}
                  </CButton>
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
