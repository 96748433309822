import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  convertNumbersInTableRows,
  getEmptyListMessage,
  makeSearchBoxFixed,
  toDateInputValue,
  toDateTableValue,
} from '../../../utils'
import { minimumWidthForWideDetailsView } from '../../erp'
import { FormActionCancelButton } from '../../../components'
import { initialDocumentsPageSize } from '../documents-of-warehouses'

export const lengthOfWarehouseNameInConfirmModal = 17

const WarehouseManagement: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { tenant, id } = useParams<{ tenant: string; id: string }>()
  const paramsManagedWarehouseId = id

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const buttonsGridSpacing = 'mt-2 mb-4 pb-2'

  const managedWarehouseTypes: actionTypes.TWarehouseType[] | undefined = useMemo(
    () =>
      warehousesState?.warehouses?.find(
        (warehouse: actionTypes.TWarehouseLight) =>
          warehouse.id === Number(paramsManagedWarehouseId)
      )?.types,
    [warehousesState.warehouses, paramsManagedWarehouseId]
  )

  // Fetch deliveries for managed warehouse
  useEffect(() => {
    if (
      location.pathname.includes('warehouse-management/') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read')
    ) {
      dispatch({
        type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsManagedWarehouseId },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
    paramsManagedWarehouseId,
  ])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [])

  const memoizedTableItems = useMemo(
    () =>
      warehousesState?.managedWarehouseDeliveries?.map(
        (managedWarehouseDelivery: actionTypes.IWarehouseManagementDelivery) => {
          return {
            ...managedWarehouseDelivery,
            orderNumberAndDelivery: `${managedWarehouseDelivery?.orderNumber || ''} \n Dostawa ${
              managedWarehouseDelivery?.deliveryNumber || '...'
            }`,
            bomElementsNames:
              managedWarehouseDelivery?.bomElements
                ?.map(
                  (bomElement: actionTypes.IBomElementInWarehouseDelivery) =>
                    bomElement?.bomElementName || '...'
                )
                ?.join(';\n\n') || '',
            bomElementsWarehouseQuantities:
              (managedWarehouseDelivery?.bomElements?.length > 1
                ? managedWarehouseDelivery?.bomElements
                    ?.map((bomElement: actionTypes.IBomElementInWarehouseDelivery) =>
                      bomElement?.warehouseQuantity
                        ? `${bomElement?.warehouseQuantity}${
                            bomElement.bomElementName.length > 14 && window.innerWidth < 1560
                              ? '\n'
                              : ''
                          }`
                        : ''
                    )
                    ?.join('\n\n')
                : managedWarehouseDelivery?.bomElements[0]?.warehouseQuantity || '') || '',
            bomElementsUnplannedMissingQuantities:
              (managedWarehouseDelivery?.bomElements?.length > 1
                ? managedWarehouseDelivery?.bomElements
                    ?.map((bomElement: actionTypes.IBomElementInWarehouseDelivery) =>
                      bomElement?.plannedMissingQuantity
                        ? `${bomElement?.plannedMissingQuantity}${
                            bomElement.bomElementName.length > 14 && window.innerWidth < 1560
                              ? '\n'
                              : ''
                          }`
                        : ''
                    )
                    ?.join('\n\n')
                : managedWarehouseDelivery?.bomElements[0]?.plannedMissingQuantity || '') || '',
            bomElementsPlannedMissingQuantities:
              (managedWarehouseDelivery?.bomElements?.length > 1
                ? managedWarehouseDelivery?.bomElements
                    ?.map((bomElement: actionTypes.IBomElementInWarehouseDelivery) =>
                      bomElement?.unplannedMissingQuantity
                        ? `${bomElement?.unplannedMissingQuantity}${
                            bomElement.bomElementName.length > 14 && window.innerWidth < 1560
                              ? '\n'
                              : ''
                          }`
                        : ''
                    )
                    ?.join('\n\n')
                : managedWarehouseDelivery?.bomElements[0]?.unplannedMissingQuantity || '') || '',
            bomElementsExpectedQuantities:
              (managedWarehouseDelivery?.bomElements?.length > 1
                ? managedWarehouseDelivery?.bomElements
                    ?.map((bomElement: actionTypes.IBomElementInWarehouseDelivery) =>
                      bomElement?.expectedQuantity
                        ? `${bomElement?.expectedQuantity}${
                            bomElement.bomElementName.length > 14 && window.innerWidth < 1560
                              ? '\n'
                              : ''
                          }`
                        : ''
                    )
                    ?.join('\n\n')
                : managedWarehouseDelivery?.bomElements[0]?.expectedQuantity || '') || '',
            closestDeliveryDate: toDateTableValue(managedWarehouseDelivery?.closestDeliveryDate),
            lastDeliveryDate: toDateTableValue(managedWarehouseDelivery?.lastDeliveryDate),
            goodsReceivedNote: managedWarehouseDelivery?.goodsReceivedNote || '',
            bomNames: managedWarehouseDelivery?.bomNames?.join('\n') || '',
            // This should come from BE as value
            type: managedWarehouseDelivery?.bomElements[0]?.type,
          }
        }
      ),
    [warehousesState.managedWarehouseDeliveries, window.innerWidth]
  )

  const researchAndDevelopmentWarehouseId = useMemo(
    () =>
      warehousesState?.warehouses
        .find((warehouse: actionTypes.TWarehouseLight) =>
          warehouse?.name?.toLowerCase().includes('r&d')
        )
        ?.id?.toString(),
    [warehousesState.warehouses]
  )

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    convertNumbersInTableRows(warehousesState.managedWarehouseDeliveries, [], [3, 4, 5, 6])
  }, [
    memoizedTableItems,
    warehousesState?.managedWarehouseDeliveriesTableFilterState,
    warehousesState?.managedWarehouseDeliveriesTableSorterState,
    warehousesState.areManagedWarehouseDeliveriesLoading,
  ])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ maxWidth: '1600px' }}
    >
      <CCard className="warehouse-management">
        <CCardBody className="p-4">
          <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
            <FormActionCancelButton
              closeFunction={() => {
                history.push(`/${tenant}/warehouses`)
              }}
              closeAction={''}
            />
          </div>

          {authState?.authData?.roles?.includes('Warehouses_write') &&
            (paramsManagedWarehouseId !== researchAndDevelopmentWarehouseId ? (
              <div className={`warehouse-management-buttons-grid ${buttonsGridSpacing}`}>
                <div className="warehouse-management-buttons-column">
                  <div className="warehouse-management-buttons-grid">
                    <CButton
                      color="success"
                      type="button"
                      className="w-100 py-5"
                      onClick={() => {
                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/materials-replenishment/deliveries`
                        )
                      }}
                      disabled={!managedWarehouseTypes?.includes(actionTypes.materialsTypeNumber)}
                    >
                      Przyjęcie materiału i półproduktów
                    </CButton>

                    <CButton
                      color="success"
                      type="button"
                      onClick={() => {
                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/wares-replenishment`
                        )
                      }}
                    >
                      Przyjęcie towarów
                    </CButton>
                  </div>

                  <div className="warehouse-management-buttons-single-row">
                    <CButton
                      color="success"
                      type="button"
                      className="w-100 py-5"
                      onClick={() => {
                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/finished-products-replenishment`
                        )
                      }}
                      disabled={
                        !managedWarehouseTypes?.includes(actionTypes.finishedProductsTypeNumber)
                      }
                    >
                      Przyjęcie wyrobów gotowych
                    </CButton>
                  </div>

                  <div className="warehouse-management-buttons-grid">
                    <CButton
                      color="dark"
                      variant="outline"
                      type="button"
                      className="w-100 py-3"
                      onClick={() => {
                        history.push(`/${tenant}/inventories-of-warehouses`)
                      }}
                    >
                      Stany magazynowe
                    </CButton>

                    <CButton
                      color="dark"
                      variant="outline"
                      type="button"
                      className="w-100 py-3"
                      onClick={() => {
                        history.push(
                          `/${tenant}/warehouse-management/${
                            warehousesState?.warehouses?.find(
                              (warehouse: actionTypes.TWarehouseLight) =>
                                warehouse?.name?.includes('&')
                            )?.id || 1
                          }`
                        )
                      }}
                    >
                      Magazyn R&amp;D
                    </CButton>
                  </div>
                </div>

                <div className="warehouse-management-buttons-column">
                  <div className="warehouse-management-buttons-grid">
                    <CButton
                      color="primary"
                      type="button"
                      className="w-100 py-5 warehouse-management-release-goods-button"
                      onClick={() => {
                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/manufacturing-replenishment`
                        )
                      }}
                      disabled={!managedWarehouseTypes?.includes(actionTypes.materialsTypeNumber)}
                    >
                      Wydanie na produkcję
                    </CButton>

                    <CButton
                      type="button"
                      className="w-100 py-5 warehouse-management-purple-button"
                      onClick={() => {
                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/move-replenishment`
                        )
                      }}
                    >
                      Przesunięcie międzymagazynowe
                    </CButton>
                  </div>

                  <div className="warehouse-management-buttons-single-row">
                    <CButton
                      color="primary"
                      type="button"
                      className="w-100 py-5 warehouse-management-release-goods-button"
                      disabled
                    >
                      Wydanie towarów i wyrobów gotowych
                    </CButton>
                  </div>

                  <div className="warehouse-management-buttons-grid">
                    <CButton
                      color="info"
                      type="button"
                      className="w-100 py-3"
                      onClick={() => {
                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/inventory-control`
                        )
                      }}
                    >
                      Inwentaryzacja
                    </CButton>

                    <CButton
                      color="info"
                      type="button"
                      className="w-100 py-3"
                      onClick={() => {
                        history.push(
                          `/${tenant}/documents-of-warehouses?warehouseId=${paramsManagedWarehouseId}&from=${null}&to=${toDateInputValue(
                            new Date().toISOString()
                          )}&searchTerm=${''}&pageSize=${initialDocumentsPageSize}&page=${1}`
                        )
                      }}
                    >
                      Dokumenty magazynowe
                    </CButton>
                  </div>
                </div>
              </div>
            ) : (
              /*
               * R&D Buttons
               */
              <div
                className={`warehouse-management-buttons-grid warehouse-management-research-and-development-buttons-grid ${buttonsGridSpacing}`}
              >
                <CButton
                  color="success"
                  type="button"
                  className="w-100 py-3"
                  onClick={() => {
                    history.push(
                      `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/materials-replenishment`
                    )
                  }}
                  disabled={!managedWarehouseTypes?.includes(1)}
                >
                  Przyjęcie materiału
                </CButton>

                <CButton
                  color="dark"
                  variant="outline"
                  type="button"
                  className="w-100 py-3"
                  onClick={() => {
                    history.push(`/${tenant}/inventories-of-warehouses`)
                  }}
                >
                  Stany magazynowe
                </CButton>

                <CButton
                  color="info"
                  type="button"
                  className="w-100 py-3"
                  onClick={() => {
                    history.push(
                      `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/inventory-control`
                    )
                  }}
                >
                  Inwentaryzacja
                </CButton>
              </div>
            ))}

          <CDataTable
            tableFilterValue={warehousesState?.managedWarehouseDeliveriesTableFilterState}
            sorterValue={warehousesState?.managedWarehouseDeliveriesTableSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              dispatch({
                type: actionTypes.SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_SORTER_STATE,
                tableSorterState: sorterState,
              })
            }}
            onTableFilterChange={(tableFilterState: string) => {
              dispatch({
                type: actionTypes.SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_FILTER_STATE,
                tableFilterState: tableFilterState,
              })
            }}
            loading={warehousesState?.areManagedWarehouseDeliveriesLoading}
            striped={!!warehousesState?.managedWarehouseDeliveries?.length}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj na stronie w tabeli...' }}
            addTableClasses="vertical-middle-list-table warehouse-management-deliveries-table mb-2"
            cleaner
            onRowClick={undefined}
            fields={[
              {
                key: 'orderNumberAndDelivery',
                label: 'Zamówienie \n Dostawa',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'bomNames',
                label: 'BOM',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'bomElementsNames',
                label: 'Element / Towar',
                _style: { width: '300px' },
              },
              {
                key: 'bomElementsWarehouseQuantities',
                label: 'Stan magazynowy',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'bomElementsUnplannedMissingQuantities',
                label: 'Stan \n niezaplanowany \n (produkcja)',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'bomElementsPlannedMissingQuantities',
                label: 'Brak \n zaplanowany \n (produkcja)',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'bomElementsExpectedQuantities',
                label: 'Spodziewana ilość',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'lastDeliveryDate',
                label: 'Data ostatniej dostawy',
                _style: { width: '110px', textAlign: 'left' },
              },
              {
                key: 'goodsReceivedNote',
                label: 'Dokument WZ',
                _style: { width: '1fr', textAlign: 'left' },
              },
              {
                key: 'closestDeliveryDate',
                label: 'Data najbliższej dostawy',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'receive',
                label: 'Przyjmij',
                _style: { width: '45px', textAlign: 'center' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {getEmptyListMessage(
                  warehousesState.fetchManagedWarehouseDeliveriesError,
                  warehousesState.areManagedWarehouseDeliveriesLoading,
                  'zamówień',
                  warehousesState?.managedWarehouseDeliveries?.length
                )}
              </div>
            }
            scopedSlots={{
              receive: (itemFromList: actionTypes.IWarehouseManagementDelivery) => (
                <td
                  className="text-center"
                  style={{ pointerEvents: 'none' }}
                  onClick={(event: SyntheticEvent) => {
                    event.preventDefault()
                    event.stopPropagation()
                    return null
                  }}
                >
                  <CButton
                    color="success"
                    variant="outline"
                    size="50"
                    className="list-action-button warehouse-receive-delivery-list-item-button"
                    style={{ pointerEvents: 'all' }}
                    onClick={(event: SyntheticEvent) => {
                      if (itemFromList.type === actionTypes.materialsTypeNumber) {
                        dispatch({
                          type: actionTypes.SET_DELIVERY_FROM_ORDER_IN_MATERIALS_REPLENISHMENT,

                          // Delivery has to be clean without any changes in keys or values
                          deliveryFromOrder: warehousesState?.managedWarehouseDeliveries?.find(
                            (delivery: actionTypes.IWarehouseManagementDelivery) =>
                              itemFromList.id === delivery.id
                          ),
                        })

                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/materials-replenishment/deliveries`
                        )
                      }

                      // Other options will not be available because button without type will be disabled

                      if (itemFromList.type === actionTypes.waresTypeNumber) {
                        dispatch({
                          type: actionTypes.SET_DELIVERY_FROM_ORDER_IN_WARES_REPLENISHMENT,

                          // Delivery has to be clean without any changes in keys or values
                          deliveryFromOrder: warehousesState?.managedWarehouseDeliveries?.find(
                            (delivery: actionTypes.IWarehouseManagementDelivery) =>
                              itemFromList.id === delivery.id
                          ),
                        })

                        history.push(
                          `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/wares-replenishment`
                        )
                      }

                      // This line blocks the opening of item on checkbox/row click
                      event.stopPropagation()
                    }}
                    disabled={!itemFromList?.type || !!itemFromList?.goodsReceivedNote}
                  >
                    <i className="cil-arrow-thick-to-left"></i>
                  </CButton>
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </Container>
  )
}

export default WarehouseManagement
