import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CLabel, CModal, CModalHeader } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { BasicFormField, FormActionsPanel, CustomErrorMessage } from '../../components'
import {
  getErrorMessageFromStatus,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  successMessageDuration,
  toDateInputValue,
} from '../../utils'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'

interface IManufacturingErrandElementModal {
  mode: actionTypes.TFormMode
}

export const ManufacturingErrandsModal: React.FC<IManufacturingErrandElementModal> = ({ mode }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)
  const bomsState = useSelector((state: TRootState) => state.boms)

  const isManufacturingErrandEditable =
    authState?.authData?.roles?.includes('Manufacturing_write') &&
    (mode === 'edit' ? !!manufacturingState.editedManufacturingErrand : true)

  const closeManufacturingErrandModal = useCallback(() => {
    history.push(`/${tenant}/manufacturing-errands`)
  }, [dispatch, history, tenant])

  // Close modal on a successful create or delete
  useEffect(() => {
    if (
      (!manufacturingState.isManufacturingErrandCreating &&
        !manufacturingState.manufacturingErrandCreateError &&
        manufacturingState.isManufacturingErrandCreated) ||
      (!manufacturingState.isManufacturingErrandDeleting &&
        !manufacturingState.manufacturingErrandDeleteError &&
        manufacturingState.isManufacturingErrandDeleted)
    ) {
      setTimeout(() => {
        closeManufacturingErrandModal()
      }, successMessageDuration)
    }
  }, [
    manufacturingState.isManufacturingErrandCreated,
    manufacturingState.isManufacturingErrandDeleted,
    closeManufacturingErrandModal,
  ])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_MANUFACTURING_ERRAND_MODAL,
      })
    }
  }, [])

  const ManufacturingErrandSchema = Yup.object().shape({
    bomId: Yup.number().required('To pole jest wymagane!'),
    plannedQuantity: Yup.number().required('To pole jest wymagane!'),
    startDate: Yup.string().required('To pole jest wymagane!'),
    endDate: Yup.string().required('To pole jest wymagane!'),
  })

  return (
    <CModal
      onClose={() => {
        closeManufacturingErrandModal()
      }}
      size=""
      show={manufacturingState.manufacturingErrandModalVisibility}
      centered
      backdrop
      closeOnBackdrop={false}
      addContentClass="manufacturing-errand-modal py-4 px-5"
    >
      <CModalHeader className="c-modal-header-grid" closeButton>
        <h4 className="mb-3 pb-1 text-center">
          {mode === 'edit'
            ? !authState?.authData?.roles?.includes('Manufacturing_write')
              ? 'Przeglądaj Zlecenie Produkcyjne'
              : 'Edytuj Zlecenie Produkcyjne'
            : 'Nowe Zlecenie Produkcyjne'}
        </h4>
      </CModalHeader>
      <Formik
        initialValues={{
          manufacturingErrandNumber:
            manufacturingState.editedManufacturingErrand?.manufacturingErrandNumber || '',
          bomName: manufacturingState.editedManufacturingErrand?.bomName || '',
          bomId: manufacturingState.editedManufacturingErrand?.bomId || '',
          plannedQuantity: manufacturingState.editedManufacturingErrand?.plannedQuantity || '',
          startDate: manufacturingState.editedManufacturingErrand?.startDate || '',
          endDate: manufacturingState.editedManufacturingErrand?.endDate || '',
        }}
        validationSchema={ManufacturingErrandSchema}
        onSubmit={(values) => {
          if (isManufacturingErrandEditable) {
            dispatch({
              type:
                mode === 'create'
                  ? actionTypes.CREATE_MANUFACTURING_ERRAND_REQUESTED
                  : actionTypes.EDIT_MANUFACTURING_ERRAND_REQUESTED,
              payload: {
                tenant: tenant,
                token: authState.authData?.token,
                manufacturingErrand: {
                  ...values,
                  canDelete: true,
                  ...(manufacturingState.editedManufacturingErrand?.id && {
                    id: manufacturingState.editedManufacturingErrand?.id,
                  }),
                },
              },
            })
          }
        }}
        validateOnBlur={false}
        validateOnChange={didFormValidationOccur}
      >
        {({ initialValues, values, errors, setFieldValue }) => (
          <Form>
            {/*
             * Display Network Error Message
             */}

            {manufacturingState?.manufacturingErrandCreateError && (
              <CustomErrorMessage
                wrapperClassNames="my-3"
                customErrorMessageText={getErrorMessageFromStatus(
                  'create',
                  manufacturingState?.manufacturingErrandCreateError?.status
                )}
              />
            )}

            {manufacturingState?.manufacturingErrandEditSaveError && (
              <CustomErrorMessage
                wrapperClassNames="my-3"
                customErrorMessageText={getErrorMessageFromStatus(
                  'edit',
                  manufacturingState?.manufacturingErrandEditSaveError?.status,
                  'zlecenia produkcyjnego'
                )}
              />
            )}

            {manufacturingState?.manufacturingErrandDeleteError && (
              <CustomErrorMessage
                wrapperClassNames="my-3"
                customErrorMessageText={getErrorMessageFromStatus(
                  'delete',
                  manufacturingState?.manufacturingErrandDeleteError?.status,
                  'zlecenia produkcyjnego'
                )}
              />
            )}

            <BasicFormField
              fieldId="manufacturing-errand-number"
              fieldLabel="Numer ZP"
              fieldIcon="cil-factory"
              formikFieldName="manufacturingErrandNumber"
              fieldValue={values.manufacturingErrandNumber}
              fieldError={errors?.manufacturingErrandNumber}
              fieldType="text"
              placeholder="Zostanie wygenerowany automatycznie"
              isDisabled
            />

            <div>
              <CLabel
                htmlFor="manufacturing-errand-product"
                className={`${inputLabelSpacingBottom} mt-1`}
              >
                Produkt
              </CLabel>
              <InputGroup
                id="manufacturing-errand-product"
                className={`${inputFieldSpacingBottom} flex-nowrap`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className={errors.bomId && 'text-danger input-error-icon'}>
                    <i className="cil-storage"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <ErrorMessage
                  name="bomId"
                  component="span"
                  className="text-danger input-error-message"
                />

                <Typeahead
                  placeholder="Wybierz z listy lub wpisz nazwę produktu"
                  isInvalid={!!errors.bomId}
                  id="manufacturing-errand-product-typeahead"
                  onChange={(selected: actionTypes.TSupplier[]) => {
                    if (selected.length > 0) {
                      setFieldValue('bomId', selected[0].id)
                    }
                  }}
                  onInputChange={(text) => {
                    if (text.length > 0) {
                      const matchedImplementationCost = bomsState.boms.find(
                        (bom: actionTypes.TBomLight) =>
                          bom.name.toLowerCase() === text.toLowerCase()
                      )

                      if (matchedImplementationCost) {
                        setFieldValue('bomId', matchedImplementationCost.id)
                      } else {
                        setFieldValue('bomId', -1)
                      }
                    } else {
                      setFieldValue('bomId', undefined)
                    }
                  }}
                  defaultInputValue={values.bomName}
                  options={bomsState.boms}
                  labelKey="name"
                  emptyLabel={'Nie znaleziono produktów'}
                />
              </InputGroup>
            </div>

            <BasicFormField
              fieldId="manufacturing-errand-planned-quantity"
              fieldLabel="Ilość planowana"
              fieldIcon="cil-asterisk"
              formikFieldName="plannedQuantity"
              fieldValue={values.plannedQuantity}
              fieldError={errors?.plannedQuantity}
              fieldType="text"
              placeholder="Wprowadź planowaną ilość"
            />

            <BasicFormField
              fieldId="manufacturing-errand-start-date"
              fieldLabel="Data rozpoczęcia"
              fieldIcon="cil-calendar"
              formikFieldName="startDate"
              fieldValue={toDateInputValue(values.startDate)}
              fieldError={errors.startDate}
              fieldType="date"
              placeholder="Wprowadź datę rozpoczęcia"
            />

            <BasicFormField
              fieldId="manufacturing-errand-end-date"
              fieldLabel="Data zakończenia"
              fieldIcon="cil-calendar"
              formikFieldName="endDate"
              fieldValue={toDateInputValue(values.endDate)}
              fieldError={errors.endDate}
              fieldType="date"
              placeholder="Wprowadź datę zakończenia"
            />

            <FormActionsPanel
              mode={mode}
              padding="pt-4 pb-1"
              isSaving={
                mode === 'create'
                  ? manufacturingState.isManufacturingErrandCreating
                  : manufacturingState.isManufacturingErrandEditSaving
              }
              isSaved={
                mode === 'create'
                  ? manufacturingState.isManufacturingErrandCreated
                  : manufacturingState.isManufacturingErrandEditSaved
              }
              isDeleting={manufacturingState.isManufacturingErrandDeleting}
              isDeleted={manufacturingState.isManufacturingErrandDeleted}
              setDidFormValidationOccur={setDidFormValidationOccur}
              didFormValidationOccur={didFormValidationOccur}
              formErrorsBool={Boolean(errors.bomId)}
              closeAction={actionTypes.CLOSE_MANUFACTURING_ERRAND_MODAL}
              deleteAction={actionTypes.DELETE_MANUFACTURING_ERRAND_REQUESTED}
              deletePayload={{
                tenant: tenant,
                token: authState.authData?.token,
                id: manufacturingState.editedManufacturingErrand?.id,
              }}
              closeFunction={closeManufacturingErrandModal}
              canDelete={Boolean(manufacturingState.editedManufacturingErrand?.canDelete)}
              isEditable={isManufacturingErrandEditable}
              disabledDeleteButtonClassNames="delete-details-button"
              confirmDeleteMessageJSX={
                <>
                  Czy na pewno chcesz usunąć zlecenie produkcyjne?
                  {initialValues?.bomName ? (
                    <>
                      <br />
                      <strong>{initialValues?.bomName}</strong>
                    </>
                  ) : (
                    ''
                  )}
                </>
              }
            />
          </Form>
        )}
      </Formik>
    </CModal>
  )
}
