import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, ErrorMessage, Field, FastField } from 'formik'
import * as Yup from 'yup'
import {
  CCard,
  CCardBody,
  CButton,
  CDataTable,
  CLabel,
  CTabs,
  CNav,
  CTabContent,
  CTabPane,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import { Select } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { confirmAlert } from 'react-confirm-alert'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  BasicFormField,
  CustomErrorMessage,
  ThreeDots,
  FormActionCancelButton,
  ConfirmActionModal,
} from '../../../components'
import {
  getErrorMessageFromStatus,
  globalThousandSeparator,
  globalDecimalSeparator,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  toDateInputValue,
  convertNumericStringToNumber,
  successMessageDuration,
  preventNavigationChange,
  toDateTimeInputValue,
  convertQuantityToNumericString,
} from '../../../utils'
import { Typeahead } from 'react-bootstrap-typeahead'

export const MaterialsReplenishment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id, tab } = useParams<{
    tenant: string
    id: string
    tab: string
  }>()
  const paramsManagedWarehouseId = id
  const paramsMaterialsReplenishmentTab = tab

  const [
    didDeliveryMaterialsReplenishmentFormValidationOccur,
    setDidDeliveryMaterialsReplenishmentFormValidationOccur,
  ] = useState(false)
  const [
    didManufacturingErrandReplenishmentFormValidationOccur,
    setDidManufacturingErrandReplenishmentFormValidationOccur,
  ] = useState(false)

  const [
    isMaterialsReplenishmentModifiedAndUnsaved,
    setIsMaterialsReplenishmentModifiedAndUnsaved,
  ] = useState(false)
  const [
    isManufacturingErrandReplenishmentAlertConfirmed,
    setIsManufacturingErrandReplenishmentAlertConfirmed,
  ] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)
  const usersState = useSelector((state: TRootState) => state.users)

  const [deliveryMaterialsReplenishmentFilterState, setDeliveryMaterialsReplenishmentFilterState] =
    useState<actionTypes.TFilterState>(undefined)
  const [deliveryMaterialsReplenishmentSorterState, setDeliveryMaterialsReplenishmentSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const [
    manufacturingErrandMaterialsReplenishmentBomIntermediatesFilterState,
    setManufacturingErrandMaterialsReplenishmentBomIntermediatesFilterState,
  ] = useState<actionTypes.TFilterState>(undefined)
  const [
    manufacturingErrandMaterialsReplenishmentBomIntermediatesSorterState,
    setManufacturingErrandMaterialsReplenishmentBomIntermediatesSorterState,
  ] = useState<actionTypes.TSorterState>({ column: '', asc: true })

  const [
    manufacturingErrandMaterialsReplenishmentElementsFilterState,
    setManufacturingErrandMaterialsReplenishmentElementsFilterState,
  ] = useState<actionTypes.TFilterState>(undefined)
  const [
    manufacturingErrandMaterialsReplenishmentElementsSorterState,
    setManufacturingErrandMaterialsReplenishmentElementsSorterState,
  ] = useState<actionTypes.TSorterState>({ column: '', asc: true })

  const closeMaterialsReplenishment = useCallback(() => {
    history.push(`/${tenant}/warehouse-management/${paramsManagedWarehouseId}`)
  }, [dispatch, history, tenant, paramsManagedWarehouseId])

  const emptyDeliveryItemsInForm = []
  const selectDeliveryPlaceholderValue = ''
  const selectManufacturingErrandPlaceholderValue = ''

  const isWarehouseEditable = authState?.authData?.roles?.includes('Warehouses_write')

  const managedWarehouseTypes: actionTypes.TWarehouseType[] | undefined = useMemo(
    () =>
      warehousesState?.warehouses?.find(
        (warehouse: actionTypes.TWarehouseLight) =>
          warehouse.id === Number(paramsManagedWarehouseId)
      )?.types,
    [warehousesState.warehouses, paramsManagedWarehouseId]
  )

  const generateBomElementsListFromDelivery = (
    bomElementsInDelivery: actionTypes.IBomElementInWarehouseDelivery[]
  ) =>
    bomElementsInDelivery?.map(
      (bomElement: actionTypes.IBomElementInWarehouseDelivery, bomElementIndex: number) => ({
        ...bomElement,
        position: `${bomElementIndex + 1}.`,
      })
    )

  const emptyList = []

  const generateManufacturingErrandReplenishmentDetails = useCallback(
    (
      manufacturingErrandId: string | number | null,
      userId: string | undefined | null,
      selectedUserPossesions: actionTypes.IUserPossessions | null
    ) => {
      if (manufacturingState?.manufacturingErrands) {
        const manufacturingErrand: any = manufacturingState.manufacturingErrands.find(
          (manufacturingErrand: actionTypes.TManufacturingErrand) =>
            manufacturingErrandId === manufacturingErrand.id
        ) as actionTypes.TManufacturingErrand

        if (manufacturingErrand && manufacturingErrand !== undefined) {
          // This is needed to display the generated values in table properly
          // setTimeout(() => convertNumbersInTableRows(['array'], [], [2]), 100)

          const bomElementsList = manufacturingErrand?.bomElements?.map(
            (bomElementItem: actionTypes.IBomFullElement, bomElementItemIndex: number) => ({
              bomElementId: bomElementItem.bomElementId,
              bomElementName: bomElementItem.bomElementName || '',
              type: actionTypes.materialsTypeNumber,
              userQuantity:
                // Never convert those values to string here with convertQuantityToNumericString. This destroys the table sorter.
                typeof userId !== 'string' && !selectedUserPossesions
                  ? ''
                  : selectedUserPossesions?.bomElementUserPossessions?.find(
                      (
                        userPossesionsItem: actionTypes.TBomElementUserPossession,
                        userPossesionsItemIndex: number
                      ) => userPossesionsItem.bomElementId === bomElementItem.bomElementId
                    )?.quantity || 0,
              quantity: '',
              position: `${bomElementItemIndex + 1}.`,
              hasChanged: false,
            })
          )

          const bomIntermediatesList = manufacturingErrand?.bomIntermediates?.map(
            (
              bomIntermediateItem: actionTypes.IBomIntermediate,
              bomIntermediateItemIndex: number
            ) => ({
              bomIntermediateId:
                bomIntermediateItem?.bomIntermediateId || bomIntermediateItem?.uuid || '',
              bomIntermediateName:
                bomIntermediateItem?.bomIntermediateName || bomIntermediateItem?.name || '',
              type: actionTypes.bomIntermediatesTypeNumber,
              userQuantity:
                // Never convert those values to string here with convertQuantityToNumericString. This destroys the table sorter.
                typeof userId !== 'string' && !selectedUserPossesions
                  ? ''
                  : selectedUserPossesions?.bomIntermediateUserPossessions?.find(
                      (
                        userPossesionsItem: actionTypes.TBomIntermediateUserPossession,
                        userPossesionsItemIndex: number
                      ) => String(userPossesionsItem.bomIntermediateId) === bomIntermediateItem.uuid
                    )?.quantity || 0,
              quantity: '',
              position: `${bomIntermediateItemIndex + 1}.`,
              hasChanged: false,
            })
          )

          return [...(bomElementsList || []), ...(bomIntermediatesList || [])]
        }
      } else {
        return emptyList
      }
    },
    [
      warehousesState?.inventoriesOfWarehouses,
      warehousesState?.selectedUserPossessions,
      manufacturingState?.manufacturingErrands,
    ]
  )

  type TManufacturingErrandReplenishmentAlertType =
    | 'equalToUserPossessions'
    | 'aboveUserPossessions'
    | 'belowUserPossessions'

  type TPredictManufacturingErrandReplenishment = (
    itemsReplenishedByUserInManufacturingErrand: actionTypes.TFromManufacturingErrandReplenishmentTableItem[],
    itemsInUserPosession: actionTypes.TBomElementUserPossession[] | undefined
  ) => {
    itemsList: actionTypes.TFromManufacturingErrandReplenishmentTableItem[] | []
    alertType: TManufacturingErrandReplenishmentAlertType
  }

  const predictManufacturingErrandReplenishmentAlert: TPredictManufacturingErrandReplenishment = (
    itemsReplenishedByUserFromManufacturingErrand,
    itemsInUserPosession
  ) => {
    const itemsAboveUserPossessions: actionTypes.TFromManufacturingErrandReplenishmentTableItem[] =
      []

    if (
      itemsReplenishedByUserFromManufacturingErrand.some(
        (replenishedBomElement: actionTypes.TFromManufacturingErrandReplenishmentTableItem) =>
          replenishedBomElement?.quantity &&
          Number(replenishedBomElement.userQuantity) < Number(replenishedBomElement?.quantity)
      )
    ) {
      itemsReplenishedByUserFromManufacturingErrand.forEach(
        (replenishedBomElement: actionTypes.TFromManufacturingErrandReplenishmentTableItem) => {
          if (
            replenishedBomElement?.quantity &&
            Number(replenishedBomElement?.userQuantity) < Number(replenishedBomElement?.quantity)
          ) {
            let shallowCopyOfElement = { ...replenishedBomElement }
            shallowCopyOfElement.quantity =
              Number(shallowCopyOfElement.quantity) -
              (Number(replenishedBomElement?.userQuantity) > 0
                ? Number(replenishedBomElement?.userQuantity)
                : 0)

            itemsAboveUserPossessions.push(shallowCopyOfElement)
          }
        }
      )

      return {
        itemsList: [...itemsAboveUserPossessions],
        alertType: 'aboveUserPossessions',
      }
    }

    if (
      itemsReplenishedByUserFromManufacturingErrand.some(
        (replenishedBomElement: actionTypes.TFromManufacturingErrandReplenishmentTableItem) =>
          replenishedBomElement?.quantity &&
          Number(replenishedBomElement?.userQuantity) > Number(replenishedBomElement?.quantity)
      )
    ) {
      return {
        itemsList:
          // For now check only BOM Elements

          (itemsInUserPosession?.map(
            (bomElementInUserPossession: actionTypes.TBomElementUserPossession) => {
              return {
                ...bomElementInUserPossession,
                quantity:
                  (Number(bomElementInUserPossession?.quantity) > 0
                    ? Number(bomElementInUserPossession?.quantity)
                    : 0) -
                  Number(
                    itemsReplenishedByUserFromManufacturingErrand.find(
                      (
                        replenishedBomElement: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                      ) =>
                        // For now check only BOM Elements

                        replenishedBomElement.bomElementId ===
                        bomElementInUserPossession.bomElementId
                    )?.quantity
                  ),
                bomIntermediateId: null,
                type: 1,
                userQuantity: bomElementInUserPossession.quantity,
                position: null,
                hasChanged: false,
              }
            }
          ) as actionTypes.TFromManufacturingErrandReplenishmentTableItem[]) || [],
        alertType: 'belowUserPossessions',
      }
    }

    return {
      itemsList: [],
      alertType: 'equalToUserPossessions',
    }
  }

  useEffect(() => {
    if (
      managedWarehouseTypes &&
      !managedWarehouseTypes?.includes(actionTypes.materialsTypeNumber)
    ) {
      closeMaterialsReplenishment()
    }
  }, [managedWarehouseTypes, history])

  // Fetch warehouse deliveries if they were not fetched before
  useEffect(() => {
    if (
      location.pathname.includes('materials-replenishment') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      isWarehouseEditable &&
      !warehousesState?.managedWarehouseDeliveries
    ) {
      dispatch({
        type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsManagedWarehouseId },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
    paramsManagedWarehouseId,
    warehousesState?.managedWarehouseDeliveries,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isMaterialsReplenishmentModifiedAndUnsaved,
      isWarehouseEditable,
      'replenishment',
      paramsManagedWarehouseId,
      ''
    )

    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isMaterialsReplenishmentModifiedAndUnsaved, isWarehouseEditable])

  // Close materials replenishment if document was generated and fetch warehouse deliveries again
  useEffect(() => {
    if (warehousesState.isDeliveryMaterialsReplenishmentDocumentGenerated) {
      setIsMaterialsReplenishmentModifiedAndUnsaved(false)

      if (
        tenant &&
        authState?.authData &&
        authState?.authData?.roles?.includes('Warehouses_read')
      ) {
        dispatch({
          type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED,
          payload: {
            tenant: tenant,
            token: authState.authData?.token,
            id: paramsManagedWarehouseId,
          },
        })
      }

      setTimeout(() => {
        closeMaterialsReplenishment()
      }, successMessageDuration)
    }
  }, [warehousesState.isDeliveryMaterialsReplenishmentDocumentGenerated])

  // Close materials replenishment from manufacturing errands if document was generated
  useEffect(() => {
    if (warehousesState.isManufacturingErrandMaterialsReplenishmentDocumentGenerated) {
      setIsMaterialsReplenishmentModifiedAndUnsaved(false)

      setTimeout(() => {
        closeMaterialsReplenishment()
      }, successMessageDuration)
    }
  }, [warehousesState.isManufacturingErrandMaterialsReplenishmentDocumentGenerated])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.SET_DELIVERY_FROM_ORDER_IN_MATERIALS_REPLENISHMENT,
        deliveryFromOrder: undefined,
      })

      dispatch({
        type: actionTypes.CLOSE_MATERIALS_REPLENISHMENT,
      })
    }
  }, [])

  const DeliveryMaterialsReplenishmentSchema = Yup.object().shape({
    deliveryDocument: Yup.string().required('To pole jest wymagane!'),
    selectedDelivery: Yup.string().required('Wybierz dostawę!'),
  })

  const ManufacturingErrandMaterialsReplenishmentSchema = Yup.object().shape({
    referenceNumber: Yup.string().required('To pole jest wymagane!'),
    manufacturingErrandId: Yup.string().required('Wybierz zlecenie!'),
    userId: Yup.string().required('To pole jest wymagane!'),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="warehouse-details">
          <h4 className="text-center mb-4">
            {authState?.authData?.roles?.includes('Warehouses_write')
              ? 'Przyjęcie materiału i półproduktów'
              : ''}
          </h4>

          <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
            <FormActionCancelButton closeFunction={closeMaterialsReplenishment} closeAction={''} />
          </div>

          {/*
           * Materials Replenishment Tabs
           */}

          <CTabs activeTab={paramsMaterialsReplenishmentTab}>
            <CNav variant="tabs" className="mb-4 mt-1">
              <CNavItem key="deliveries">
                <CNavLink
                  className={`${false ? 'text-danger is-invalid' : ''}`}
                  data-tab="deliveries"
                  onClick={() => {
                    history.replace(
                      `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/materials-replenishment/deliveries`
                    )
                  }}
                >
                  Dostawy
                </CNavLink>
              </CNavItem>

              <CNavItem key="manufacturing-errands">
                <CNavLink
                  className={`${false ? 'text-danger is-invalid' : ''}`}
                  data-tab="manufacturing-errands"
                  onClick={() => {
                    history.replace(
                      `/${tenant}/warehouse-management/${paramsManagedWarehouseId}/materials-replenishment/manufacturing-errands`
                    )
                  }}
                >
                  Zlecenia Produkcyjne
                </CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              {/*
               * Materials From Delivery Replenishment Tab
               */}

              <CTabPane data-tab="deliveries">
                {warehousesState.areManagedWarehouseDeliveriesLoading ? (
                  <div
                    style={{
                      height: '300px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      deliveryDate: toDateInputValue(new Date().toISOString()),
                      deliveryDocument: '',
                      selectedDelivery:
                        JSON.stringify(
                          warehousesState?.deliveryFromOrderInMaterialsReplenishment
                        ) || '',
                      deliveryItems: warehousesState?.deliveryFromOrderInMaterialsReplenishment
                        ?.bomElements
                        ? generateBomElementsListFromDelivery(
                            warehousesState?.deliveryFromOrderInMaterialsReplenishment?.bomElements
                          )
                        : emptyDeliveryItemsInForm,
                    }}
                    validationSchema={DeliveryMaterialsReplenishmentSchema}
                    onSubmit={(values) => {
                      if (isWarehouseEditable) {
                        setDidDeliveryMaterialsReplenishmentFormValidationOccur(true)
                        setIsMaterialsReplenishmentModifiedAndUnsaved(false)

                        const replenishmentDetails:
                          | actionTypes.TMaterialsOrWaresInReplenishment[]
                          | undefined = values?.deliveryItems?.map(
                          (replenishmentItem: actionTypes.IBomElementInWarehouseDelivery) => {
                            return {
                              // TODO: There is no orderLineId in the Warehouse Delivery BE
                              orderDeliveryOrderLineId: replenishmentItem?.orderDeliveryOrderLineId
                                ? Number(replenishmentItem?.orderDeliveryOrderLineId)
                                : null,
                              quantity: Number(
                                convertNumericStringToNumber(replenishmentItem?.receivedQuantity)
                              ),
                            }
                          }
                        )

                        const selectedDelivery: actionTypes.IWarehouseManagementDelivery =
                          values?.selectedDelivery
                            ? JSON.parse(values?.selectedDelivery)
                            : undefined

                        dispatch({
                          type: actionTypes.GENERATE_DELIVERY_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED,
                          payload: {
                            tenant: tenant,
                            token: authState.authData?.token,
                            documentData: {
                              warehouseId: Number(paramsManagedWarehouseId),
                              date: values?.deliveryDate || '',
                              deliveryDocument: values?.deliveryDocument || '',
                              referenceNumber: selectedDelivery?.orderNumber || '',
                              replenishmentDetails: replenishmentDetails,
                              orderDeliveryId: selectedDelivery?.id || 0,
                            },
                          },
                        })
                      }
                    }}
                    enableReinitialize={false}
                    validateOnBlur={false}
                    validateOnChange={didDeliveryMaterialsReplenishmentFormValidationOccur}
                  >
                    {({ values, errors, setFieldValue, handleChange }) => (
                      <Form>
                        {/*
                         * Display Network Error Message
                         */}

                        {!isMaterialsReplenishmentModifiedAndUnsaved &&
                          warehousesState?.deliveryMaterialsReplenishmentDocumentGenerateError && (
                            <CustomErrorMessage
                              wrapperClassNames="mb-4"
                              customErrorMessageText={getErrorMessageFromStatus(
                                'create',
                                warehousesState?.deliveryMaterialsReplenishmentDocumentGenerateError
                                  ?.status,
                                'danych przyjęcia materiału i półproduktów'
                              )}
                            />
                          )}

                        <div className="settings-view-double-grid-fields">
                          <BasicFormField
                            fieldId="warehouse-management-materials-replenishment-delivery-date"
                            fieldLabel="Data dostawy"
                            fieldIcon="cil-calendar"
                            formikFieldName="deliveryDate"
                            fieldValue={values.deliveryDate}
                            fieldError={errors.deliveryDate}
                            fieldType="date"
                            placeholder=""
                          />

                          <BasicFormField
                            fieldId="warehouse-management-materials-replenishment-delivery-document"
                            fieldLabel="Dokument dostawy"
                            fieldIcon="cil-short-text"
                            formikFieldName="deliveryDocument"
                            fieldValue={values.deliveryDocument}
                            fieldError={errors.deliveryDocument}
                            fieldType="text"
                            placeholder="Wpisz numer WZ"
                          />
                        </div>

                        <div className="mb-4">
                          <CLabel
                            htmlFor="warehouse-management-materials-replenishment-delivery-id"
                            className={inputLabelSpacingBottom}
                          >
                            Zamówienie (Dostawa)
                          </CLabel>
                          <InputGroup
                            id="warehouse-management-materials-replenishment-delivery-id"
                            className={`${inputFieldSpacingBottom} dropdown-selector`}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={
                                  errors.selectedDelivery && 'text-danger input-error-icon'
                                }
                              >
                                <i className="cil-truck"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <ErrorMessage
                              name="selectedDelivery"
                              component="span"
                              className="text-danger input-error-message"
                            />
                            <Field
                              as={Select}
                              name="selectedDelivery"
                              value={values.selectedDelivery}
                              variant="outlined"
                              native
                              className={`item-selector element-selector w-100 text-center ${
                                String(values.selectedDelivery) ===
                                  selectDeliveryPlaceholderValue && 'element-selector-placeholder'
                              } ${errors.selectedDelivery && 'invalid-native-selector'}`}
                              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                const changeDelivery = (selectedDeliveryString: string) => {
                                  if (selectedDeliveryString) {
                                    const selectedDelivery: actionTypes.IWarehouseManagementDelivery =
                                      JSON.parse(selectedDeliveryString)

                                    // This is important to change the displayed value in select
                                    event.target.value = selectedDeliveryString

                                    if (selectedDelivery) {
                                      const bomElementsInDelivery =
                                        generateBomElementsListFromDelivery(
                                          selectedDelivery?.bomElements
                                        )

                                      setFieldValue('deliveryItems', bomElementsInDelivery)
                                    }
                                  } else {
                                    setFieldValue('deliveryItems', emptyDeliveryItemsInForm)
                                  }

                                  setIsMaterialsReplenishmentModifiedAndUnsaved(false)

                                  return handleChange(event)
                                }

                                if (isMaterialsReplenishmentModifiedAndUnsaved) {
                                  confirmAlert({
                                    closeOnEscape: true,
                                    message: String(event?.target?.value),
                                    customUI: ({ message, onClose }) => {
                                      return (
                                        <ConfirmActionModal
                                          mode="changeDelivery"
                                          onClose={onClose}
                                          confirmMessageJSX={
                                            <>
                                              <strong>Masz niezapisane zmiany!</strong>
                                              <br />
                                              Czy na pewno chcesz zmienić dostawę?
                                            </>
                                          }
                                          onConfirmFunction={() => {
                                            changeDelivery(message)
                                          }}
                                        />
                                      )
                                    },
                                  })
                                } else {
                                  changeDelivery(String(event?.target?.value))
                                }
                              }}
                            >
                              <option
                                className="select-option-placeholder text-center"
                                value={selectDeliveryPlaceholderValue}
                                disabled={false}
                              >
                                Wybierz dostawę z zamówienia
                              </option>
                              {warehousesState?.managedWarehouseDeliveries
                                ?.filter(
                                  (warehouseDelivery: actionTypes.IWarehouseManagementDelivery) =>
                                    // this should be warehouseDelivery?.type from BE not warehouseDelivery?.bomElements[0]?.type
                                    warehouseDelivery?.bomElements[0]?.type ===
                                    actionTypes.materialsTypeNumber
                                )
                                // only non-received deliveries should be displayed
                                ?.filter(
                                  (warehouseDelivery: actionTypes.IWarehouseManagementDelivery) =>
                                    !warehouseDelivery?.goodsReceivedNote
                                )
                                ?.sort(
                                  (
                                    warehouseDeliveryFirst: actionTypes.IWarehouseManagementDelivery,
                                    warehouseDeliveryNext: actionTypes.IWarehouseManagementDelivery
                                  ) =>
                                    Date.parse(warehouseDeliveryFirst.closestDeliveryDate) -
                                    Date.parse(warehouseDeliveryNext.closestDeliveryDate)
                                )
                                ?.map(
                                  (warehouseDelivery: actionTypes.IWarehouseManagementDelivery) => {
                                    return (
                                      <option
                                        key={`warehouse-delivery-option-${warehouseDelivery.id}`}
                                        value={JSON.stringify(warehouseDelivery)}
                                        className="text-center"
                                      >
                                        {warehouseDelivery?.orderNumber || 'Zamówienie bez nazwy'} —
                                        Dostawa {warehouseDelivery?.deliveryNumber || 'bez numeru'}{' '}
                                        —{' '}
                                        {toDateInputValue(warehouseDelivery?.closestDeliveryDate) ||
                                          'brak numeru'}
                                      </option>
                                    )
                                  }
                                )}
                            </Field>
                          </InputGroup>
                        </div>

                        <CDataTable
                          tableFilterValue={deliveryMaterialsReplenishmentFilterState}
                          sorterValue={deliveryMaterialsReplenishmentSorterState}
                          onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                            setDeliveryMaterialsReplenishmentSorterState(sorterState)
                          }}
                          onTableFilterChange={(tableFilterState: string) => {
                            setDeliveryMaterialsReplenishmentFilterState(tableFilterState)
                          }}
                          loading={warehousesState?.areManagedWarehouseDeliveriesLoading}
                          striped={!!warehousesState?.managedWarehouseDeliveries?.length}
                          border
                          sorter
                          tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                          addTableClasses="vertical-middle-list-table warehouse-replenishment-table"
                          cleaner
                          onRowClick={undefined}
                          fields={[
                            {
                              key: 'position',
                              label: '#',
                              _style: { width: '40px', textAlign: 'center' },
                            },
                            {
                              key: 'bomElementName',
                              label: 'Nazwa elementu',
                              _style: { width: '320px' },
                            },
                            {
                              key: 'receivedQuantity',
                              label: 'Ilość',
                              _style: { width: '120px', textAlign: 'right' },
                            },
                          ]}
                          items={values?.deliveryItems || undefined}
                          noItemsViewSlot={
                            <div className="no-items-in-table">
                              {values?.deliveryItems && values?.selectedDelivery
                                ? 'Brak materiału w dostawie!'
                                : 'Nie wybrano dostawy!'}
                            </div>
                          }
                          scopedSlots={{
                            receivedQuantity: (
                              itemFromDelivery: actionTypes.IBomElementInWarehouseDelivery,
                              indexOfItemFromDelivery: number
                            ) => {
                              const indexOfStabilizedItem = values?.deliveryItems?.findIndex(
                                (deliveryItem: actionTypes.IBomElementInWarehouseDelivery) =>
                                  deliveryItem.bomElementId === itemFromDelivery.bomElementId
                              )

                              return (
                                <td>
                                  <InputGroup
                                    id={`${
                                      values?.selectedDelivery
                                        ? JSON.parse(values?.selectedDelivery)?.id
                                        : 'empty'
                                    }-${
                                      itemFromDelivery.bomElementId
                                    }-materials-replenishment-delivery-item-group-id`}
                                    style={{ pointerEvents: 'all' }}
                                  >
                                    <ErrorMessage
                                      name={`deliveryItems.${indexOfStabilizedItem}.receivedQuantity`}
                                      component="span"
                                      className="text-danger input-error-message"
                                    />
                                    <FastField
                                      as={NumberFormat}
                                      displayType="input"
                                      thousandSeparator={globalThousandSeparator}
                                      decimalSeparator={globalDecimalSeparator}
                                      decimalScale={0}
                                      fixedDecimalScale={false}
                                      allowNegative={false}
                                      allowLeadingZeros={true}
                                      placeholder={''}
                                      name={`deliveryItems.${indexOfStabilizedItem}.receivedQuantity`}
                                      value={itemFromDelivery.receivedQuantity || ''}
                                      className="form-control text-right"
                                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        itemFromDelivery.hasChanged = true

                                        setIsMaterialsReplenishmentModifiedAndUnsaved(true)

                                        handleChange(event)
                                      }}
                                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                        if (
                                          itemFromDelivery &&
                                          itemFromDelivery?.hasChanged &&
                                          Number(
                                            convertNumericStringToNumber(
                                              itemFromDelivery?.receivedQuantity
                                            )
                                          ) > Number(itemFromDelivery?.expectedQuantity)
                                        ) {
                                          confirmAlert({
                                            closeOnEscape: true,
                                            customUI: ({ onClose }) => {
                                              return (
                                                <ConfirmActionModal
                                                  mode="replenishment"
                                                  onClose={() => {
                                                    setFieldValue(
                                                      `deliveryItems.${indexOfStabilizedItem}.receivedQuantity`,
                                                      ''
                                                    )

                                                    event?.target?.focus()

                                                    onClose()
                                                  }}
                                                  confirmMessageJSX={
                                                    <>
                                                      {itemFromDelivery?.bomElementName ? (
                                                        <>
                                                          <strong>{`${itemFromDelivery?.bomElementName}: `}</strong>{' '}
                                                          <br />
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                      Wpisana ilość przekracza ilość spodziewaną!
                                                      <br />
                                                      Czy wartość{' '}
                                                      <strong>
                                                        {itemFromDelivery.receivedQuantity}
                                                      </strong>{' '}
                                                      jest prawidłowa?
                                                    </>
                                                  }
                                                  onConfirmFunction={() => {
                                                    itemFromDelivery.hasChanged = false

                                                    onClose()
                                                  }}
                                                />
                                              )
                                            },
                                          })
                                        }
                                      }}
                                      // Might be useful:
                                      required
                                    />
                                  </InputGroup>
                                </td>
                              )
                            },
                          }}
                        />

                        <CButton
                          color="success"
                          className="save-button w-100 mt-3 mb-2"
                          type="submit"
                          onClick={() => {
                            setDidDeliveryMaterialsReplenishmentFormValidationOccur &&
                              setDidDeliveryMaterialsReplenishmentFormValidationOccur(true)
                          }}
                          disabled={
                            warehousesState?.isDeliveryMaterialsReplenishmentDocumentGenerating ||
                            warehousesState?.isDeliveryMaterialsReplenishmentDocumentGenerated ||
                            (didDeliveryMaterialsReplenishmentFormValidationOccur
                              ? Boolean(errors.deliveryItems || errors.deliveryDocument)
                              : false)
                          }
                        >
                          {warehousesState?.isDeliveryMaterialsReplenishmentDocumentGenerating ? (
                            <>
                              Generowanie PZ
                              <ThreeDots />
                            </>
                          ) : warehousesState?.isDeliveryMaterialsReplenishmentDocumentGenerated ? (
                            <>Wygenerowano PZ!</>
                          ) : (
                            'Generuj PZ'
                          )}
                        </CButton>
                      </Form>
                    )}
                  </Formik>
                )}
              </CTabPane>

              {/*
               * Materials (Elements or Intermediates) From Manufacturing Errand Replenishment Tab
               */}

              <CTabPane data-tab="manufacturing-errands">
                {manufacturingState.areManufacturingErrandsLoading && usersState.areUsersLoading ? (
                  <div
                    style={{
                      height: '300px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      date: toDateTimeInputValue(new Date().toISOString()),
                      referenceNumber: '',
                      userId: null,
                      manufacturingErrandId: '',
                      replenishmentDetails:
                        [] as actionTypes.TFromManufacturingErrandReplenishmentTableItem[],
                    }}
                    validationSchema={ManufacturingErrandMaterialsReplenishmentSchema}
                    onSubmit={(values) => {
                      if (isWarehouseEditable) {
                        setDidManufacturingErrandReplenishmentFormValidationOccur(true)
                        setIsMaterialsReplenishmentModifiedAndUnsaved(false)

                        const replenishmentDetails:
                          | actionTypes.TManufacturingReplenishmentItem[]
                          | [] =
                          values?.replenishmentDetails
                            ?.map(
                              (
                                replenishmentItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                              ) => {
                                return {
                                  bomElementId: replenishmentItem?.bomElementId,
                                  bomIntermediateId: replenishmentItem?.bomIntermediateId,
                                  quantity: Number(
                                    convertNumericStringToNumber(replenishmentItem?.quantity)
                                  ),
                                }
                              }
                            )
                            ?.filter(
                              (
                                manufacturingReplenishmentItem: actionTypes.TManufacturingReplenishmentItem
                              ) =>
                                !!convertNumericStringToNumber(
                                  manufacturingReplenishmentItem?.quantity
                                )
                            ) || []

                        dispatch({
                          type: actionTypes.GENERATE_MANUFACTURING_ERRAND_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED,
                          payload: {
                            tenant: tenant,
                            token: authState.authData?.token,
                            documentData: {
                              warehouseId: Number(paramsManagedWarehouseId),
                              date: values?.date || '',
                              referenceNumber: values?.referenceNumber || '',
                              manufacturingErrandId: values?.manufacturingErrandId || 0,
                              userId: values?.userId,
                              replenishmentDetails: replenishmentDetails,
                            },
                          },
                        })
                      }
                    }}
                    enableReinitialize={false}
                    validateOnBlur={false}
                    validateOnChange={didManufacturingErrandReplenishmentFormValidationOccur}
                  >
                    {({
                      values,
                      errors,
                      setFieldValue,
                      handleChange,
                      validateForm,
                      setErrors,
                      setFieldTouched,
                    }) => (
                      <Form>
                        {/*
                         * Display Network Error Message
                         */}

                        {!isMaterialsReplenishmentModifiedAndUnsaved &&
                          warehousesState?.manufacturingErrandMaterialsReplenishmentDocumentGenerateError && (
                            <CustomErrorMessage
                              wrapperClassNames="mb-4"
                              customErrorMessageText={getErrorMessageFromStatus(
                                'create',
                                warehousesState
                                  ?.manufacturingErrandMaterialsReplenishmentDocumentGenerateError
                                  ?.status,
                                'danych przyjęcia materiału i półproduktów ze zlecenia produkcyjnego'
                              )}
                            />
                          )}

                        {usersState?.fetchUsersError && (
                          <CustomErrorMessage
                            wrapperClassNames="mb-4"
                            customErrorMessageText={getErrorMessageFromStatus(
                              'fetch',
                              usersState?.fetchUsersError?.status,
                              'listy użytkowników'
                            )}
                          />
                        )}

                        {manufacturingState?.fetchManufacturingErrandsError && (
                          <CustomErrorMessage
                            wrapperClassNames="mb-4"
                            customErrorMessageText={getErrorMessageFromStatus(
                              'fetch',
                              manufacturingState?.fetchManufacturingErrandsError?.status,
                              'zleceń produkcyjnych'
                            )}
                          />
                        )}

                        {warehousesState?.fetchUserPossessionsError && (
                          <CustomErrorMessage
                            wrapperClassNames="mb-4"
                            customErrorMessageText={getErrorMessageFromStatus(
                              'fetch',
                              warehousesState?.fetchUserPossessionsError?.status,
                              'stanu posiadania przekazującego'
                            )}
                          />
                        )}

                        <div className="settings-view-double-grid-fields">
                          <BasicFormField
                            fieldId="warehouse-management-manufacturing-errand-materials-replenishment-delivery-date"
                            fieldLabel="Data i godzina przyjęcia"
                            fieldIcon="cil-calendar"
                            formikFieldName="date"
                            fieldValue={values.date}
                            fieldError={errors.date}
                            fieldType="datetime-local"
                            placeholder=""
                          />

                          <BasicFormField
                            fieldId="warehouse-management-manufacturing-errand-materials-replenishment-delivery-document"
                            fieldLabel="Dokument przyjęcia"
                            fieldIcon="cil-short-text"
                            formikFieldName="referenceNumber"
                            fieldValue={values.referenceNumber}
                            fieldError={errors.referenceNumber}
                            fieldType="text"
                            placeholder="Kolejny numer RW"
                          />
                        </div>

                        <div>
                          <CLabel
                            htmlFor="warehouse-management-manufacturing-errand-materials-replenishment-giver"
                            className={`${inputLabelSpacingBottom}`}
                          >
                            Przekazujący
                          </CLabel>
                          <InputGroup
                            id="warehouse-management-manufacturing-errand-materials-replenishment-giver"
                            className={`${inputFieldSpacingBottom} flex-nowrap`}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={errors.userId && 'text-danger input-error-icon'}
                              >
                                <i className="cil-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>

                            <ErrorMessage
                              name="userId"
                              component="span"
                              className="text-danger input-error-message"
                            />

                            <Typeahead
                              placeholder="Wybierz z listy lub wpisz e-mail przekazującego"
                              isInvalid={!!errors.userId}
                              id="warehouse-management-manufacturing-errand-materials-replenishment-giver-typeahead"
                              onChange={(selected: actionTypes.TUser[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('userId', selected[0].userId)

                                  dispatch({
                                    type: actionTypes.FETCH_USER_POSSESSIONS_REQUESTED,
                                    payload: {
                                      tenant: tenant,
                                      token: authState.authData?.token,
                                      userId: selected[0].userId,
                                    },
                                  })

                                  setFieldValue('replenishmentDetails', [])
                                  setFieldValue('manufacturingErrandId', '')
                                  setFieldTouched('replenishmentDetails')
                                }
                              }}
                              onInputChange={(text) => {
                                setFieldValue('replenishmentDetails', [])
                                setFieldValue('manufacturingErrandId', '')
                                setFieldTouched('replenishmentDetails')

                                if (text.length > 0) {
                                  const matchedUser = usersState?.users?.find(
                                    (user: actionTypes.TUser) =>
                                      user.userName.toLowerCase() === text.toLowerCase()
                                  )

                                  if (matchedUser) {
                                    setFieldValue('userId', matchedUser.userId)

                                    dispatch({
                                      type: actionTypes.FETCH_USER_POSSESSIONS_REQUESTED,
                                      payload: {
                                        tenant: tenant,
                                        token: authState.authData?.token,
                                        userId: matchedUser.userId,
                                      },
                                    })
                                  } else {
                                    setFieldValue('userId', undefined)
                                  }
                                } else {
                                  setFieldValue('userId', undefined)
                                }
                              }}
                              defaultInputValue={''}
                              options={usersState.users}
                              labelKey="userName"
                              emptyLabel="Nie znaleziono użytkowników"
                            />
                          </InputGroup>
                        </div>

                        <div className="mb-4">
                          <CLabel
                            htmlFor="warehouse-management-manufacturing-errand-materials-replenishment-errand-id"
                            className={inputLabelSpacingBottom}
                          >
                            Zlecenie produkcyjne
                          </CLabel>
                          <InputGroup
                            id="warehouse-management-manufacturing-errand-materials-replenishment-errand-id"
                            className={`${inputFieldSpacingBottom} dropdown-selector`}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={
                                  errors.manufacturingErrandId && 'text-danger input-error-icon'
                                }
                              >
                                <i className="cil-factory"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <ErrorMessage
                              name="manufacturingErrandId"
                              component="span"
                              className="text-danger input-error-message"
                            />
                            <Field
                              as={Select}
                              variant="outlined"
                              native
                              className={`item-selector element-selector w-100 text-center ${
                                String(values.manufacturingErrandId) ===
                                  selectManufacturingErrandPlaceholderValue &&
                                'element-selector-placeholder'
                              } ${errors.manufacturingErrandId && 'invalid-native-selector'}`}
                              name="manufacturingErrandId"
                              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                if (
                                  values.userId &&
                                  !warehousesState.areUserPossessionsLoading &&
                                  event.currentTarget.value !== ''
                                ) {
                                  setFieldValue(
                                    'replenishmentDetails',
                                    generateManufacturingErrandReplenishmentDetails(
                                      Number(event.currentTarget.value),
                                      values.userId,
                                      warehousesState.selectedUserPossessions
                                    )
                                  )
                                } else {
                                  // This runs when placeholder is selected again
                                  setFieldValue('replenishmentDetails', [])
                                }

                                return handleChange(event)
                              }}
                              disabled={!manufacturingState?.manufacturingErrands || !values.userId}
                            >
                              <option
                                className="select-option-placeholder text-center"
                                value={selectManufacturingErrandPlaceholderValue}
                                disabled={false}
                              >
                                {!values.userId
                                  ? 'Najpierw wybierz przekazującego!'
                                  : warehousesState.areUserPossessionsLoading
                                  ? 'Trwa pobieranie stanu posiadania przekazującego...'
                                  : manufacturingState?.manufacturingErrands
                                  ? 'Wybierz zlecenie'
                                  : 'Brak zleceń do wyboru!'}
                              </option>
                              {manufacturingState?.manufacturingErrands?.map(
                                (manufacturingErrand: actionTypes.TManufacturingErrand) => {
                                  return (
                                    <option
                                      key={`materials-replenishment-manufacturing-errand-option-${manufacturingErrand.id}`}
                                      value={manufacturingErrand.id}
                                      className="text-center"
                                    >
                                      Zlecenie:{' '}
                                      {manufacturingErrand?.manufacturingErrandNumber ||
                                        'brak numeru'}{' '}
                                      | Produkt: {manufacturingErrand?.bomName || 'brak nazwy'}
                                    </option>
                                  )
                                }
                              )}
                            </Field>
                          </InputGroup>
                        </div>

                        {/*
                         * BOM Intermediates Table
                         */}

                        <CDataTable
                          tableFilterValue={
                            manufacturingErrandMaterialsReplenishmentBomIntermediatesFilterState
                          }
                          sorterValue={
                            manufacturingErrandMaterialsReplenishmentBomIntermediatesSorterState
                          }
                          onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                            setManufacturingErrandMaterialsReplenishmentBomIntermediatesSorterState(
                              sorterState
                            )
                          }}
                          onTableFilterChange={(tableFilterState: string) => {
                            setManufacturingErrandMaterialsReplenishmentBomIntermediatesFilterState(
                              tableFilterState
                            )
                          }}
                          loading={false}
                          striped={
                            !!values.replenishmentDetails?.filter(
                              (
                                tableItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                              ) => tableItem.type === actionTypes.bomIntermediatesTypeNumber
                            ).length
                          }
                          border
                          sorter
                          tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                          addTableClasses="vertical-middle-list-table warehouse-replenishment-table warehouse-from-manufacturing-errand-replenishment-table"
                          cleaner
                          onRowClick={undefined}
                          fields={[
                            {
                              key: 'position',
                              label: '#',
                              _style: { width: '40px', textAlign: 'center' },
                            },
                            {
                              key: 'bomIntermediateName',
                              label: 'Nazwa półproduktu',
                              _style: { width: '320px' },
                            },
                            {
                              key: 'userQuantity',
                              label: 'Posiadane',
                              _style: { width: '100px' },
                            },
                            {
                              key: 'quantity',
                              label: 'Ilość',
                              _style: { width: '120px', textAlign: 'right' },
                            },
                          ]}
                          items={
                            values?.replenishmentDetails?.filter(
                              (
                                tableItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                              ) => tableItem.type === actionTypes.bomIntermediatesTypeNumber
                            ) || undefined
                          }
                          noItemsViewSlot={
                            <div className="no-items-in-table">
                              {!values?.manufacturingErrandId
                                ? 'Nie wybrano zlecenia produkcyjnego!'
                                : !values?.replenishmentDetails?.filter(
                                    (
                                      tableItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                                    ) => tableItem.type === actionTypes.bomIntermediatesTypeNumber
                                  )?.length
                                ? 'Brak półproduktów w etapach produkcji BOM ze zlecenia!'
                                : ''}
                            </div>
                          }
                          scopedSlots={{
                            quantity: (
                              itemFromManufacturingErrand: actionTypes.TFromManufacturingErrandReplenishmentTableItem,
                              indexOfItemFromManufacturingErrand: number
                            ) => {
                              const indexOfStabilizedItem = values?.replenishmentDetails?.findIndex(
                                (
                                  findItemFromManufacturingErrand: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                                ) =>
                                  findItemFromManufacturingErrand.bomIntermediateName ===
                                    itemFromManufacturingErrand.bomIntermediateName &&
                                  findItemFromManufacturingErrand.bomIntermediateId ===
                                    itemFromManufacturingErrand.bomIntermediateId &&
                                  findItemFromManufacturingErrand.type ===
                                    itemFromManufacturingErrand.type
                              )

                              return (
                                <td>
                                  <InputGroup
                                    id={`${
                                      values?.manufacturingErrandId
                                        ? JSON.parse(values?.manufacturingErrandId)?.id
                                        : 'empty'
                                    }-${
                                      itemFromManufacturingErrand.bomIntermediateId
                                    }-materials-replenishment-manufacturing-errand-bom-intermediate-item-group-id`}
                                    style={{ pointerEvents: 'all' }}
                                  >
                                    <ErrorMessage
                                      name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                      component="span"
                                      className="text-danger input-error-message"
                                    />
                                    <FastField
                                      as={NumberFormat}
                                      displayType="input"
                                      thousandSeparator={globalThousandSeparator}
                                      decimalSeparator={globalDecimalSeparator}
                                      decimalScale={0}
                                      fixedDecimalScale={false}
                                      allowNegative={false}
                                      allowLeadingZeros={true}
                                      placeholder={''}
                                      name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                      value={itemFromManufacturingErrand.quantity || ''}
                                      className="form-control text-right"
                                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        itemFromManufacturingErrand.hasChanged = true

                                        setIsMaterialsReplenishmentModifiedAndUnsaved(true)

                                        handleChange(event)
                                      }}
                                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                        if (
                                          itemFromManufacturingErrand &&
                                          itemFromManufacturingErrand?.hasChanged &&
                                          !!itemFromManufacturingErrand.quantity &&
                                          Number(
                                            convertNumericStringToNumber(
                                              itemFromManufacturingErrand?.quantity
                                            )
                                          ) > Number(itemFromManufacturingErrand?.userQuantity)
                                        ) {
                                          confirmAlert({
                                            closeOnEscape: true,
                                            customUI: ({ onClose }) => {
                                              return (
                                                <ConfirmActionModal
                                                  mode="replenishment"
                                                  onClose={() => {
                                                    setFieldValue(
                                                      `replenishmentDetails.${indexOfStabilizedItem}.quantity`,
                                                      ''
                                                    )
                                                    event?.target?.focus()
                                                    onClose()
                                                  }}
                                                  confirmMessageJSX={
                                                    <>
                                                      {itemFromManufacturingErrand?.bomIntermediateName ? (
                                                        <>
                                                          <strong>{`${itemFromManufacturingErrand?.bomIntermediateName}: `}</strong>
                                                          <br />
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                      Wpisana ilość przekracza ilość posiadaną!
                                                      <br />
                                                      Czy wartość{' '}
                                                      <strong>
                                                        {itemFromManufacturingErrand.quantity}
                                                      </strong>{' '}
                                                      jest prawidłowa?
                                                    </>
                                                  }
                                                  onConfirmFunction={() => {
                                                    itemFromManufacturingErrand.hasChanged = false
                                                    onClose()
                                                  }}
                                                />
                                              )
                                            },
                                          })
                                        }
                                      }}
                                    />
                                  </InputGroup>
                                </td>
                              )
                            },
                          }}
                        />

                        <div className="hidden">
                          <Field
                            type="hidden"
                            name="replenishmentDetails"
                            defaultValue={generateManufacturingErrandReplenishmentDetails(
                              values.manufacturingErrandId,
                              values.userId,
                              warehousesState.selectedUserPossessions
                            )}
                            // onChange={(e) => setFieldValue('replenishmentDetails', e.target.value)}
                          />
                        </div>

                        {/*
                         * BOM Elements Table
                         */}

                        <CDataTable
                          tableFilterValue={
                            manufacturingErrandMaterialsReplenishmentElementsFilterState
                          }
                          sorterValue={manufacturingErrandMaterialsReplenishmentElementsSorterState}
                          onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                            setManufacturingErrandMaterialsReplenishmentElementsSorterState(
                              sorterState
                            )
                          }}
                          onTableFilterChange={(tableFilterState: string) => {
                            setManufacturingErrandMaterialsReplenishmentElementsFilterState(
                              tableFilterState
                            )
                          }}
                          loading={warehousesState.areUserPossessionsLoading}
                          striped={
                            !!values?.replenishmentDetails?.filter(
                              (
                                tableItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                              ) => tableItem.type === actionTypes.materialsTypeNumber
                            ).length
                          }
                          border
                          sorter
                          tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                          addTableClasses="vertical-middle-list-table warehouse-replenishment-table warehouse-from-manufacturing-errand-replenishment-table"
                          cleaner
                          onRowClick={undefined}
                          fields={[
                            {
                              key: 'position',
                              label: '#',
                              _style: { width: '40px', textAlign: 'center' },
                            },
                            {
                              key: 'bomElementName',
                              label: 'Nazwa elementu',
                              _style: { width: '320px' },
                            },
                            {
                              key: 'userQuantity',
                              label: 'Posiadane',
                              _style: { width: '100px' },
                            },
                            {
                              key: 'quantity',
                              label: 'Ilość',
                              _style: { width: '120px', textAlign: 'right' },
                            },
                          ]}
                          items={values?.replenishmentDetails?.filter(
                            (
                              tableItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                            ) => tableItem.type === actionTypes.materialsTypeNumber
                          )}
                          noItemsViewSlot={
                            <div className="no-items-in-table">
                              {!values?.manufacturingErrandId
                                ? 'Nie wybrano zlecenia produkcyjnego!'
                                : !values?.replenishmentDetails?.filter(
                                    (
                                      tableItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                                    ) => tableItem.type === actionTypes.materialsTypeNumber
                                  )?.length
                                ? 'Brak elementów w BOM ze zlecenia!'
                                : ''}
                            </div>
                          }
                          scopedSlots={{
                            quantity: (
                              itemFromManufacturingErrand: actionTypes.TFromManufacturingErrandReplenishmentTableItem,
                              indexOfItemFromManufacturingErrand: number
                            ) => {
                              const indexOfStabilizedItem = values?.replenishmentDetails?.findIndex(
                                (
                                  replenishmentDetailsItem: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                                ) =>
                                  replenishmentDetailsItem.bomElementName ===
                                    itemFromManufacturingErrand.bomElementName &&
                                  replenishmentDetailsItem.bomElementId ===
                                    itemFromManufacturingErrand.bomElementId &&
                                  replenishmentDetailsItem.type === itemFromManufacturingErrand.type
                              )

                              return (
                                <td>
                                  <InputGroup
                                    id={`${
                                      values?.manufacturingErrandId
                                        ? JSON.parse(values?.manufacturingErrandId)?.id
                                        : 'empty'
                                    }-${
                                      itemFromManufacturingErrand.bomElementId
                                    }-materials-replenishment-manufacturing-errand-element-item-group-id`}
                                    style={{ pointerEvents: 'all' }}
                                  >
                                    <ErrorMessage
                                      name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                      component="span"
                                      className="text-danger input-error-message"
                                    />
                                    <FastField
                                      as={NumberFormat}
                                      displayType="input"
                                      thousandSeparator={globalThousandSeparator}
                                      decimalSeparator={globalDecimalSeparator}
                                      decimalScale={0}
                                      fixedDecimalScale={false}
                                      allowNegative={false}
                                      allowLeadingZeros={true}
                                      placeholder={''}
                                      name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                      value={itemFromManufacturingErrand.quantity || ''}
                                      className="form-control text-right"
                                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        itemFromManufacturingErrand.hasChanged = true

                                        setIsMaterialsReplenishmentModifiedAndUnsaved(true)

                                        handleChange(event)
                                      }}
                                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                        if (
                                          itemFromManufacturingErrand &&
                                          itemFromManufacturingErrand?.hasChanged &&
                                          !!itemFromManufacturingErrand.quantity &&
                                          Number(
                                            convertNumericStringToNumber(
                                              itemFromManufacturingErrand?.quantity
                                            )
                                          ) > Number(itemFromManufacturingErrand?.userQuantity)
                                        ) {
                                          confirmAlert({
                                            closeOnEscape: true,
                                            customUI: ({ onClose }) => {
                                              return (
                                                <ConfirmActionModal
                                                  mode="replenishment"
                                                  onClose={() => {
                                                    setFieldValue(
                                                      `replenishmentDetails.${indexOfStabilizedItem}.quantity`,
                                                      ''
                                                    )
                                                    event?.target?.focus()
                                                    onClose()
                                                  }}
                                                  confirmMessageJSX={
                                                    <>
                                                      {itemFromManufacturingErrand?.bomElementName ? (
                                                        <>
                                                          <strong>{`${itemFromManufacturingErrand?.bomElementName}: `}</strong>{' '}
                                                          <br />
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                      Wpisana ilość przekracza ilość posiadaną!
                                                      <br />
                                                      Czy wartość{' '}
                                                      <strong>
                                                        {itemFromManufacturingErrand.quantity}
                                                      </strong>{' '}
                                                      jest prawidłowa?
                                                    </>
                                                  }
                                                  onConfirmFunction={() => {
                                                    itemFromManufacturingErrand.hasChanged = false
                                                    onClose()
                                                  }}
                                                />
                                              )
                                            },
                                          })
                                        }
                                      }}
                                    />
                                  </InputGroup>
                                </td>
                              )
                            },
                          }}
                        />

                        {/*
                         * Generate Replenishment Document From Manufacturing Errand Button
                         */}

                        <CButton
                          color="success"
                          className="save-button w-100 mt-3 mb-2"
                          type="submit"
                          onClick={(event: React.FocusEvent<HTMLInputElement>) => {
                            var thisButton = event.currentTarget

                            if (!isManufacturingErrandReplenishmentAlertConfirmed) {
                              event.preventDefault()

                              if (!didManufacturingErrandReplenishmentFormValidationOccur) {
                                validateForm()

                                setDidManufacturingErrandReplenishmentFormValidationOccur &&
                                  setDidManufacturingErrandReplenishmentFormValidationOccur(true)

                                event.stopPropagation()

                                validateForm().then((errors) => {
                                  setErrors(errors)

                                  // This is needed to display the error message
                                  Object.keys(errors)?.forEach((fieldName: string) =>
                                    setFieldTouched(fieldName)
                                  )
                                })
                              }
                            }

                            {
                              /*
                               * Three Different Alerts
                               */
                            }

                            if (
                              !isManufacturingErrandReplenishmentAlertConfirmed &&
                              didManufacturingErrandReplenishmentFormValidationOccur &&
                              !errors.referenceNumber &&
                              !errors.userId &&
                              !errors.manufacturingErrandId
                            ) {
                              confirmAlert({
                                closeOnEscape: false,
                                customUI: ({ onClose }) => {
                                  const predictedAlertObject =
                                    predictManufacturingErrandReplenishmentAlert(
                                      values.replenishmentDetails,
                                      warehousesState.selectedUserPossessions
                                        ?.bomElementUserPossessions
                                    )

                                  return (
                                    <ConfirmActionModal
                                      mode="materialsFromUserReplenishment"
                                      onClose={() => {
                                        event?.target?.focus()
                                        event.preventDefault()
                                        onClose()
                                      }}
                                      confirmMessageJSX={
                                        <>
                                          {
                                            //
                                            predictedAlertObject.alertType ===
                                            'aboveUserPossessions' ? (
                                              <>
                                                Przekazujący zdaje elementy, których nie posiada.
                                                <br />
                                                Lista oraz ilość nadmiarowych elementów:
                                                <table className="warehouse-from-manufacturing-errand-replenishment-alert-table mt-4">
                                                  <thead>
                                                    <th>Nazwa elementu</th>
                                                    <th>Ilość</th>
                                                  </thead>

                                                  <tbody>
                                                    {predictedAlertObject.itemsList?.map(
                                                      (
                                                        replenishedBomElement: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                                                      ) => (
                                                        <tr>
                                                          <td>
                                                            {replenishedBomElement.bomElementName}
                                                          </td>
                                                          <td>
                                                            {convertQuantityToNumericString(
                                                              replenishedBomElement.quantity
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </>
                                            ) : predictedAlertObject.alertType ===
                                              'belowUserPossessions' ? (
                                              <>
                                                W posiadaniu przekazującego nadal pozostają poniższe
                                                elementy:
                                                <table className="warehouse-from-manufacturing-errand-replenishment-alert-table mt-4">
                                                  <thead>
                                                    <th>Nazwa elementu</th>
                                                    <th>Ilość</th>
                                                  </thead>

                                                  <tbody>
                                                    {predictedAlertObject.itemsList?.map(
                                                      (
                                                        replenishedBomElement: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                                                      ) => (
                                                        <tr>
                                                          <td>
                                                            {replenishedBomElement.bomElementName}
                                                          </td>
                                                          <td>
                                                            {convertQuantityToNumericString(
                                                              replenishedBomElement.quantity
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                                <br />
                                              </>
                                            ) : (
                                              <>Przkazujący zdał wszystkie posiadane elementy.</>
                                            )
                                          }
                                        </>
                                      }
                                      onConfirmFunction={() => {
                                        setIsManufacturingErrandReplenishmentAlertConfirmed &&
                                          setIsManufacturingErrandReplenishmentAlertConfirmed(true)

                                        thisButton.click()

                                        setTimeout(() => {
                                          thisButton.click()
                                        }, 100)

                                        onClose()
                                      }}
                                    />
                                  )
                                },
                              })
                            }
                          }}
                          disabled={
                            !values?.replenishmentDetails.some(
                              (
                                bomElementOrIntermediateFromManufacturing: actionTypes.TFromManufacturingErrandReplenishmentTableItem
                              ) => !!bomElementOrIntermediateFromManufacturing?.quantity
                            ) ||
                            warehousesState?.isManufacturingErrandMaterialsReplenishmentDocumentGenerating ||
                            warehousesState?.isManufacturingErrandMaterialsReplenishmentDocumentGenerated ||
                            (didManufacturingErrandReplenishmentFormValidationOccur
                              ? Boolean(
                                  errors.referenceNumber ||
                                    errors.userId ||
                                    errors.manufacturingErrandId
                                )
                              : false)
                          }
                        >
                          {warehousesState?.isManufacturingErrandMaterialsReplenishmentDocumentGenerating ? (
                            <>
                              Generowanie RW
                              <ThreeDots />
                            </>
                          ) : warehousesState?.isManufacturingErrandMaterialsReplenishmentDocumentGenerated ? (
                            <>Wygenerowano RW!</>
                          ) : (
                            'Generuj RW'
                          )}
                        </CButton>
                      </Form>
                    )}
                  </Formik>
                )}
              </CTabPane>
            </CTabContent>
          </CTabs>
        </CCardBody>
      </CCard>
    </Container>
  )
}
