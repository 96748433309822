import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CHeader, CToggler, CHeaderNav, CHeaderBrand } from '@coreui/react'
import { useLocation } from 'react-router-dom'

import * as actionTypes from '../store/action-types'
import { DropdownsHeader } from './index'
import { TRootState } from '../store/reducers'
import { maximumWarehouseNameLengthInTitle } from '../views/warehouses/warehouse-management/inventory-control'

export const Header: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const sidebarVisibility = useSelector((state: TRootState) => state.admin.sidebarVisibility)
  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)
  const location = useLocation()

  let managedWarehouseName: string | undefined = useMemo(() => {
    return location?.pathname?.includes(`warehouse-management/`)
      ? warehousesState?.warehouses?.find(
          (warehouse: actionTypes.TWarehouseLight) =>
            String(warehouse?.id) === String(warehousesState?.currentlyManagedWarehouseId)
        )?.name || ''
      : ''
  }, [
    location?.pathname,
    warehousesState?.currentlyManagedWarehouseId,
    warehousesState?.warehouses,
  ])

  const toggleSidebar = () => {
    const visibility = [true, 'responsive'].includes(sidebarVisibility) ? false : 'responsive'
    dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: visibility })
  }

  const toggleSidebarMobile = () => {
    const visibility = [false, 'responsive'].includes(sidebarVisibility) ? true : 'responsive'
    dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: visibility })
  }

  const getHeaderTitleFromRoute = (name: string): string => {
    if (
      name.includes('edit') &&
      !name.includes('producers/edit') &&
      !name.includes('services/edit') &&
      !name.includes('implementation-costs/edit') &&
      !name.includes('manufacturing-activities/edit')
    ) {
      return ''
    }
    if (
      name.includes('suppliers') &&
      authState.authData?.roles.includes('Suppliers_read') &&
      !name.includes('/suppliers/create')
    ) {
      return 'Dostawcy'
    }
    if (
      name.includes('bom-elements') &&
      authState.authData?.roles.includes('BomElements_read') &&
      !name.includes('create') &&
      !name.includes('edit') &&
      !name.includes('purchase-plan')
    ) {
      return 'Elementy'
    }
    if (
      name.includes('boms') &&
      authState.authData?.roles.includes('Boms_read') &&
      !name.includes('create')
    ) {
      return 'Lista BOM'
    }

    if (
      name.includes('purchase-plans') &&
      authState.authData?.roles.includes('PurchasePlans_read')
    ) {
      return 'Zapotrzebowania'
    }

    if (
      name.includes('offers') &&
      authState.authData?.roles.includes('Offers_read') &&
      !name.includes('offers/create') &&
      !name.includes('services/edit') &&
      !name.includes('implementation-costs/edit')
    ) {
      return 'Oferty'
    }

    if (
      name.includes('orders') &&
      authState.authData?.roles.includes('Orders_read') &&
      !name.includes('services/edit') &&
      !name.includes('implementation-costs/edit')
    ) {
      return 'Zamówienia'
    }

    if (name.includes('producers') && authState.authData?.roles.includes('Producers_read')) {
      return 'Producenci'
    }

    if (
      name.includes('services') &&
      !name.includes('services/edit') &&
      !name.includes('services/create') &&
      authState.authData?.roles.includes('Services_read') &&
      !name.includes('purchase-plan') &&
      !name.includes('boms/create')
    ) {
      return 'Usługi'
    }

    if (
      name.includes('implementation-costs') &&
      !name.includes('implementation-costs/edit') &&
      !name.includes('implementation-costs/create') &&
      authState.authData?.roles.includes('ImplementationCosts_read') &&
      !name.includes('purchase-plan') &&
      !name.includes('boms/create')
    ) {
      return 'Tooling'
    }

    if (
      name.includes('/warehouses') &&
      authState.authData?.roles.includes('Warehouses_read') &&
      !name.includes('warehouses/')
    ) {
      return 'Magazyny'
    }

    if (
      name.includes('warehouse-management') &&
      authState.authData?.roles.includes('Warehouses_read') &&
      !name.includes('inventory-control')
    ) {
      return managedWarehouseName
        ? `Magazyn ${
            managedWarehouseName?.length > maximumWarehouseNameLengthInTitle
              ? managedWarehouseName?.slice(0, maximumWarehouseNameLengthInTitle)?.concat('...')
              : managedWarehouseName
          }`
        : ''
    }

    if (
      name.includes('/inventories-of-warehouses') &&
      authState.authData?.roles.includes('Warehouses_read')
    ) {
      return 'Stany magazynowe'
    }

    if (
      name.includes('/documents-of-warehouses') &&
      authState.authData?.roles.includes('Warehouses_read')
    ) {
      return 'Dokumenty magazynowe'
    }

    if (
      name.includes('/manufacturing-steps') &&
      authState.authData?.roles.includes('Manufacturing_read') &&
      !name.includes('manufacturing-steps/')
    ) {
      return 'Etapy produkcji'
    }

    if (
      (name.includes('/manufacturing-activities') || name.includes('/manufacturing-activities/')) &&
      authState.authData?.roles.includes('Manufacturing_read')
    ) {
      return 'Czynności'
    }

    if (
      name.includes('/team-members') &&
      authState.authData?.roles.includes('Manufacturing_read') &&
      !name.includes('team-members/')
    ) {
      return 'Zespół'
    }

    if (
      name.includes('/manufacturing-errands') &&
      authState.authData?.roles.includes('Manufacturing_read')
    ) {
      return 'Zlecenia produkcyjne'
    }

    if (
      name.includes('/current-manufacturing-report') &&
      authState.authData?.roles.includes('Manufacturing_read')
    ) {
      return 'Produkcja w toku'
    }

    if (
      name.includes('/reports') &&
      !name.includes('/reports/') &&
      // This might require a different permission in the future
      authState.authData?.roles.includes('Users_read')
    ) {
      return 'Raporty'
    }

    if (name.includes('logs') && authState.authData?.roles.includes('AuditLogs_read')) {
      return 'Audyt akcji'
    }

    if (
      name.includes('users') &&
      !name.includes('users/') &&
      authState.authData?.roles.includes('Users_read')
    ) {
      return 'Użytkownicy'
    }

    return ''
  }

  return (
    <CHeader style={{ zIndex: 800 }} withSubheader>
      <CToggler inHeader className="ml-0 md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-0 d-md-down-none" onClick={toggleSidebar} />

      <CHeaderBrand className="mx-auto d-lg-none" />

      <div
        className="d-md-down-none ml-auto mr-auto d-flex flex-column justify-content-center align-items-center"
        style={{ fontSize: '18px' }}
      >
        {getHeaderTitleFromRoute(location?.pathname)}
      </div>

      <CHeaderNav className="pr-2">
        <DropdownsHeader />
      </CHeaderNav>
    </CHeader>
  )
}
