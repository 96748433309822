import React, { useEffect, useState, useMemo, SyntheticEvent } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'
import { ManufacturingActivityModal } from './manufacturing-activity-modal'
import { ListActionButton, ListStickyErrorMessage } from '../../components'

const ManufacturingActivities: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{ tenant: string; id: string }>()
  const paramsManufacturingActivityId = id

  const authState = useSelector((state: TRootState) => state.auth)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)

  const [manufacturingActivityModalMode, setManufacturingActivityModalMode] =
    useState<actionTypes.TFormMode>('create')

  // Open create modal
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      location.pathname.includes(`/${tenant}/manufacturing-activities/create`) &&
      !manufacturingState.manufacturingActivityModalVisibility
    ) {
      dispatch({
        type: actionTypes.OPEN_MANUFACTURING_ACTIVITY_MODAL,
        editedManufacturingActivity: null,
      })
    }
  }, [
    dispatch,
    tenant,
    authState,
    manufacturingState.manufacturingActivities,
    history,
    location.pathname,
    manufacturingActivityModalMode,
    manufacturingState.manufacturingActivityModalVisibility,
  ])

  // Set data for editedManufacturingActivity in modal when clicked on row or opened in new window
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      paramsManufacturingActivityId &&
      manufacturingState.manufacturingActivities
    ) {
      setManufacturingActivityModalMode('edit')

      if (manufacturingState.manufacturingActivities.length) {
        const foundManufacturingActivity = manufacturingState.manufacturingActivities.find(
          (prod: actionTypes.IManufacturingActivity) =>
            Number(prod.id) === Number(paramsManufacturingActivityId)
        )

        if (foundManufacturingActivity) {
          dispatch({
            type: actionTypes.OPEN_MANUFACTURING_ACTIVITY_MODAL,
            editedManufacturingActivity: foundManufacturingActivity,
          })
        } else if (!manufacturingState.isManufacturingActivityDeleted) {
          history.replace(`/${tenant}/manufacturing-activities`)
        }
      }
    }
  }, [
    dispatch,
    tenant,
    authState,
    paramsManufacturingActivityId,
    manufacturingState.manufacturingActivities,
    history,
  ])

  // Close ManufacturingActivity Modal when going back in browser history
  useEffect(() => {
    if (
      !paramsManufacturingActivityId &&
      manufacturingState.manufacturingActivityModalVisibility &&
      !location.pathname.includes('manufacturing-activities/create') &&
      !location.pathname.includes('manufacturing-activities/edit')
    ) {
      dispatch({
        type: actionTypes.CLOSE_MANUFACTURING_ACTIVITY_MODAL,
      })
    }
  }, [
    dispatch,
    paramsManufacturingActivityId,
    manufacturingState.manufacturingActivityModalVisibility,
    location.pathname,
  ])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      manufacturingState?.manufacturingActivities?.map(
        (manufacturingActivity: actionTypes.IManufacturingActivity) => {
          return {
            ...manufacturingActivity,
            name: manufacturingActivity?.name || '',
            icon: '🖊️',
          }
        }
      ),
    [manufacturingState.manufacturingActivities]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="7">
        <CCard>
          <CCardBody className="pb-2">
            {authState?.authData?.roles?.includes('Manufacturing_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/manufacturing-activities/create`)
                    setManufacturingActivityModalMode('create')
                    dispatch({
                      type: actionTypes.OPEN_MANUFACTURING_ACTIVITY_MODAL,
                      editedManufacturingActivity: null,
                    })
                  }}
                >
                  Nowa Czynność
                </CButton>
              </div>
            )}

            {manufacturingState?.manufacturingActivityDeleteError && (
              <ListStickyErrorMessage
                listErrorMessage={`Wystąpił ${
                  manufacturingState?.manufacturingActivityDeleteError?.status
                    ? `błąd ${manufacturingState?.manufacturingActivityDeleteError?.status}`
                    : 'nieznany błąd'
                } podczas usuwania czynności!`}
              />
            )}

            <CDataTable
              tableFilterValue={manufacturingState?.manufacturingActivitiesTableFilterState}
              sorterValue={manufacturingState?.manufacturingActivitiesTableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_MANUFACTURING_ACTIVITIES_TABLE_SORTER_STATE,
                  manufacturingActivitiesTableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_MANUFACTURING_ACTIVITIES_TABLE_FILTER_STATE,
                  manufacturingActivitiesTableFilterState: tableFilterState,
                })
              }
              loading={manufacturingState.areManufacturingActivitiesLoading}
              striped={!!manufacturingState.manufacturingActivities.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table"
              onRowClick={(manufacturingActivity: actionTypes.IManufacturingActivity) => {
                history.push(`/${tenant}/manufacturing-activities/edit/${manufacturingActivity.id}`)
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '40px',
                    display: !manufacturingState.manufacturingActivities.length ? 'none' : '',
                  },
                },
                { key: 'name', label: 'Nazwa', _style: { width: '700px', textAlign: 'left' } },
                {
                  key: 'delete',
                  label: '',
                  _style: { width: '45px' },
                },
              ]}
              scopedSlots={{
                delete: (itemFromList: actionTypes.TLightItemInList) => (
                  <td
                    className="text-center"
                    style={{ pointerEvents: 'none' }}
                    onClick={(event: SyntheticEvent) => {
                      if (!itemFromList.canDelete) {
                        event.preventDefault()
                        event.stopPropagation()
                        return null
                      } else {
                        return undefined
                      }
                    }}
                  >
                    <ListActionButton
                      mainActionName="delete"
                      buttonColor="danger"
                      classNames="delete-list-item-button"
                      typeOfAction={actionTypes.DELETE_MANUFACTURING_ACTIVITY_REQUESTED}
                      iconName="cil-trash"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={
                        manufacturingState.currentlyManagedManufacturingActivityId
                      }
                      isItemCopying={false}
                      isItemArchiving={false}
                      isItemDeleting={manufacturingState.isManufacturingActivityDeleting}
                    />
                  </td>
                ),
              }}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    manufacturingState.fetchManufacturingActivitiesError,
                    manufacturingState.areManufacturingActivitiesLoading,
                    'czynności',
                    manufacturingState?.manufacturingActivities?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
      {manufacturingState.manufacturingActivityModalVisibility && (
        <ManufacturingActivityModal mode={manufacturingActivityModalMode} />
      )}
    </Container>
  )
}

export default ManufacturingActivities
