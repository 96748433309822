import React, { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Container, Table } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import {
  CDataTable,
  CCard,
  CCardBody,
  CButton,
  CInput,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  getEmptyListMessage,
  toDateInputValue,
  convertQuantityToNumericString,
  successMessageDuration,
  toDateTableValue,
  convertNumbersInTableRows,
  getErrorMessageFromStatus,
} from '../../utils'
import {
  BasicFormField,
  ConfirmActionModal,
  FormActionDeleteButton,
  CustomErrorMessage,
  ThreeDots,
} from '../../components'
import { PurchasePlanDetailsModal } from './purchase-plan-details-modal'
import { minimumWidthForWideDetailsView } from '../erp'
import { confirmAlert } from 'react-confirm-alert'

export type TPurchasePlanTab = {
  name: string
  title: string
}

export const purchasePlanNavTabs: TPurchasePlanTab[] = [
  { name: 'bom-elements', title: 'Elementy' },
  { name: 'implementation-costs', title: 'Tooling' },
  { name: 'services', title: 'Usługi' },
]

const PurchasePlanDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { tenant, id, tab, elementDetailsId } = useParams<{
    tenant: string
    id: string
    tab: string
    elementDetailsId: string
  }>()
  const paramsPurchasePlanId = id
  const paramsPurchasePlanTab = tab

  let location = useLocation()

  let [orderInfo, setOrderInfo] = useState<actionTypes.TGenerateOrderPayload>({
    purchasePlanId: Number(id),
    bomElementsWithOffer: [],
  })

  const authState = useSelector((state: TRootState) => state.auth)
  const adminState = useSelector((state: TRootState) => state.admin)
  const bomsState = useSelector((state: TRootState) => state.boms)
  const offersState = useSelector((state: TRootState) => state.offers)
  const purchasePlansState = useSelector((state: TRootState) => state.purchasePlans)
  const currentlyDisplayedPurchasePlan = purchasePlansState.purchasePlans.find(
    (purchasePlan: actionTypes.TPurchasePlan) => purchasePlan.id === Number(paramsPurchasePlanId)
  )

  const isPurchasePlanEditable =
    authState?.authData?.roles?.includes('PurchasePlans_write') &&
    !purchasePlansState.singlePurchasePlanFetchError

  // Open single purchase plan
  useEffect(() => {
    if (tenant && authState.authData && paramsPurchasePlanId) {
      dispatch({
        type: actionTypes.FETCH_SINGLE_PURCHASE_PLAN_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsPurchasePlanId },
      })
    }
  }, [dispatch, tenant, authState, paramsPurchasePlanId])

  // Close purchase plan view after deleting it
  useEffect(() => {
    if (purchasePlansState?.isPurchasePlanDeleted) {
      setTimeout(() => {
        history.push(`/${tenant}/purchase-plans`)
      }, successMessageDuration)
    }
  }, [purchasePlansState.isPurchasePlanDeleted])

  // Hide sidebar for smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Clear checkboxes after order is generated and redirect to generated order
  useEffect(() => {
    if (
      purchasePlansState.isOrderGenerated &&
      purchasePlansState.generatedOrderId &&
      !purchasePlansState.isSinglePurchasePlanLoading &&
      purchasePlansState.singlePurchasePlan
    ) {
      history.push(`/${tenant}/order/${purchasePlansState.generatedOrderId}/general-information`)
    }
  }, [
    history,
    tenant,
    purchasePlansState.isOrderGenerated,
    purchasePlansState.generatedOrderId,
    purchasePlansState.isSinglePurchasePlanLoading,
    purchasePlansState.singlePurchasePlan,
  ])

  // Open purchase plan details modal
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      elementDetailsId &&
      location.pathname.includes(
        `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${paramsPurchasePlanTab}/${elementDetailsId}`
      )
    ) {
      if (
        paramsPurchasePlanTab === 'bom-elements' &&
        !purchasePlansState.purchasePlanBomElementModalVisibility &&
        !purchasePlansState.isPurchasePlanBomElementLoading &&
        purchasePlansState.singlePurchasePlan?.purchasePlanBomElements
      ) {
        const bomIdsInBomElement =
          purchasePlansState.singlePurchasePlan?.purchasePlanBomElements?.find(
            (purchasePlanBomElement: actionTypes.TBomElementInPurchasePlan) =>
              purchasePlanBomElement.bomElementId === Number(elementDetailsId)
          )?.bomIds

        if (bomIdsInBomElement && !purchasePlansState.purchasePlanBomElementModalTabs) {
          dispatch({
            type: actionTypes.FETCH_PURCHASE_PLAN_BOM_ELEMENT_REQUESTED,
            payload: {
              tenant: tenant,
              token: authState.authData?.token,
              purchasePlanId: paramsPurchasePlanId,
              bomElementId: elementDetailsId,
              bomIds: bomIdsInBomElement,
            },
          })
        }
      }

      if (
        paramsPurchasePlanTab === 'implementation-costs' &&
        !purchasePlansState.purchasePlanImplementationCostModalVisibility &&
        !purchasePlansState.isPurchasePlanImplementationCostLoading &&
        purchasePlansState.singlePurchasePlan?.purchasePlanImplementationCosts
      ) {
        const bomIdsInImplementationCost =
          purchasePlansState.singlePurchasePlan?.purchasePlanImplementationCosts?.find(
            (purchasePlanImplementationCost: actionTypes.TImplementationCostInPurchasePlan) =>
              purchasePlanImplementationCost.implementationCostId === Number(elementDetailsId)
          )?.bomIds

        if (bomIdsInImplementationCost) {
          dispatch({
            type: actionTypes.FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_REQUESTED,
            payload: {
              tenant: tenant,
              token: authState.authData?.token,
              purchasePlanId: paramsPurchasePlanId,
              implementationCostId: elementDetailsId,
              bomIds: [bomIdsInImplementationCost],
            },
          })
        }
      }

      if (
        paramsPurchasePlanTab === 'services' &&
        !purchasePlansState.purchasePlanServiceModalVisibility &&
        !purchasePlansState.isPurchasePlanServiceLoading &&
        purchasePlansState.singlePurchasePlan?.purchasePlanServices
      ) {
        const bomIdsInService = purchasePlansState.singlePurchasePlan?.purchasePlanServices?.find(
          (purchasePlanService: actionTypes.TServiceInPurchasePlan) =>
            purchasePlanService.serviceId === Number(elementDetailsId)
        )?.bomIds

        if (bomIdsInService && !purchasePlansState.purchasePlanServiceModalTabs) {
          dispatch({
            type: actionTypes.FETCH_PURCHASE_PLAN_SERVICE_REQUESTED,
            payload: {
              tenant: tenant,
              token: authState.authData?.token,
              purchasePlanId: paramsPurchasePlanId,
              serviceId: elementDetailsId,
              bomIds: [bomIdsInService],
            },
          })
        }
      }
    }
  }, [
    dispatch,
    tenant,
    authState,
    location.pathname,
    paramsPurchasePlanId,
    elementDetailsId,
    purchasePlansState.purchasePlanBomElementModalVisibility,
    purchasePlansState.purchasePlanServiceModalVisibility,
    purchasePlansState.purchasePlanImplementationCostModalVisibility,

    purchasePlansState.isPurchasePlanBomElementLoading,
    purchasePlansState.isPurchasePlanServiceLoading,
    purchasePlansState.isPurchasePlanImplementationCostLoading,

    purchasePlansState.singlePurchasePlan,

    purchasePlansState.purchasePlanBomElementModalTabs,
    purchasePlansState.purchasePlanImplementationCostModalTabs,
    purchasePlansState.purchasePlanServiceModalTabs,
  ])

  // Redirect on not found editable Purchase Plan Element
  useEffect(() => {
    if (
      purchasePlansState.purchasePlanBomElementModalError &&
      purchasePlansState.purchasePlanBomElementModalError.status === 404
    ) {
      history.replace(
        `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${purchasePlanNavTabs[0].name}`
      )
    }
  }, [
    dispatch,
    purchasePlansState.purchasePlanBomElementModalError,
    paramsPurchasePlanId,
    history,
    tenant,
    location,
  ])

  // Redirect on not found editable Purchase Plan Implementation Cost
  useEffect(() => {
    if (
      purchasePlansState.purchasePlanImplementationCostModalError &&
      purchasePlansState.purchasePlanImplementationCostModalError.status === 404
    ) {
      history.replace(
        `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${purchasePlanNavTabs[1].name}`
      )
    }
  }, [
    dispatch,
    purchasePlansState.purchasePlanImplementationCostModalError,
    paramsPurchasePlanId,
    history,
    tenant,
    location,
  ])

  // Redirect on not found editable Purchase Plan Service
  useEffect(() => {
    if (
      purchasePlansState.purchasePlanServiceModalError &&
      purchasePlansState.purchasePlanServiceModalError.status === 404
    ) {
      history.replace(
        `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${purchasePlanNavTabs[2].name}`
      )
    }
  }, [
    dispatch,
    purchasePlansState.purchasePlanServiceModalError,
    paramsPurchasePlanId,
    history,
    tenant,
    location,
  ])

  // Close Purchase Plan Element Modal when going back in browser history
  useEffect(() => {
    if (
      !elementDetailsId &&
      purchasePlansState.purchasePlanBomElementModalVisibility &&
      location.pathname.includes(
        `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${purchasePlanNavTabs[0].name}`
      )
    ) {
      dispatch({
        type: actionTypes.CLOSE_PURCHASE_PLAN_BOM_ELEMENT_MODAL,
      })
    }
  }, [
    dispatch,
    paramsPurchasePlanId,
    elementDetailsId,
    tenant,
    purchasePlansState.purchasePlanBomElementModalVisibility,
    location.pathname,
  ])

  // Close Purchase Plan Implementation Cost Modal when going back in browser history
  useEffect(() => {
    if (
      !elementDetailsId &&
      purchasePlansState.purchasePlanImplementationCostModalVisibility &&
      location.pathname.includes(
        `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${purchasePlanNavTabs[1].name}`
      )
    ) {
      dispatch({
        type: actionTypes.CLOSE_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL,
      })
    }
  }, [
    dispatch,
    tenant,
    paramsPurchasePlanId,
    elementDetailsId,
    purchasePlansState.purchasePlanImplementationCostModalVisibility,
    location.pathname,
  ])

  // Close Purchase Plan Service Modal when going back in browser history
  useEffect(() => {
    if (
      !elementDetailsId &&
      purchasePlansState.purchasePlanServiceModalVisibility &&
      location.pathname.includes(
        `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${purchasePlanNavTabs[2].name}`
      )
    ) {
      dispatch({
        type: actionTypes.CLOSE_PURCHASE_PLAN_SERVICE_MODAL,
      })
    }
  }, [
    dispatch,
    tenant,
    paramsPurchasePlanId,
    elementDetailsId,
    purchasePlansState.purchasePlanServiceModalVisibility,
    location.pathname,
  ])

  const memoizedBomElementsInTable = useMemo(
    () =>
      purchasePlansState.singlePurchasePlan &&
      purchasePlansState.singlePurchasePlan.purchasePlanBomElements
        ? purchasePlansState?.singlePurchasePlan?.purchasePlanBomElements?.map(
            (purchasePlanBomElement: actionTypes.TBomElementInPurchasePlan) => {
              return {
                ...purchasePlanBomElement,
                bomNames: purchasePlanBomElement.bomNames?.join(';\n') || '',
                bomElementName: purchasePlanBomElement?.bomElementName || '',
                supplierName: purchasePlanBomElement?.supplierName || '',
                plannedQuantity: purchasePlanBomElement?.plannedQuantity,
                price: purchasePlanBomElement?.orderPrice
                  ? purchasePlanBomElement?.orderPrice
                  : purchasePlanBomElement?.offer?.bomElementPrice
                  ? purchasePlanBomElement?.offer?.bomElementPrice
                  : '',

                priceCurrency: purchasePlanBomElement?.orderPriceCurrency
                  ? purchasePlanBomElement?.orderPriceCurrency || ''
                  : purchasePlanBomElement?.offer?.bomElementPriceCurrency
                  ? purchasePlanBomElement?.offer?.bomElementPriceCurrency || ''
                  : '',
                status: purchasePlanBomElement?.status || '',
                warehouseState: purchasePlanBomElement.warehouseState || '',
                expirationDate: purchasePlanBomElement?.offer?.expirationDate
                  ? toDateTableValue(purchasePlanBomElement?.offer?.expirationDate)
                  : '',
                icon: '🖊️',
                uuid: uuidv4(),
              }
            }
          )
        : undefined,
    [purchasePlansState.singlePurchasePlan]
  )

  const memoizedImplementationCostsInTable = useMemo(
    () =>
      purchasePlansState.singlePurchasePlan &&
      purchasePlansState.singlePurchasePlan.purchasePlanImplementationCosts
        ? purchasePlansState?.singlePurchasePlan?.purchasePlanImplementationCosts?.map(
            (purchasePlanImplementationCost: actionTypes.TImplementationCostInPurchasePlan) => {
              return {
                ...purchasePlanImplementationCost,
                implementationCostName:
                  purchasePlanImplementationCost?.implementationCostName || '',
                supplierName: purchasePlanImplementationCost?.supplierName || '',
                estimatedPrice: purchasePlanImplementationCost?.estimatedPrice || '',
                estimatedPriceCurrency:
                  purchasePlanImplementationCost?.estimatedPriceCurrency || '',
                bomElementName: purchasePlanImplementationCost?.bomElementName || '',
                icon: '🖊️',
              }
            }
          )
        : undefined,
    [purchasePlansState.singlePurchasePlan]
  )

  const memoizedServicesInTable = useMemo(
    () =>
      purchasePlansState.singlePurchasePlan &&
      purchasePlansState.singlePurchasePlan.purchasePlanServices
        ? purchasePlansState?.singlePurchasePlan.purchasePlanServices?.map(
            (purchasePlanService: actionTypes.TServiceInPurchasePlan) => {
              return {
                ...purchasePlanService,
                serviceName: purchasePlanService?.serviceName || '',
                supplierName: purchasePlanService?.supplierName || '',
                bomNames: purchasePlanService?.bomNames || '',
                estimatedQuantity: purchasePlanService?.estimatedQuantity || '',
                estimatedPrice: purchasePlanService?.estimatedPrice || '',
                estimatedPriceCurrency: purchasePlanService?.estimatedPriceCurrency || '',
                icon: '🖊️',
              }
            }
          )
        : undefined,
    [purchasePlansState.singlePurchasePlan]
  )

  useEffect(() => {
    if (paramsPurchasePlanTab === purchasePlanNavTabs[0].name) {
      convertNumbersInTableRows(
        purchasePlansState?.singlePurchasePlan?.purchasePlanBomElements,
        [6],
        [5]
      )
    }
  }, [memoizedBomElementsInTable, paramsPurchasePlanTab])

  useEffect(() => {
    if (paramsPurchasePlanTab === purchasePlanNavTabs[1].name) {
      convertNumbersInTableRows(
        purchasePlansState?.singlePurchasePlan?.purchasePlanImplementationCosts,
        [3],
        []
      )
    }
  }, [memoizedImplementationCostsInTable, paramsPurchasePlanTab])

  useEffect(() => {
    if (paramsPurchasePlanTab === purchasePlanNavTabs[2].name) {
      convertNumbersInTableRows(
        purchasePlansState?.singlePurchasePlan?.purchasePlanServices,
        [5],
        [4]
      )
    }
  }, [memoizedServicesInTable, paramsPurchasePlanTab])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.UNLOCK_GENERATED_ORDER })
    }
  }, [])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard className="purchase-plan-details-container">
        <CCardBody className="pb-2">
          {/*
           * Display Network Error Message
           */}

          {purchasePlansState?.singlePurchasePlanFetchError && (
            <CustomErrorMessage
              wrapperClassNames="purchase-plan-error-message-margin"
              messageClassNames="text-center"
              customErrorMessageText={getErrorMessageFromStatus(
                'fetch',
                purchasePlansState?.singlePurchasePlanFetchError?.status,
                'zapotrzebowania'
              )}
            />
          )}

          {purchasePlansState?.purchasePlanEditSaveError && (
            <CustomErrorMessage
              wrapperClassNames="purchase-plan-error-message-margin"
              messageClassNames="text-center"
              customErrorMessageText={getErrorMessageFromStatus(
                'edit',
                purchasePlansState?.purchasePlanEditSaveError?.status,
                'zapotrzebowania'
              )}
            />
          )}

          {purchasePlansState?.purchasePlanDeleteError && (
            <CustomErrorMessage
              wrapperClassNames="purchase-plan-error-message-margin"
              messageClassNames="text-center"
              customErrorMessageText={getErrorMessageFromStatus(
                'delete',
                purchasePlansState?.purchasePlanDeleteError?.status,
                'zapotrzebowania'
              )}
            />
          )}

          <h4
            className={`text-left font-weight-light purchase-plan-title ${
              adminState.sidebarVisibility === 'responsive' && !adminState.sidebarMinimize
                ? 'purchase-plan-title-with-visible-sidebar'
                : ''
            } ${
              adminState.sidebarMinimize && adminState.sidebarVisibility
                ? 'purchase-plan-title-with-visible-sidebar-minimized'
                : ''
            }`}
          >
            <strong>
              Zapotrzebowanie nr {currentlyDisplayedPurchasePlan?.number || '...'}
              {purchasePlansState?.singlePurchasePlan?.createdByLogin
                ? `, utworzone przez: ${purchasePlansState?.singlePurchasePlan?.createdByLogin}`
                : ''}
            </strong>
          </h4>

          <div className="purchase-plan-header">
            <div />
            {bomsState.boms &&
            purchasePlansState.singlePurchasePlan &&
            purchasePlansState.singlePurchasePlan.purchasePlanBomInfos &&
            purchasePlansState.singlePurchasePlan.purchasePlanBomInfos.length > 0 ? (
              <div className="purchase-plan-table-with-boms-wrapper">
                <Table className="purchase-plan-table-with-boms" bordered>
                  <tbody>
                    <tr>
                      <th style={{ width: '75px', textAlign: 'center' }}>BOM</th>
                      {purchasePlansState.singlePurchasePlan?.purchasePlanBomInfos?.map(
                        (purchasePlanBom: actionTypes.TBomPurchasePlan) => {
                          return (
                            <td key={`bom-${purchasePlanBom.bomId}-${purchasePlanBom?.bomName}`}>
                              {purchasePlanBom?.bomName}
                            </td>
                          )
                        }
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '75px', textAlign: 'center' }}>Ilość</th>
                      {purchasePlansState.singlePurchasePlan?.purchasePlanBomInfos?.map(
                        (purchasePlanBom: actionTypes.TBomPurchasePlan) => {
                          return (
                            <td key={`bom-${purchasePlanBom.bomId}-${purchasePlanBom?.quantity}`}>
                              {convertQuantityToNumericString(purchasePlanBom?.quantity)}
                            </td>
                          )
                        }
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                {purchasePlansState.singlePurchasePlanFetchError
                  ? `Wystąpił błąd ${
                      purchasePlansState?.singlePurchasePlanFetchError?.status || ''
                    } podczas pobierania tabeli BOM!`
                  : 'Brak przypisanych BOM'}
              </div>
            )}

            <Formik
              enableReinitialize
              initialValues={{
                productionStartDate:
                  toDateInputValue(purchasePlansState.singlePurchasePlan?.productionStartDate) ||
                  '',
              }}
              onSubmit={(values) => {
                if (isPurchasePlanEditable) {
                  dispatch({
                    type: actionTypes.EDIT_PURCHASE_PLAN_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      purchasePlan: {
                        ...purchasePlansState.singlePurchasePlan,
                        productionStartDate: values.productionStartDate,
                      },
                    },
                  })
                }
              }}
            >
              {({ values, errors, resetForm }) => (
                <Form className="production-date-form">
                  <div
                    className={`purchase-plan-form-buttons-wrapper ${
                      adminState.sidebarVisibility === 'responsive' && !adminState.sidebarMinimize
                        ? 'purchase-plan-form-buttons-wrapper-with-visible-sidebar'
                        : ''
                    } ${
                      adminState.sidebarMinimize && adminState.sidebarVisibility
                        ? 'purchase-plan-form-buttons-wrapper-with-visible-sidebar-minimized'
                        : ''
                    }`}
                  >
                    {isPurchasePlanEditable && (
                      <FormActionDeleteButton
                        isSaving={
                          purchasePlansState.isSinglePurchasePlanLoading ||
                          purchasePlansState.isPurchasePlanEditSaving ||
                          purchasePlansState.isOrderGenerating
                        }
                        isSaved={
                          purchasePlansState.isPurchasePlanEditSaved ||
                          purchasePlansState.isOrderGenerated
                        }
                        isDeleting={purchasePlansState.isPurchasePlanDeleting}
                        isDeleted={purchasePlansState.isPurchasePlanDeleted}
                        deleteAction={actionTypes.DELETE_PURCHASE_PLAN_REQUESTED}
                        deletePayload={{
                          tenant: tenant,
                          token: authState.authData?.token,
                          id: purchasePlansState?.singlePurchasePlan?.id,
                        }}
                        phrasesInDelete={undefined}
                        setIsOneFieldChanged={undefined}
                        canDelete={purchasePlansState?.singlePurchasePlan?.canDelete}
                        disabledDeleteButtonClassNames={'delete-details-button'}
                        confirmDeleteMessageJSX={
                          <>
                            Czy na pewno chcesz usunąć zapotrzebowanie?
                            {purchasePlansState?.singlePurchasePlan?.number ? (
                              <>
                                <br />
                                <strong>{purchasePlansState?.singlePurchasePlan?.number}</strong>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        }
                      />
                    )}

                    <CButton
                      color="primary"
                      variant="outline"
                      type="button"
                      className="cancel-button ml-4"
                      onClick={() => {
                        resetForm()

                        history.push(`/${tenant}/purchase-plans`)
                      }}
                    >
                      Wyjdź
                    </CButton>

                    {isPurchasePlanEditable && (
                      <CButton
                        color="success"
                        variant=""
                        className="save-button px-3 ml-4"
                        type="submit"
                        disabled={
                          purchasePlansState?.isSinglePurchasePlanLoading ||
                          purchasePlansState?.isPurchasePlanEditSaving ||
                          purchasePlansState?.isPurchasePlanEditSaved ||
                          purchasePlansState?.isPurchasePlanDeleting ||
                          purchasePlansState?.isOrderGenerating
                        }
                      >
                        {purchasePlansState?.isPurchasePlanEditSaving ? (
                          <>
                            {'Zapisywanie'}
                            <ThreeDots />
                          </>
                        ) : purchasePlansState?.isPurchasePlanEditSaved ? (
                          <>{'Zapisano!'}</>
                        ) : (
                          'Zapisz'
                        )}
                      </CButton>
                    )}
                  </div>

                  <BasicFormField
                    fieldId="purchase-plan-production-start-date"
                    fieldLabel="Data startu produkcji"
                    fieldIcon="cil-factory"
                    formikFieldName="productionStartDate"
                    fieldValue={toDateInputValue(values?.productionStartDate)}
                    fieldError={errors?.productionStartDate}
                    fieldType="date"
                    placeholder="Wprowadź datę produkcji"
                  />
                </Form>
              )}
            </Formik>
          </div>

          <CTabs activeTab={paramsPurchasePlanTab || purchasePlanNavTabs[0].name}>
            <CNav variant="tabs" className="mt-2" style={{ marginBottom: '-55px' }}>
              {purchasePlanNavTabs?.map((modalTab: TPurchasePlanTab) => {
                return (
                  <CNavItem key={modalTab.name}>
                    <CNavLink
                      data-tab={modalTab.name}
                      onClick={() => {
                        history.replace(
                          `/${tenant}/purchase-plan/${paramsPurchasePlanId}/${modalTab.name}`
                        )
                      }}
                    >
                      {
                        purchasePlanNavTabs.find(
                          (purchasePlanNavTab: TPurchasePlanTab) =>
                            modalTab.name === purchasePlanNavTab.name
                        )?.title
                      }
                    </CNavLink>
                  </CNavItem>
                )
              })}
            </CNav>
            <CTabContent>
              {/*
               * Bom Elements Tab
               */}

              <CTabPane data-tab="bom-elements">
                <CDataTable
                  sorterValue={purchasePlansState?.purchasePlanDetailsBomElementsTableSorterState}
                  onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                    if (purchasePlansState?.singlePurchasePlan?.purchasePlanBomElements) {
                      convertNumbersInTableRows(
                        purchasePlansState?.singlePurchasePlan?.purchasePlanBomElements,
                        [6],
                        [5]
                      )
                    }

                    dispatch({
                      type: actionTypes.SET_PURCHASE_PLAN_DETAILS_BOM_ELEMENTS_TABLE_SORTER_STATE,
                      tableSorterState: sorterState,
                    })
                  }}
                  onTableFilterChange={(tableFilterState: string) => {
                    if (purchasePlansState?.singlePurchasePlan?.purchasePlanBomElements) {
                      convertNumbersInTableRows(
                        purchasePlansState?.singlePurchasePlan?.purchasePlanBomElements,
                        [6],
                        [5]
                      )
                    }
                  }}
                  loading={purchasePlansState.isSinglePurchasePlanLoading}
                  striped={
                    purchasePlansState.singlePurchasePlan?.purchasePlanBomElements
                      ? !!purchasePlansState.singlePurchasePlan.purchasePlanBomElements.length
                      : false
                  }
                  addTableClasses="purchase-plan-items-table purchase-plan-bom-elements-table"
                  border
                  sorter
                  tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                  cleaner
                  onRowClick={(purchasePlanBomElement: actionTypes.TBomElementInPurchasePlan) => {
                    history.push(
                      `/${tenant}/purchase-plan/${paramsPurchasePlanId}/bom-elements/${purchasePlanBomElement?.bomElementId}`
                    )
                  }}
                  fields={[
                    {
                      key: 'icon',
                      label: '',
                      sorter: false,
                      filter: false,
                      _style: {
                        width: '50px',
                        display: !purchasePlansState.purchasePlans.length ? 'none' : '',
                      },
                    },
                    {
                      key: 'bomElementName',
                      label: 'Element',
                      _style: { width: '320px' },
                    },
                    {
                      key: 'mpn',
                      label: 'MPN',
                      _style: { width: '240px' },
                    },
                    { key: 'bomNames', label: 'BOMy', _style: { width: '160px' } },
                    {
                      key: 'supplierName',
                      label: 'Dostawca',
                      _style: { width: '80px' },
                    },
                    {
                      key: 'plannedQuantity',
                      label: 'Ilość',
                      _style: { width: '130px' },
                    },
                    {
                      key: 'price',
                      label: 'Cena',
                      _style: { width: '90px' },
                    },
                    {
                      key: 'priceCurrency',
                      label: 'CCY',
                      _style: { width: '60px' },
                    },
                    {
                      key: 'status',
                      label: 'Status',
                      _style: { width: '100px' },
                    },
                    {
                      key: 'warehouseState',
                      label: 'Magazyn',
                      _style: { width: '100px' },
                    },
                    {
                      key: 'expirationDate',
                      label: 'Ważność oferty',
                      _style: { width: '170px' },
                    },
                    {
                      key: 'generateOrderCheckbox',
                      label: '',
                      _style: { width: '50px' },
                    },
                  ]}
                  items={memoizedBomElementsInTable}
                  noItemsViewSlot={
                    <div className="no-items-in-table">
                      {getEmptyListMessage(
                        purchasePlansState.singlePurchasePlanFetchError,
                        purchasePlansState.isSinglePurchasePlanLoading,
                        'elementów',
                        purchasePlansState?.singlePurchasePlan?.purchasePlanBomElements?.length
                      )}
                    </div>
                  }
                  scopedSlots={{
                    generateOrderCheckbox: (
                      item: actionTypes.TBomElementInPurchasePlan,
                      indexOfItem: number
                    ) => (
                      <td style={{ pointerEvents: 'none' }}>
                        <CInput
                          type="checkbox"
                          className="table-row-checkbox"
                          onClick={(event: SyntheticEvent) => {
                            // This line blocks the opening of a modal with details on table row click
                            event.stopPropagation()
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            let mutableOrderInfo: actionTypes.TGenerateOrderPayload = orderInfo

                            if (event.currentTarget.checked) {
                              mutableOrderInfo.bomElementsWithOffer.push({
                                bomElementId: item.bomElementId,
                                offerId: item.offerId,
                                supplierId: item.supplierId,
                                uuid: item.uuid,

                                bomElementName: item.bomElementName,
                                offerQuantity: item.offerQuantity,
                                plannedQuantity: item.plannedQuantity,
                                warehouseState: item.warehouseState,
                              })

                              setOrderInfo((prevState: actionTypes.TGenerateOrderPayload) => ({
                                ...prevState,
                                bomElementsWithOffer: mutableOrderInfo.bomElementsWithOffer,
                              }))
                            } else {
                              mutableOrderInfo.bomElementsWithOffer =
                                mutableOrderInfo.bomElementsWithOffer.filter(
                                  (bomElementWithOffer: actionTypes.TBomElementWithOffer) =>
                                    bomElementWithOffer.bomElementId !== item.bomElementId
                                )

                              setOrderInfo((prevState: actionTypes.TGenerateOrderPayload) => ({
                                ...prevState,
                                bomElementsWithOffer: mutableOrderInfo.bomElementsWithOffer,
                              }))
                            }

                            event.stopPropagation()
                          }}
                          checked={Boolean(
                            orderInfo?.bomElementsWithOffer.find(
                              (bomElementPreparedToGenerate: actionTypes.TBomElementWithOffer) =>
                                bomElementPreparedToGenerate.uuid === item.uuid
                            )
                          )}
                          style={{ pointerEvents: 'all' }}
                          disabled={
                            !authState?.authData?.roles?.includes('PurchasePlans_write') ||
                            item.supplierId
                              ? orderInfo.bomElementsWithOffer.length
                                ? item.supplierId !== orderInfo?.bomElementsWithOffer[0]?.supplierId
                                : false
                              : true
                          }
                        />
                      </td>
                    ),
                  }}
                />

                {isPurchasePlanEditable && (
                  <div className="d-flex justify-content-end mb-3 mt-2">
                    {purchasePlansState?.generateOrderError && (
                      <CustomErrorMessage
                        wrapperClassNames="mr-5"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'create',
                          purchasePlansState?.generateOrderError?.status
                        )}
                      />
                    )}
                    <div className="d-flex">
                      {/* <CButton
                        variant={!orderInfo.bomElementsWithOffer.length ? 'outline' : ''}
                        color="info"
                        type="button"
                        disabled={!orderInfo.bomElementsWithOffer.length}
                        style={{ paddingRight: '25px', paddingLeft: '25px' }}
                        onClick={() => {
                          //  orderInfo =     orderInfo?.bomElementsWithOffer.map((bomElementWithOffer: actionTypes.TBomElementWithOffer) => ({...bomElementWithOffer, }))
                          // let mutableOrderInfo: actionTypes.TGenerateOrderPayload = orderInfo
                          // mutableOrderInfo.bomElementsWithOffer.push({
                          //   bomElementId: item.bomElementId,
                          //   offerId: item.offerId,
                          //   supplierId: item.supplierId,
                          //   uuid: item.uuid,
                          // })
                          // setOrderInfo((prevState: actionTypes.TGenerateOrderPayload) => ({
                          //   ...prevState,
                          //   bomElementsWithOffer: mutableOrderInfo.bomElementsWithOffer,
                          // }))
                        }}
                      >
                        Zaznacz wszystko
                      </CButton> */}

                      <CButton
                        variant={!orderInfo.bomElementsWithOffer.length ? 'outline' : ''}
                        color="info"
                        type="button"
                        disabled={
                          !orderInfo.bomElementsWithOffer.length ||
                          purchasePlansState.isOrderGenerating
                        }
                        style={{ paddingRight: '25px', paddingLeft: '25px' }}
                        onClick={() => {
                          confirmAlert({
                            closeOnEscape: true,
                            customUI: ({ onClose }) => {
                              return (
                                <ConfirmActionModal
                                  mode="generateOrder"
                                  onClose={onClose}
                                  confirmMessageJSX={
                                    <>
                                      <ul
                                        className="d-flex flex-column align-items-left pl-3 pt-2 mb-0"
                                        style={{ listStyle: 'none' }}
                                      >
                                        {orderInfo.bomElementsWithOffer.map(
                                          (
                                            bomElementWithOffer: actionTypes.TBomElementWithOffer
                                          ) => (
                                            <li
                                              key={bomElementWithOffer.bomElementId}
                                              className="pt-2"
                                            >
                                              <h4>
                                                <strong>
                                                  {bomElementWithOffer.bomElementName}
                                                </strong>
                                              </h4>
                                              <p className="mb-1">
                                                Potrzebna ilość:{' '}
                                                <strong>
                                                  {bomElementWithOffer.plannedQuantity}
                                                </strong>
                                              </p>
                                              <p className="mb-1">
                                                Ilość na magazynie:{' '}
                                                <strong>
                                                  {bomElementWithOffer.warehouseState}
                                                </strong>
                                              </p>
                                              <p className="mb-1">
                                                Oferta nr{' '}
                                                <strong>
                                                  {offersState.offers.find(
                                                    (offer: actionTypes.TLightOfferInTable) =>
                                                      offer.id === bomElementWithOffer.offerId
                                                  )?.offerNumber || '...'}
                                                </strong>
                                              </p>
                                              <p>
                                                Ilość na ofercie:{' '}
                                                <strong>
                                                  {offersState.offers.find(
                                                    (offer: actionTypes.TLightOfferInTable) =>
                                                      offer.id === bomElementWithOffer.offerId
                                                  )?.quantity || '...'}
                                                </strong>
                                              </p>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  }
                                  onConfirmFunction={() =>
                                    dispatch({
                                      type: actionTypes.GENERATE_ORDER_REQUESTED,
                                      payload: {
                                        tenant: tenant,
                                        token: authState.authData?.token,
                                        orderInfo: orderInfo,
                                      },
                                    })
                                  }
                                />
                              )
                            },
                          })
                        }}
                      >
                        {purchasePlansState.isOrderGenerating ? (
                          <>
                            {'Generowanie zamówienia z zaznaczonych elementów!'}
                            <ThreeDots />
                          </>
                        ) : purchasePlansState.isOrderGenerated ? (
                          'Wygenerowano zamówienie!'
                        ) : (
                          'Generuj zamówienie z zaznaczonych elementów'
                        )}
                      </CButton>
                    </div>
                  </div>
                )}
              </CTabPane>

              {/*
               * Implementation Costs Tab
               */}

              <CTabPane data-tab="implementation-costs">
                <CDataTable
                  sorterValue={
                    purchasePlansState?.purchasePlanDetailsImplementationCostsTableSorterState
                  }
                  onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                    if (purchasePlansState?.singlePurchasePlan?.purchasePlanImplementationCosts) {
                      convertNumbersInTableRows(
                        purchasePlansState?.singlePurchasePlan?.purchasePlanImplementationCosts,
                        [3],
                        []
                      )
                    }

                    dispatch({
                      type: actionTypes.SET_PURCHASE_PLAN_DETAILS_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE,
                      tableSorterState: sorterState,
                    })
                  }}
                  onTableFilterChange={(tableFilterState: string) => {
                    if (purchasePlansState?.singlePurchasePlan?.purchasePlanImplementationCosts) {
                      convertNumbersInTableRows(
                        purchasePlansState?.singlePurchasePlan?.purchasePlanImplementationCosts,
                        [3],
                        []
                      )
                    }
                  }}
                  loading={purchasePlansState.isSinglePurchasePlanLoading}
                  striped={
                    purchasePlansState.singlePurchasePlan?.purchasePlanImplementationCosts
                      ? !!purchasePlansState.singlePurchasePlan.purchasePlanImplementationCosts
                          .length
                      : false
                  }
                  addTableClasses="purchase-plan-items-table purchase-plan-implementation-costs-table "
                  border
                  sorter
                  tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                  cleaner
                  onRowClick={(
                    purchasePlanImplementationCost: actionTypes.TImplementationCostInPurchasePlan
                  ) => {
                    history.push(
                      `/${tenant}/purchase-plan/${paramsPurchasePlanId}/implementation-costs/${purchasePlanImplementationCost?.implementationCostId}`
                    )
                  }}
                  fields={[
                    {
                      key: 'icon',
                      label: '',
                      sorter: false,
                      filter: false,
                      _style: {
                        width: '50px',
                        display: !purchasePlansState.purchasePlans.length ? 'none' : '',
                      },
                    },
                    {
                      key: 'implementationCostName',
                      label: 'Nazwa',
                      _style: { width: '400px' },
                    },
                    {
                      key: 'supplierName',
                      label: 'Dostawca',
                      _style: { width: '310px' },
                    },
                    {
                      key: 'estimatedPrice',
                      label: 'Cena',
                      _style: { width: '100px' },
                    },
                    {
                      key: 'estimatedPriceCurrency',
                      label: 'CCY',
                      _style: { width: '60px' },
                    },
                    {
                      key: 'bomElementName',
                      label: 'Element BOM',
                      _style: { width: '400px' },
                    },
                  ]}
                  items={memoizedImplementationCostsInTable || []}
                  noItemsViewSlot={
                    <div className="no-items-in-table">
                      {getEmptyListMessage(
                        purchasePlansState.singlePurchasePlanFetchError,
                        purchasePlansState.isSinglePurchasePlanLoading,
                        'toolingu',
                        purchasePlansState?.singlePurchasePlan?.purchasePlanImplementationCosts
                          ?.length
                      )}
                    </div>
                  }
                />
              </CTabPane>

              {/*
               * Services Tab
               */}

              <CTabPane data-tab="services">
                <CDataTable
                  sorterValue={purchasePlansState?.purchasePlanDetailsServicesTableSorterState}
                  onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                    if (purchasePlansState?.singlePurchasePlan?.purchasePlanServices) {
                      convertNumbersInTableRows(
                        purchasePlansState?.singlePurchasePlan?.purchasePlanServices,
                        [5],
                        [4]
                      )
                    }

                    dispatch({
                      type: actionTypes.SET_PURCHASE_PLAN_DETAILS_SERVICES_TABLE_SORTER_STATE,
                      tableSorterState: sorterState,
                    })
                  }}
                  onTableFilterChange={(tableFilterState: string) => {
                    if (purchasePlansState?.singlePurchasePlan?.purchasePlanServices) {
                      convertNumbersInTableRows(
                        purchasePlansState?.singlePurchasePlan?.purchasePlanServices,
                        [5],
                        [4]
                      )
                    }
                  }}
                  loading={purchasePlansState.isSinglePurchasePlanLoading}
                  striped={
                    purchasePlansState.singlePurchasePlan?.purchasePlanServices
                      ? !!purchasePlansState.singlePurchasePlan.purchasePlanServices.length
                      : false
                  }
                  addTableClasses="purchase-plan-items-table purchase-plan-services-table "
                  border
                  sorter
                  tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                  cleaner
                  onRowClick={(purchasePlanService: actionTypes.TServiceInPurchasePlan) => {
                    history.push(
                      `/${tenant}/purchase-plan/${paramsPurchasePlanId}/services/${purchasePlanService?.serviceId}`
                    )
                  }}
                  fields={[
                    {
                      key: 'icon',
                      label: '',
                      sorter: false,
                      filter: false,
                      _style: {
                        width: '50px',
                        display: !purchasePlansState.purchasePlans.length ? 'none' : '',
                      },
                    },
                    {
                      key: 'serviceName',
                      label: 'Nazwa',
                      _style: { width: '500px' },
                    },
                    {
                      key: 'supplierName',
                      label: 'Dostawca',
                      _style: { width: '510px' },
                    },
                    {
                      key: 'bomNames',
                      label: 'BOM',
                      _style: { width: '500px' },
                    },
                    {
                      key: 'estimatedQuantity',
                      label: 'Ilość',
                      _style: { width: '100px' },
                    },
                    {
                      key: 'estimatedPrice',
                      label: 'Cena',
                      _style: { width: '100px' },
                    },
                    {
                      key: 'estimatedPriceCurrency',
                      label: 'CCY',
                      _style: { width: '60px' },
                    },
                  ]}
                  items={memoizedServicesInTable || []}
                  noItemsViewSlot={
                    <div className="no-items-in-table">
                      {getEmptyListMessage(
                        purchasePlansState.singlePurchasePlanFetchError,
                        purchasePlansState.isSinglePurchasePlanLoading,
                        'usług',
                        purchasePlansState?.singlePurchasePlan?.purchasePlanServices?.length
                      )}
                    </div>
                  }
                />
              </CTabPane>
            </CTabContent>
          </CTabs>
        </CCardBody>
      </CCard>

      {purchasePlansState.purchasePlanBomElementModalVisibility && (
        <PurchasePlanDetailsModal
          section="bom-elements"
          closeAction={actionTypes.CLOSE_PURCHASE_PLAN_BOM_ELEMENT_MODAL}
          modalError={purchasePlansState.purchasePlanBomElementModalError}
          modalTabs={purchasePlansState.purchasePlanBomElementModalTabs}
          loadingState={purchasePlansState.isPurchasePlanBomElementLoading}
        />
      )}

      {purchasePlansState.purchasePlanServiceModalVisibility && (
        <PurchasePlanDetailsModal
          section="services"
          closeAction={actionTypes.CLOSE_PURCHASE_PLAN_SERVICE_MODAL}
          modalError={purchasePlansState.purchasePlanServiceModalError}
          modalTabs={purchasePlansState.purchasePlanServiceModalTabs}
          loadingState={purchasePlansState.isPurchasePlanServiceLoading}
        />
      )}

      {purchasePlansState.purchasePlanImplementationCostModalVisibility && (
        <PurchasePlanDetailsModal
          section="implementation-costs"
          closeAction={actionTypes.CLOSE_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL}
          modalError={purchasePlansState.purchasePlanImplementationCostModalError}
          modalTabs={purchasePlansState.purchasePlanImplementationCostModalTabs}
          loadingState={purchasePlansState.isPurchasePlanImplementationCostLoading}
        />
      )}
    </Container>
  )
}

export default PurchasePlanDetails
