import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertDateInTableRows,
  coverTimestamp,
  getEmptyListMessage,
  makeSearchBoxFixed,
  toDateTableValue,
} from '../../utils'
import { PurchasePlanCreateFromBomsModal } from './purchase-plans-create-from-boms-modal'
import { ListActionButton } from '../../components'
import { ListStickyErrorMessage } from '../../components/list-sticky-error-message'

const PurchasePlans: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()
  let location = useLocation()

  const authState = useSelector((state: TRootState) => state.auth)
  const purchasePlansState = useSelector((state: TRootState) => state.purchasePlans)
  const bomsState = useSelector((state: TRootState) => state.boms)

  // Track this when the order of columns in table change
  let dateColumnNumber = 2

  // Open Create From BOMs Modal
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      location.pathname.includes(`/${tenant}/purchase-plans/create-from-boms`) &&
      !purchasePlansState.purchasePlanCreateFromBomsModalVisibility &&
      !bomsState.areBomsLoading
    ) {
      dispatch({ type: actionTypes.OPEN_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL })
    }
  }, [
    dispatch,
    tenant,
    authState,
    history,
    location.pathname,
    purchasePlansState.purchasePlanCreateFromBomsModalVisibility,
    bomsState.areBomsLoading,
  ])

  // Close Purchase Plan Create From BOMs Modal when going back in browser history
  useEffect(() => {
    if (
      purchasePlansState.purchasePlanCreateFromBomsModalVisibility &&
      !location.pathname.includes(`/${tenant}/purchase-plans/create-from-boms`)
    ) {
      dispatch({
        type: actionTypes.CLOSE_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL,
      })
    }
  }, [
    dispatch,
    tenant,
    purchasePlansState.purchasePlanCreateFromBomsModalVisibility,
    location.pathname,
  ])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      purchasePlansState?.purchasePlans?.map((purchasePlan: actionTypes.TPurchasePlan) => {
        return {
          ...purchasePlan,
          createDate: purchasePlan?.createDate || '',
          bomNames: purchasePlan?.bomNames?.join(';\n') || '',
          productionStartDate: toDateTableValue(purchasePlan?.productionStartDate) || '',
          firstPossibleProductionStartDate:
            toDateTableValue(purchasePlan?.firstPossibleProductionStartDate) || '',
          lastPossibleOrderDate: toDateTableValue(purchasePlan?.lastPossibleOrderDate) || '',
          icon: '🖊️',
        }
      }),
    [purchasePlansState.purchasePlans]
  )

  useEffect(() => {
    convertDateInTableRows(purchasePlansState.purchasePlans, [dateColumnNumber])
  }, [memoizedTableItems, purchasePlansState.tableFilterState, purchasePlansState.tableSorterState])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-between">
      <CCard>
        <CCardBody className="pb-2">
          {authState?.authData?.roles?.includes('PurchasePlans_write') && (
            <div
              style={{ zIndex: 10 }}
              className="d-flex justify-content-end list-fixed-create-new-button-wrapper"
            >
              <CButton
                color="info"
                variant="outline"
                onClick={() => {
                  history.push(`/${tenant}/purchase-plans/create-from-boms`)
                }}
              >
                Nowe Zapotrzebowanie
              </CButton>
            </div>
          )}

          {purchasePlansState?.purchasePlanDeleteError && (
            <ListStickyErrorMessage
              listErrorMessage={`Wystąpił ${
                purchasePlansState?.purchasePlanDeleteError?.status
                  ? `błąd ${purchasePlansState?.purchasePlanDeleteError?.status}`
                  : 'nieznany błąd'
              } podczas usuwania zapotrzebowania!`}
            />
          )}

          <CDataTable
            tableFilterValue={purchasePlansState?.tableFilterState}
            sorterValue={purchasePlansState?.tableSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              coverTimestamp(purchasePlansState.purchasePlans, [dateColumnNumber])

              dispatch({
                type: actionTypes.SET_PURCHASE_PLANS_TABLE_SORTER_STATE,
                tableSorterState: sorterState,
              })
            }}
            onTableFilterChange={(tableFilterState: string) => {
              coverTimestamp(purchasePlansState.purchasePlans, [dateColumnNumber])

              dispatch({
                type: actionTypes.SET_PURCHASE_PLANS_TABLE_FILTER_STATE,
                tableFilterState: tableFilterState,
              })
            }}
            loading={purchasePlansState.arePurchasePlansLoading}
            striped={
              purchasePlansState.purchasePlans ? !!purchasePlansState.purchasePlans.length : false
            }
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
            cleaner
            onRowClick={(purchasePlan: actionTypes.TPurchasePlan) => {
              history.push(`/${tenant}/purchase-plan/${purchasePlan.id}/bom-elements`)
            }}
            addTableClasses="vertical-middle-list-table purchase-plans-list-table"
            fields={[
              {
                key: 'icon',
                label: '',
                sorter: false,
                filter: false,
                _style: {
                  width: '40px',
                  display: !purchasePlansState.purchasePlans.length ? 'none' : '',
                },
              },
              {
                key: 'number',
                label: 'Numer',
                _style: { width: '140px' },
              },
              {
                key: 'createDate',
                label: 'Data utworzenia',
                _style: { width: '160px' },
              },
              {
                key: 'bomNames',
                label: 'BOM / Element',
                _style: { width: '1fr' },
              },
              {
                key: 'productionStartDate',
                label: 'Systemowy start produkcji',
                _style: { width: '170px' },
              },
              {
                key: 'firstPossibleProductionStartDate',
                label: 'Planowany start produkcji',
                _style: { width: '170px' },
              },
              {
                key: 'lastPossibleOrderDate',
                label: 'Data najpóźniejszego zamówienia',
                _style: { width: '190px' },
              },
              {
                key: 'delete',
                label: '',
                _style: { width: '45px' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {getEmptyListMessage(
                  purchasePlansState.fetchPurchasePlansError,
                  purchasePlansState.arePurchasePlansLoading,
                  'zapotrzebowań',
                  purchasePlansState?.purchasePlans?.length
                )}
              </div>
            }
            scopedSlots={{
              delete: (itemFromList: actionTypes.TPurchasePlanLight) => (
                <td
                  className="text-center"
                  style={{ pointerEvents: 'none' }}
                  onClick={(event: SyntheticEvent) => {
                    if (!itemFromList.canDelete) {
                      event.preventDefault()
                      event.stopPropagation()
                      return null
                    } else {
                      return undefined
                    }
                  }}
                >
                  <ListActionButton
                    mainActionName="delete"
                    buttonColor="danger"
                    classNames="delete-list-item-button"
                    typeOfAction={actionTypes.DELETE_PURCHASE_PLAN_REQUESTED}
                    iconName="cil-trash"
                    itemFromList={{
                      id: itemFromList.id,
                      name: itemFromList.number,
                      canDelete: itemFromList.canDelete,
                    }}
                    currentlyManagedItemId={purchasePlansState.currentlyManagedPurchasePlanId}
                    // Change this when those two options will be added later maybe
                    isItemCopying={false}
                    isItemArchiving={false}
                    isItemDeleting={purchasePlansState.isPurchasePlanDeleting}
                  />
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>

      {purchasePlansState.purchasePlanCreateFromBomsModalVisibility && (
        <PurchasePlanCreateFromBomsModal />
      )}
    </Container>
  )
}

export default PurchasePlans
