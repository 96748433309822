import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  serviceCall,
  sectionFetch,
  itemAttachmentsFetch,
  deleteItemCall,
  addSingleAttachmentCall,
  singleItemFetch,
} from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration, editSuccessMessageDuration } from '../../utils'

export function* fetchServices(action: actionTypes.fetchServicesAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'services')

    yield put({ type: actionTypes.FETCH_SERVICES_SUCCEEDED, services: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SERVICES_ERROR,
      error: error?.response,
    })
  }
}

export function* createService(action: actionTypes.createServiceAction) {
  try {
    const response = yield call(serviceCall, action.payload)

    yield put({
      type: actionTypes.CREATE_SERVICE_SUCCEEDED,
      service: { ...action.payload.service, id: response.data.id },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_SERVICE_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_SERVICE_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.SERVICE_DETAILS_ACTIONS_UNLOCK,
    })
  }
}

export function* editService(action: actionTypes.editServiceAction) {
  try {
    yield call(serviceCall, action.payload)
    yield put({
      type: actionTypes.EDIT_SERVICE_SUCCEEDED,
      service: action.payload.service,
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.SERVICE_DETAILS_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_SERVICE_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.SERVICE_DETAILS_ACTIONS_UNLOCK,
    })
  }
}

export function* deleteService(action: actionTypes.deleteServiceAction) {
  try {
    yield call(deleteItemCall, action.payload, 'services')
    yield put({
      type: actionTypes.DELETE_SERVICE_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_SERVICE_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_SERVICE_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.SERVICE_DETAILS_ACTIONS_UNLOCK,
    })
  }
}

export function* fetchServiceBoms(action: actionTypes.fetchServiceBomsAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `services/getServiceBoms/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_SERVICE_BOMS_SUCCEEDED,
      serviceBoms: response?.data?.boms || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SERVICE_BOMS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchServiceOffers(action: actionTypes.fetchServiceOffersAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `services/getServiceOffers/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_SERVICE_OFFERS_SUCCEEDED,
      serviceOffers: response?.data?.offers || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SERVICE_OFFERS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchServiceOrders(action: actionTypes.fetchServiceOrdersAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `services/getServiceOrders/${action.payload.id}`
    )

    yield put({
      type: actionTypes.FETCH_SERVICE_ORDERS_SUCCEEDED,
      serviceOrders: response?.data?.orders || response?.data || [],
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SERVICE_ORDERS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchServiceAttachments(action: actionTypes.fetchServiceAttachmentsAction) {
  try {
    const response = yield call(itemAttachmentsFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_SERVICE_ATTACHMENTS_SUCCEEDED,
      serviceAttachments: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SERVICE_ATTACHMENTS_ERROR,
      error: error?.response,
    })
  }
}

export function* uploadServiceAttachment(action: actionTypes.uploadServiceAttachmentAction) {
  try {
    const response = yield call(addSingleAttachmentCall, action.payload)

    yield put({
      type: actionTypes.UPLOAD_SERVICE_ATTACHMENT_SUCCEEDED,
      attachment: {
        id: response.data.id,
        name: response.data?.name || '',
      },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.SERVICE_DETAILS_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.UPLOAD_SERVICE_ATTACHMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* servicesSaga() {
  yield takeLatest(actionTypes.FETCH_SERVICES_REQUESTED, fetchServices)
  yield takeLatest(actionTypes.FETCH_SERVICE_BOMS_REQUESTED, fetchServiceBoms)
  yield takeLatest(actionTypes.FETCH_SERVICE_OFFERS_REQUESTED, fetchServiceOffers)
  yield takeLatest(actionTypes.FETCH_SERVICE_ORDERS_REQUESTED, fetchServiceOrders)
  yield takeLatest(actionTypes.FETCH_SERVICE_ATTACHMENTS_REQUESTED, fetchServiceAttachments)
  yield takeLatest(actionTypes.UPLOAD_SERVICE_ATTACHMENT_REQUESTED, uploadServiceAttachment)
  yield takeLatest(actionTypes.CREATE_SERVICE_REQUESTED, createService)
  yield takeLatest(actionTypes.DELETE_SERVICE_REQUESTED, deleteService)
  yield takeLatest(actionTypes.EDIT_SERVICE_REQUESTED, editService)
}
