import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, ErrorMessage, Field, FastField } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton, CDataTable, CLabel } from '@coreui/react'
import { Select } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { confirmAlert } from 'react-confirm-alert'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  BasicFormField,
  CustomErrorMessage,
  ThreeDots,
  FormActionCancelButton,
  ConfirmActionModal,
} from '../../../components'
import {
  getErrorMessageFromStatus,
  globalThousandSeparator,
  globalDecimalSeparator,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  convertNumericStringToNumber,
  preventNavigationChange,
  convertNumbersInTableRows,
  toDateTimeInputValue,
  successMessageDuration,
} from '../../../utils'
import { lengthOfWarehouseNameInConfirmModal } from './warehouse-management'
import { Typeahead } from 'react-bootstrap-typeahead'

export const ManufacturingReplenishment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{
    tenant: string
    id: string
  }>()
  const paramsManagedWarehouseId = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [
    isManufacturingReplenishmentModifiedAndUnsaved,
    setIsManufacturingReplenishmentModifiedAndUnsaved,
  ] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)
  const manufacturingState = useSelector((state: TRootState) => state.manufacturing)
  const usersState = useSelector((state: TRootState) => state.users)

  const selectManufacturingErrandPlaceholderValue = ''
  const resetErrorsDelay = 300

  const [
    manufacturingReplenishmentBomIntermediatesFilterState,
    setManufacturingReplenishmentBomIntermediatesFilterState,
  ] = useState<actionTypes.TFilterState>(undefined)
  const [
    manufacturingReplenishmentBomIntermediatesSorterState,
    setManufacturingReplenishmentBomIntermediatesSorterState,
  ] = useState<actionTypes.TSorterState>({ column: '', asc: true })

  const [
    manufacturingReplenishmentElementsFilterState,
    setManufacturingReplenishmentElementsFilterState,
  ] = useState<actionTypes.TFilterState>(undefined)
  const [
    manufacturingReplenishmentElementsSorterState,
    setManufacturingReplenishmentElementsSorterState,
  ] = useState<actionTypes.TSorterState>({ column: '', asc: true })

  const closeManufacturingReplenishment = useCallback(() => {
    history.push(`/${tenant}/warehouse-management/${paramsManagedWarehouseId}`)
  }, [dispatch, history, tenant, paramsManagedWarehouseId])

  const isWarehouseEditable = authState?.authData?.roles?.includes('Warehouses_write')

  // Fetch warehouse inventories if they were not fetched before
  useEffect(() => {
    if (
      location.pathname.includes('manufacturing-replenishment') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      isWarehouseEditable
    ) {
      dispatch({
        type: actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    warehousesState.isManufacturingReplenishmentDocumentGenerated,
  ])

  // Fetch warehouses if they were not fetched before
  useEffect(() => {
    if (
      tenant &&
      authState?.isAuthenticated &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      !warehousesState?.warehouses?.length
    ) {
      dispatch({
        type: actionTypes.FETCH_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    warehousesState?.warehouses,
  ])

  const emptyList = []

  const generateReplenishmentDetails = (
    warehouseId: number,
    manufacturingErrandId: number | null
  ) => {
    if (manufacturingState?.manufacturingErrands) {
      const manufacturingErrand: any = manufacturingState.manufacturingErrands.find(
        (manufacturingErrand: actionTypes.TManufacturingErrand) =>
          manufacturingErrandId === manufacturingErrand.id
      ) as actionTypes.TManufacturingErrand

      const inventoryOfSelectedWarehouse:
        | actionTypes.IInventoriesOfWarehousesDataItem[]
        | undefined = warehousesState?.inventoriesOfWarehouses?.filter(
        (inventoryItem: actionTypes.IInventoriesOfWarehousesDataItem) =>
          inventoryItem.warehouseId === warehouseId
      )

      if (manufacturingErrand && manufacturingErrand !== undefined) {
        // This is needed to display the generated values in table properly
        setTimeout(() => convertNumbersInTableRows(['array'], [], [2]), 100)

        const bomElementsList = manufacturingErrand?.bomElements?.map(
          (bomElementItem: actionTypes.IBomFullElement, bomElementItemIndex: number) => {
            const foundWarehouseQuantity = inventoryOfSelectedWarehouse?.find(
              (
                inventoryItem: actionTypes.IInventoriesOfWarehousesDataItem,
                inventoryItemIndex: number
              ) => inventoryItem.bomElementId === bomElementItem.bomElementId
            )?.warehouseQuantity

            return {
              bomElementId: bomElementItem.bomElementId,
              bomElementName: bomElementItem.bomElementName || '',
              type: actionTypes.materialsTypeNumber,
              warehouseQuantity:
                // Never convert those values to string here with convertQuantityToNumericString. This destroys the table sorter.
                // Also turn all negative values to 0 for better validation because converter turns them to positive string numbers

                Number(foundWarehouseQuantity) >= 0 ? foundWarehouseQuantity : 0,
              quantity: '',
              position: `${bomElementItemIndex + 1}.`,
              hasChanged: false,
            }
          }
        )

        const bomIntermediatesList = manufacturingErrand?.bomIntermediates?.map(
          (bomIntermediateItem: actionTypes.IBomIntermediate, bomIntermediateItemIndex: number) => {
            const foundWarehouseQuantity = inventoryOfSelectedWarehouse?.find(
              (
                inventoryItem: actionTypes.IInventoriesOfWarehousesDataItem,
                inventoryItemIndex: number
                // This should be probably bomIntermediateId
              ) => String(inventoryItem.bomElementId) === bomIntermediateItem.uuid
            )?.warehouseQuantity

            return {
              bomIntermediateId:
                bomIntermediateItem?.bomIntermediateId || bomIntermediateItem?.uuid,
              bomIntermediateName:
                bomIntermediateItem?.bomIntermediateName || bomIntermediateItem?.name || '',
              type: actionTypes.bomIntermediatesTypeNumber,
              warehouseQuantity:
                // Never convert those values to string here with convertQuantityToNumericString. This destroys the table sorter.
                // Also turn all negative values to 0 for better validation because converter turns them to positive string numbers

                Number(foundWarehouseQuantity) >= 0 ? foundWarehouseQuantity : 0,
              quantity: '',
              position: `${bomIntermediateItemIndex + 1}.`,
              hasChanged: false,
            }
          }
        )

        return [...(bomElementsList || []), ...(bomIntermediatesList || [])]
      }
    } else {
      return emptyList
    }
  }

  useEffect(() => {
    if (
      location.pathname.includes('manufacturing-replenishment') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      isWarehouseEditable &&
      paramsManagedWarehouseId
    ) {
      dispatch({
        type: actionTypes.SET_CURRENTLY_MANAGED_WAREHOUSE_ID,
        currentlyManagedWarehouseId: Number(paramsManagedWarehouseId),
      })

      dispatch({
        type: actionTypes.CLEAR_MANUFACTURING_REPLENISHMENT_ERROR,
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    paramsManagedWarehouseId,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isManufacturingReplenishmentModifiedAndUnsaved,
      isWarehouseEditable,
      'replenishment',
      paramsManagedWarehouseId,
      ''
    )

    // Without pathname in location there is no tab change detection
  }, [
    location.pathname,
    history,
    isManufacturingReplenishmentModifiedAndUnsaved,
    isWarehouseEditable,
  ])

  // Convert numbers to numeric strings in table
  useEffect(() => {
    convertNumbersInTableRows(['array'], [], [2])
  }, [
    paramsManagedWarehouseId,
    manufacturingReplenishmentBomIntermediatesSorterState,
    manufacturingReplenishmentBomIntermediatesFilterState,
    manufacturingReplenishmentElementsSorterState,
    manufacturingReplenishmentElementsFilterState,
  ])

  // Close manufacturing replenishment if document was generated and fetch manufacturing errands again
  useEffect(() => {
    if (warehousesState.isManufacturingReplenishmentDocumentGenerated) {
      setIsManufacturingReplenishmentModifiedAndUnsaved(false)

      setTimeout(() => {
        closeManufacturingReplenishment()
      }, successMessageDuration)
    }
  }, [warehousesState.isManufacturingReplenishmentDocumentGenerated])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_MANUFACTURING_REPLENISHMENT,
      })
    }
  }, [])

  const ManufacturingReplenishmentElementsSchema = Yup.object().shape({
    referenceNumber: Yup.string().required('To pole jest wymagane!'),
    manufacturingErrandId: Yup.string().required('To pole jest wymagane!'),
    userId: Yup.string().required('To pole jest wymagane!'),
    replenishmentDetails: Yup.array().of(
      Yup.object().shape({
        warehouseQuantity: Yup.number().nullable(),
        quantity: Yup.number()
          .nullable()
          .test({
            name: 'max',
            exclusive: true,
            params: {},
            message: 'Ponad stan!',
            test: function (value) {
              if (value) {
                return (
                  Number((this as any)?.parent?.warehouseQuantity) >=
                  Number(convertNumericStringToNumber(value))
                )
              } else {
                return true
              }
            },
          }),
      })
    ),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="warehouse-details">
          <h4
            className={`text-center ${
              !warehousesState?.manufacturingReplenishmentDocumentGenerateError ? 'mb-4' : 'mb-3'
            } pb-1`}
          >
            {authState?.authData?.roles?.includes('Warehouses_write') ? 'Wydanie na produkcję' : ''}
          </h4>

          <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
            <FormActionCancelButton
              closeFunction={closeManufacturingReplenishment}
              closeAction={''}
            />
          </div>

          {warehousesState?.areInventoriesOfWarehousesLoading &&
          warehousesState?.areWarehousesLoading &&
          manufacturingState?.areManufacturingErrandsLoading ? (
            <div
              style={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                date: toDateTimeInputValue(new Date().toISOString()),
                referenceNumber: '',
                warehouseId: Number(paramsManagedWarehouseId),
                manufacturingErrandId: '',
                userId: '',
                replenishmentDetails: generateReplenishmentDetails(
                  Number(paramsManagedWarehouseId),
                  null
                ),
              }}
              validationSchema={ManufacturingReplenishmentElementsSchema}
              onSubmit={(values) => {
                if (isWarehouseEditable) {
                  setDidFormValidationOccur(true)

                  const replenishmentDetails: actionTypes.TManufacturingReplenishmentItem[] | [] =
                    values?.replenishmentDetails
                      ?.map(
                        (replenishmentItem: actionTypes.TManufacturingReplenishmentTableItem) => {
                          return {
                            bomElementId: replenishmentItem?.bomElementId,
                            bomIntermediateId: replenishmentItem?.bomIntermediateId,
                            quantity: Number(
                              convertNumericStringToNumber(replenishmentItem?.quantity)
                            ),
                          }
                        }
                      )
                      ?.filter(
                        (
                          manufacturingReplenishmentItem: actionTypes.TManufacturingReplenishmentItem
                        ) =>
                          !!convertNumericStringToNumber(manufacturingReplenishmentItem?.quantity)
                      ) || []

                  dispatch({
                    type: actionTypes.GENERATE_MANUFACTURING_REPLENISHMENT_DOCUMENT_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      documentData: {
                        date: values.date,
                        referenceNumber: values.referenceNumber,
                        userId: values.userId,
                        warehouseId: Number(values.warehouseId),
                        manufacturingErrandId: Number(values.manufacturingErrandId),
                        replenishmentDetails: replenishmentDetails,
                      },
                    },
                  })
                }
              }}
              enableReinitialize={!isManufacturingReplenishmentModifiedAndUnsaved}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({
                values,
                errors,
                setFieldValue,
                handleChange,
                resetForm,
                setErrors,
                validateForm,
              }) => (
                <Form>
                  {/*
                   * Display Network Error Message
                   */}

                  {warehousesState?.manufacturingReplenishmentDocumentGenerateError && (
                    <CustomErrorMessage
                      wrapperClassNames="mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'create',
                        warehousesState?.manufacturingReplenishmentDocumentGenerateError?.status,
                        'danych wydania na produkcję'
                      )}
                    />
                  )}

                  {usersState?.fetchUsersError && (
                    <CustomErrorMessage
                      wrapperClassNames="mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        usersState?.fetchUsersError?.status,
                        'listy użytkowników'
                      )}
                    />
                  )}

                  {manufacturingState?.fetchManufacturingErrandsError && (
                    <CustomErrorMessage
                      wrapperClassNames="mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        manufacturingState?.fetchManufacturingErrandsError?.status,
                        'zleceń produkcyjnych'
                      )}
                    />
                  )}

                  <div className="settings-view-double-grid-fields">
                    <BasicFormField
                      fieldId="warehouse-management-manufacturing-replenishment-date"
                      fieldLabel="Data i godzina wydania"
                      fieldIcon="cil-calendar"
                      formikFieldName="date"
                      fieldValue={values.date}
                      fieldError={errors.date}
                      fieldType="datetime-local"
                      placeholder=""
                    />

                    <BasicFormField
                      fieldId="warehouse-management-manufacturing-replenishment-document"
                      fieldLabel="Dokument wydania"
                      fieldIcon="cil-short-text"
                      formikFieldName="referenceNumber"
                      fieldValue={values.referenceNumber}
                      fieldError={errors.referenceNumber}
                      fieldType="text"
                      placeholder="Wpisz numer WP"
                    />
                  </div>

                  <div className="settings-view-double-grid-fields">
                    <div>
                      <CLabel
                        htmlFor="warehouse-management-manufacturing-replenishment-from-warehouse-id"
                        className={inputLabelSpacingBottom}
                      >
                        Magazyn źródłowy
                      </CLabel>
                      <InputGroup
                        id="warehouse-management-manufacturing-replenishment-from-warehouse-id"
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={errors.warehouseId && 'text-danger input-error-icon'}
                          >
                            <i className="cil-storage"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <ErrorMessage
                          name="warehouseId"
                          component="span"
                          className="text-danger input-error-message"
                        />
                        <Field
                          as={Select}
                          name="warehouseId"
                          variant="outlined"
                          native
                          value={values?.warehouseId}
                          className={`item-selector element-selector ${
                            errors.warehouseId && 'invalid-native-selector'
                          }`}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            const changeFromWarehouseId = (selectedFromWarehouseId: number) => {
                              handleChange(event)

                              setIsManufacturingReplenishmentModifiedAndUnsaved(false)
                              setDidFormValidationOccur(false)
                              resetForm()

                              if (errors) {
                                setErrors({})
                              }

                              // This is important to change the displayed value in select
                              event.target.value = String(selectedFromWarehouseId)

                              if (selectedFromWarehouseId) {
                                return history.push(
                                  `/${tenant}/warehouse-management/${selectedFromWarehouseId}/manufacturing-replenishment`
                                )
                              } else {
                                setFieldValue('replenishmentDetails', emptyList)
                              }

                              return handleChange(event)
                            }

                            if (isManufacturingReplenishmentModifiedAndUnsaved) {
                              confirmAlert({
                                closeOnEscape: true,
                                // This is a hack, message is the new selected warehouseId passed down to scope
                                message: event?.target?.value,
                                customUI: ({ onClose, message }) => {
                                  return (
                                    <ConfirmActionModal
                                      mode="selectOther"
                                      onClose={onClose}
                                      confirmMessageJSX={
                                        <>
                                          <strong>Masz niezapisane zmiany!</strong>
                                          <br />
                                          Czy na pewno chcesz ustawić{' '}
                                          <strong>
                                            {warehousesState?.warehouses
                                              ?.find(
                                                (warehouse: actionTypes.TWarehouseLight) =>
                                                  Number(message) === warehouse.id
                                              )
                                              ?.name?.substring(
                                                0,
                                                lengthOfWarehouseNameInConfirmModal
                                              ) || 'magazyn bez nazwy'}
                                          </strong>
                                          <br />
                                          jako magazyn źródłowy?
                                        </>
                                      }
                                      onConfirmFunction={() => {
                                        changeFromWarehouseId(Number(message))
                                      }}
                                    />
                                  )
                                },
                              })
                            } else {
                              changeFromWarehouseId(Number(event?.target?.value))
                            }
                          }}
                        >
                          {warehousesState?.warehouses?.map(
                            (warehouse: actionTypes.TWarehouseLight) => {
                              return (
                                <option
                                  key={`from-warehouse-option-${warehouse.id}`}
                                  value={warehouse.id}
                                >
                                  {warehouse?.name || 'Brak nazwy magazynu!'}
                                </option>
                              )
                            }
                          )}
                        </Field>
                      </InputGroup>
                    </div>

                    <div className="manufacturing-replenishment-receiver-wrapper">
                      <CLabel
                        htmlFor="manufacturing-replenishment-receiver"
                        className={`${inputLabelSpacingBottom}`}
                      >
                        Odbierający
                      </CLabel>
                      <InputGroup
                        id="manufacturing-replenishment-receiver"
                        className={`${inputFieldSpacingBottom} flex-nowrap`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={errors.userId && 'text-danger input-error-icon'}
                          >
                            <i className="cil-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <ErrorMessage
                          name="userId"
                          component="span"
                          className="text-danger input-error-message"
                        />

                        <Typeahead
                          placeholder="Wybierz z listy lub wpisz e-mail odbierającego"
                          isInvalid={!!errors.userId}
                          id="manufacturing-replenishment-receiver-typeahead"
                          onChange={(selected: actionTypes.TUser[]) => {
                            if (selected.length > 0) {
                              setFieldValue('userId', selected[0].userId)
                            }
                          }}
                          onInputChange={(text) => {
                            if (text.length > 0) {
                              const matchedUser = usersState.users.find(
                                (user: actionTypes.TUser) =>
                                  user.userName.toLowerCase() === text.toLowerCase()
                              )

                              if (matchedUser) {
                                setFieldValue('userId', matchedUser.userId)
                              } else {
                                setFieldValue('userId', -1)
                              }
                            } else {
                              setFieldValue('userId', undefined)
                            }
                          }}
                          defaultInputValue={''}
                          options={usersState.users}
                          labelKey="userName"
                          emptyLabel="Nie znaleziono użytkowników"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="mb-4">
                    <CLabel
                      htmlFor="warehouse-management-manufacturing-replenishment-manufacturing-errand-id"
                      className={inputLabelSpacingBottom}
                    >
                      Zlecenie produkcyjne
                    </CLabel>
                    <InputGroup
                      id="warehouse-management-manufacturing-replenishment-manufacturing-errand-id"
                      className={`${inputFieldSpacingBottom} dropdown-selector`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          className={errors.manufacturingErrandId && 'text-danger input-error-icon'}
                        >
                          <i className="cil-factory"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <ErrorMessage
                        name="manufacturingErrandId"
                        component="span"
                        className="text-danger input-error-message"
                      />
                      <Field
                        as={Select}
                        variant="outlined"
                        native
                        className={`item-selector element-selector w-100 text-center ${
                          String(values.manufacturingErrandId) ===
                            selectManufacturingErrandPlaceholderValue &&
                          'element-selector-placeholder'
                        } ${errors.manufacturingErrandId && 'invalid-native-selector'}`}
                        name="manufacturingErrandId"
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue(
                            'replenishmentDetails',
                            generateReplenishmentDetails(
                              Number(paramsManagedWarehouseId),
                              Number(event.currentTarget.value)
                            )
                          )

                          if (errors.replenishmentDetails) {
                            validateForm().then((errors) => setErrors(errors))

                            setTimeout(() => {
                              validateForm()
                            }, resetErrorsDelay)
                          }

                          return handleChange(event)
                        }}
                        disabled={!manufacturingState?.manufacturingErrands}
                      >
                        <option
                          className="select-option-placeholder text-center"
                          value={selectManufacturingErrandPlaceholderValue}
                          disabled={false}
                        >
                          {manufacturingState?.areManufacturingErrandsLoading
                            ? 'Wczytywanie zleceń...'
                            : manufacturingState?.manufacturingErrands
                            ? 'Wybierz zlecenie'
                            : 'Brak zleceń do wyboru!'}
                        </option>
                        {manufacturingState?.manufacturingErrands?.map(
                          (manufacturingErrand: actionTypes.TManufacturingErrand) => {
                            return (
                              <option
                                key={`warehouse-finished-product-production-commission-option-${manufacturingErrand.id}`}
                                value={manufacturingErrand.id}
                                className="text-center"
                              >
                                Zlecenie:{' '}
                                {manufacturingErrand?.manufacturingErrandNumber || 'brak nazwy'} |
                                Produkt: {manufacturingErrand?.bomName || 'brak nazwy'}
                              </option>
                            )
                          }
                        )}
                      </Field>
                    </InputGroup>
                  </div>

                  <div className="hidden">
                    <Field
                      type="hidden"
                      name="replenishmentDetails"
                      value={generateReplenishmentDetails(
                        Number(paramsManagedWarehouseId),
                        Number(values.manufacturingErrandId)
                      )}
                    />
                  </div>

                  {/*
                   * Intermediates Table
                   */}

                  <CDataTable
                    tableFilterValue={manufacturingReplenishmentBomIntermediatesFilterState}
                    sorterValue={manufacturingReplenishmentBomIntermediatesSorterState}
                    onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                      setManufacturingReplenishmentBomIntermediatesSorterState(sorterState)

                      if (errors.replenishmentDetails) {
                        validateForm()
                      }
                    }}
                    onTableFilterChange={(tableFilterState: string) => {
                      setManufacturingReplenishmentBomIntermediatesFilterState(tableFilterState)

                      if (errors.replenishmentDetails) {
                        validateForm()
                      }
                    }}
                    loading={false}
                    striped={!!values?.replenishmentDetails?.length}
                    border
                    sorter
                    tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                    addTableClasses="vertical-middle-list-table warehouse-replenishment-table warehouse-manufacturing-replenishment-table"
                    cleaner
                    onRowClick={undefined}
                    fields={[
                      {
                        key: 'position',
                        label: '#',
                        _style: { width: '45px', textAlign: 'center' },
                      },
                      {
                        key: 'bomIntermediateName',
                        label: 'Nazwa półproduktu',
                        _style: { width: '270px' },
                      },
                      {
                        key: 'warehouseQuantity',
                        label: 'Stan',
                        _style: { width: '100px', textAlign: 'right' },
                      },
                      {
                        key: 'quantity',
                        label: 'Ilość WP',
                        _style: { width: '120px', textAlign: 'right' },
                      },
                    ]}
                    items={values?.replenishmentDetails?.filter(
                      (tableItem: actionTypes.TManufacturingReplenishmentTableItem) =>
                        tableItem.type === actionTypes.bomIntermediatesTypeNumber
                    )}
                    noItemsViewSlot={
                      <div className="no-items-in-table">
                        {!values?.manufacturingErrandId
                          ? 'Nie wybrano zlecenia produkcyjnego!'
                          : !values?.replenishmentDetails?.filter(
                              (tableItem: actionTypes.TManufacturingReplenishmentTableItem) =>
                                tableItem.type === actionTypes.bomIntermediatesTypeNumber
                            )?.length
                          ? 'Brak półproduktów w etapach produkcji BOM ze zlecenia!'
                          : ''}
                      </div>
                    }
                    scopedSlots={{
                      position: (
                        manufacturingReplenishmentTableItem: actionTypes.TManufacturingReplenishmentTableItem,
                        manufacturingReplenishmentTableItemIndex: number
                      ) => (
                        <td
                          key={`manufacturing-replenishment-table-cell-${manufacturingReplenishmentTableItemIndex}-${manufacturingReplenishmentTableItem?.bomIntermediateId}-intermediate-position`}
                        >
                          {manufacturingReplenishmentTableItemIndex + 1}.
                        </td>
                      ),
                      quantity: (
                        manufacturingReplenishmentTableItem: actionTypes.TManufacturingReplenishmentTableItem,
                        manufacturingReplenishmentTableItemIndex: number
                      ) => {
                        const indexOfStabilizedItem = values?.replenishmentDetails?.findIndex(
                          (tableItem: actionTypes.TManufacturingReplenishmentTableItem) =>
                            tableItem.bomIntermediateName &&
                            tableItem.bomIntermediateName ===
                              manufacturingReplenishmentTableItem.bomIntermediateName &&
                            tableItem.bomIntermediateId ===
                              manufacturingReplenishmentTableItem.bomIntermediateId &&
                            tableItem.type === manufacturingReplenishmentTableItem.type
                        )

                        return (
                          <td>
                            <InputGroup
                              id={`manufacturing-replenishment-quantity-item-group-id-${manufacturingReplenishmentTableItem.bomIntermediateId}-${manufacturingReplenishmentTableItem.type}-intermediate`}
                              style={{ pointerEvents: 'all' }}
                            >
                              {/* This component can not be used because it does not re-render properly when table is sorted or filtered and messages linger in wrong places!
                              
                              <ErrorMessage
                                name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                component="span"
                                className="text-danger input-error-message"
                              /> */}
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                placeholder={''}
                                name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                value={
                                  (values?.replenishmentDetails &&
                                    values?.replenishmentDetails[indexOfStabilizedItem || 0]
                                      ?.quantity) ||
                                  ''
                                }
                                className={`form-control text-right ${
                                  errors?.replenishmentDetails &&
                                  errors?.replenishmentDetails[indexOfStabilizedItem || 0] &&
                                  (errors?.replenishmentDetails[indexOfStabilizedItem || 0] as any)
                                    ?.quantity &&
                                  'invalid-field-without-exclamation invalid-field-without-exclamation--replenishment'
                                }`}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  manufacturingReplenishmentTableItem.hasChanged = true

                                  setIsManufacturingReplenishmentModifiedAndUnsaved(true)

                                  handleChange(event)
                                }}
                              />
                            </InputGroup>
                          </td>
                        )
                      },
                    }}
                  />

                  {/*
                   * Elements Table
                   */}

                  <CDataTable
                    tableFilterValue={manufacturingReplenishmentElementsFilterState}
                    sorterValue={manufacturingReplenishmentElementsSorterState}
                    onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                      setManufacturingReplenishmentElementsSorterState(sorterState)

                      if (errors.replenishmentDetails) {
                        validateForm()
                      }
                    }}
                    onTableFilterChange={(tableFilterState: string) => {
                      setManufacturingReplenishmentElementsFilterState(tableFilterState)

                      if (errors.replenishmentDetails) {
                        validateForm()
                      }
                    }}
                    loading={false}
                    striped={!!values?.replenishmentDetails?.length}
                    border
                    sorter
                    tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                    addTableClasses="vertical-middle-list-table warehouse-replenishment-table warehouse-manufacturing-replenishment-table"
                    cleaner
                    onRowClick={undefined}
                    fields={[
                      {
                        key: 'position',
                        label: '#',
                        _style: { width: '45px', textAlign: 'center' },
                      },
                      {
                        key: 'bomElementName',
                        label: 'Nazwa elementu',
                        _style: { width: '270px' },
                      },
                      {
                        key: 'warehouseQuantity',
                        label: 'Stan',
                        _style: { width: '100px', textAlign: 'right' },
                      },
                      {
                        key: 'quantity',
                        label: 'Ilość WP',
                        _style: { width: '120px', textAlign: 'right' },
                      },
                    ]}
                    items={values?.replenishmentDetails?.filter(
                      (tableItem: actionTypes.TManufacturingReplenishmentTableItem) =>
                        tableItem.type === actionTypes.materialsTypeNumber
                    )}
                    noItemsViewSlot={
                      <div className="no-items-in-table">
                        {!values?.manufacturingErrandId
                          ? 'Nie wybrano zlecenia produkcyjnego!'
                          : !values?.replenishmentDetails?.filter(
                              (tableItem: actionTypes.TManufacturingReplenishmentTableItem) =>
                                tableItem.type === actionTypes.materialsTypeNumber
                            )?.length
                          ? 'Brak elementów w BOM ze zlecenia!'
                          : ''}
                      </div>
                    }
                    scopedSlots={{
                      position: (
                        manufacturingReplenishmentTableItem: actionTypes.TManufacturingReplenishmentTableItem,
                        manufacturingReplenishmentTableItemIndex: number
                      ) => (
                        <td
                          key={`manufacturing-replenishment-table-cell-${manufacturingReplenishmentTableItemIndex}-${manufacturingReplenishmentTableItem?.bomElementId}-element-position`}
                        >
                          {manufacturingReplenishmentTableItemIndex + 1}.
                        </td>
                      ),
                      quantity: (
                        manufacturingReplenishmentTableItem: actionTypes.TManufacturingReplenishmentTableItem,
                        manufacturingReplenishmentTableItemIndex: number
                      ) => {
                        const indexOfStabilizedItem = values?.replenishmentDetails?.findIndex(
                          (tableItem: actionTypes.TManufacturingReplenishmentTableItem) =>
                            tableItem.bomElementName ===
                              manufacturingReplenishmentTableItem.bomElementName &&
                            tableItem.bomElementId ===
                              manufacturingReplenishmentTableItem.bomElementId &&
                            tableItem.type === manufacturingReplenishmentTableItem.type
                        )

                        return (
                          <td>
                            <InputGroup
                              id={`manufacturing-replenishment-quantity-item-group-id-${manufacturingReplenishmentTableItem.bomElementId}-${manufacturingReplenishmentTableItem.type}-element`}
                              style={{ pointerEvents: 'all' }}
                            >
                              {/* This component can not be used because it does not re-render properly when table is sorted or filtered and messages linger in wrong places!
                              
                              <ErrorMessage
                                name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                component="span"
                                className="text-danger input-error-message"
                              /> */}
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                placeholder={''}
                                name={`replenishmentDetails.${indexOfStabilizedItem}.quantity`}
                                value={
                                  (values?.replenishmentDetails &&
                                    values?.replenishmentDetails[indexOfStabilizedItem || 0]
                                      ?.quantity) ||
                                  ''
                                }
                                className={`form-control text-right ${
                                  errors?.replenishmentDetails &&
                                  errors?.replenishmentDetails[indexOfStabilizedItem || 0] &&
                                  (errors?.replenishmentDetails[indexOfStabilizedItem || 0] as any)
                                    ?.quantity &&
                                  'invalid-field-without-exclamation invalid-field-without-exclamation--replenishment'
                                }`}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  manufacturingReplenishmentTableItem.hasChanged = true

                                  setIsManufacturingReplenishmentModifiedAndUnsaved(true)

                                  handleChange(event)
                                }}
                              />
                            </InputGroup>
                          </td>
                        )
                      },
                    }}
                  />

                  <CButton
                    color="success"
                    className="save-button w-100 mt-3 mb-2"
                    type="submit"
                    onClick={() => {
                      setDidFormValidationOccur && setDidFormValidationOccur(true)
                    }}
                    disabled={
                      !values?.replenishmentDetails?.filter(
                        (tableItem) => !!convertNumericStringToNumber(tableItem?.quantity)
                      )?.length ||
                      warehousesState.isManufacturingReplenishmentDocumentGenerating ||
                      warehousesState.isManufacturingReplenishmentDocumentGenerated ||
                      (didFormValidationOccur
                        ? Boolean(errors.referenceNumber) || Boolean(errors.replenishmentDetails)
                        : false)
                    }
                  >
                    {warehousesState?.isManufacturingReplenishmentDocumentGenerating ? (
                      <>
                        Generowanie WP
                        <ThreeDots />
                      </>
                    ) : warehousesState?.isManufacturingReplenishmentDocumentGenerated ? (
                      <>Wygenerowano WP!</>
                    ) : (
                      'Generuj WP'
                    )}
                  </CButton>
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
