import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Table } from 'reactstrap'
import { Formik, Form, ErrorMessage, FieldArray, FastField, Field } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton, CLabel } from '@coreui/react'
import deepEqual from 'deep-equal'
import { Select } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  BasicFormField,
  CountrySelectField,
  FormActionsPanel,
  CustomErrorMessage,
  ConfirmActionModal,
} from '../../components'
import {
  successMessageDuration,
  getErrorMessageFromStatus,
  preventNavigationChange,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
} from '../../utils'
import { warehouseTypesObject } from '../../store/action-types'
import { Typeahead, TypeaheadMenuProps } from 'react-bootstrap-typeahead'
import { confirmAlert } from 'react-confirm-alert'

export const WarehouseDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, mode, id } = useParams<{
    tenant: string
    mode: actionTypes.TFormMode
    id: string
  }>()
  const paramsWarehouseId = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isOneFieldChanged, setIsOneFieldChanged] = useState(false)
  const [isWarehouseModifiedAndUnsaved, setIsWarehouseModifiedAndUnsaved] = useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const usersState = useSelector((state: TRootState) => state.users)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const usersWithWarehouseAccessTypeaheadRef = useRef([])

  const closeWarehouseDetails = useCallback(() => {
    history.push(`/${tenant}/warehouses`)
  }, [dispatch, history, tenant])

  const isWarehouseEditable =
    authState?.authData?.roles?.includes('Warehouses_write') &&
    (mode === 'edit' ? !!warehousesState.editedWarehouse : true)

  const isThereNetworkError =
    warehousesState?.warehouseCreateError?.status ||
    warehousesState?.warehouseEditSaveError?.status ||
    warehousesState?.warehouseDeleteError?.status

  // Set data for editedWarehouse in modal when clicked on row or opened in new window
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      paramsWarehouseId &&
      warehousesState.warehouses &&
      warehousesState.warehouses.length &&
      mode === 'edit'
    ) {
      const foundWarehouse = warehousesState.warehouses.find(
        (warehouse: actionTypes.TWarehouseLight) =>
          Number(warehouse.id) === Number(paramsWarehouseId)
      )

      if (foundWarehouse) {
        dispatch({
          type: actionTypes.OPEN_WAREHOUSE_DETAILS,
          editedWarehouse: foundWarehouse,
        })
      } else {
        history.replace(`/${tenant}/warehouses`)
      }
    }
  }, [dispatch, tenant, authState, history, paramsWarehouseId, warehousesState.warehouses])

  // Close modal on a successful create
  useEffect(() => {
    if (
      !warehousesState.isWarehouseCreating &&
      !warehousesState.warehouseCreateError &&
      warehousesState.isWarehouseCreated
    ) {
      setTimeout(() => {
        closeWarehouseDetails()
      }, successMessageDuration)
    }
  }, [warehousesState, closeWarehouseDetails])

  // Track the changes again
  useEffect(() => {
    if (
      warehousesState.isWarehouseEditSaved ||
      warehousesState.isWarehouseCreated ||
      warehousesState.isWarehouseDeleted
    ) {
      setIsWarehouseModifiedAndUnsaved(false)
    }
  }, [
    warehousesState.isWarehouseEditSaved,
    warehousesState.isWarehouseCreated,
    warehousesState.isWarehouseDeleted,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isWarehouseModifiedAndUnsaved,
      isWarehouseEditable,
      'warehouses',
      paramsWarehouseId,
      mode
    )

    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isWarehouseModifiedAndUnsaved, isWarehouseEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_WAREHOUSE_DETAILS,
      })
    }
  }, [])

  const WarehouseSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane!'),
    address1: Yup.string().required('To pole jest wymagane!'),
    city: Yup.string().required('To pole jest wymagane!'),
    post: Yup.string().required('To pole jest wymagane!'),
    types: Yup.array().of(Yup.number().required().notOneOf([0], 'Uzupełnij to pole!')),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="warehouse-details">
          <h4 className={`text-center ${isThereNetworkError ? 'mb-2 pb-2' : 'mb-2 pb-2'}`}>
            {mode === 'edit'
              ? !authState?.authData?.roles?.includes('Warehouses_write')
                ? 'Przeglądaj'
                : 'Edytuj'
              : 'Nowy'}{' '}
            Magazyn
          </h4>

          {((mode === 'edit' && warehousesState.editedWarehouse) || mode === 'create') && (
            <Formik
              initialValues={{
                name: warehousesState.editedWarehouse?.name || '',
                address1: warehousesState.editedWarehouse?.address1 || '',
                city: warehousesState.editedWarehouse?.city || '',
                post: warehousesState.editedWarehouse?.post || '',
                country: warehousesState.editedWarehouse?.country || 'Polska',
                types: warehousesState.editedWarehouse?.types || [0],
                usersWithAccess: warehousesState.editedWarehouse?.usersWithAccess || [],
                uuid: mode === 'create' ? uuidv4() : warehousesState.editedWarehouse?.uuid || null,
              }}
              validationSchema={WarehouseSchema}
              onSubmit={(values) => {
                if (isWarehouseEditable) {
                  setDidFormValidationOccur(true)
                  setIsOneFieldChanged(false)

                  dispatch({
                    type:
                      mode === 'create'
                        ? actionTypes.CREATE_WAREHOUSE_REQUESTED
                        : actionTypes.EDIT_WAREHOUSE_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      warehouse: {
                        ...values,
                        uuid: values?.uuid || uuidv4(),
                        types: values?.types?.map((convertTypeFromSelect: number) =>
                          Number(convertTypeFromSelect)
                        ),
                        ...(warehousesState.editedWarehouse?.id && {
                          id: warehousesState.editedWarehouse?.id,
                        }),
                      },
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ initialValues, values, errors, setFieldValue }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsWarehouseModifiedAndUnsaved(true)
                    } else {
                      setIsWarehouseModifiedAndUnsaved(false)
                    }

                    if (errors.name) {
                      setDidFormValidationOccur(true)
                    }
                    setIsOneFieldChanged(true)
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {!isOneFieldChanged && warehousesState?.warehouseCreateError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'create',
                        warehousesState?.warehouseCreateError?.status
                      )}
                    />
                  )}

                  {!isOneFieldChanged && warehousesState?.warehouseEditSaveError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'edit',
                        warehousesState?.warehouseEditSaveError?.status,
                        'magazynu'
                      )}
                    />
                  )}

                  {warehousesState?.warehouseDeleteError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'delete',
                        warehousesState?.warehouseDeleteError?.status,
                        'magazynu'
                      )}
                    />
                  )}

                  <BasicFormField
                    fieldId="warehouse-name"
                    fieldLabel="Nazwa"
                    fieldIcon="cil-short-text"
                    formikFieldName="name"
                    fieldValue={values.name}
                    fieldError={errors.name}
                    fieldType="text"
                    placeholder="Wprowadź nazwę magazynu"
                  />

                  <div className="settings-view-double-grid-fields">
                    <BasicFormField
                      fieldId={`warehouse-details-address-street`}
                      fieldLabel="Ulica"
                      fieldIcon="cil-home"
                      formikFieldName="address1"
                      fieldValue={values.address1}
                      fieldError={errors.address1}
                      fieldType="text"
                      placeholder="Ulica"
                    />

                    <BasicFormField
                      fieldId={`warehouse-details-address-post`}
                      fieldLabel="Kod pocztowy"
                      fieldIcon="cil-envelope-closed"
                      formikFieldName="post"
                      fieldValue={values.post}
                      fieldError={errors.post}
                      fieldType="text"
                      placeholder="Kod pocztowy"
                      isShortLengthInput
                    />

                    <BasicFormField
                      fieldId={`warehouse-details-address-city`}
                      fieldLabel="Miasto"
                      fieldIcon="cil-building"
                      formikFieldName="city"
                      fieldValue={values.city}
                      fieldError={errors.city}
                      fieldType="text"
                      placeholder="Miasto"
                      isShortLengthInput
                    />

                    <CountrySelectField
                      fieldId={`warehouse-details-address-country`}
                      fieldLabel="Kraj"
                      fieldIcon="cil-globe-alt"
                      formikFieldName="country"
                      fieldValue={values.country}
                      fieldError={''}
                      placeholder="Wybierz z listy lub wpisz nazwę kraju"
                      setFieldValue={setFieldValue}
                      refCountryTypeahead={null}
                    />
                  </div>

                  {usersState?.users
                    ?.find((user: actionTypes.TUser) => user.userId === authState.authData?.userId)
                    ?.userProfiles?.some(
                      (userProfile: actionTypes.TLightUserProfile) =>
                        userProfile?.name?.toLowerCase() === 'super admin'
                    ) && (
                    <FieldArray
                      name="usersWithAccess"
                      render={(arrayHelpers) => (
                        <div className="py-1">
                          <CLabel
                            htmlFor="list-of-users-with-access-to-warehouse"
                            className={`${inputLabelSpacingBottom}`}
                          >
                            Użytkownicy z dostępem do magazynu
                          </CLabel>
                          <InputGroup
                            id="list-of-users-with-access-to-warehouse"
                            className={`${inputFieldSpacingBottom} flex-nowrap`}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={errors.usersWithAccess && 'text-danger input-error-icon'}
                              >
                                <i className="cil-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <ErrorMessage
                              name="usersWithAccess"
                              component="span"
                              className="text-danger input-error-message"
                            />
                            <Typeahead
                              id="warehouse-details-users-with-access-typeahead"
                              defaultInputValue={undefined}
                              placeholder={
                                usersState?.users
                                  ? 'Wpisz nazwę lub wybierz użytkownika z listy'
                                  : 'Brak użytkowników do wyboru!'
                              }
                              ref={(HTMLElement: never) => {
                                usersWithWarehouseAccessTypeaheadRef.current[0] = HTMLElement
                              }}
                              onChange={(selectedUser: actionTypes.TUser[]) => {
                                if (selectedUser && selectedUser[0]) {
                                  const lightUser: actionTypes.TUserLight = {
                                    userId: selectedUser[0]?.userId || '',
                                    userName: selectedUser[0]?.userName || '',
                                  }

                                  const typeaheadRef: any =
                                    usersWithWarehouseAccessTypeaheadRef.current[0]

                                  if (
                                    typeaheadRef &&
                                    typeaheadRef?.inputNode?.value &&
                                    typeaheadRef?.state?.text &&
                                    typeaheadRef?.state?.selected
                                  ) {
                                    typeaheadRef.inputNode.value = ''
                                    typeaheadRef.state.text = ''
                                    typeaheadRef.state.selected = []
                                  }

                                  arrayHelpers.push(lightUser)

                                  if (isWarehouseModifiedAndUnsaved === false) {
                                    setIsWarehouseModifiedAndUnsaved(true)
                                  }
                                }
                              }}
                              options={
                                usersState?.users
                                  ?.filter(
                                    (user: actionTypes.TUser) =>
                                      !values?.usersWithAccess?.some(
                                        (userInTable: actionTypes.TUserLight) =>
                                          userInTable?.userId === user?.userId
                                      )
                                  )
                                  ?.sort() || []
                              }
                              labelKey={(option: actionTypes.TUser) => option.userName}
                              filterBy={['userName']}
                              emptyLabel={'Brak użytkowników do wyboru!'}
                              renderMenuItemChildren={(
                                user: actionTypes.TUser,
                                props: TypeaheadMenuProps<actionTypes.TUser>,
                                indexOfUserChild: number
                              ) => (
                                <option
                                  key={`users-typeahead-dropdown-item-${user.userId}-${indexOfUserChild}`}
                                >
                                  {user?.userName || ''} | {user?.userId || ''}
                                </option>
                              )}
                              disabled={
                                !usersState?.users?.length || warehousesState?.isWarehouseEditSaving
                              }
                            />
                          </InputGroup>

                          {values?.usersWithAccess?.length > 0 && (
                            <Table bordered striped className="item-inner-details-table mb-3">
                              <thead>
                                <tr>
                                  <th style={{ width: '45px', textAlign: 'center' }}>#</th>
                                  <th style={{ width: '100%' }}>Użytkownik</th>
                                  <th style={{ width: '50px' }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.usersWithAccess?.map(
                                  (user: actionTypes.TUserLight, indexOfUser: number) => (
                                    <tr
                                      key={`user-in-table-${user.userName}-${user.userId}-${indexOfUser}`}
                                    >
                                      <td style={{ textAlign: 'center' }}>{indexOfUser + 1}.</td>
                                      <td>{user?.userName || ''}</td>

                                      <td>
                                        <CButton
                                          color="danger"
                                          type="button"
                                          variant="outline"
                                          onClick={() => {
                                            if (
                                              initialValues?.usersWithAccess?.find(
                                                (initialUser: actionTypes.TUserLight) =>
                                                  initialUser.userId === user.userId
                                              ) ||
                                              !isWarehouseModifiedAndUnsaved
                                            ) {
                                              confirmAlert({
                                                closeOnEscape: true,
                                                customUI: ({ onClose }) => {
                                                  return (
                                                    <ConfirmActionModal
                                                      mode="delete"
                                                      onClose={onClose}
                                                      confirmMessageJSX={
                                                        <>
                                                          Czy na pewno chcesz usunąć zapisanego
                                                          użytkownika?
                                                          {user?.userName ? (
                                                            <>
                                                              <br />
                                                              <strong>{user?.userName}</strong>
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      }
                                                      onConfirmFunction={() =>
                                                        arrayHelpers.remove(indexOfUser)
                                                      }
                                                    />
                                                  )
                                                },
                                              })
                                            } else {
                                              arrayHelpers.remove(indexOfUser)
                                            }
                                          }}
                                          className="list-action-button"
                                        >
                                          <i className="cil-trash"></i>
                                        </CButton>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      )}
                    />
                  )}

                  <p className="mt-1 mb-1 pb-1">Rodzaje obsługiwanych magazynów</p>
                  <FieldArray
                    name="types"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.types?.map(
                          (chosenWarehouseType: number, chosenWarehouseTypeIndex: number) => {
                            return (
                              <div
                                className={`warehouse-details-types-double-grid mb-3 ${
                                  chosenWarehouseTypeIndex > 0 && 'pt-1'
                                }`}
                                key={`warehouse-type-field-key-${chosenWarehouseTypeIndex}`}
                              >
                                <InputGroup
                                  id={`warehouse-type-${chosenWarehouseTypeIndex}-${uuidv4()}`}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText
                                      className={
                                        errors?.types && errors?.types[chosenWarehouseTypeIndex]
                                          ? 'text-danger input-error-icon'
                                          : ''
                                      }
                                    >
                                      <i className="cil-storage"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <ErrorMessage
                                    name={`types.${chosenWarehouseTypeIndex}`}
                                    component="span"
                                    className="text-danger input-error-message"
                                  />
                                  <Field
                                    as={Select}
                                    variant="outlined"
                                    native
                                    className={`item-selector element-selector w-100 ${
                                      String(chosenWarehouseType) === '0' &&
                                      'element-selector-placeholder'
                                    } ${
                                      errors?.types &&
                                      errors?.types[chosenWarehouseTypeIndex] &&
                                      'invalid-native-selector'
                                    }`}
                                    name={`types.${chosenWarehouseTypeIndex}`}
                                  >
                                    <option
                                      className="select-option-placeholder"
                                      value={0}
                                      disabled={true}
                                    >
                                      {Object.keys(warehouseTypesObject).every((warehouseTypeKey) =>
                                        (values?.types as unknown[])
                                          ?.map((chosenWarehouseType: unknown) =>
                                            String(chosenWarehouseType)
                                          )
                                          .includes(warehouseTypeKey)
                                      )
                                        ? 'Wybrano wszystkie rodzaje'
                                        : 'Wybierz rodzaj magazynu'}
                                    </option>
                                    {Object.keys(warehouseTypesObject)?.map(
                                      (warehouseTypeKey: string) => {
                                        const isTypeAlreadyChosen: boolean = (
                                          values?.types as unknown[]
                                        )
                                          ?.map((chosenWarehouseType: unknown) =>
                                            String(chosenWarehouseType)
                                          )
                                          .includes(warehouseTypeKey)

                                        return (
                                          <option
                                            key={`warehouse-option-${warehouseTypeKey}-${chosenWarehouseTypeIndex}`}
                                            value={Number(warehouseTypeKey)}
                                            disabled={isTypeAlreadyChosen}
                                            style={{
                                              display: isTypeAlreadyChosen ? 'none' : 'inherit',
                                            }}
                                          >
                                            {warehouseTypesObject[warehouseTypeKey]}
                                          </option>
                                        )
                                      }
                                    )}
                                  </Field>
                                </InputGroup>

                                <CButton
                                  color="danger"
                                  type="button"
                                  variant="outline"
                                  onClick={() => {
                                    if (values?.types) {
                                      arrayHelpers.remove(chosenWarehouseTypeIndex)
                                    }
                                  }}
                                  className="select-option-remove-button"
                                  disabled={values?.types ? values?.types?.length < 2 : false}
                                >
                                  <i className="cil-trash"></i>
                                </CButton>
                              </div>
                            )
                          }
                        )}

                        <div className="d-flex justify-content-center mt-4 mb-3">
                          <CButton
                            color="info"
                            type="button"
                            variant="outline"
                            onClick={() => {
                              arrayHelpers.push(0)
                            }}
                            disabled={
                              warehousesState.isWarehouseDeleting ||
                              warehousesState.isWarehouseCreating ||
                              warehousesState.isWarehouseEditSaving ||
                              values.types.length >= Object.keys(warehouseTypesObject).length
                            }
                            className="px-4"
                          >
                            Dodaj rodzaj
                          </CButton>
                        </div>
                      </div>
                    )}
                  />

                  <FormActionsPanel
                    mode={mode}
                    padding="pt-3 pb-1"
                    isSaving={
                      mode === 'create'
                        ? warehousesState.isWarehouseCreating
                        : warehousesState.isWarehouseEditSaving
                    }
                    isSaved={
                      mode === 'create'
                        ? warehousesState.isWarehouseCreated
                        : warehousesState.isWarehouseEditSaved
                    }
                    isDeleting={warehousesState.isWarehouseDeleting}
                    isDeleted={warehousesState.isWarehouseDeleted}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    formErrorsBool={Boolean(errors.name)}
                    closeAction={actionTypes.CLOSE_WAREHOUSE_DETAILS}
                    deleteAction={actionTypes.DELETE_WAREHOUSE_REQUESTED}
                    deletePayload={{
                      tenant: tenant,
                      token: authState.authData?.token,
                      id: warehousesState.editedWarehouse?.id,
                    }}
                    closeFunction={closeWarehouseDetails}
                    isEditable={isWarehouseEditable}
                    canDelete={warehousesState?.editedWarehouse?.canDelete}
                    disabledDeleteButtonClassNames={`delete-details-button`}
                    confirmDeleteMessageJSX={
                      <>
                        Czy na pewno chcesz usunąć magazyn?
                        {initialValues?.name ? (
                          <>
                            <br />
                            <strong>{initialValues?.name}</strong>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    }
                  />
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
