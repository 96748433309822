import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'
import { useParams, useHistory } from 'react-router-dom'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'
import { ListActionButton } from '../../components'
import { ListStickyErrorMessage } from '../../components/list-sticky-error-message'
import { bomElementNavTabs } from './bom-element-details'

const BomElementsList: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()
  const mainTab = bomElementNavTabs[0].name

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [])

  const bomElementsState = useSelector((state: TRootState) => state.bomElements)
  const authState = useSelector((state: TRootState) => state.auth)

  // Redirect on not found editable Element
  useEffect(() => {
    if (
      bomElementsState.singleBomElementFetchError &&
      bomElementsState.singleBomElementFetchError.status === 404
    ) {
      history.replace(`/${tenant}/bom-elements`)
    }
  }, [dispatch, bomElementsState.singleBomElementFetchError, history, tenant])

  const memoizedTableItems = useMemo(
    () =>
      bomElementsState?.bomElements?.map((bomElement: actionTypes.TLightBomElement) => {
        return {
          ...bomElement,
          name: bomElement?.name || '',
          mpnWithDin: `${bomElement?.mpn || ''}${
            bomElement?.symbolDin && bomElement?.mpn ? ' / ' : ''
          }${bomElement?.symbolDin || ''}`,
          producerName: bomElement?.producerName || '',
          icon: '🖊️',
        }
      }),
    [bomElementsState.bomElements]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="10">
        <CCard>
          <CCardBody className="pb-2">
            {authState?.authData?.roles?.includes('BomElements_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  onClick={() => {
                    history.push(`/${tenant}/bom-elements/create/${mainTab}`)

                    dispatch({ type: actionTypes.SET_EDITED_BOM_ELEMENT, bomElement: null })
                  }}
                >
                  Nowy Element
                </CButton>
              </div>
            )}

            {(bomElementsState?.bomElementArchiveError ||
              bomElementsState?.bomElementCopyError ||
              bomElementsState?.bomElementDeleteError) && (
              <ListStickyErrorMessage
                listErrorMessage={`Wystąpił błąd ${
                  bomElementsState?.bomElementArchiveError?.status ||
                  bomElementsState?.bomElementCopyError?.status ||
                  bomElementsState?.bomElementDeleteError?.status ||
                  'nieznany'
                } podczas ${
                  (bomElementsState?.bomElementArchiveError && 'archiwizowania') ||
                  (bomElementsState?.bomElementCopyError && 'kopiowania') ||
                  (bomElementsState?.bomElementDeleteError && 'usuwania') ||
                  'zmiany'
                } elementu!`}
              />
            )}

            <CDataTable
              tableFilterValue={bomElementsState?.tableFilterState}
              sorterValue={bomElementsState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_BOM_ELEMENTS_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_BOM_ELEMENTS_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={bomElementsState.areBomElementsLoading}
              striped={!!bomElementsState.bomElements.length}
              border
              sorter
              addTableClasses="vertical-middle-list-table bom-elements-list-body"
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              onRowClick={(bomElement: actionTypes.TBomElement) => {
                history.push(`/${tenant}/bom-elements/edit/${bomElement.id}/${mainTab}`)
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '25px',
                    verticalAlign: 'middle',
                    display: !bomElementsState.bomElements.length ? 'none' : '',
                  },
                },
                {
                  key: 'name',
                  label: 'Nazwa',
                  _style: { width: '45%', textAlign: 'left', verticalAlign: 'middle' },
                },
                {
                  key: 'mpnWithDin',
                  label: 'MPN / DIN',
                  _style: { width: '40%', textAlign: 'left', verticalAlign: 'middle' },
                },
                {
                  key: 'producerName',
                  label: 'Producent',
                  _style: { width: '20%', textAlign: 'left', verticalAlign: 'middle' },
                },
                {
                  key: 'copy',
                  label: '',
                  _style: { width: '45px' },
                },
                {
                  key: 'archive',
                  label: '',
                  _style: { width: '45px' },
                },
                {
                  key: 'delete',
                  label: '',
                  _style: { width: '45px' },
                },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    bomElementsState.fetchBomElementsError,
                    bomElementsState.areBomElementsLoading,
                    'elementów',
                    bomElementsState?.bomElements?.length
                  )}
                </div>
              }
              scopedSlots={{
                copy: (itemFromList: actionTypes.TLightBomElement) => (
                  <td style={{ pointerEvents: 'none' }}>
                    <ListActionButton
                      mainActionName="copy"
                      buttonColor="info"
                      classNames="copy-list-item-button"
                      typeOfAction={actionTypes.COPY_BOM_ELEMENT_REQUESTED}
                      iconName="cil-clone"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={bomElementsState.currentlyManagedBomElementId}
                      isItemCopying={bomElementsState.isBomElementCopying}
                      isItemArchiving={bomElementsState.isBomElementArchiving}
                      isItemDeleting={bomElementsState.isBomElementDeleting}
                    />
                  </td>
                ),
                archive: (itemFromList: actionTypes.TLightBomElement) => (
                  <td style={{ pointerEvents: 'none' }}>
                    <ListActionButton
                      mainActionName="archive"
                      buttonColor="dark"
                      classNames="archive-list-item-button"
                      typeOfAction={actionTypes.ARCHIVE_BOM_ELEMENT_REQUESTED}
                      iconName="cil-storage"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={bomElementsState.currentlyManagedBomElementId}
                      isItemCopying={bomElementsState.isBomElementCopying}
                      isItemArchiving={bomElementsState.isBomElementArchiving}
                      isItemDeleting={bomElementsState.isBomElementDeleting}
                    />
                  </td>
                ),
                delete: (itemFromList: actionTypes.TLightBomElement) => (
                  <td
                    className={`text-center`}
                    style={{ pointerEvents: 'none' }}
                    onClick={(event: SyntheticEvent) => {
                      if (!itemFromList.canDelete) {
                        event.preventDefault()
                        event.stopPropagation()
                        return null
                      } else {
                        return undefined
                      }
                    }}
                  >
                    <ListActionButton
                      mainActionName="delete"
                      buttonColor="danger"
                      classNames="delete-list-item-button"
                      typeOfAction={actionTypes.DELETE_BOM_ELEMENT_REQUESTED}
                      iconName="cil-trash"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={bomElementsState.currentlyManagedBomElementId}
                      isItemCopying={bomElementsState.isBomElementCopying}
                      isItemArchiving={bomElementsState.isBomElementArchiving}
                      isItemDeleting={bomElementsState.isBomElementDeleting}
                    />
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default BomElementsList
