import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Container, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CButton, CLabel } from '@coreui/react'
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import { Select } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  convertNumbersInTableRows,
  convertNumericStringToNumber,
  getEmptyListMessage,
  getErrorMessageFromStatus,
  globalCurrencyFallback,
  globalDecimalSeparator,
  globalThousandSeparator,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  preventNavigationChange,
  toDateInputValue,
} from '../../../utils'
import { minimumWidthForWideDetailsView } from '../../erp'
import {
  BasicFormField,
  ConfirmActionModal,
  CustomErrorMessage,
  FormActionCancelButton,
  FormActionSaveButton,
} from '../../../components'
import { confirmAlert } from 'react-confirm-alert'
import { lengthOfWarehouseNameInConfirmModal } from './warehouse-management'

export const maximumWarehouseNameLengthInTitle = 24

export const InventoryControl: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { tenant, id } = useParams<{ tenant: string; id: string }>()
  let paramsManagedWarehouseId = id

  const authState = useSelector((state: TRootState) => state.auth)
  const adminState = useSelector((state: TRootState) => state.admin)
  const settingsState = useSelector((state: TRootState) => state.settings)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const [isInventoryControlModifiedAndUnsaved, setIsInventoryControlModifiedAndUnsaved] =
    useState<boolean>(false)

  const [isInventoryControlSaveConfirmed, setIsInventoryControlSaveConfirmed] =
    useState<boolean>(false)

  const [inventoryControlFilterState, setInventoryControlFilterState] =
    useState<actionTypes.TFilterState>(undefined)

  const [inventoryControlSorterState, setInventoryControlSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const isInventoryControlEditable =
    authState?.authData?.roles?.includes('Warehouses_write') &&
    !warehousesState?.fetchWarehouseInventoryControlDataError

  const inventoryControlWarehouseName = warehousesState?.warehouses.find(
    (warehouse: actionTypes.TWarehouseLight) => String(warehouse.id) === paramsManagedWarehouseId
  )?.name

  let realQuantityInputsRefs: HTMLInputElement[] = []
  let userRealPossessionQuantityInputsRefs: HTMLInputElement[] = []

  const memoizedTableItems = useMemo(
    () =>
      warehousesState?.warehouseInventoryControlData?.details?.map(
        (inventoryControlItem: actionTypes.IWarehouseInventoryControlDataItem) => {
          return {
            ...inventoryControlItem,
            bomElementName: inventoryControlItem?.bomElementName || '',
            bomElementSymbol: inventoryControlItem?.bomElementSymbol || '',
            bomElementMpn: inventoryControlItem?.bomElementMpn || '',

            warehouseQuantity: inventoryControlItem?.warehouseQuantity || '',
            realQuantity: null || '',

            userName: inventoryControlItem?.userName || '',
            userPossessionQuantity: inventoryControlItem?.userPossessionQuantity || '',
            userRealPossessionQuantity: null,

            purchasePrice: inventoryControlItem?.purchasePrice || '',
            purchasePriceCurrency: inventoryControlItem?.purchasePriceCurrency || '',
            totalPrice: inventoryControlItem?.totalPrice || '',
            totalPriceCurrency: inventoryControlItem?.totalPriceCurrency || '',
            development: inventoryControlItem?.development || '',

            bomName: inventoryControlItem?.bomName || '',

            uuid: inventoryControlItem?.uuid || uuidv4(),
          }
        }
      ),
    [paramsManagedWarehouseId, warehousesState?.warehouseInventoryControlData]
  )

  const closeInventoryControl = useCallback(() => {
    history.push(`/${tenant}/warehouse-management/${paramsManagedWarehouseId}`)
  }, [history, tenant, paramsManagedWarehouseId])

  // Reset the view on dropdown change
  useEffect(() => {
    realQuantityInputsRefs?.forEach((input: HTMLInputElement) => {
      input.value = ''
    })

    setIsInventoryControlModifiedAndUnsaved(false)
  }, [paramsManagedWarehouseId])

  useEffect(() => {
    if (
      isInventoryControlModifiedAndUnsaved &&
      warehousesState.isWarehouseInventoryControlDataSaved
    ) {
      setIsInventoryControlModifiedAndUnsaved(false)
    }
  }, [warehousesState.isWarehouseInventoryControlDataSaved])

  // Fetch inventory control data
  useEffect(() => {
    if (
      location.pathname.includes(
        `warehouse-management/${paramsManagedWarehouseId}/inventory-control`
      ) &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      isInventoryControlEditable
    ) {
      dispatch({
        type: actionTypes.FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsManagedWarehouseId },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
    paramsManagedWarehouseId,
    warehousesState.isWarehouseInventoryControlDataSaved,
  ])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    // [2, 5]
    convertNumbersInTableRows(['array'], [8, 10], [])
  }, [
    memoizedTableItems,
    inventoryControlFilterState,
    inventoryControlSorterState,
    warehousesState.isWarehouseInventoryControlDataLoading,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isInventoryControlModifiedAndUnsaved,
      isInventoryControlEditable,
      'inventory-control',
      paramsManagedWarehouseId,
      ''
    )
    // Without pathname in location there is no tab change detection
  }, [
    location.pathname,
    history,
    isInventoryControlModifiedAndUnsaved,
    isInventoryControlEditable,
    paramsManagedWarehouseId,
  ])

  // Unmount component
  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.CLOSE_WAREHOUSE_INVENTORY_CONTROL })
    }
  }, [])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ maxWidth: '1800px' }}
    >
      <CCard className="warehouse-inventory-control">
        <CCardBody className="p-4">
          <Formik
            initialValues={{
              inventoryControlDate: new Date().toJSON(),
              inventoryControlTotalPrice:
                warehousesState?.warehouseInventoryControlData?.inventoryControlTotalPrice || '',
              inventoryControlTotalPriceCurrency:
                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                globalCurrencyFallback,
              inventoryControlWarehouseId:
                paramsManagedWarehouseId || warehousesState?.warehouses[0]?.id,
              details: memoizedTableItems,
            }}
            onSubmit={(values) => {
              if (isInventoryControlEditable) {
                dispatch({
                  type: actionTypes.SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED,
                  payload: {
                    tenant: tenant,
                    token: authState.authData?.token,
                    saveInventoryControlData: {
                      warehouseId: Number(values?.inventoryControlWarehouseId),
                      date: values?.inventoryControlDate || '',
                      details: values?.details
                        ?.filter(
                          (dataItem: actionTypes.IWarehouseInventoryControlDataItem) =>
                            dataItem.realQuantity || dataItem.userRealPossessionQuantity
                        )
                        .map((dataItem: actionTypes.IWarehouseInventoryControlDataItem) => ({
                          bomElementId: dataItem.bomElementId,
                          realQuantity:
                            String(dataItem?.realQuantity) === '0'
                              ? 0
                              : convertNumericStringToNumber(dataItem?.realQuantity),
                          type: dataItem.type,

                          userPossessionId: dataItem.userPossessionId,
                          userRealPossessionQuantity:
                            String(dataItem?.userRealPossessionQuantity) === '0'
                              ? 0
                              : convertNumericStringToNumber(dataItem?.userRealPossessionQuantity),

                          firstRow: dataItem.firstRow,
                        })),
                    },
                  },
                })

                setIsInventoryControlSaveConfirmed(false)
              }
            }}
            enableReinitialize={!isInventoryControlModifiedAndUnsaved}
          >
            {({ values, errors, resetForm, handleChange, setFieldValue, setErrors }) => (
              <Form>
                {authState?.authData?.roles?.includes('Warehouses_write') && (
                  <div
                    className={`form-fixed-panel-in-header form-fixed-panel-in-header--inventory-control ${
                      adminState.sidebarVisibility === 'responsive' && !adminState.sidebarMinimize
                        ? `form-fixed-panel-in-header--inventory-control-smaller-screen-with-sidebar`
                        : ''
                    } ${
                      adminState.sidebarMinimize && adminState.sidebarVisibility
                        ? `form-fixed-panel-in-header--inventory-control-smaller-screen-with-sidebar-minimized`
                        : ''
                    }`}
                  >
                    <h4 className="form-fixed-panel-in-header-title text-center font-weight-light mb-0">
                      <strong>
                        Magazyn{' '}
                        {inventoryControlWarehouseName
                          ? inventoryControlWarehouseName.length > maximumWarehouseNameLengthInTitle
                            ? inventoryControlWarehouseName
                                ?.slice(0, maximumWarehouseNameLengthInTitle)
                                ?.concat('...')
                            : inventoryControlWarehouseName
                          : ''}{' '}
                        - Inwentaryzacja
                      </strong>
                    </h4>

                    <div>
                      <CButton color="info" variant="outline" className="mr-4 px-4" type="button">
                        Drukuj raport
                      </CButton>

                      <CButton color="info" variant="outline" className="mr-4 px-4" type="button">
                        Drukuj szablon
                      </CButton>

                      <FormActionCancelButton
                        closeFunction={() => {
                          resetForm()
                          closeInventoryControl()
                        }}
                        closeAction={''}
                      />

                      {isInventoryControlEditable && (
                        <span
                          id="warehouse-inventory-control-submit-button-span"
                          onClick={(event) => {
                            if (!isInventoryControlSaveConfirmed) {
                              event.preventDefault()
                              event.stopPropagation()

                              if (isInventoryControlModifiedAndUnsaved) {
                                confirmAlert({
                                  closeOnEscape: true,

                                  customUI: ({ onClose }) => {
                                    return (
                                      <ConfirmActionModal
                                        mode="save"
                                        onClose={onClose}
                                        confirmMessageJSX={
                                          <>
                                            Czy na pewno chcesz zapisać dane wprowadzone w tej
                                            inwentaryzacji?
                                          </>
                                        }
                                        onConfirmFunction={() => {
                                          const submitButton: any = document.getElementById(
                                            'warehouse-inventory-control-submit-button-span'
                                          )?.children[0]

                                          if (submitButton) {
                                            setIsInventoryControlSaveConfirmed(true)

                                            setTimeout(() => {
                                              submitButton.click()
                                            }, 100)
                                          }
                                        }}
                                      />
                                    )
                                  },
                                })
                              }
                            }
                          }}
                        >
                          <FormActionSaveButton
                            mode="edit"
                            isSaving={warehousesState.isWarehouseInventoryControlDataSaving}
                            isSaved={warehousesState.isWarehouseInventoryControlDataSaved}
                            isDeleted={false}
                            isDeleting={false}
                            formErrorsBool={false}
                            setDidFormValidationOccur={null}
                            didFormValidationOccur={false}
                            canSave={
                              isInventoryControlEditable && isInventoryControlModifiedAndUnsaved
                            }
                          />
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/*
                 * Display Network Error Message
                 */}

                {warehousesState?.fetchWarehouseInventoryControlDataError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'fetch',
                      warehousesState?.fetchWarehousesError?.status,
                      'danych do inwentaryzacji'
                    )}
                  />
                )}

                {warehousesState?.saveWarehouseInventoryControlError && (
                  <CustomErrorMessage
                    wrapperClassNames="purchase-plan-error-message-margin"
                    messageClassNames="text-center"
                    customErrorMessageText={getErrorMessageFromStatus(
                      'edit',
                      warehousesState?.saveWarehouseInventoryControlError?.status,
                      'inwentaryzacji'
                    )}
                  />
                )}

                <div className="inventory-control-main-form-fields d-flex justify-content-evenly align-items-center">
                  <BasicFormField
                    fieldId="inventory-control-date"
                    fieldLabel="Data inwentaryzacji"
                    fieldIcon="cil-calendar"
                    formikFieldName="inventoryControlDate"
                    fieldValue={toDateInputValue(values.inventoryControlDate)}
                    fieldError={null}
                    fieldType="date"
                    placeholder="Wprowadź datę inwentaryzacji"
                  />

                  <div className="mx-4" style={{ maxWidth: '250px' }}>
                    <CLabel
                      htmlFor="inventory-control-warehouse-id-selector"
                      className={`${inputLabelSpacingBottom}`}
                    >
                      Magazyn
                    </CLabel>
                    <InputGroup
                      id="inventory-control-warehouse-id-selector"
                      className={`${inputFieldSpacingBottom} dropdown-selector`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          className={
                            errors.inventoryControlWarehouseId && 'text-danger input-error-icon'
                          }
                        >
                          <i className="cil-storage"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <ErrorMessage
                        name="inventoryControlWarehouseId"
                        component="span"
                        className="text-danger input-error-message"
                      />
                      <Field
                        as={Select}
                        name="inventoryControlWarehouseId"
                        variant="outlined"
                        native
                        value={String(values.inventoryControlWarehouseId)}
                        className="item-selector warehouse-selector"
                        style={{ minWidth: '200px' }}
                        disabled={!warehousesState?.warehouses}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                          const changeInventoryControlWarehouseId = (
                            selectedWarehouseId: number
                          ) => {
                            handleChange(event)

                            setIsInventoryControlModifiedAndUnsaved(false)
                            resetForm()

                            if (errors) {
                              setErrors({})
                            }

                            // This is important to change the displayed value in select
                            event.target.value = String(selectedWarehouseId)

                            if (selectedWarehouseId) {
                              return setTimeout(
                                () =>
                                  history.push(
                                    `/${tenant}/warehouse-management/${selectedWarehouseId}/inventory-control`
                                  ),
                                10
                              )
                            }

                            return handleChange(event)
                          }

                          if (isInventoryControlModifiedAndUnsaved) {
                            confirmAlert({
                              closeOnEscape: true,
                              // This is a hack, message is the new selected fromWarehouseId passed down to scope
                              message: event?.target?.value,
                              customUI: ({ onClose, message }) => {
                                return (
                                  <ConfirmActionModal
                                    mode="selectOther"
                                    onClose={onClose}
                                    confirmMessageJSX={
                                      <>
                                        <strong>Masz niezapisane zmiany!</strong>
                                        <br />
                                        Czy na pewno chcesz ustawić{' '}
                                        <strong>
                                          {warehousesState?.warehouses
                                            ?.find(
                                              (warehouse: actionTypes.TWarehouseLight) =>
                                                Number(message) === warehouse.id
                                            )
                                            ?.name?.substring(
                                              0,
                                              lengthOfWarehouseNameInConfirmModal
                                            ) || 'magazyn bez nazwy'}
                                        </strong>
                                        <br />
                                        jako magazyn do inwentaryzacji?
                                      </>
                                    }
                                    onConfirmFunction={() => {
                                      changeInventoryControlWarehouseId(Number(message))
                                    }}
                                  />
                                )
                              },
                            })
                          } else {
                            changeInventoryControlWarehouseId(Number(event?.target?.value))
                          }
                        }}
                      >
                        {warehousesState?.warehouses?.map(
                          (warehouse: actionTypes.TWarehouseLight, warehouseIndex: number) => {
                            return (
                              <option
                                key={`warehouse-select-option-${warehouse.id}-${warehouseIndex}`}
                                value={warehouse.id}
                              >
                                {warehouse?.name || '...'}
                              </option>
                            )
                          }
                        )}
                      </Field>
                    </InputGroup>
                  </div>

                  <div style={{ maxWidth: '250px' }}>
                    <BasicFormField
                      fieldId="inventory-control-total-price"
                      fieldLabel="Łączna wartość"
                      fieldIcon="cil-cash"
                      formikFieldName="inventoryControlTotalPrice"
                      fieldValue={values.inventoryControlTotalPrice}
                      fieldError={errors.inventoryControlTotalPrice}
                      fieldType="number"
                      placeholder="Łączna wartość"
                      fieldCurrencyName="inventoryControlTotalPriceCurrency"
                      fieldCurrencyValue={values.inventoryControlTotalPriceCurrency}
                      handleChange={handleChange}
                      isDisabled
                    />
                  </div>
                </div>

                <CDataTable
                  tableFilterValue={inventoryControlFilterState}
                  sorterValue={inventoryControlSorterState}
                  onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                    setInventoryControlSorterState(sorterState)
                  }}
                  onTableFilterChange={(tableFilterState: string) => {
                    setInventoryControlFilterState(tableFilterState)
                  }}
                  loading={warehousesState?.isWarehouseInventoryControlDataLoading}
                  striped={!!warehousesState?.warehouseInventoryControlData?.details?.length}
                  border
                  sorter
                  tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                  addTableClasses="vertical-middle-list-table warehouse-inventory-control-table mb-2 mt-1"
                  cleaner
                  onRowClick={undefined}
                  fields={[
                    {
                      key: 'bomElementName',
                      label: 'Nazwa elementu',
                      _style: { width: '260px' },
                    },
                    {
                      key: 'bomElementSymbol',
                      label: 'Symbol elementu | DIN',
                      _style: { width: '210px', textAlign: 'left' },
                    },
                    {
                      key: 'bomElementMpn',
                      label: 'MPN',
                      _style: { width: '210px', textAlign: 'left' },
                    },
                    {
                      key: 'warehouseQuantity',
                      label: 'Stan magaz.',
                      _style: { width: '90px', textAlign: 'left' },
                    },
                    {
                      key: 'realQuantity',
                      label: 'Stan faktyczny magazynu',
                      _style: { width: '110px', textAlign: 'left' },
                    },
                    {
                      key: 'userName',
                      label: 'Użytkownik',
                      _style: { width: '210px' },
                    },
                    {
                      key: 'userPossessionQuantity',
                      label: 'Stan użytk.',
                      _style: { width: '90px', textAlign: 'left' },
                    },
                    {
                      key: 'userRealPossessionQuantity',
                      label: 'Stan faktyczny użytkownika',
                      _style: { width: '125px', textAlign: 'left' },
                    },
                    {
                      key: 'purchasePrice',
                      label: 'Cena zakupu',
                      _style: { width: '90px', textAlign: 'left' },
                    },
                    {
                      key: 'purchasePriceCurrency',
                      label: 'CCY',
                      _style: { width: '70px', textAlign: 'left' },
                    },
                    {
                      key: 'totalPrice',
                      label: 'Wartość',
                      _style: { width: '100px', textAlign: 'left' },
                    },
                    {
                      key: 'totalPriceCurrency',
                      label: 'CCY',
                      _style: { width: '70px', textAlign: 'left' },
                    },
                    {
                      key: 'bomName',
                      label: 'BOM',
                      _style: { width: '150px', textAlign: 'left' },
                    },
                  ]}
                  items={values.details}
                  noItemsViewSlot={
                    <div className="no-items-in-table">
                      {getEmptyListMessage(
                        warehousesState.fetchWarehouseInventoryControlDataError,
                        warehousesState.isWarehouseInventoryControlDataLoading,
                        'danych',
                        warehousesState?.warehouseInventoryControlData?.details?.length
                      )}
                    </div>
                  }
                  scopedSlots={{
                    realQuantity: (
                      itemFromList: actionTypes.IWarehouseInventoryControlDataItem,
                      itemFromListIndex: number
                    ) => {
                      //
                      const indexOfStabilizedItem = values?.details?.findIndex(
                        (inventoryItem: actionTypes.IWarehouseInventoryControlDataItem) =>
                          inventoryItem.uuid === itemFromList.uuid
                      )

                      return (
                        <td
                          key={`${paramsManagedWarehouseId}-${itemFromList.uuid}-${indexOfStabilizedItem}-warehouse-real-quantity-key`}
                        >
                          <InputGroup
                            id={`${paramsManagedWarehouseId}-${itemFromList.uuid}-${indexOfStabilizedItem}-warehouse-real-quantity-group-id`}
                            style={{ pointerEvents: 'all' }}
                          >
                            <ErrorMessage
                              name={`details.${indexOfStabilizedItem}.realQuantity`}
                              component="span"
                              className="text-danger input-error-message"
                            />
                            <FastField
                              as={NumberFormat}
                              displayType="input"
                              thousandSeparator={globalThousandSeparator}
                              decimalSeparator={globalDecimalSeparator}
                              decimalScale={0}
                              fixedDecimalScale={false}
                              allowNegative={false}
                              allowLeadingZeros={true}
                              placeholder={''}
                              name={`details.${indexOfStabilizedItem}.realQuantity`}
                              value={itemFromList.realQuantity || ''}
                              className="form-control text-right"
                              onValueChange={(valuesObject: any) => {
                                setFieldValue(
                                  `details.${indexOfStabilizedItem}.realQuantity`,
                                  valuesObject?.value ? Number(valuesObject?.value) : ''
                                )

                                // Check if all real quantity values were cleared
                                if (valuesObject?.value) {
                                  setIsInventoryControlModifiedAndUnsaved(true)
                                }
                              }}
                              getInputRef={(realQuantityInput: HTMLInputElement) => {
                                if (
                                  realQuantityInput &&
                                  !realQuantityInputsRefs.includes(realQuantityInput)
                                ) {
                                  realQuantityInputsRefs.push(realQuantityInput)
                                }
                              }}
                              disabled={!itemFromList.firstRow}
                            />
                          </InputGroup>
                        </td>
                      )
                    },
                    userRealPossessionQuantity: (
                      itemFromList: actionTypes.IWarehouseInventoryControlDataItem,
                      itemFromListIndex: number
                    ) => {
                      //
                      const indexOfStabilizedItem = values?.details?.findIndex(
                        (inventoryItem: actionTypes.IWarehouseInventoryControlDataItem) =>
                          inventoryItem.uuid === itemFromList.uuid
                      )

                      return (
                        <td
                          key={`${paramsManagedWarehouseId}-${itemFromList.uuid}-${indexOfStabilizedItem}-warehouse-user-real-posession-quantity-key`}
                        >
                          {(itemFromList?.userPossessionQuantity ||
                            itemFromList?.userPossessionQuantity === 0) && (
                            <InputGroup
                              id={`${paramsManagedWarehouseId}-${itemFromList.uuid}-${indexOfStabilizedItem}-warehouse-user-real-posession-quantity-group-id`}
                              style={{ pointerEvents: 'all' }}
                            >
                              <ErrorMessage
                                name={`details.${indexOfStabilizedItem}.userRealPossessionQuantity`}
                                component="span"
                                className="text-danger input-error-message"
                              />
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                placeholder={''}
                                name={`details.${indexOfStabilizedItem}.userRealPossessionQuantity`}
                                value={itemFromList.userRealPossessionQuantity || ''}
                                className="form-control text-right"
                                onValueChange={(valuesObject: any) => {
                                  setFieldValue(
                                    `details.${indexOfStabilizedItem}.userRealPossessionQuantity`,
                                    valuesObject?.value ? Number(valuesObject?.value) : ''
                                  )

                                  // Check if all real quantity values were cleared
                                  if (valuesObject?.value) {
                                    setIsInventoryControlModifiedAndUnsaved(true)
                                  }
                                }}
                                getInputRef={(
                                  userRealPossessionQuantityInput: HTMLInputElement
                                ) => {
                                  if (
                                    userRealPossessionQuantityInput &&
                                    !userRealPossessionQuantityInputsRefs.includes(
                                      userRealPossessionQuantityInput
                                    )
                                  ) {
                                    userRealPossessionQuantityInputsRefs.push(
                                      userRealPossessionQuantityInput
                                    )
                                  }
                                }}
                              />
                            </InputGroup>
                          )}
                        </td>
                      )
                    },
                  }}
                />
              </Form>
            )}
          </Formik>
        </CCardBody>
      </CCard>
    </Container>
  )
}
