import { TAdminPayload, ISingleItemActionPayload, TFilterState, TSorterState } from './admin'
import { TNetworkError } from './auth'

export const FETCH_PRODUCERS_REQUESTED = 'FETCH_PRODUCERS_REQUESTED'
export const FETCH_PRODUCERS_SUCCEEDED = 'FETCH_PRODUCERS_SUCCEEDED'
export const FETCH_PRODUCERS_ERROR = 'FETCH_PRODUCERS_ERROR'

export const SET_PRODUCERS_TABLE_FILTER_STATE = 'SET_PRODUCERS_TABLE_FILTER_STATE'
export const SET_PRODUCERS_TABLE_SORTER_STATE = 'SET_PRODUCERS_TABLE_SORTER_STATE'

export const CREATE_PRODUCER_REQUESTED = 'CREATE_PRODUCER_REQUESTED'
export const CREATE_PRODUCER_SUCCEEDED = 'CREATE_PRODUCER_SUCCEEDED'
export const CREATE_PRODUCER_ERROR = 'CREATE_PRODUCER_ERROR'

export const EDIT_PRODUCER_REQUESTED = 'EDIT_PRODUCER_REQUESTED'
export const EDIT_PRODUCER_SUCCEEDED = 'EDIT_PRODUCER_SUCCEEDED'
export const EDIT_PRODUCER_ERROR = 'EDIT_PRODUCER_ERROR'

export const DELETE_PRODUCER_REQUESTED = 'DELETE_PRODUCER_REQUESTED'
export const DELETE_PRODUCER_SUCCEEDED = 'DELETE_PRODUCER_SUCCEEDED'
export const DELETE_PRODUCER_ERROR = 'DELETE_PRODUCER_ERROR'

export const ADD_PRODUCER_TO_STORE = 'ADD_PRODUCER_TO_STORE'

export const OPEN_PRODUCER_MODAL = 'OPEN_PRODUCER_MODAL'
export const CLOSE_PRODUCER_MODAL = 'CLOSE_PRODUCER_MODAL'

export type TProducer = {
  name: string
  code: string
  canDelete: boolean
  id: number
}

export interface ICreateProducerPayload extends TAdminPayload {
  producer: TProducer
}

// FETCH_PRODUCERS_REQUESTED

export interface fetchProducersAction {
  type: typeof FETCH_PRODUCERS_REQUESTED
  payload: TAdminPayload
}

export interface fetchProducersSucceeded {
  type: typeof FETCH_PRODUCERS_SUCCEEDED
  producers: TProducer[]
}

export interface fetchProducersError {
  type: typeof FETCH_PRODUCERS_ERROR
  error: TNetworkError
}

// SET_PRODUCERS_TABLE_FILTER_STATE

export interface setProducersTableFilterState {
  type: typeof SET_PRODUCERS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_PRODUCERS_TABLE_SORTER_STATE

export interface setProducersTableSorterState {
  type: typeof SET_PRODUCERS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_PRODUCER_REQUESTED

export interface createProducerAction {
  type: typeof CREATE_PRODUCER_REQUESTED
  payload: ICreateProducerPayload
}

export interface createProducerSucceeded {
  type: typeof CREATE_PRODUCER_SUCCEEDED
  producer: TProducer
}

export interface createProducerError {
  type: typeof CREATE_PRODUCER_ERROR
  error: TNetworkError
}

// EDIT_PRODUCER_REQUESTED

export interface editProducerAction {
  type: typeof EDIT_PRODUCER_REQUESTED
  payload: ICreateProducerPayload
}

export interface editProducerSucceeded {
  type: typeof EDIT_PRODUCER_SUCCEEDED
  producer: TProducer
}

export interface editProducerError {
  type: typeof EDIT_PRODUCER_ERROR
  error: TNetworkError
}

// DELETE_PRODUCER_REQUESTED

export interface deleteProducerAction {
  type: typeof DELETE_PRODUCER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteProducerSucceeded {
  type: typeof DELETE_PRODUCER_SUCCEEDED
  id: number
}

export interface deleteProducerError {
  type: typeof DELETE_PRODUCER_ERROR
  error: TNetworkError
}

// ADD_PRODUCER_TO_STORE

export interface addProducerToStore {
  type: typeof ADD_PRODUCER_TO_STORE
  producer: TProducer
}

// OPEN_PRODUCER_MODAL

export interface openProducerModalAction {
  type: typeof OPEN_PRODUCER_MODAL
  editedProducer: TProducer | null
}

export interface closeProducerModalAction {
  type: typeof CLOSE_PRODUCER_MODAL
}

export type ProducersActionTypes =
  | fetchProducersAction
  | fetchProducersSucceeded
  | fetchProducersError
  | setProducersTableFilterState
  | setProducersTableSorterState
  | createProducerAction
  | createProducerSucceeded
  | createProducerError
  | editProducerAction
  | editProducerSucceeded
  | editProducerError
  | deleteProducerAction
  | deleteProducerSucceeded
  | deleteProducerError
  | addProducerToStore
  | openProducerModalAction
  | closeProducerModalAction
