import * as actionTypes from '../action-types'

export interface IReportsState {
  isCurrentManufacturingReportLoading: boolean
  currentManufacturingReport: actionTypes.TCurrentManufacturingReport | null
  fetchCurrentManuafcturingReportError: actionTypes.TNetworkError
}

const initialState: IReportsState = {
  isCurrentManufacturingReportLoading: false,
  currentManufacturingReport: null,
  fetchCurrentManuafcturingReportError: null,
}

export default function reportsReducer(
  state = initialState,
  action: actionTypes.ReportsActionTypes
): IReportsState {
  switch (action.type) {
    case actionTypes.FETCH_CURRENT_MANUFACTURING_REPORT_REQUESTED: {
      return {
        ...state,
        currentManufacturingReport: null,
        fetchCurrentManuafcturingReportError: null,
        isCurrentManufacturingReportLoading: true,
      }
    }

    case actionTypes.FETCH_CURRENT_MANUFACTURING_REPORT_SUCCEEDED: {
      return {
        ...state,
        currentManufacturingReport: action.currentManufacturingReport,
        isCurrentManufacturingReportLoading: false,
      }
    }

    case actionTypes.FETCH_CURRENT_MANUFACTURING_REPORT_ERROR: {
      return {
        ...state,
        currentManufacturingReport: null,
        fetchCurrentManuafcturingReportError: action.error,
        isCurrentManufacturingReportLoading: false,
      }
    }

    default:
      return state
  }
}
