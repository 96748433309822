import React from 'react'
import BomElementsList from '../views/bom-elements/bom-elements'
import BOMs from '../views/boms/boms'
import Orders from '../views/orders/orders'
import AuditLogs from '../views/audit-logs/audit-logs'
import Offers from '../views/offers/offers'
import PurchasePlans from '../views/purchase-plans/purchase-plans'
import PurchasePlanDetails from '../views/purchase-plans/purchase-plan-details'
import Services from '../views/dictionaries/services'
import Producers from '../views/dictionaries/producers'
import ImplementationCosts from '../views/dictionaries/implementation-costs'
import Settings from '../views/settings/settings'
import Users from '../views/users/users'
import Warehouses from '../views/warehouses/warehouses'
import WarehouseManagement from '../views/warehouses/warehouse-management/warehouse-management'
import ManufacturingSteps from '../views/manufacturing/manufacturing-steps'
import ManufacturingActivities from '../views/manufacturing/manufacturing-activities'
import TeamMembers from '../views/manufacturing/team-members'
import ManufacturingErrands from '../views/manufacturing/manufacturing-errands'
import Reports from '../views/reports/reports'

import { OrderDetails } from '../views/orders/order-details'
import { BomElementDetails } from '../views/bom-elements/bom-element-details'
import { SupplierDetails } from '../views/suppliers/supplier-details'
import { OfferDetails } from '../views/offers/offer-details'
import { BomDetails } from '../views/boms/bom-details'
import { TUserRoleSpecificReadWrite } from '../store/action-types'
import { UserDetails } from '../views/users/user-details'
import { Profiles } from '../views/users/profiles'
import { ImplementationCostDetails } from '../views/dictionaries/implementation-cost-details'
import { ServiceDetails } from '../views/dictionaries/service-details'
import { WarehouseDetails } from '../views/warehouses/warehouse-details'
import { InventoriesOfWarehouses } from '../views/warehouses/inventories-of-warehouses'
import { InventoryControl } from '../views/warehouses/warehouse-management/inventory-control'
import { MaterialsReplenishment } from '../views/warehouses/warehouse-management/materials-replenishment'
import { WaresReplenishment } from '../views/warehouses/warehouse-management/wares-replenishment'
import { FinishedProductsReplenishment } from '../views/warehouses/warehouse-management/finished-products-replenishment'
import { MoveReplenishment } from '../views/warehouses/warehouse-management/move-replenishment'
import { DocumentsOfWarehouses } from '../views/warehouses/documents-of-warehouses'
import { ManufacturingStepsDetails } from '../views/manufacturing/manufacturing-steps-details'
import { TeamMemberDetails } from '../views/manufacturing/team-member-details'
import { ManufacturingReplenishment } from '../views/warehouses/warehouse-management/manufacturing-replenishment'
import { CurrentManufacturingReport } from '../views/reports/current-manufacturing-report'

const Suppliers = React.lazy(() => import('../views/suppliers/suppliers'))

export type TErpRoute = {
  path: string
  exact?: boolean
  name: string
  component: React.FC
  roleReadKeyword?: TUserRoleSpecificReadWrite
}

const erpRoutes: TErpRoute[] = [
  {
    path: '/:tenant/suppliers',
    exact: true,
    name: 'SuppliersList',
    component: Suppliers,
    roleReadKeyword: 'Suppliers_read',
  },
  // It is important to keep Create above Edit for proper tab work in create mode
  {
    path: '/:tenant/suppliers/:mode',
    exact: true,
    name: 'SupplierCreate',
    component: SupplierDetails,
    roleReadKeyword: 'Suppliers_write',
  },
  {
    path: '/:tenant/suppliers/:mode/:tab',
    exact: true,
    name: 'SupplierCreate',
    component: SupplierDetails,
    roleReadKeyword: 'Suppliers_write',
  },
  {
    path: '/:tenant/suppliers/:mode/:id/:tab',
    exact: true,
    name: 'SupplierEdit',
    component: SupplierDetails,
    roleReadKeyword: 'Suppliers_read',
  },

  {
    path: '/:tenant/bom-elements',
    exact: true,
    name: 'BomElements',
    component: BomElementsList,
    roleReadKeyword: 'BomElements_read',
  },
  {
    path: '/:tenant/bom-elements/:mode',
    exact: true,
    name: 'BomElementCreate',
    component: BomElementDetails,
    roleReadKeyword: 'BomElements_write',
  },
  // It is important to keep Create above Edit for proper tab work in create mode
  {
    path: '/:tenant/bom-elements/:mode/:tab',
    exact: true,
    name: 'BomElementCreate',
    component: BomElementDetails,
    roleReadKeyword: 'BomElements_write',
  },
  {
    path: '/:tenant/bom-elements/:mode/:id',
    exact: true,
    name: 'BomElementEdit',
    component: BomElementDetails,
    roleReadKeyword: 'BomElements_read',
  },
  {
    path: '/:tenant/bom-elements/:mode/:id/:tab',
    exact: true,
    name: 'BomElementEdit',
    component: BomElementDetails,
    roleReadKeyword: 'BomElements_read',
  },

  {
    path: '/:tenant/boms',
    exact: true,
    name: 'Boms',
    component: BOMs,
    roleReadKeyword: 'Boms_read',
  },
  // It is important to keep Create above Edit for proper tab work in create mode
  {
    path: '/:tenant/boms/:mode',
    exact: true,
    name: 'BomDetailsCreate',
    component: BomDetails,
    roleReadKeyword: 'Boms_write',
  },
  {
    path: '/:tenant/boms/:mode/:tab',
    exact: true,
    name: 'BomDetailsCreate',
    component: BomDetails,
    roleReadKeyword: 'Boms_write',
  },
  {
    path: '/:tenant/boms/:mode/:id',
    exact: true,
    name: 'BomDetailsEdit',
    component: BomDetails,
    roleReadKeyword: 'Boms_read',
  },
  {
    path: '/:tenant/boms/:mode/:id/:tab',
    exact: true,
    name: 'BomDetailsEdit',
    component: BomDetails,
    roleReadKeyword: 'Boms_read',
  },

  {
    path: '/:tenant/offers',
    exact: true,
    name: 'Offers',
    component: Offers,
    roleReadKeyword: 'Offers_read',
  },
  // It is important to keep Create above Edit for proper tab work in create mode
  {
    path: '/:tenant/offers/:mode',
    exact: true,
    name: 'OfferDetailsCreate',
    component: OfferDetails,
    roleReadKeyword: 'Offers_write',
  },
  {
    path: '/:tenant/offers/:mode/:tab',
    exact: true,
    name: 'OfferDetailsCreate',
    component: OfferDetails,
    roleReadKeyword: 'Offers_write',
  },
  {
    path: '/:tenant/offers/:mode/:id',
    exact: true,
    name: 'OfferDetailsEdit',
    component: OfferDetails,
    roleReadKeyword: 'Offers_read',
  },
  {
    path: '/:tenant/offers/:mode/:id/:tab',
    exact: true,
    name: 'OfferDetailsEdit',
    component: OfferDetails,
    roleReadKeyword: 'Offers_read',
  },

  /* PURCHASE PLANS */

  {
    path: '/:tenant/purchase-plans',
    exact: true,
    name: 'PurchasePlans',
    component: PurchasePlans,
    roleReadKeyword: 'PurchasePlans_read',
  },
  {
    path: '/:tenant/purchase-plans/create-from-boms',
    exact: true,
    name: 'PurchasePlans',
    component: PurchasePlans,
    roleReadKeyword: 'PurchasePlans_write',
  },

  /* PURCHASE PLAN */

  {
    path: '/:tenant/purchase-plan/:id',
    exact: true,
    name: 'PurchasePlanDetails',
    component: PurchasePlanDetails,
    roleReadKeyword: 'PurchasePlans_read',
  },
  {
    path: '/:tenant/purchase-plan/:id/:tab',
    exact: true,
    name: 'PurchasePlanDetails',
    component: PurchasePlanDetails,
    roleReadKeyword: 'PurchasePlans_read',
  },
  {
    path: '/:tenant/purchase-plan/:id/:tab/:elementDetailsId',
    exact: true,
    name: 'PurchasePlanDetails',
    component: PurchasePlanDetails,
    roleReadKeyword: 'PurchasePlans_read',
  },
  {
    path: '/:tenant/purchase-plan/:id/:tab/:elementDetailsId/:bomIdTab',
    exact: true,
    name: 'PurchasePlanDetails',
    component: PurchasePlanDetails,
    roleReadKeyword: 'PurchasePlans_read',
  },

  /* ORDER */

  {
    path: '/:tenant/orders',
    exact: true,
    name: 'Orders',
    component: Orders,
    roleReadKeyword: 'Orders_read',
  },
  {
    path: '/:tenant/order/:id',
    exact: true,
    name: 'Order',
    component: OrderDetails,
    roleReadKeyword: 'Orders_read',
  },
  {
    path: '/:tenant/order/:id/:tab',
    exact: true,
    name: 'Order',
    component: OrderDetails,
    roleReadKeyword: 'Orders_read',
  },

  /* DICTIONARIES */

  {
    path: '/:tenant/producers',
    exact: true,
    name: 'Producers',
    component: Producers,
    roleReadKeyword: 'Producers_read',
  },
  {
    path: '/:tenant/producers/create',
    exact: true,
    name: 'Producers',
    component: Producers,
    roleReadKeyword: 'Producers_read',
  },
  {
    path: '/:tenant/producers/edit/:id',
    exact: true,
    name: 'Producers',
    component: Producers,
    roleReadKeyword: 'Producers_read',
  },

  {
    path: '/:tenant/services',
    exact: true,
    name: 'Services',
    component: Services,
    roleReadKeyword: 'Services_read',
  },
  // It is important to keep Create above Edit for proper tab work in create mode
  {
    path: '/:tenant/services/:mode',
    exact: true,
    name: 'ServiceCreate',
    component: ServiceDetails,
    roleReadKeyword: 'Services_write',
  },
  {
    path: '/:tenant/services/:mode/:tab',
    exact: true,
    name: 'ServiceCreate',
    component: ServiceDetails,
    roleReadKeyword: 'Services_write',
  },
  {
    path: '/:tenant/services/:mode/:id',
    exact: true,
    name: 'ServiceEdit',
    component: ServiceDetails,
    roleReadKeyword: 'Services_read',
  },
  {
    path: '/:tenant/services/:mode/:id/:tab',
    exact: true,
    name: 'ServiceEdit',
    component: ServiceDetails,
    roleReadKeyword: 'Services_read',
  },
  {
    path: '/:tenant/implementation-costs',
    exact: true,
    name: 'ImplementationCosts',
    component: ImplementationCosts,
    roleReadKeyword: 'ImplementationCosts_read',
  },
  // It is important to keep Create above Edit for proper tab work in create mode
  {
    path: '/:tenant/implementation-costs/:mode',
    exact: true,
    name: 'ImplementationCostCreate',
    component: ImplementationCostDetails,
    roleReadKeyword: 'ImplementationCosts_write',
  },
  {
    path: '/:tenant/implementation-costs/:mode/:tab',
    exact: true,
    name: 'ImplementationCostCreate',
    component: ImplementationCostDetails,
    roleReadKeyword: 'ImplementationCosts_write',
  },
  {
    path: '/:tenant/implementation-costs/:mode/:id',
    exact: true,
    name: 'ImplementationCostEdit',
    component: ImplementationCostDetails,
    roleReadKeyword: 'ImplementationCosts_read',
  },
  {
    path: '/:tenant/implementation-costs/:mode/:id/:tab',
    exact: true,
    name: 'ImplementationCostEdit',
    component: ImplementationCostDetails,
    roleReadKeyword: 'ImplementationCosts_read',
  },

  /* WAREHOUSES */

  {
    path: '/:tenant/warehouses',
    exact: true,
    name: 'Warehouses',
    component: Warehouses,
    roleReadKeyword: 'Warehouses_read',
  },
  {
    path: '/:tenant/warehouses/:mode',
    exact: true,
    name: 'WarehouseCreate',
    component: WarehouseDetails,
    roleReadKeyword: 'Warehouses_write',
  },
  {
    path: '/:tenant/warehouses/:mode/:id',
    exact: true,
    name: 'WarehouseEdit',
    component: WarehouseDetails,
    roleReadKeyword: 'Warehouses_read',
  },

  /* WAREHOUSE MANGAGEMENT */

  {
    path: '/:tenant/warehouse-management/:id',
    exact: true,
    name: 'WarehouseManagement',
    component: WarehouseManagement,
    roleReadKeyword: 'Warehouses_read',
  },
  {
    path: '/:tenant/warehouse-management/:id/materials-replenishment/:tab',
    exact: true,
    name: 'WarehouseMaterialsReplenishment',
    component: MaterialsReplenishment,
    roleReadKeyword: 'Warehouses_read',
  },
  {
    path: '/:tenant/warehouse-management/:id/wares-replenishment',
    exact: true,
    name: 'WarehouseWaresReplenishment',
    component: WaresReplenishment,
    roleReadKeyword: 'Warehouses_read',
  },
  {
    path: '/:tenant/warehouse-management/:id/finished-products-replenishment',
    exact: true,
    name: 'WarehouseFinishedProductsReplenishment',
    component: FinishedProductsReplenishment,
    roleReadKeyword: 'Warehouses_read',
  },
  {
    path: '/:tenant/warehouse-management/:id/move-replenishment',
    exact: true,
    name: 'WarehouseMoveReplenishment',
    component: MoveReplenishment,
    roleReadKeyword: 'Warehouses_read',
  },
  {
    path: '/:tenant/warehouse-management/:id/manufacturing-replenishment',
    exact: true,
    name: 'WarehouseManufacturingReplenishment',
    component: ManufacturingReplenishment,
    roleReadKeyword: 'Warehouses_read',
  },
  {
    path: '/:tenant/warehouse-management/:id/inventory-control',
    exact: true,
    name: 'WarehouseInventoryControl',
    component: InventoryControl,
    roleReadKeyword: 'Warehouses_read',
  },

  /* INVENTORIES OF WAREHOUSES */

  {
    path: '/:tenant/inventories-of-warehouses',
    exact: true,
    name: 'InventoriesOfWarehouses',
    component: InventoriesOfWarehouses,
    roleReadKeyword: 'Warehouses_read',
  },

  /* DOCUMENTS OF WAREHOUSES */

  {
    path: '/:tenant/documents-of-warehouses',
    exact: true,
    name: 'DocumentsOfWarehouses',
    component: DocumentsOfWarehouses,
    roleReadKeyword: 'Warehouses_read',
  },

  /* MANUFACTURING STEPS */

  {
    path: '/:tenant/manufacturing-steps',
    exact: true,
    name: 'ManufacturingSteps',
    component: ManufacturingSteps,
    // ManufacturingSteps_read
    roleReadKeyword: 'Manufacturing_read',
  },
  {
    path: '/:tenant/manufacturing-steps/:mode',
    exact: true,
    name: 'ManufacturingStepsCreate',
    component: ManufacturingStepsDetails,
    // ManufacturingSteps_write
    roleReadKeyword: 'Manufacturing_write',
  },
  {
    path: '/:tenant/manufacturing-steps/:mode/:id',
    exact: true,
    name: 'ManufacturingStepsEdit',
    component: ManufacturingStepsDetails,
    // ManufacturingSteps_write
    roleReadKeyword: 'Manufacturing_write',
  },

  /* MANUFACTURING ACTIVITIES */

  {
    path: '/:tenant/manufacturing-activities',
    exact: true,
    name: 'ManufacturingActivities',
    component: ManufacturingActivities,
    // ManufacturingSteps_write
    roleReadKeyword: 'Manufacturing_write',
  },
  {
    path: '/:tenant/manufacturing-activities/create',
    exact: true,
    name: 'ManufacturingActivities',
    component: ManufacturingActivities,
    roleReadKeyword: 'Manufacturing_write',
  },
  {
    path: '/:tenant/manufacturing-activities/edit/:id',
    exact: true,
    name: 'ManufacturingActivities',
    component: ManufacturingActivities,
    roleReadKeyword: 'Manufacturing_write',
  },

  /* TEAM MEMBERS */

  {
    path: '/:tenant/team-members',
    exact: true,
    name: 'TeamMembers',
    component: TeamMembers,
    // Manufacturing_write
    roleReadKeyword: 'Manufacturing_read',
  },
  {
    path: '/:tenant/team-members/edit/:id',
    exact: true,
    name: 'TeamMemberEdit',
    component: TeamMemberDetails,
    roleReadKeyword: 'Manufacturing_write',
  },
  {
    path: '/:tenant/team-members/edit/:id/:tab',
    exact: true,
    name: 'TeamMemberEdit',
    component: TeamMemberDetails,
    roleReadKeyword: 'Manufacturing_write',
  },

  /* MANUFACTURING ERRANDS */

  {
    path: '/:tenant/manufacturing-errands',
    exact: true,
    name: 'ManufacturingErrands',
    component: ManufacturingErrands,
    // ManufacturingSteps_write
    roleReadKeyword: 'Manufacturing_write',
  },
  {
    path: '/:tenant/manufacturing-errands/create',
    exact: true,
    name: 'ManufacturingErrands',
    component: ManufacturingErrands,
    roleReadKeyword: 'Manufacturing_write',
  },
  {
    path: '/:tenant/manufacturing-errands/edit/:id',
    exact: true,
    name: 'ManufacturingErrands',
    component: ManufacturingErrands,
    roleReadKeyword: 'Manufacturing_write',
  },

  /* REPORTS */

  {
    path: '/:tenant/reports',
    exact: true,
    name: 'Reports',
    component: Reports,
    roleReadKeyword: 'Users_read',
  },

  {
    path: '/:tenant/reports/current-manufacturing-report',
    exact: true,
    name: 'CurrentManufacturingReport',
    component: CurrentManufacturingReport,
    roleReadKeyword: 'Users_read',
  },

  /* SETTINGS */

  {
    path: '/:tenant/admin/settings',
    exact: true,
    name: 'Settings',
    component: Settings,
    roleReadKeyword: 'Users_write',
  },

  {
    path: '/:tenant/admin/settings/:tab',
    exact: true,
    name: 'Settings',
    component: Settings,
    roleReadKeyword: 'Users_write',
  },

  /* AUDIT LOGS */

  {
    path: '/:tenant/admin/logs',
    exact: true,
    name: 'AuditLogs',
    component: AuditLogs,
    roleReadKeyword: 'AuditLogs_read',
  },

  /* USERS */

  {
    path: '/:tenant/admin/users',
    exact: true,
    name: 'Users',
    component: Users,
    roleReadKeyword: 'Users_read',
  },

  {
    path: '/:tenant/admin/users/:mode',
    exact: true,
    name: 'UserCreate',
    component: UserDetails,
    roleReadKeyword: 'Users_write',
  },

  {
    path: '/:tenant/admin/users/:mode/:id',
    exact: true,
    name: 'UserEdit',
    component: UserDetails,
    roleReadKeyword: 'Users_write',
  },

  {
    path: '/:tenant/admin/profiles',
    exact: true,
    name: 'ProfilesEdit',
    component: Profiles,
    roleReadKeyword: 'Users_write',
  },
]

export default erpRoutes
