import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner, Table } from 'reactstrap'
import { Formik, Form, ErrorMessage, FieldArray, Field } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton, CLabel } from '@coreui/react'
import deepEqual from 'deep-equal'
import { confirmAlert } from 'react-confirm-alert'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { ConfirmActionModal, FormActionsPanel, ThreeDots } from '../../components'
import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  getErrorMessageFromStatus,
  preventNavigationChange,
} from '../../utils'
import { BasicFormField, CustomErrorMessage } from '../../components'
import { Select } from '@material-ui/core'

export const Profiles: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{
    tenant: string
    tab: string
  }>()

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isOneFieldChanged, setIsOneFieldChanged] = useState(false)
  const [areProfilesModifiedAndUnsaved, setAreProfilesModifiedAndUnsaved] = useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const usersState = useSelector((state: TRootState) => state.users)

  const userRoleStateValues: actionTypes.TUserRoleStateValue[] = [0, 1, 2]

  const areProfilesEditable =
    authState?.authData?.roles?.includes('Users_write') && !usersState.fetchProfilesError

  const closeProfiles = useCallback(() => {
    history.push(`/${tenant}/admin/users`)
  }, [history, tenant])

  const rolesTranslatedToPolish: { [key in actionTypes.EUserRoleName]: string } = {
    Suppliers: 'Dostawcy',
    BomElements: 'Elementy',
    Offers: 'Oferty',
    Producers: 'Producenci',
    ImplementationCosts: 'Tooling',
    Services: 'Usługi',
    Boms: 'Lista BOM',
    PurchasePlans: 'Zapotrzebowania',
    Orders: 'Zamówienia',
    Warehouses: 'Magazyny',
    Manufacturing: 'Produkcja',
    AuditLogs: 'Audyt akcji',
    Users: 'Użytkownicy',
  }

  const allAvailableRoles = Object.values(actionTypes.EUserRoleName)?.map(
    (roleNameFromEnum: actionTypes.TUserRoleName) => ({
      roleName: roleNameFromEnum,
      state: 0,
    })
  ) as actionTypes.TEditableUserRole[]

  // Fetch Profiles on each mount because the canDelete parameter might change
  useEffect(() => {
    if (tenant && authState.authData) {
      dispatch({
        type: actionTypes.FETCH_PROFILES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [dispatch, tenant, authState])

  // Track the changes again
  useEffect(() => {
    if (usersState.areProfilesSaved) {
      setAreProfilesModifiedAndUnsaved(false)
    }
  }, [usersState.areProfilesSaved])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      areProfilesModifiedAndUnsaved,
      areProfilesEditable,
      'profiles',
      '',
      ''
    )

    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, areProfilesModifiedAndUnsaved, areProfilesEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.PROFILES_ACTIONS_UNLOCK })
    }
  }, [])

  // Validation Schema
  const ProfilesSchema = Yup.object().shape({})

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="users-profiles-details">
          <h4
            className={`text-center ${
              usersState.profilesSaveError || usersState.fetchProfilesError ? 'mb-2' : 'mb-4'
            }`}
          >
            Profile Użytkowników{usersState.areProfilesLoading && <ThreeDots />}
          </h4>

          {usersState.areProfilesLoading ? (
            <div
              style={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                profiles: usersState?.profiles || [],
                selectedProfileIndex: undefined,
              }}
              validationSchema={ProfilesSchema}
              onSubmit={(values) => {
                if (areProfilesEditable) {
                  setDidFormValidationOccur(true)
                  setIsOneFieldChanged(false)

                  dispatch({
                    type: actionTypes.SAVE_PROFILES_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      profiles: { profiles: values.profiles },
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ initialValues, values, handleChange, setFieldValue }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setAreProfilesModifiedAndUnsaved(true)
                    } else {
                      setAreProfilesModifiedAndUnsaved(false)
                    }

                    setIsOneFieldChanged(true)
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {usersState?.fetchProfilesError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        usersState?.fetchProfilesError?.status ||
                          usersState?.fetchProfilesError?.data?.status,
                        'profili'
                      )}
                    />
                  )}

                  {!isOneFieldChanged && usersState?.profilesSaveError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'edit',
                        usersState?.profilesSaveError?.status ||
                          usersState?.profilesSaveError?.data?.status,
                        'profili'
                      )}
                    />
                  )}

                  <FieldArray
                    name="profiles"
                    render={(arrayHelpers) => (
                      <>
                        <div>
                          <CLabel
                            htmlFor="profiles-select"
                            className={`${inputLabelSpacingBottom}`}
                          >
                            Wybierz profil
                          </CLabel>
                          <InputGroup id="profiles-select" className={`${inputFieldSpacingBottom}`}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className={'' && 'text-danger input-error-icon'}>
                                <i className="cil-contact"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <ErrorMessage
                              name="selectedProfileIndex"
                              component="span"
                              className="text-danger input-error-message"
                            />
                            <Field
                              as={Select}
                              name="selectedProfileIndex"
                              variant="outlined"
                              native
                              className={`item-selector element-selector w-100 ${
                                '' && 'form-control is-invalid'
                              } ${
                                values?.selectedProfileIndex !== undefined &&
                                values?.selectedProfileIndex >= 0
                                  ? ''
                                  : 'element-selector-placeholder'
                              }`}
                              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                if (document && document.activeElement) {
                                  const elem: any = document.activeElement
                                  elem.blur()
                                }

                                handleChange(event)
                              }}
                            >
                              <option className="select-option-placeholder" value={undefined}>
                                Wybierz profil z listy
                              </option>
                              {values?.profiles?.length > 0 ? (
                                values?.profiles?.map(
                                  (
                                    profileItem: actionTypes.TUserProfile,
                                    profileItemIndex: number
                                  ) => {
                                    return (
                                      <option key={profileItem.uuid} value={profileItemIndex}>
                                        {profileItem?.newName || profileItem.name}
                                      </option>
                                    )
                                  }
                                )
                              ) : (
                                <option value="none" disabled>
                                  Brak profili do wyboru!
                                </option>
                              )}
                              )
                            </Field>
                          </InputGroup>
                        </div>

                        <BasicFormField
                          fieldId="profiles-new-name"
                          fieldLabel="Dostosuj nazwę"
                          fieldIcon="cil-short-text"
                          formikFieldName={`profiles.${values?.selectedProfileIndex}.newName`}
                          fieldValue={
                            (values?.selectedProfileIndex !== undefined &&
                              values?.profiles[values?.selectedProfileIndex]?.newName) ||
                            ''
                          }
                          fieldError={''}
                          fieldType="text"
                          placeholder="Opcjonalnie dostosuj nazwę profilu"
                          isDisabled={isNaN(values?.selectedProfileIndex as any)}
                        />

                        {values?.selectedProfileIndex !== undefined &&
                          values?.selectedProfileIndex >= 0 && (
                            <div className="pt-2">
                              <Table
                                bordered
                                striped
                                className="item-inner-details-table mb-3 mt-2"
                                id="profiles-list-roles-states-panel"
                              >
                                <thead>
                                  <tr>
                                    <th style={{ width: '1fr', textAlign: 'left' }}>Funkcja</th>
                                    <th style={{ width: '140px', textAlign: 'center' }}>
                                      Brak dostępu
                                    </th>
                                    <th style={{ width: '140px', textAlign: 'center' }}>Odczyt</th>
                                    <th style={{ width: '130px', textAlign: 'center' }}>
                                      Modyfikacja
                                    </th>
                                  </tr>
                                </thead>

                                {values?.profiles &&
                                values?.profiles[values.selectedProfileIndex]?.roles?.length > 0 ? (
                                  <tbody>
                                    {values?.profiles[values.selectedProfileIndex]?.roles?.map(
                                      (
                                        roleItem: actionTypes.TEditableUserRole,
                                        roleIndex: number
                                      ) => (
                                        <tr key={`profile-${roleIndex}-${roleItem.roleName}`}>
                                          <td>{rolesTranslatedToPolish[roleItem.roleName]}</td>

                                          {userRoleStateValues?.map(
                                            (
                                              roleStateValue: actionTypes.TUserRoleStateValue,
                                              roleStateValueIndex: number
                                            ) => (
                                              <td
                                                className="text-center"
                                                key={`profile-${roleIndex}-${roleItem.roleName}-state-${roleStateValueIndex}-${roleStateValue}`}
                                                onClick={() => {
                                                  setFieldValue(
                                                    `profiles.${values.selectedProfileIndex}.roles.${roleIndex}.state`,
                                                    roleStateValue
                                                  )
                                                }}
                                              >
                                                <Field
                                                  as="input"
                                                  type="radio"
                                                  name={`profiles.${values.selectedProfileIndex}.roles.${roleIndex}.state`}
                                                  id={`profiles.${values.selectedProfileIndex}.roles.${roleIndex}.state`}
                                                  value={roleStateValue}
                                                  checked={
                                                    Number(roleItem.state) === roleStateValue
                                                  }
                                                  onChange={(
                                                    event: ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    if (document && document.activeElement) {
                                                      const elem: any = document.activeElement
                                                      elem.blur()
                                                    }

                                                    handleChange(event)
                                                  }}
                                                />
                                              </td>
                                            )
                                          )}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                ) : null}
                              </Table>
                            </div>
                          )}

                        <div className="d-flex justify-content-center mb-3 mt-4 pt-2">
                          <CButton
                            color="danger"
                            type="button"
                            variant="outline"
                            onClick={() => {
                              confirmAlert({
                                closeOnEscape: true,

                                customUI: ({ onClose }) => {
                                  return (
                                    <ConfirmActionModal
                                      mode="delete"
                                      onClose={onClose}
                                      confirmMessageJSX={
                                        <>
                                          Czy na pewno chcesz usunąć profil?
                                          {values.selectedProfileIndex !== undefined &&
                                          values?.profiles[values.selectedProfileIndex] ? (
                                            <>
                                              <br />
                                              <strong>
                                                {values.profiles[values.selectedProfileIndex]
                                                  .newName ||
                                                  values.profiles[values.selectedProfileIndex].name}
                                              </strong>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      }
                                      onConfirmFunction={() => {
                                        if (
                                          values?.selectedProfileIndex !== undefined ||
                                          values?.selectedProfileIndex === 0
                                        ) {
                                          if (values.profiles.length === 1) {
                                            setFieldValue(`selectedProfileIndex`, undefined)
                                          }

                                          if (
                                            values.profiles.length > 1 &&
                                            values.selectedProfileIndex > 0
                                          ) {
                                            setFieldValue(
                                              `selectedProfileIndex`,
                                              values.selectedProfileIndex - 1
                                            )
                                          }

                                          arrayHelpers.remove(values?.selectedProfileIndex)

                                          setAreProfilesModifiedAndUnsaved(true)
                                        }
                                      }}
                                    />
                                  )
                                },
                              })
                            }}
                            disabled={isNaN(values?.selectedProfileIndex as any)}
                            className="px-4"
                          >
                            Usuń
                          </CButton>

                          <CButton
                            color="primary"
                            type="button"
                            variant="outline"
                            onClick={() => {
                              if (
                                values?.selectedProfileIndex ||
                                values?.selectedProfileIndex === 0
                              ) {
                                let copiedProfile: actionTypes.TUserProfile = {
                                  name: `${
                                    values.profiles[values.selectedProfileIndex].name
                                  } - Kopia`,
                                  newName: values.profiles[values.selectedProfileIndex].newName
                                    ? `${
                                        values.profiles[values.selectedProfileIndex].newName
                                      } - Kopia`
                                    : '',
                                  roles: values.profiles[values.selectedProfileIndex].roles,
                                  id: 0,
                                  uuid: uuidv4(),
                                }

                                arrayHelpers.push(copiedProfile)

                                setFieldValue(`selectedProfileIndex`, values.profiles.length)

                                setAreProfilesModifiedAndUnsaved(true)
                              }
                            }}
                            disabled={isNaN(values?.selectedProfileIndex as any)}
                            className="px-4 mx-4"
                          >
                            Kopiuj
                          </CButton>

                          <CButton
                            color="info"
                            type="button"
                            variant="outline"
                            onClick={() => {
                              const newProfile: actionTypes.TUserProfile = {
                                name: `Profil ${values.profiles.length + 1}`,
                                newName: '',
                                roles: allAvailableRoles,
                                id: 0,
                                uuid: uuidv4(),
                              }

                              arrayHelpers.push(newProfile)

                              setFieldValue(`selectedProfileIndex`, values.profiles.length)

                              setAreProfilesModifiedAndUnsaved(true)
                            }}
                            disabled={false}
                            className="px-4"
                          >
                            Nowy
                          </CButton>
                        </div>
                      </>
                    )}
                  />

                  <FormActionsPanel
                    mode={'edit'}
                    padding="pt-4 pb-1"
                    isSaving={usersState.areProfilesSaving}
                    isSaved={usersState.areProfilesSaved}
                    isDeleting={false}
                    isDeleted={false}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    setIsOneFieldChanged={setIsOneFieldChanged}
                    formErrorsBool={false}
                    closeAction={''}
                    deleteAction={''}
                    deletePayload={null}
                    closeFunction={closeProfiles}
                    isEditable={areProfilesEditable}
                    canDelete={true}
                    disabledDeleteButtonClassNames=""
                  />
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
