import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Formik, Form, FieldArray, FastField, ErrorMessage } from 'formik'
import NumberFormat from 'react-number-format'
import * as Yup from 'yup'
import { CModal, CButton, CInput, CModalHeader, CDataTable } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumericStringToNumber,
  getEmptyListMessage,
  getErrorMessageFromStatus,
  globalDecimalSeparator,
  globalThousandSeparator,
  successMessageDuration,
} from '../../utils'
import { CustomErrorMessage, ThreeDots } from '../../components'

export const PurchasePlanCreateFromBomsModal: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isOneFieldChanged, setIsOneFieldChanged] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const purchasePlansState = useSelector((state: TRootState) => state.purchasePlans)
  const bomsState = useSelector((state: TRootState) => state.boms)

  const closePurchasePlanCreateFromBomsModal = useCallback(() => {
    history.push(`/${tenant}/purchase-plans`)
    dispatch({
      type: actionTypes.CLOSE_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL,
    })
  }, [dispatch, history, tenant])

  // Close modal on a successful save
  useEffect(() => {
    if (
      !purchasePlansState.isPurchasePlanCreatingFromBoms &&
      !purchasePlansState.purchasePlanCreateFromBomsError &&
      purchasePlansState.isPurchasePlanCreatedFromBoms
    ) {
      setTimeout(() => {
        closePurchasePlanCreateFromBomsModal()
      }, successMessageDuration)
    }
  }, [purchasePlansState, closePurchasePlanCreateFromBomsModal])

  const PurchasePlanSchema = Yup.object().shape({
    bomsWithQuantity: Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number().when('checkbox', {
            is: true,
            then: Yup.number().min(1, 'Zbyt mała ilość produktu').required('Ilość jest wymagana'),
            otherwise: null,
          }),
          checkbox: Yup.boolean(),
          bomId: Yup.number(),
          name: Yup.string(),
        })
      )
      .test('atLeastOneChecked', 'Wybierz minimum jeden BOM!', (bomsWithQuantity) =>
        Boolean(bomsWithQuantity?.find((bom) => bom?.checkbox === true))
      ),
  })

  return (
    <CModal
      onClose={() => {
        closePurchasePlanCreateFromBomsModal()
      }}
      size=""
      show={true}
      centered
      backdrop
      closeOnBackdrop
      addContentClass="create-purchase-plan-modal py-4 px-5"
    >
      <CModalHeader className="c-modal-header-grid mb-1" closeButton>
        <h3
          className={`text-center ${
            !isOneFieldChanged && purchasePlansState.purchasePlanCreateFromBomsError ? '' : 'pb-2'
          }`}
        >
          Nowe Zapotrzebowanie z BOM
        </h3>
      </CModalHeader>

      <Formik
        enableReinitialize
        initialValues={{
          bomsWithQuantity: bomsState?.boms?.map((bom: actionTypes.IBomFull) => ({
            checkbox: false,
            bomId: bom.id,
            name: bom.name,
            quantity: '',
          })),
        }}
        validationSchema={PurchasePlanSchema}
        onSubmit={(values) => {
          setDidFormValidationOccur(true)
          setIsOneFieldChanged(false)

          dispatch({
            type: actionTypes.CREATE_PURCHASE_PLAN_FROM_BOMS_REQUESTED,
            payload: {
              tenant: tenant,
              token: authState.authData?.token,
              items: {
                purchasePlanBomInfos: values.bomsWithQuantity
                  ?.filter((bomPurchasePlan: any) => bomPurchasePlan.checkbox)
                  ?.map((bomPurchasePlan: actionTypes.TBomWithQuantity) => ({
                    bomId: bomPurchasePlan.bomId,
                    quantity: convertNumericStringToNumber(bomPurchasePlan.quantity),
                  })),
              },
            },
          })
        }}
        validateOnBlur={false}
        validateOnChange={didFormValidationOccur}
      >
        {({ errors, values, resetForm }) => (
          <Form
            onChange={() => {
              setIsOneFieldChanged(true)
            }}
            className="mt-1"
          >
            {!isOneFieldChanged && purchasePlansState?.purchasePlanCreateFromBomsError && (
              <CustomErrorMessage
                wrapperClassNames="my-3"
                customErrorMessageText={getErrorMessageFromStatus(
                  'create',
                  purchasePlansState?.purchasePlanCreateFromBomsError?.status
                )}
              />
            )}

            {!bomsState?.areBomsLoading && !bomsState?.boms?.length ? (
              <p className="text-center py-3">
                {bomsState?.fetchBomsError
                  ? `Wystąpił błąd podczas pobierania listy BOM!`
                  : `Brak BOM do wyboru!`}
              </p>
            ) : (
              <FieldArray
                name="bomsWithQuantity"
                render={() => (
                  <>
                    <CDataTable
                      loading={bomsState?.areBomsLoading}
                      striped={!!bomsState?.boms?.length}
                      border
                      tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                      sorter
                      cleaner
                      onRowClick={() => null}
                      addTableClasses="vertical-middle-list-table item-inner-details-table"
                      fields={[
                        {
                          key: 'checkbox',
                          label: '',
                          _style: { width: '40px' },
                        },
                        {
                          key: 'name',
                          label: 'BOM',
                          _style: { width: '300px' },
                        },
                        {
                          key: 'quantity',
                          label: 'Ilość',
                          sorter: false,
                          _style: { width: '150px' },
                        },
                      ]}
                      items={values?.bomsWithQuantity}
                      noItemsViewSlot={
                        <div className="no-items-in-table">
                          {getEmptyListMessage(
                            bomsState.fetchBomsError,
                            bomsState.areBomsLoading,
                            'BOM',
                            bomsState?.boms?.length
                          )}
                        </div>
                      }
                      scopedSlots={{
                        checkbox: (itemFromList: actionTypes.TBomWithQuantity) => (
                          <td style={{ textAlign: 'center' }}>
                            <FastField
                              as={CInput}
                              style={{ width: '20px', height: '20px' }}
                              type="checkbox"
                              // Do not use item index here because of filtering on table is enabled in this case
                              name={`bomsWithQuantity.${values.bomsWithQuantity.findIndex(
                                (bomWithQuantity: actionTypes.TBomWithQuantity) =>
                                  bomWithQuantity.bomId === itemFromList.bomId
                              )}.checkbox`}
                            />
                          </td>
                        ),
                        quantity: (itemFromList: actionTypes.TBomWithQuantity) => {
                          const stableItemIndex = values.bomsWithQuantity.findIndex(
                            (bomWithQuantity: actionTypes.TBomWithQuantity) =>
                              bomWithQuantity.bomId === itemFromList.bomId
                          )

                          return (
                            <td>
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                // Do not use item index here because of filtering on table is enabled in this case
                                name={`bomsWithQuantity.${stableItemIndex}.quantity`}
                                placeholder="Ilość produktów"
                                style={{ maxWidth: '125px' }}
                                className={`form-control text-right ${
                                  errors.bomsWithQuantity !== undefined &&
                                  typeof errors.bomsWithQuantity !== 'string' &&
                                  errors.bomsWithQuantity[stableItemIndex] &&
                                  errors.bomsWithQuantity[stableItemIndex]['quantity']
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                            </td>
                          )
                        },
                      }}
                    />
                  </>
                )}
              />
            )}

            <div
              className={`${
                errors?.bomsWithQuantity ? 'pt-2' : 'pt-3'
              } pb-1 d-flex flex-column justify-content-between`}
            >
              {errors?.bomsWithQuantity ? (
                typeof errors?.bomsWithQuantity === 'string' ? (
                  <ErrorMessage
                    name="bomsWithQuantity"
                    component="div"
                    className="text-danger d-flex justify-content-center mb-4"
                  />
                ) : (
                  <div className="text-danger text-center d-flex justify-content-center mb-4">
                    Wprowadź ilość produktów w wybranych BOM!
                    <br />
                    <span
                      style={{
                        fontSize: '0.82rem',
                        display: 'contents',
                        lineHeight: 2,
                        color: 'gray',
                      }}
                    >
                      Jeśli ich nie widzisz, to wyczyść filtrowanie tabeli!
                    </span>
                  </div>
                )
              ) : null}

              <div className="d-flex justify-content-between">
                <CButton
                  color="primary"
                  className="cancel-button mr-4"
                  variant="outline"
                  type="button"
                  onClick={() => {
                    resetForm()
                    setDidFormValidationOccur(false)
                    closePurchasePlanCreateFromBomsModal()
                  }}
                >
                  Anuluj
                </CButton>

                <CButton
                  color="success"
                  className="save-button px-1"
                  style={{
                    width: values?.bomsWithQuantity?.some(
                      (bomWithQuantity: actionTypes.TBomWithQuantity) => bomWithQuantity?.checkbox
                    )
                      ? '325px'
                      : '270px',
                  }}
                  type="submit"
                  onClick={() => {
                    setDidFormValidationOccur(true)
                  }}
                  disabled={
                    !values?.bomsWithQuantity?.some(
                      (bomWithQuantity: actionTypes.TBomWithQuantity) => bomWithQuantity?.checkbox
                    ) ||
                    (!purchasePlansState?.isPurchasePlanCreatingFromBoms &&
                    purchasePlansState?.isPurchasePlanCreatedFromBoms
                      ? true
                      : purchasePlansState?.isPurchasePlanCreatingFromBoms) ||
                    purchasePlansState?.isPurchasePlanCreatedFromBoms ||
                    (didFormValidationOccur ? Boolean(errors.bomsWithQuantity) : false)
                  }
                >
                  {values?.bomsWithQuantity?.some(
                    (bomWithQuantity: actionTypes.TBomWithQuantity) => bomWithQuantity?.checkbox
                  ) ? (
                    purchasePlansState?.isPurchasePlanCreatingFromBoms ? (
                      <>
                        Tworzenie zapotrzebowania z wybranych BOM
                        <ThreeDots />
                      </>
                    ) : purchasePlansState?.isPurchasePlanCreatedFromBoms ? (
                      <>Stworzono zapotrzebowanie z wybranych BOM!</>
                    ) : (
                      <>Stwórz zapotrzebowanie z wybranych BOM</>
                    )
                  ) : (
                    <>Wybierz BOM za pomocą checkboxa!</>
                  )}
                </CButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CModal>
  )
}
