import { CButton, CDataTable, CInput, CModal, CModalHeader } from '@coreui/react'
import { Field, FieldArrayRenderProps, FormikHandlers, FormikHelpers } from 'formik'
import { ChangeEvent } from 'react'
import NumberFormat from 'react-number-format'

import * as actionTypes from '../../store/action-types'
import { IBomsState } from '../../store/reducers/boms'
import { IManfuacturingState } from '../../store/reducers/manufacturing'
import {
  convertNumericStringToNumber,
  getEmptyListMessage,
  globalDecimalSeparator,
  globalThousandSeparator,
} from '../../utils'

interface IEditableBomForManufacturingSteps extends actionTypes.IBomFull {
  bomElementDetails: actionTypes.IBomElementFromEditableBomTableInModal[]
}

interface IBomIntermediateModal {
  setBomIntermediateInStepModalVisibility: React.Dispatch<React.SetStateAction<boolean>>
  setBomIntermediateInStepModalUuid: React.Dispatch<React.SetStateAction<string | null>>
  setInitialUnsavedEditedBomIntermediate: React.Dispatch<
    React.SetStateAction<actionTypes.IBomIntermediate | null>
  >
  setFieldValue: FormikHelpers<any>['setFieldValue']
  handleChange: FormikHandlers['handleChange']
  calculateRemainingQuantity: (
    bomElementFromEditableBomTableInModal: actionTypes.IBomElementFromEditableBomTableInModal,
    manufacturingSteps: actionTypes.IManufacturingStep[]
  ) => number
  bomIntermediateModalMode: 'create' | 'edit'
  manufacturingStepBomIntermediatesListArrayHelpers: FieldArrayRenderProps
  indexOfBomIntermediate: number
  indexOfManufacturingStep: number
  initialUnsavedEditedBomIntermediate: any
  bomIntermediate: actionTypes.IBomIntermediate
  manufacturingStep: actionTypes.IManufacturingStep
  manufacturingState: IManfuacturingState
  bomsState: IBomsState
  editableBomForManufacturingSteps: IEditableBomForManufacturingSteps | undefined
  manufacturingSteps: actionTypes.IManufacturingStep[]
}

export const ManufacturingStepsDetailsBomIntermediateModal: React.FC<IBomIntermediateModal> = ({
  setBomIntermediateInStepModalVisibility,
  setBomIntermediateInStepModalUuid,
  setFieldValue,
  setInitialUnsavedEditedBomIntermediate,
  handleChange,
  calculateRemainingQuantity,
  bomIntermediateModalMode,
  manufacturingStepBomIntermediatesListArrayHelpers,
  indexOfBomIntermediate,
  indexOfManufacturingStep,
  initialUnsavedEditedBomIntermediate,
  bomIntermediate,
  manufacturingStep,
  manufacturingState,
  bomsState,
  editableBomForManufacturingSteps,
  manufacturingSteps,
}) => {
  return (
    <CModal
      onClose={() => {
        setBomIntermediateInStepModalVisibility(false)
        setBomIntermediateInStepModalUuid(null)

        if (bomIntermediateModalMode === 'create') {
          manufacturingStepBomIntermediatesListArrayHelpers.remove(indexOfBomIntermediate)
        } else {
          setFieldValue(
            `manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}`,
            initialUnsavedEditedBomIntermediate
          )

          setInitialUnsavedEditedBomIntermediate(null)
        }
      }}
      size="xl"
      show={true}
      centered
      backdrop
      closeOnBackdrop={false}
      addContentClass="manufacturing-steps-details-intermediate-table-modal py-4 px-5"
    >
      <CModalHeader className="c-modal-header-grid mb-1" closeButton>
        <h3 className={`text-center pb-3`}>
          {bomIntermediateModalMode === 'create'
            ? 'Nowy Półprodukt'
            : `Edytuj ${bomIntermediate.name}`}{' '}
          ({manufacturingStep?.name})
        </h3>
      </CModalHeader>

      <CDataTable
        loading={false}
        striped={!!editableBomForManufacturingSteps?.bomElementDetails?.length}
        border
        tableFilter={{
          label: ' ',
          placeholder: 'Szukaj w tabeli...',
        }}
        sorterValue={bomIntermediateModalMode === 'edit' ? { column: 'checkbox', asc: false } : {}}
        sorter
        cleaner
        onRowClick={() => null}
        addTableClasses="vertical-middle-list-table item-inner-details-table maufacturing-step-intermediate-table-in-modal"
        fields={[
          {
            key: 'checkbox',
            label: '',
            _style: { width: '40px' },
          },
          {
            key: 'bomElementName',
            label: 'Nazwa elementu',
            _style: { width: '550px' },
          },
          {
            key: 'category',
            label: 'Typ',
            _style: { width: '100px' },
          },
          {
            key: 'initialQuantityInBom',
            label: 'Ilość w BOM',
            _style: { width: '100px' },
          },
          {
            key: 'quantity',
            label: 'Dostępna ilość',
            _style: { width: '100px' },
          },
          {
            key: 'quantityInStep',
            label: 'Ilość w półprodukcie',
            sorter: false,
            _style: { width: '100px' },
          },
        ]}
        items={
          editableBomForManufacturingSteps
            ? editableBomForManufacturingSteps?.bomElementDetails
            : []
        }
        noItemsViewSlot={
          <div className="no-items-in-table">
            {editableBomForManufacturingSteps
              ? getEmptyListMessage(
                  bomsState.fetchBomsError,
                  bomsState.areBomsLoading,
                  'produktu',
                  bomsState?.boms?.length
                )
              : 'Nie wybrano produktu!'}
          </div>
        }
        scopedSlots={{
          checkbox: (
            bomElementFromEditableBomTableInModal: actionTypes.IBomElementFromEditableBomTableInModal
          ) => {
            const stableItemIndex = editableBomForManufacturingSteps?.bomElementDetails?.findIndex(
              (bomElement: actionTypes.IBomElementFromEditableBomTableInModal) =>
                bomElement.bomElementId === bomElementFromEditableBomTableInModal.bomElementId
            )

            return (
              <td style={{ textAlign: 'center' }}>
                <Field
                  as={CInput}
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  type="checkbox"
                  name={`editableBomForManufacturingSteps.bomElementDetails.${stableItemIndex}.checkbox`}
                  checked={bomElementFromEditableBomTableInModal.checkbox}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (
                      event.currentTarget.value &&
                      bomElementFromEditableBomTableInModal.checkbox
                    ) {
                      setFieldValue(
                        `manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.bomElements.${stableItemIndex}.quantity`,
                        ''
                      )

                      return handleChange(event)
                    } else {
                      return null
                    }
                  }}
                />
              </td>
            )
          },

          quantity: (
            bomElementFromEditableBomTableInModal: actionTypes.IBomElementFromEditableBomTableInModal
          ) => {
            const stableItemIndex = editableBomForManufacturingSteps?.bomElementDetails?.findIndex(
              (bomElement: actionTypes.IBomElementFromEditableBomTableInModal) =>
                bomElement.bomElementId === bomElementFromEditableBomTableInModal.bomElementId
            )

            const calculatedRemainingQuantity = calculateRemainingQuantity(
              bomElementFromEditableBomTableInModal,
              manufacturingSteps
            )

            return (
              <td>
                <Field
                  as={NumberFormat}
                  displayType="text"
                  name={`editableBomForManufacturingSteps.bomElementDetails.${stableItemIndex}.quantity`}
                  value={
                    Number.isInteger(calculatedRemainingQuantity)
                      ? calculatedRemainingQuantity
                      : calculatedRemainingQuantity.toFixed(
                          !Number.isInteger(
                            convertNumericStringToNumber(
                              bomElementFromEditableBomTableInModal.initialQuantityInBom
                            )
                          ) || bomElementFromEditableBomTableInModal.bomElementMeasurementType === 2
                            ? 2
                            : 0
                        )
                  }
                />
              </td>
            )
          },
          quantityInStep: (
            bomElementFromEditableBomTableInModal: actionTypes.IBomElementFromEditableBomTableInModal
          ) => {
            const stableItemIndex = editableBomForManufacturingSteps?.bomElementDetails?.findIndex(
              (bomElement: actionTypes.IBomElementFromEditableBomTableInModal) =>
                bomElement.bomElementId === bomElementFromEditableBomTableInModal.bomElementId
            )

            const calculatedRemainingQuantity = calculateRemainingQuantity(
              bomElementFromEditableBomTableInModal,
              manufacturingSteps
            )

            return (
              <td>
                <Field
                  as={NumberFormat}
                  displayType="input"
                  thousandSeparator={globalThousandSeparator}
                  decimalSeparator={globalDecimalSeparator}
                  decimalScale={
                    !Number.isInteger(
                      convertNumericStringToNumber(
                        bomElementFromEditableBomTableInModal.initialQuantityInBom
                      )
                    ) || bomElementFromEditableBomTableInModal.bomElementMeasurementType === 2
                      ? 2
                      : 0
                  }
                  fixedDecimalScale={false}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  // Do not use item index here because of filtering on table is enabled in this case
                  name={`manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}.bomElements.${stableItemIndex}.quantity`}
                  placeholder="Ilość"
                  style={{ maxWidth: '125px' }}
                  className={`form-control text-right ${
                    calculatedRemainingQuantity < 0 && 'is-invalid'
                  }`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (
                      event.currentTarget.value &&
                      convertNumericStringToNumber(event.currentTarget.value)
                    ) {
                      setFieldValue(
                        `editableBomForManufacturingSteps.bomElementDetails.${stableItemIndex}.checkbox`,
                        true
                      )
                    } else {
                      setFieldValue(
                        `editableBomForManufacturingSteps.bomElementDetails.${stableItemIndex}.checkbox`,
                        false
                      )
                    }

                    handleChange(event)
                  }}
                  disabled={
                    !(manufacturingSteps[indexOfManufacturingStep] as any)?.bomIntermediates[
                      indexOfBomIntermediate
                    ]?.bomElements[stableItemIndex || 0]?.quantity &&
                    /*
                     * ^ If there is quantity in the field then you may never disable it when overall quantity available is 0
                     */
                    calculatedRemainingQuantity <= 0
                  }
                />
              </td>
            )
          },
        }}
      />

      {editableBomForManufacturingSteps?.bomElementDetails.some(
        (
          bomElementFromEditableBomTableInModal: actionTypes.IBomElementFromEditableBomTableInModal
        ) => {
          return (
            calculateRemainingQuantity(bomElementFromEditableBomTableInModal, manufacturingSteps) <
            0
          )
        }
      ) && (
        <div className="text-danger text-center pt-3 pb-2">
          Przed {bomIntermediateModalMode === 'create' ? 'dodaniem' : 'zmianą elementów'}{' '}
          półproduktu skoryguj ujemne wartości w tabeli!
        </div>
      )}

      <div className="p-0 mt-4 d-flex justify-content-end">
        <CButton
          color="primary"
          type="button"
          variant="outline"
          className="px-4 mr-4"
          onClick={() => {
            setBomIntermediateInStepModalVisibility(false)

            setBomIntermediateInStepModalUuid(null)

            if (bomIntermediateModalMode === 'create') {
              manufacturingStepBomIntermediatesListArrayHelpers.remove(indexOfBomIntermediate)
            } else {
              setFieldValue(
                `manufacturingSteps.${indexOfManufacturingStep}.bomIntermediates.${indexOfBomIntermediate}`,
                initialUnsavedEditedBomIntermediate
              )

              setInitialUnsavedEditedBomIntermediate(null)
            }
          }}
          disabled={manufacturingState.isBomForManufacturingStepsLoading}
        >
          Anuluj
        </CButton>

        <CButton
          color="info"
          /* This is a hack because validateForm would always be one step behind manufacturingStepsListArrayHelpers.push function */
          type="button"
          variant="outline"
          className="add-intermediate-modal-button px-4"
          onClick={() => {
            setBomIntermediateInStepModalVisibility(false)
          }}
          disabled={
            manufacturingState.isBomForManufacturingStepsLoading ||
            !bomIntermediate?.bomElements?.some(
              (bomElement: actionTypes.TBomElementInManufacturingStep) =>
                bomElement?.quantity && convertNumericStringToNumber(bomElement?.quantity)
            ) ||
            editableBomForManufacturingSteps?.bomElementDetails.some(
              (
                bomElementFromEditableBomTableInModal: actionTypes.IBomElementFromEditableBomTableInModal
              ) => {
                return (
                  calculateRemainingQuantity(
                    bomElementFromEditableBomTableInModal,
                    manufacturingSteps
                  ) < 0
                )
              }
            )
          }
        >
          {bomIntermediateModalMode === 'create' ? 'Dodaj' : 'Zmień'}
        </CButton>
      </div>
    </CModal>
  )
}
