import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { ListActionButton } from '../../components'
import { ListStickyErrorMessage } from '../../components/list-sticky-error-message'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'
import { warehouseTypesObject } from '../../store/action-types'

const Warehouses: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const warehousesState = useSelector((state: TRootState) => state.warehouses)
  const authState = useSelector((state: TRootState) => state.auth)

  const memoizedTableItems = useMemo(
    () =>
      warehousesState.warehouses?.map(
        (warehouse: actionTypes.TWarehouseLight, warehouseIndex: number) => {
          return {
            ...warehouse,
            name: warehouse?.name || '',
            fullAddress: [warehouse?.address1, warehouse?.post, warehouse?.city].join(', ') || '',
            types:
              warehouse?.types?.map((type: number) => warehouseTypesObject[type]).join(' \n') || [],
            position: `${warehouseIndex + 1}.`,
          }
        }
      ),
    [warehousesState.warehouses]
  )

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()

    // Remove all errors on refresh or path change
    dispatch({
      type: actionTypes.CLOSE_WAREHOUSE_DETAILS,
    })
  }, [dispatch, makeSearchBoxFixed])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="10">
        <CCard>
          <CCardBody className="pb-2">
            {warehousesState?.warehouseDeleteError && (
              <ListStickyErrorMessage
                listErrorMessage={`Wystąpił błąd ${
                  warehousesState?.warehouseDeleteError?.status || 'nieznany'
                } podczas ${
                  (warehousesState?.warehouseDeleteError && 'usuwania') || 'zmiany'
                } magazynu!`}
              />
            )}

            {authState?.authData?.roles?.includes('Warehouses_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/warehouses/create`)
                  }}
                >
                  Nowy Magazyn
                </CButton>
              </div>
            )}

            <CDataTable
              tableFilterValue={warehousesState?.tableFilterState}
              sorterValue={warehousesState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_WAREHOUSES_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_WAREHOUSES_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={warehousesState.areWarehousesLoading}
              striped={!!warehousesState.warehouses.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              onRowClick={(warehouse: actionTypes.TWarehouseLight) => {
                history.push(`/${tenant}/warehouse-management/${warehouse.id}`)
              }}
              addTableClasses="warehouses-list-table"
              fields={[
                {
                  key: 'position',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '45px',
                    display: !warehousesState.warehouses.length ? 'none' : '',
                    textAlign: 'center',
                  },
                },
                {
                  key: 'name',
                  label: 'Nazwa',
                  _style: { width: '1fr', textAlign: 'left' },
                },
                {
                  key: 'fullAddress',
                  label: 'Adres',
                  _style: { width: '220px', textAlign: 'center' },
                },
                {
                  key: 'types',
                  label: 'Rodzaje',
                  _style: { width: '180px', textAlign: 'center' },
                },
                {
                  key: 'edit',
                  label: '',
                  _style: { width: '45px' },
                },
                {
                  key: 'delete',
                  label: '',
                  _style: { width: '45px' },
                },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    warehousesState.fetchWarehousesError,
                    warehousesState.areWarehousesLoading,
                    'Magazynów',
                    warehousesState?.warehouses?.length
                  )}
                </div>
              }
              scopedSlots={{
                edit: (itemFromList: actionTypes.TWarehouseLight) => (
                  <td className="text-center" style={{ pointerEvents: 'none' }}>
                    <CButton
                      color="dark"
                      variant="outline"
                      size="50"
                      className="list-action-button edit-list-item-button"
                      style={{ pointerEvents: 'all' }}
                      onClick={(event: SyntheticEvent) => {
                        history.push(`/${tenant}/warehouses/edit/${itemFromList.id}`)

                        // This line blocks the opening of Warehouse Management on checkbox/row click
                        event.stopPropagation()
                      }}
                    >
                      <i className="cil-pen"></i>
                    </CButton>
                  </td>
                ),
                delete: (itemFromList: actionTypes.TWarehouseLight) => (
                  <td
                    className="text-center"
                    style={{ pointerEvents: 'none' }}
                    onClick={(event: SyntheticEvent) => {
                      if (!itemFromList.canDelete) {
                        event.preventDefault()
                        event.stopPropagation()
                        return null
                      } else {
                        return undefined
                      }
                    }}
                  >
                    <ListActionButton
                      mainActionName="delete"
                      buttonColor="danger"
                      classNames="delete-list-item-button delete-list-item-button--alt-text"
                      typeOfAction={actionTypes.DELETE_WAREHOUSE_REQUESTED}
                      iconName="cil-trash"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={warehousesState.currentlyManagedWarehouseId}
                      isItemCopying={false}
                      isItemArchiving={false}
                      isItemDeleting={warehousesState.isWarehouseDeleting}
                    />
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default Warehouses
