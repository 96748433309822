import * as actionTypes from '../action-types'

export interface IServicesState {
  services: actionTypes.TService[] | any
  areServicesLoading: boolean
  fetchServicesError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState

  editedService: actionTypes.TService | null

  isServiceCreating: boolean
  serviceCreateError: actionTypes.TNetworkError
  isServiceCreated: boolean

  isServiceDeleting: boolean
  serviceDeleteError: actionTypes.TNetworkError
  isServiceDeleted: boolean

  currentlyManagedServiceId: number | null

  isServiceEditSaving: boolean
  serviceEditSaveError: actionTypes.TNetworkError
  isServiceEditSaved: boolean

  editedServiceBoms: actionTypes.TServiceBom[] | null
  areServiceBomsLoading: boolean
  fetchServiceBomsError: actionTypes.TNetworkError

  editedServiceOffers: actionTypes.TOffer[] | null
  areServiceOffersLoading: boolean
  fetchServiceOffersError: actionTypes.TNetworkError

  editedServiceOrders: actionTypes.TOrder[] | null
  areServiceOrdersLoading: boolean
  fetchServiceOrdersError: actionTypes.TNetworkError

  editedServiceAttachments: actionTypes.TServiceAttachment[] | null
  areServiceAttachmentsLoading: boolean
  fetchServiceAttachmentsError: actionTypes.TNetworkError
  isServiceAttachmentUploading: boolean
  serviceAttachmentUploadingError: actionTypes.TNetworkError
  isServiceAttachmentUploaded: boolean
}

const initialState: IServicesState = {
  services: [],
  areServicesLoading: false,
  fetchServicesError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,

  editedService: null,

  isServiceCreating: false,
  serviceCreateError: null,
  isServiceCreated: false,

  isServiceDeleting: false,
  serviceDeleteError: null,
  isServiceDeleted: false,

  currentlyManagedServiceId: null,

  isServiceEditSaving: false,
  serviceEditSaveError: null,
  isServiceEditSaved: false,

  editedServiceBoms: null,
  areServiceBomsLoading: false,
  fetchServiceBomsError: null,

  editedServiceOffers: null,
  areServiceOffersLoading: false,
  fetchServiceOffersError: null,

  editedServiceOrders: null,
  areServiceOrdersLoading: false,
  fetchServiceOrdersError: null,

  editedServiceAttachments: null,
  areServiceAttachmentsLoading: false,
  fetchServiceAttachmentsError: null,
  isServiceAttachmentUploading: false,
  serviceAttachmentUploadingError: null,
  isServiceAttachmentUploaded: false,
}

export default function servicesReducer(
  state = initialState,
  action: actionTypes.ServicesActionTypes
): IServicesState {
  switch (action.type) {
    case actionTypes.FETCH_SERVICES_REQUESTED: {
      return {
        ...state,
        fetchServicesError: null,
        areServicesLoading: true,
      }
    }

    case actionTypes.FETCH_SERVICES_SUCCEEDED: {
      return {
        ...state,
        services: action.services,
        areServicesLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICES_ERROR: {
      return {
        ...state,
        fetchServicesError: action.error,
        areServicesLoading: false,
      }
    }

    case actionTypes.SET_SERVICES_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_SERVICES_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_SERVICE_REQUESTED: {
      return {
        ...state,
        serviceCreateError: null,
        isServiceCreating: true,
        isServiceCreated: false,
      }
    }

    case actionTypes.CREATE_SERVICE_SUCCEEDED: {
      return {
        ...state,
        isServiceCreating: false,
        services: [...state.services, action.service],
        isServiceCreated: true,
      }
    }

    case actionTypes.CREATE_SERVICE_ERROR: {
      return {
        ...state,
        serviceCreateError: action.error,
        isServiceCreating: false,
        isServiceCreated: false,
      }
    }

    case actionTypes.EDIT_SERVICE_REQUESTED: {
      return {
        ...state,
        serviceEditSaveError: null,
        isServiceEditSaving: true,
        isServiceEditSaved: false,
      }
    }

    case actionTypes.EDIT_SERVICE_SUCCEEDED: {
      return {
        ...state,
        isServiceEditSaving: false,
        isServiceEditSaved: true,
        services: state.services?.map((service: actionTypes.TService) => {
          if (service.id === action.service.id) {
            return action.service
          } else {
            return service
          }
        }),
      }
    }

    case actionTypes.EDIT_SERVICE_ERROR: {
      return {
        ...state,
        serviceEditSaveError: action.error,
        isServiceEditSaving: false,
        isServiceEditSaved: false,
      }
    }

    case actionTypes.DELETE_SERVICE_REQUESTED: {
      return {
        ...state,
        serviceDeleteError: null,
        isServiceDeleting: true,
        isServiceDeleted: false,
        currentlyManagedServiceId: action.payload.id,
      }
    }

    case actionTypes.DELETE_SERVICE_SUCCEEDED: {
      return {
        ...state,
        isServiceDeleting: false,
        isServiceDeleted: true,
        services: state.services.filter(
          (service: actionTypes.TService) => service.id !== action.id
        ),
        editedServiceAttachments: null,
        currentlyManagedServiceId: null,
      }
    }

    case actionTypes.DELETE_SERVICE_ERROR: {
      return {
        ...state,
        serviceDeleteError: action.error,
        isServiceDeleting: false,
        isServiceDeleted: false,
        currentlyManagedServiceId: null,
      }
    }

    // External sections

    case actionTypes.FETCH_SERVICE_BOMS_REQUESTED: {
      return {
        ...state,
        fetchServiceBomsError: null,
        areServiceBomsLoading: true,
      }
    }

    case actionTypes.FETCH_SERVICE_BOMS_SUCCEEDED: {
      return {
        ...state,
        editedServiceBoms: action.serviceBoms,
        areServiceBomsLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICE_BOMS_ERROR: {
      return {
        ...state,
        fetchServiceBomsError: action.error,
        areServiceBomsLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICE_OFFERS_REQUESTED: {
      return {
        ...state,
        fetchServiceOffersError: null,
        areServiceOffersLoading: true,
      }
    }

    case actionTypes.FETCH_SERVICE_OFFERS_SUCCEEDED: {
      return {
        ...state,
        editedServiceOffers: action.serviceOffers,
        areServiceOffersLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICE_OFFERS_ERROR: {
      return {
        ...state,
        fetchServiceOffersError: action.error,
        areServiceOffersLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICE_ORDERS_REQUESTED: {
      return {
        ...state,
        fetchServiceOrdersError: null,
        areServiceOrdersLoading: true,
      }
    }

    case actionTypes.FETCH_SERVICE_ORDERS_SUCCEEDED: {
      return {
        ...state,
        editedServiceOrders: action.serviceOrders,
        areServiceOrdersLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICE_ORDERS_ERROR: {
      return {
        ...state,
        fetchServiceOrdersError: action.error,
        areServiceOrdersLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICE_ATTACHMENTS_REQUESTED: {
      return {
        ...state,
        editedServiceAttachments: null,
        fetchServiceAttachmentsError: null,
        areServiceAttachmentsLoading: true,
      }
    }

    case actionTypes.FETCH_SERVICE_ATTACHMENTS_SUCCEEDED: {
      return {
        ...state,
        editedServiceAttachments: action.serviceAttachments,
        areServiceAttachmentsLoading: false,
      }
    }

    case actionTypes.FETCH_SERVICE_ATTACHMENTS_ERROR: {
      return {
        ...state,
        fetchServiceAttachmentsError: action.error,
        areServiceAttachmentsLoading: false,
      }
    }

    case actionTypes.UPLOAD_SERVICE_ATTACHMENT_REQUESTED: {
      return {
        ...state,
        serviceAttachmentUploadingError: null,
        isServiceAttachmentUploading: true,
        isServiceAttachmentUploaded: false,
      }
    }

    case actionTypes.UPLOAD_SERVICE_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        isServiceAttachmentUploading: false,
        isServiceAttachmentUploaded: true,
        editedServiceAttachments: state.editedServiceAttachments
          ? [...state.editedServiceAttachments, action.attachment]
          : [action.attachment],
      }
    }

    case actionTypes.UPLOAD_SERVICE_ATTACHMENT_ERROR: {
      return {
        ...state,
        serviceAttachmentUploadingError: action.error,
        isServiceAttachmentUploading: false,
        isServiceAttachmentUploaded: false,
      }
    }

    case actionTypes.DELETE_SERVICE_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        editedServiceAttachments:
          state.editedServiceAttachments?.filter(
            (attachment: actionTypes.TAttachment) => attachment.id !== action.payload.id
          ) || [],
      }
    }

    case actionTypes.OPEN_SERVICE_DETAILS: {
      return {
        ...state,
        serviceCreateError: null,
        serviceEditSaveError: null,
        serviceDeleteError: null,

        isServiceCreating: false,
        isServiceEditSaving: false,
        isServiceDeleting: false,

        editedService: action.editedService,
        editedServiceAttachments: null,
      }
    }

    case actionTypes.CLOSE_SERVICE_DETAILS: {
      return {
        ...state,
        serviceCreateError: null,
        serviceEditSaveError: null,
        serviceDeleteError: null,

        isServiceCreating: false,
        isServiceEditSaving: false,
        isServiceDeleting: false,

        isServiceCreated: false,
        isServiceEditSaved: false,
        isServiceDeleted: false,

        editedService: null,

        editedServiceBoms: null,
        areServiceBomsLoading: false,
        fetchServiceBomsError: null,

        editedServiceOffers: null,
        areServiceOffersLoading: false,
        fetchServiceOffersError: null,

        editedServiceAttachments: null,
        areServiceAttachmentsLoading: false,
        fetchServiceAttachmentsError: null,

        isServiceAttachmentUploading: false,
        serviceAttachmentUploadingError: null,
        isServiceAttachmentUploaded: false,
      }
    }

    case actionTypes.SERVICE_DETAILS_ACTIONS_UNLOCK: {
      return {
        ...state,
        isServiceCreating: false,
        isServiceEditSaving: false,
        isServiceDeleting: false,

        isServiceCreated: false,
        isServiceEditSaved: false,
        isServiceDeleted: false,

        serviceAttachmentUploadingError: null,
        isServiceAttachmentUploading: false,
        isServiceAttachmentUploaded: false,
      }
    }

    default:
      return state
  }
}
