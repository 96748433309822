import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  CLabel,
  CTabs,
  CTabContent,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTextarea,
  CCard,
  CCardBody,
} from '@coreui/react'
import deepEqual from 'deep-equal'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  FormActionsPanel,
  AttachmentsUploadPanel,
  ConnectedBomsTable,
  ConnectedOffersTable,
  ConnectedOrdersTable,
  IConnectedOrdersTableItem,
  getUploadedFileType,
  CustomErrorMessage,
  BasicFormField,
} from '../../components'
import {
  inputLabelSpacingBottom,
  successMessageDuration,
  maximumDescriptionLength,
  getErrorMessageFromStatus,
  preventNavigationChange,
} from '../../utils'
import { TFormTab } from '../erp'

export const implementationCostNavTabs: TFormTab[] = [
  { name: 'general-information', title: 'Informacje ogólne', isEditOnly: false },
  { name: 'additional-information', title: 'Informacje dodatkowe', isEditOnly: false },
  { name: 'offers', title: 'Oferty', isEditOnly: true },
  { name: 'orders', title: 'Zamówienia', isEditOnly: true },
  { name: 'attachments', title: 'Załączniki', isEditOnly: false },
]

export const ImplementationCostDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, mode, id, tab } = useParams<{
    tenant: string
    mode: actionTypes.TFormMode
    id: string
    tab: string
  }>()
  const paramsImplementationCostId = id
  const paramsImplementationCostTab = tab

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isOneFieldChanged, setIsOneFieldChanged] = useState(false)
  const [isImplementationCostModifiedAndUnsaved, setIsImplementationCostModifiedAndUnsaved] =
    useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const implementationCostsState = useSelector((state: TRootState) => state.implementationCosts)

  const mainTab = implementationCostNavTabs[0].name

  const closeImplementationCostDetails = useCallback(() => {
    history.push(`/${tenant}/implementation-costs`)
  }, [dispatch, history, tenant])

  const isImplementationCostEditable =
    authState?.authData?.roles?.includes('ImplementationCosts_write') &&
    (mode === 'edit' ? !!implementationCostsState.editedImplementationCost : true)

  const isThereNetworkError =
    implementationCostsState?.implementationCostCreateError?.status ||
    implementationCostsState?.implementationCostEditSaveError?.status ||
    implementationCostsState?.implementationCostDeleteError?.status

  // Set data for editedImplementationCost in modal when clicked on row or opened in new window
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      paramsImplementationCostId &&
      implementationCostsState.implementationCosts &&
      implementationCostsState.implementationCosts.length &&
      mode === 'edit'
    ) {
      const foundImplementationCost = implementationCostsState.implementationCosts.find(
        (implementationCost: actionTypes.TImplementationCost) =>
          Number(implementationCost.id) === Number(paramsImplementationCostId)
      )

      if (foundImplementationCost) {
        dispatch({
          type: actionTypes.OPEN_IMPLEMENTATION_COST_DETAILS,
          editedImplementationCost: foundImplementationCost,
        })
      } else {
        history.replace(`/${tenant}/implementation-costs`)
      }
    }
  }, [
    dispatch,
    tenant,
    authState,
    history,
    paramsImplementationCostId,
    implementationCostsState.implementationCosts,
    paramsImplementationCostTab,
  ])

  // Fix the path in edit
  useEffect(() => {
    if (tenant && authState.authData) {
      const tabsArray = implementationCostNavTabs?.map((navTab: TFormTab) =>
        mode === 'edit' ? navTab.name : !navTab.isEditOnly ? navTab.name : undefined
      )

      if (
        paramsImplementationCostTab === undefined ||
        !tabsArray.includes(paramsImplementationCostTab)
      ) {
        history.replace(
          `/${tenant}/implementation-costs/${
            mode === 'create' ? 'create' : `edit/${paramsImplementationCostId}`
          }/${mainTab}`
        )
      }
    }
  }, [tenant, authState, history, paramsImplementationCostId, paramsImplementationCostTab, mode])

  // Fetch more data for edit modal
  useEffect(() => {
    if (
      tenant &&
      authState.isAuthenticated &&
      authState.authData &&
      paramsImplementationCostId &&
      mode === 'edit'
    ) {
      dispatch({
        type: actionTypes.FETCH_IMPLEMENTATION_COST_BOMS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          id: paramsImplementationCostId,
        },
      })

      dispatch({
        type: actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          id: paramsImplementationCostId,
        },
      })

      dispatch({
        type: actionTypes.FETCH_IMPLEMENTATION_COST_ATTACHMENTS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          entityId: Number(paramsImplementationCostId),
          fileAttachmentTypeId: 5,
        },
      })
    }
  }, [dispatch, tenant, authState.isAuthenticated, authState.authData, paramsImplementationCostId])

  // Close modal on a successful create
  useEffect(() => {
    if (
      !implementationCostsState.isImplementationCostCreating &&
      !implementationCostsState.implementationCostCreateError &&
      implementationCostsState.isImplementationCostCreated
    ) {
      setTimeout(() => {
        closeImplementationCostDetails()
      }, successMessageDuration)
    }
  }, [implementationCostsState, closeImplementationCostDetails])

  // Track the changes again
  useEffect(() => {
    if (
      implementationCostsState.isImplementationCostEditSaved ||
      implementationCostsState.isImplementationCostCreated ||
      implementationCostsState.isImplementationCostDeleted
    ) {
      setIsImplementationCostModifiedAndUnsaved(false)
    }
  }, [
    implementationCostsState.isImplementationCostEditSaved,
    implementationCostsState.isImplementationCostCreated,
    implementationCostsState.isImplementationCostDeleted,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isImplementationCostModifiedAndUnsaved,
      isImplementationCostEditable,
      'implementation-costs',
      paramsImplementationCostId,
      mode
    )

    // Without pathname in location there is no tab change detection
  }, [
    location.pathname,
    history,
    isImplementationCostModifiedAndUnsaved,
    isImplementationCostEditable,
  ])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_IMPLEMENTATION_COST_DETAILS,
      })
      dispatch({
        type: actionTypes.CLEAR_SINGLE_ATTACHMENT_ERRORS,
      })
    }
  }, [])

  const ImplementationCostSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane!'),
  })

  const initialFileInput: any = undefined

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="bom-element-details">
          <h4 className={`text-center ${isThereNetworkError ? 'mb-2 pb-2' : 'mb-3 pb-2'}`}>
            {mode === 'edit'
              ? !authState?.authData?.roles?.includes('ImplementationCosts_write')
                ? 'Przeglądaj'
                : 'Edytuj'
              : 'Nowy'}{' '}
            Tooling
          </h4>

          {((mode === 'edit' && implementationCostsState.editedImplementationCost) ||
            mode === 'create') && (
            <Formik
              initialValues={{
                name: implementationCostsState.editedImplementationCost?.name || '',
                supplierInfos:
                  implementationCostsState.editedImplementationCost?.supplierInfos || [],
                description: implementationCostsState.editedImplementationCost?.description || '',
                newAttachmentFileInput: initialFileInput,
                newAttachmentAltName: '',

                uuid:
                  mode === 'create'
                    ? uuidv4()
                    : implementationCostsState.editedImplementationCost?.uuid || null,
              }}
              validationSchema={ImplementationCostSchema}
              onSubmit={(values) => {
                if (isImplementationCostEditable) {
                  setDidFormValidationOccur(true)
                  setIsOneFieldChanged(false)
                  const shortenedSuppliers = values?.supplierInfos?.map(
                    (supplierInfo: actionTypes.TSupplier) => ({
                      id: supplierInfo.id,
                    })
                  )

                  dispatch({
                    type:
                      mode === 'create'
                        ? actionTypes.CREATE_IMPLEMENTATION_COST_REQUESTED
                        : actionTypes.EDIT_IMPLEMENTATION_COST_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      implementationCost: {
                        name: values.name,
                        description: values.description,
                        // This should be supplierInfos instead maybe
                        supplierId: shortenedSuppliers[0]?.id || null,
                        //
                        uuid: values?.uuid || uuidv4(),
                        ...(implementationCostsState.editedImplementationCost?.id && {
                          id: implementationCostsState.editedImplementationCost?.id,
                        }),
                      },
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ initialValues, values, errors, setFieldValue }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsImplementationCostModifiedAndUnsaved(true)
                    } else {
                      setIsImplementationCostModifiedAndUnsaved(false)
                    }

                    if (errors.name) {
                      setDidFormValidationOccur(true)
                    }
                    setIsOneFieldChanged(true)
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {!isOneFieldChanged &&
                    implementationCostsState?.implementationCostCreateError && (
                      <CustomErrorMessage
                        wrapperClassNames="my-3"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'create',
                          implementationCostsState?.implementationCostCreateError?.status
                        )}
                      />
                    )}

                  {!isOneFieldChanged &&
                    implementationCostsState?.implementationCostEditSaveError && (
                      <CustomErrorMessage
                        wrapperClassNames="my-3"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'edit',
                          implementationCostsState?.implementationCostEditSaveError?.status,
                          'toolingu'
                        )}
                      />
                    )}

                  {implementationCostsState?.implementationCostDeleteError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'delete',
                        implementationCostsState?.implementationCostDeleteError?.status,
                        'toolingu'
                      )}
                    />
                  )}

                  {/*
                   * Form Tabs
                   */}

                  <CTabs activeTab={paramsImplementationCostTab}>
                    <CNav variant="tabs" className="mb-4 mt-4">
                      {implementationCostNavTabs?.map((modalTab: TFormTab) => {
                        if (modalTab.isEditOnly && mode === 'create') {
                          return null
                        } else {
                          return (
                            <CNavItem key={modalTab.name}>
                              <CNavLink
                                className={`${
                                  modalTab.name === 'general-information' && errors.name
                                    ? // (modalTab.name === 'additional-information' &&
                                      //   (errors.producerId || errors.code))
                                      'text-danger is-invalid'
                                    : ''
                                }`}
                                data-tab={modalTab.name}
                                onClick={() => {
                                  history.replace(
                                    `/${tenant}/implementation-costs/${
                                      mode === 'edit'
                                        ? `edit/${paramsImplementationCostId}`
                                        : 'create'
                                    }/${modalTab.name}`
                                  )
                                }}
                              >
                                {modalTab.title}
                              </CNavLink>
                            </CNavItem>
                          )
                        }
                      })}
                    </CNav>
                    <CTabContent>
                      {/*
                       * General Information Tab
                       */}

                      <CTabPane data-tab="general-information">
                        <BasicFormField
                          fieldId="implementation-cost-name"
                          fieldLabel="Nazwa"
                          fieldIcon="cil-short-text"
                          formikFieldName="name"
                          fieldValue={values.name}
                          fieldError={errors.name}
                          fieldType="text"
                          placeholder="Wprowadź nazwę toolingu"
                        />
                      </CTabPane>

                      <CTabPane data-tab="additional-information">
                        {/*
                         * Additional Information Tab
                         */}

                        <CLabel
                          htmlFor="implementation-cost-description"
                          className={`${inputLabelSpacingBottom}`}
                        >
                          Opis toolingu
                        </CLabel>
                        <InputGroup id="implementation-cost-description" className={`mb-4`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText
                              className={errors.description && 'text-danger input-error-icon'}
                            >
                              <i className="cil-layers"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <ErrorMessage
                            name="description"
                            component="span"
                            className="text-danger input-error-message"
                          />
                          <Field
                            as={CTextarea}
                            name="description"
                            placeholder="Wprowadź opis toolingu..."
                            maxLength={maximumDescriptionLength}
                            className={
                              errors.description
                                ? 'item-description form-control is-invalid'
                                : 'item-description'
                            }
                          />
                        </InputGroup>

                        {mode === 'edit' && (
                          <ConnectedBomsTable
                            mode="implementation-cost"
                            editedItemBoms={implementationCostsState.editedImplementationCostBoms}
                            fetchItemBomsError={
                              implementationCostsState.fetchImplementationCostBomsError
                            }
                            tenant={tenant}
                          />
                        )}
                      </CTabPane>

                      {/*
                       * Offers Tab
                       */}

                      <CTabPane data-tab="offers">
                        <ConnectedOffersTable
                          mode="implementation-cost"
                          areItemOffersLoading={
                            implementationCostsState.areImplementationCostOffersLoading
                          }
                          editedItemOffers={implementationCostsState.editedImplementationCostOffers}
                          fetchItemOffersError={
                            implementationCostsState.fetchImplementationCostOffersError
                          }
                          tenant={tenant}
                        />
                      </CTabPane>

                      {/*
                       * Orders Tab
                       */}

                      <CTabPane data-tab="orders">
                        <ConnectedOrdersTable
                          mode="implementation-cost"
                          areItemOrdersLoading={
                            implementationCostsState.areImplementationCostOrdersLoading
                          }
                          editedItemOrders={
                            (implementationCostsState?.editedImplementationCost
                              ?.orders as IConnectedOrdersTableItem[]) || null
                          }
                          fetchItemOrdersError={
                            implementationCostsState.fetchImplementationCostOrdersError
                          }
                          tenant={tenant}
                        />
                      </CTabPane>

                      {/*
                       * Attachments Tab
                       */}

                      <CTabPane data-tab="attachments">
                        <AttachmentsUploadPanel
                          attachments={implementationCostsState.editedImplementationCostAttachments}
                          fetchAttachmentsError={
                            implementationCostsState.fetchImplementationCostAttachmentsError
                          }
                          isAttachmentUploading={
                            implementationCostsState.isImplementationCostAttachmentUploading
                          }
                          isAttachmentUploaded={
                            implementationCostsState.isImplementationCostAttachmentUploaded
                          }
                          attachmentUploadError={
                            implementationCostsState.implementationCostAttachmentUploadingError
                          }
                          newAttachmentFileInput={values.newAttachmentFileInput}
                          newAttachmentAltNameError={errors.newAttachmentAltName}
                          dispatchAttachmentUpload={(encodedFile) => {
                            dispatch({
                              type: actionTypes.UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                uploadAttachment: {
                                  fileAttachmentTypeId: 5,
                                  entityUuid: values.uuid,
                                  entityId: paramsImplementationCostId
                                    ? Number(paramsImplementationCostId)
                                    : null,

                                  type: getUploadedFileType(
                                    values.newAttachmentFileInput?.type,
                                    values.newAttachmentFileInput?.name
                                  ),
                                  name:
                                    values.newAttachmentAltName ||
                                    values.newAttachmentFileInput.name,
                                  base64EncodedFile: encodedFile,
                                  altName: values?.newAttachmentAltName || null,
                                },
                              },
                            })

                            mode === 'create' && setIsImplementationCostModifiedAndUnsaved(true)
                          }}
                          dispatchModalActionsUnlock={() =>
                            dispatch({
                              type: actionTypes.IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK,
                            })
                          }
                          dispatchSingleAttachmentFetch={(
                            attachmentId,
                            attachmentType,
                            fileName,
                            download
                          ) =>
                            dispatch({
                              type: actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                id: attachmentId,
                                attachmentType: attachmentType,
                                fileName: fileName,
                                download: download,
                              },
                            })
                          }
                          dispatchSingleAttachmentDelete={(attachmentId) =>
                            dispatch({
                              type: actionTypes.DELETE_SINGLE_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                id: attachmentId,
                              },
                              section: 'implementationCosts',
                            })
                          }
                          isUploadDisabled={
                            implementationCostsState.isImplementationCostCreating ||
                            implementationCostsState.isImplementationCostEditSaving ||
                            implementationCostsState.isImplementationCostDeleting ||
                            implementationCostsState.isImplementationCostCreated ||
                            implementationCostsState.isImplementationCostEditSaved ||
                            implementationCostsState.isImplementationCostDeleted ||
                            implementationCostsState.isImplementationCostAttachmentUploading ||
                            !values.newAttachmentFileInput
                          }
                          isEditable={isImplementationCostEditable}
                          setFieldValue={setFieldValue}
                          noAttachmentsTextFirstWords="Ten tooling"
                        />
                      </CTabPane>
                    </CTabContent>
                  </CTabs>

                  <FormActionsPanel
                    mode={mode}
                    padding="pt-3 pb-1"
                    isSaving={
                      mode === 'create'
                        ? implementationCostsState.isImplementationCostCreating
                        : implementationCostsState.isImplementationCostEditSaving
                    }
                    isSaved={
                      mode === 'create'
                        ? implementationCostsState.isImplementationCostCreated
                        : implementationCostsState.isImplementationCostEditSaved
                    }
                    isDeleting={implementationCostsState.isImplementationCostDeleting}
                    isDeleted={implementationCostsState.isImplementationCostDeleted}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    formErrorsBool={Boolean(errors.name)}
                    closeAction={actionTypes.CLOSE_IMPLEMENTATION_COST_DETAILS}
                    deleteAction={actionTypes.DELETE_IMPLEMENTATION_COST_REQUESTED}
                    deletePayload={{
                      tenant: tenant,
                      token: authState.authData?.token,
                      id: implementationCostsState.editedImplementationCost?.id,
                    }}
                    closeFunction={closeImplementationCostDetails}
                    isEditable={isImplementationCostEditable}
                    canDelete={implementationCostsState?.editedImplementationCost?.canDelete}
                    disabledDeleteButtonClassNames={`delete-details-button`}
                    confirmDeleteMessageJSX={
                      <>
                        Czy na pewno chcesz usunąć tooling?
                        {initialValues?.name ? (
                          <>
                            <br />
                            <strong>{initialValues?.name}</strong>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    }
                  />
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
