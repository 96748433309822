import React, { ReactComponentElement } from 'react'
import { useSelector } from 'react-redux'
import { CButton } from '@coreui/react'

import {
  IFormActionDeleteButton,
  IFormActionSaveButton,
  FormActionDeleteButton,
  FormActionSaveButton,
  FormActionCancelButton,
  IFormActionCancelButton,
  ThreeDots,
} from './index'
import { TRootState } from '../store/reducers'

interface IFormFixedActionsPanel
  extends IFormActionSaveButton,
    IFormActionDeleteButton,
    IFormActionCancelButton {
  title: string | ReactComponentElement<any>
  section:
    | 'bom'
    | 'order'
    | 'inventories'
    | 'documents-of-warehouses'
    | 'current-manufacturing-report'
  errorComponent?: any
  isEditable?: boolean
  orderToPurchasePlanFn?: () => any
  generateOrderPDF?: () => any
  isOrderPDFGenerating?: boolean
  isOrderPDFGenerated?: boolean
  isGenerateOrderPDFButtonDisabled?: boolean
}

export const FormFixedActionsPanel: React.FC<IFormFixedActionsPanel> = ({
  title,
  mode,
  section,
  isSaving,
  isSaved,
  isDeleted,
  isDeleting,
  deletePayload,
  deleteAction,
  formErrorsBool,
  setDidFormValidationOccur,
  didFormValidationOccur,
  closeAction,
  closeFunction,
  phrasesInDelete,
  setIsOneFieldChanged,
  canDelete,
  disabledDeleteButtonClassNames,
  isEditable = true,
  canSave,
  confirmDeleteMessageJSX,
  orderToPurchasePlanFn,
  generateOrderPDF,
  isOrderPDFGenerating = false,
  isOrderPDFGenerated = false,
  isGenerateOrderPDFButtonDisabled = false,
}) => {
  const adminState = useSelector((state: TRootState) => state.admin)

  return (
    <div
      className={`form-fixed-panel-in-header form-fixed-panel-in-header--${section} ${
        adminState.sidebarVisibility === 'responsive' && !adminState.sidebarMinimize
          ? `form-fixed-panel-in-header--${section}-smaller-screen-with-sidebar`
          : ''
      } ${
        adminState.sidebarMinimize && adminState.sidebarVisibility
          ? `form-fixed-panel-in-header--${section}-smaller-screen-with-sidebar-minimized`
          : ''
      }`}
    >
      <h4 className="form-fixed-panel-in-header-title text-center font-weight-light mb-0">
        <strong>{title}</strong>
      </h4>

      <div>
        {mode === 'edit' && isEditable && (
          <FormActionDeleteButton
            isSaving={isSaving}
            isSaved={isSaved}
            isDeleting={isDeleting}
            isDeleted={isDeleted}
            deleteAction={deleteAction}
            deletePayload={deletePayload}
            phrasesInDelete={phrasesInDelete}
            setIsOneFieldChanged={setIsOneFieldChanged}
            canDelete={canDelete}
            disabledDeleteButtonClassNames={disabledDeleteButtonClassNames}
            confirmDeleteMessageJSX={confirmDeleteMessageJSX}
          />
        )}

        {section === 'order' && (
          <>
            {isEditable && (
              <CButton
                color="info"
                variant="outline"
                className={`mr-4 px-4 ${
                  !isOrderPDFGenerated &&
                  !isOrderPDFGenerating &&
                  isGenerateOrderPDFButtonDisabled &&
                  'generate-order-pdf-button'
                }`}
                onClick={generateOrderPDF}
                disabled={
                  isGenerateOrderPDFButtonDisabled || isOrderPDFGenerating || isOrderPDFGenerated
                }
              >
                {isOrderPDFGenerated ? (
                  'Wygenerowano PDF!'
                ) : isOrderPDFGenerating ? (
                  <>
                    Generowanie PDF
                    <ThreeDots />
                  </>
                ) : (
                  'Generuj PDF'
                )}
              </CButton>
            )}

            <CButton
              color="info"
              variant="outline"
              className="mr-4 px-4"
              onClick={orderToPurchasePlanFn}
            >
              Zapotrzebowanie
            </CButton>
          </>
        )}

        <FormActionCancelButton closeFunction={closeFunction} closeAction={closeAction} />

        {isEditable && (
          <FormActionSaveButton
            mode={mode}
            isSaving={isSaving}
            isSaved={isSaved}
            isDeleted={isDeleted}
            isDeleting={isDeleting}
            formErrorsBool={formErrorsBool}
            setDidFormValidationOccur={setDidFormValidationOccur}
            didFormValidationOccur={didFormValidationOccur}
            canSave={canSave}
          />
        )}
      </div>
    </div>
  )
}
