import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { CButton, CTooltip } from '@coreui/react'
import { ErrorMessage, FastField, Field, FieldArray } from 'formik'
import { Typeahead, TypeaheadMenuProps } from 'react-bootstrap-typeahead'
import { InputGroup, InputGroupAddon, InputGroupText, Table } from 'reactstrap'
import CIcon from '@coreui/icons-react'
import { cilExpandDown } from '@coreui/icons'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import NumberFormat from 'react-number-format'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DropResult,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { IBomFullElement } from '../../store/action-types'

import {
  globalDecimalSeparator,
  globalThousandSeparator,
  convertNumberToNumericString,
  convertNumericStringToNumber,
  handleNumberFormatPaste,
  currencyExchanger,
  globalCurrencyFallback,
} from '../../utils'
import { CurrencyPicker } from '../../components'
import {
  IBomDetailsTableValues,
  numeralsTextAlign,
  tableRowItemNameLeftPadding,
} from './bom-details'
import { offlineCurrencyExchangeRates } from '../erp'

const bomElementAutofilledFields: Array<keyof IBomFullElement> = [
  'bomElementId',
  'bomElementName',
  'bomElementMeasurementType',
  'bomElementCentimetersInPiece',
  'category',
]

export const BomDetailsElementsTable: React.FC<IBomDetailsTableValues> = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  setIsBomModifiedAndUnsaved,
  isBomChangeOccuring,
  didFormValidationOccur,
  validateForm,
}) => {
  const adminState = useSelector((state: TRootState) => state.admin)
  const bomElementsState = useSelector((state: TRootState) => state.bomElements)
  const offersState = useSelector((state: TRootState) => state.offers)
  const implementationCostsState = useSelector((state: TRootState) => state.implementationCosts)
  const servicesState = useSelector((state: TRootState) => state.services)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const [shouldOfferTypeaheadBeFiltered, setShouldOfferTypeaheadBeFiltered] =
    useState<boolean>(false)

  const elementOfferInputs = useRef([])
  const elementNameTypeaheads = useRef([])

  const unchosenBomElementCategory = -1
  const indexOfFirstUnchosenElement = 0
  const delayOfFormValidationCall = 1

  const exchangeRates = adminState.todayCurrencyExchange
    ? adminState.todayCurrencyExchange.rates
    : offlineCurrencyExchangeRates

  const isThereOneElementInUnchosenCategory = Boolean(
    values?.bomElementDetails?.filter(
      (bomElementToFilter: actionTypes.IBomFullElement) =>
        bomElementToFilter?.category === unchosenBomElementCategory ||
        bomElementToFilter?.category === undefined
    )?.length === 1
  )

  const addNewElementButton = document.getElementById('add-element-to-bom-table-button')

  const numberOfElementsInUnchosenCategory = Number(
    values?.bomElementDetails?.filter(
      (bomElement: actionTypes.IBomFullElement) =>
        bomElement?.category === unchosenBomElementCategory
    )?.length
  )

  // Prepare empty first element in unchosen category always after BOM is loaded
  useEffect(() => {
    if (
      addNewElementButton &&
      // if there is one empty element already then prevent adding new one (second)
      !values?.bomElementDetails?.some(
        (bomElement: actionTypes.IBomFullElement) =>
          bomElement.category === unchosenBomElementCategory
      )
    ) {
      addNewElementButton?.click()
    }
  }, [addNewElementButton])

  const removeImplementationCostAndServiceOfferPrice = (
    tableRowItem: actionTypes.IBomFullElement
  ) => {
    const indexOfImpCostToRemoveOfferPrice = values.bomImplementationCostDetails.findIndex(
      (bomImpCost: actionTypes.IBomImplementationCost) =>
        bomImpCost.offerId === tableRowItem.offerId
    )

    if (indexOfImpCostToRemoveOfferPrice !== unchosenBomElementCategory) {
      values.bomImplementationCostDetails[indexOfImpCostToRemoveOfferPrice].offer = null
      values.bomImplementationCostDetails[indexOfImpCostToRemoveOfferPrice].offerId = null
    }

    const indexOfServiceToRemoveOfferPrice = values.bomServiceDetails.findIndex(
      (bomService: actionTypes.IBomService) => bomService.offerId === tableRowItem.offerId
    )

    if (indexOfServiceToRemoveOfferPrice !== unchosenBomElementCategory) {
      values.bomServiceDetails[indexOfServiceToRemoveOfferPrice].offer = null
      values.bomServiceDetails[indexOfServiceToRemoveOfferPrice].offerId = null
    }
  }

  const editImplementationCostOnOfferOrElementChange = (
    selectedOffer: actionTypes.TLightOfferInTable
  ) => {
    const alreadyAddedBomImplementationCostInTable = values?.bomImplementationCostDetails?.find(
      (impCost: actionTypes.IBomImplementationCost) =>
        impCost.bomImplementationCostId === selectedOffer.implementationCostId
    )

    if (alreadyAddedBomImplementationCostInTable) {
      alreadyAddedBomImplementationCostInTable.offer = selectedOffer
      alreadyAddedBomImplementationCostInTable.offerId = selectedOffer.id
    } else {
      const impCostFromElement: actionTypes.IBomImplementationCost = {
        bomImplementationCostId: selectedOffer.implementationCostId,
        // This could be returned from back-end
        bomImplementationCostName:
          implementationCostsState.implementationCosts?.find(
            (impCost: actionTypes.TImplementationCost) =>
              impCost.id === selectedOffer.implementationCostId
          )?.name || '',
        quantity: 1,
        offer: selectedOffer,
        offerId: selectedOffer.id,
        isChecked: true,

        estimatedPrice: '',
        estimatedPriceCurrency:
          settingsState?.settings?.otherSettings?.defaultSystemCurrency || globalCurrencyFallback,
        lastOrderPrice: '',
        lastOrderPriceCurrency:
          settingsState?.settings?.otherSettings?.defaultSystemCurrency || globalCurrencyFallback,

        cost: '',
        id: null,
        uuId: uuidv4(),
      }

      setFieldValue('bomImplementationCostDetails', [
        ...values.bomImplementationCostDetails,
        impCostFromElement,
      ])
    }
  }

  const removeServiceOfferPrice = (tableRowItem: actionTypes.IBomFullElement) => {
    const indexOfServiceToRemoveOfferPrice = values.bomServiceDetails.findIndex(
      (bomService: actionTypes.IBomService) => bomService.offerId === tableRowItem.offerId
    )

    if (indexOfServiceToRemoveOfferPrice !== unchosenBomElementCategory) {
      values.bomServiceDetails[indexOfServiceToRemoveOfferPrice].offer = null
      values.bomServiceDetails[indexOfServiceToRemoveOfferPrice].offerId = null
    }
  }

  const editServiceOnOfferOrElementChange = (selectedOffer: actionTypes.TLightOfferInTable) => {
    const alreadyAddedBomServiceInTable = values?.bomServiceDetails?.find(
      (service: actionTypes.IBomService) => service.bomServiceId === selectedOffer.serviceId
    )

    if (alreadyAddedBomServiceInTable) {
      alreadyAddedBomServiceInTable.offer = selectedOffer
      alreadyAddedBomServiceInTable.offerId = selectedOffer.id
    } else {
      const serviceFromElement: actionTypes.IBomService = {
        bomServiceId: selectedOffer.serviceId,
        // This could be returned from back-end
        bomServiceName:
          servicesState.services?.find(
            (service: actionTypes.TService) => service.id === selectedOffer.serviceId
          )?.name || '',
        quantity: 1,
        offer: selectedOffer,
        offerId: selectedOffer.id,
        isChecked: true,

        estimatedPrice: '',
        estimatedPriceCurrency:
          settingsState?.settings?.otherSettings?.defaultSystemCurrency || globalCurrencyFallback,
        lastOrderPrice: '',
        lastOrderPriceCurrency:
          settingsState?.settings?.otherSettings?.defaultSystemCurrency || globalCurrencyFallback,

        cost: '',
        id: null,
        uuId: uuidv4(),
      }

      setFieldValue('bomServiceDetails', [...values.bomServiceDetails, serviceFromElement])
    }
  }

  return (
    <FieldArray
      name="bomElementDetails"
      render={(arrayHelpers) => (
        <>
          <div className="p-0 my-4 d-flex justify-content-center">
            <CButton
              color="info"
              /* This is a hack because validateForm would always be one step behind arrayHelpers.push function */
              type={`${didFormValidationOccur && errors?.bomElementDetails ? 'submit' : 'button'}`}
              variant="outline"
              className="px-5"
              id="add-element-to-bom-table-button"
              onClick={() => {
                let newBomElementInTable: actionTypes.IBomFullElement = {
                  bomElementId: null,
                  bomElementMeasurementType: 1,
                  bomElementName: '',
                  bomElementCentimetersInPiece: null,
                  category: unchosenBomElementCategory,
                  position: undefined,

                  quantity: '',
                  estimatedPrice: '',
                  estimatedPriceCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                    globalCurrencyFallback,

                  offer: null,
                  offerId: null,
                  offers: [],

                  lastOrderPrice: '',
                  lastOrderPriceCurrency: '',

                  estimatedLeadTimeFrom: '',
                  estimatedLeadTimeTo: '',

                  cost: '',

                  id: null,
                  uuId: uuidv4(),
                }

                arrayHelpers.push(newBomElementInTable)
              }}
              disabled={isBomChangeOccuring}
            >
              Dodaj pozycję
            </CButton>
          </div>

          <Table
            className={`bom-details-elements-table ${
              values?.bomElementDetails?.length ? 'mb-0' : 'mb-3'
            }`}
          >
            <thead className="bom-elements-table-sticky-thead">
              {/*
               * When changing width values below remember to also change them in .dragged-bom-element-row-in-table class
               * Especially width of name Typeahead in fixed pixels! last value was: 394px
               */}
              <tr>
                <th style={{ width: '45px', textAlign: 'center' }}>#</th>
                <th style={{ paddingLeft: tableRowItemNameLeftPadding }}>Informacje o elemencie</th>
                <th style={{ width: '140px' }}>Ilość</th>
                <th style={{ width: '185px' }}>Cena szacunkowa</th>
                <th style={{ width: '165px' }}>Cena ofertowa</th>
                <th style={{ width: '165px' }}>Cena zamówienia</th>
                <th style={{ width: '75px' }}>LT od</th>
                <th style={{ width: '75px' }}>LT do</th>

                <th style={{ width: '180px' }}>Wartość</th>

                <th style={{ width: '45px' }}></th>
              </tr>
            </thead>

            {/*
             * Maybe now this code can be refactored without category extract because later bomElements are sorted anyway
             */}

            {values?.bomElementDetails
              ?.reduce((accumulator: number[], bomElement: actionTypes.IBomFullElement) => {
                if (!bomElement?.category) {
                  bomElement.category = actionTypes.bomElementCategoryOtherValue
                }

                if (
                  !accumulator?.includes(bomElement?.category) &&
                  Number.isInteger(bomElement?.category)
                ) {
                  accumulator?.push(bomElement?.category)
                }

                return accumulator
              }, [] as number[])
              ?.sort((category: number, nextCategory: number) => category - nextCategory)
              ?.map((category: number) => {
                const isDragDropDisabled =
                  values?.bomElementDetails?.filter(
                    (bomElementToFilter: actionTypes.IBomFullElement) =>
                      bomElementToFilter.category === category
                  )?.length < 2

                return (
                  <DragDropContext
                    key={`bom-elements-table-category-dnd-context-${category}`}
                    onDragEnd={(result: DropResult) => {
                      if (
                        (result?.source?.index === indexOfFirstUnchosenElement ||
                          result?.source?.index) &&
                        (result?.destination?.index === indexOfFirstUnchosenElement ||
                          result?.destination?.index)
                      ) {
                        arrayHelpers.move(result.source.index, result.destination.index)

                        // After drag and drop of element form should again be validated to update the red error fields display
                        if (didFormValidationOccur && errors?.bomElementDetails && validateForm) {
                          setTimeout(() => validateForm(), delayOfFormValidationCall)
                        }
                      }
                    }}
                  >
                    <Droppable
                      key={`bom-elements-table-category-droppable-${category}`}
                      droppableId={`${
                        actionTypes.OBomElementCategory[Number(category)]
                      }-${category}`}
                      isDropDisabled={isDragDropDisabled}
                    >
                      {(droppableProvided: DroppableProvided) => (
                        <tbody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          <tr>
                            <td />
                            <td className="bom-elements-table-row-category-cell">
                              {category === unchosenBomElementCategory
                                ? 'WYBIERZ ELEMENT'
                                : actionTypes.OBomElementCategory[Number(category)]?.toUpperCase()}
                            </td>
                            <td />
                            <td />
                            <td />
                          </tr>

                          {values?.bomElementDetails
                            ?.sort(
                              (
                                bomElementToSort: actionTypes.IBomFullElement,
                                nextBomElement: actionTypes.IBomFullElement
                              ) =>
                                Number(bomElementToSort?.category) -
                                Number(nextBomElement?.category)
                            )
                            ?.map(
                              (
                                tableRowItem: actionTypes.IBomFullElement,
                                indexOfTableRowItem: number
                              ) => {
                                if (category === tableRowItem?.category) {
                                  return (
                                    <Draggable
                                      key={`bom-element-${tableRowItem.uuId}`}
                                      draggableId={tableRowItem.uuId}
                                      index={indexOfTableRowItem}
                                      isDragDisabled={isDragDropDisabled}
                                    >
                                      {(
                                        draggableProvided: DraggableProvided,
                                        snapshot: DraggableStateSnapshot
                                      ) => {
                                        return (
                                          <tr
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            className={
                                              snapshot?.isDragging
                                                ? 'dragged-bom-element-row-in-table'
                                                : ''
                                            }
                                          >
                                            {/**
                                             * ELEMENT POSITION
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-position`}
                                              style={{
                                                lineHeight: '2.4',
                                                textAlign: numeralsTextAlign,
                                                minWidth: '45px',
                                              }}
                                            >
                                              {tableRowItem.category ===
                                              unchosenBomElementCategory ? (
                                                <>&#8942;</>
                                              ) : (
                                                `${
                                                  Number(indexOfTableRowItem + 1) -
                                                  numberOfElementsInUnchosenCategory
                                                }.`
                                              )}
                                            </td>

                                            {/**
                                             * SELECT ELEMENT / TYPE NAME
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-name-info`}
                                              style={{ paddingLeft: tableRowItemNameLeftPadding }}
                                            >
                                              <div
                                                className={`bom-table-element-typeahead-wrapper`}
                                              >
                                                <Typeahead
                                                  placeholder="Wybierz z listy lub wpisz nazwę elementu"
                                                  isInvalid={
                                                    errors.bomElementDetails &&
                                                    errors.bomElementDetails[indexOfTableRowItem] &&
                                                    Boolean(
                                                      errors?.bomElementDetails[indexOfTableRowItem]
                                                        ?.bomElementName
                                                    )
                                                  }
                                                  id={`bomElementDetails.${indexOfTableRowItem}.bomElementName`}
                                                  ref={(HTMLElement: never) => {
                                                    elementNameTypeaheads.current[
                                                      indexOfTableRowItem
                                                    ] = HTMLElement
                                                  }}
                                                  onChange={(
                                                    selectedBomElementsArray: actionTypes.TBomElement[]
                                                  ) => {
                                                    const selectedElement =
                                                      selectedBomElementsArray[0]

                                                    /* Fills or Clears the remaining fields of bomElement object in Formik values */
                                                    /* This is needed to check the element's measurement type: piece or cm : 1 or 2 */

                                                    if (selectedElement) {
                                                      setFieldValue(
                                                        `bomElementDetails.${indexOfTableRowItem}.${bomElementAutofilledFields[0]}`,
                                                        selectedElement.id || null,
                                                        false
                                                      )

                                                      /* If you clear the output the input should always be an empty string "" never null because it allows the menu to open again */

                                                      setFieldValue(
                                                        `bomElementDetails.${indexOfTableRowItem}.${bomElementAutofilledFields[1]}`,
                                                        selectedElement?.name || '',
                                                        false
                                                      )

                                                      setFieldValue(
                                                        `bomElementDetails.${indexOfTableRowItem}.${bomElementAutofilledFields[2]}`,
                                                        selectedElement?.measurementType || 1,
                                                        false
                                                      )

                                                      setFieldValue(
                                                        `bomElementDetails.${indexOfTableRowItem}.${bomElementAutofilledFields[3]}`,
                                                        selectedElement?.centimetersInPiece || null,
                                                        false
                                                      )

                                                      if (
                                                        tableRowItem?.category ===
                                                        unchosenBomElementCategory
                                                      ) {
                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.categoryPlaceholderBeforeAssignment`,
                                                          selectedElement?.category,
                                                          false
                                                        )
                                                      } else if (selectedElement?.category) {
                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.${bomElementAutofilledFields[4]}`,
                                                          Number(selectedElement?.category),
                                                          false
                                                        )
                                                      }

                                                      /* Fills the offers information */

                                                      const listOfElementOffers =
                                                        offersState.offers?.filter(
                                                          (offer: actionTypes.TLightOfferInTable) =>
                                                            offer.bomElementId ===
                                                            selectedElement?.id
                                                        )

                                                      if (listOfElementOffers.length) {
                                                        //
                                                        let latestElementOffer: actionTypes.TLightOfferInTable =
                                                          listOfElementOffers.sort(
                                                            (
                                                              offer: actionTypes.TLightOfferInTable,
                                                              nextOffer: actionTypes.TLightOfferInTable
                                                            ) => {
                                                              if (
                                                                offer.createDate &&
                                                                nextOffer.createDate
                                                              ) {
                                                                if (
                                                                  offer.createDate >
                                                                  nextOffer.createDate
                                                                ) {
                                                                  return unchosenBomElementCategory
                                                                }
                                                                if (
                                                                  offer.createDate <
                                                                  nextOffer.createDate
                                                                ) {
                                                                  return 1
                                                                }
                                                                return 0
                                                              } else {
                                                                return 0
                                                              }
                                                            }
                                                          )[0] || listOfElementOffers[0]

                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.offers`,
                                                          listOfElementOffers,
                                                          false
                                                        )

                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.offerId`,
                                                          Number(latestElementOffer?.id),
                                                          false
                                                        )

                                                        if (
                                                          latestElementOffer &&
                                                          latestElementOffer?.bomElementPrice ===
                                                            null
                                                        ) {
                                                          latestElementOffer.bomElementPrice =
                                                            latestElementOffer?.bomElementPrice ||
                                                            ' '
                                                        }

                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.offer`,
                                                          latestElementOffer,
                                                          false
                                                        )

                                                        /* Here the created ref is used to change the value of an input because it does not automatically update with setFieldValue */

                                                        const offerTypeaheadInput: any =
                                                          elementOfferInputs?.current[
                                                            indexOfTableRowItem
                                                          ]

                                                        if (offerTypeaheadInput) {
                                                          offerTypeaheadInput.inputNode.value =
                                                            convertNumberToNumericString(
                                                              latestElementOffer?.bomElementPrice
                                                            )

                                                          offerTypeaheadInput.state.text =
                                                            convertNumberToNumericString(
                                                              latestElementOffer?.bomElementPrice
                                                            )

                                                          offerTypeaheadInput.state.selected = [
                                                            latestElementOffer,
                                                          ]
                                                        }

                                                        /*
                                                         * A place to fill the implementation costs
                                                         */

                                                        if (
                                                          latestElementOffer?.implementationCostId
                                                        ) {
                                                          editImplementationCostOnOfferOrElementChange(
                                                            latestElementOffer
                                                          )
                                                        }

                                                        if (latestElementOffer?.serviceId) {
                                                          editServiceOnOfferOrElementChange(
                                                            latestElementOffer
                                                          )
                                                        }
                                                      }
                                                    }
                                                  }}
                                                  onInputChange={(text: string) => {
                                                    /* Delete other fields when the length of an input field when it is smaller than 1 */

                                                    if (text.length < 1) {
                                                      setFieldValue(
                                                        `bomElementDetails.${indexOfTableRowItem}.bomElementName`,
                                                        tableRowItem?.bomElementName || '',
                                                        false
                                                      )

                                                      if (
                                                        tableRowItem?.category ===
                                                        unchosenBomElementCategory
                                                      ) {
                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.categoryPlaceholderBeforeAssignment`,
                                                          undefined,
                                                          false
                                                        )
                                                      }

                                                      /* Removes the remaining fields of bomElement object in Formik values */
                                                      for (
                                                        var i = 0,
                                                          len = bomElementAutofilledFields.length;
                                                        i < len;
                                                        ++i
                                                      ) {
                                                        if (
                                                          bomElementAutofilledFields[i] !==
                                                          'category'
                                                        ) {
                                                          setFieldValue(
                                                            `bomElementDetails.${indexOfTableRowItem}.${bomElementAutofilledFields[i]}`,

                                                            bomElementAutofilledFields[i] ===
                                                              'bomElementName'
                                                              ? ''
                                                              : bomElementAutofilledFields[i] ===
                                                                'bomElementMeasurementType'
                                                              ? 1
                                                              : null,

                                                            false
                                                          )
                                                        }
                                                      }

                                                      const fieldsToEmpty: Array<
                                                        keyof actionTypes.IBomFullElement
                                                      > = [
                                                        'quantity',
                                                        'estimatedPrice',
                                                        'offer',
                                                        'offerId',
                                                        'offers',
                                                        'lastOrderPrice',
                                                        'estimatedLeadTimeFrom',
                                                        'estimatedLeadTimeTo',
                                                      ]

                                                      fieldsToEmpty.forEach(
                                                        (
                                                          field: keyof actionTypes.IBomFullElement
                                                        ) =>
                                                          setFieldValue(
                                                            `bomElementDetails.${indexOfTableRowItem}.${field}`,
                                                            field === 'offerId'
                                                              ? null
                                                              : field === 'offers'
                                                              ? []
                                                              : '',
                                                            false
                                                          )
                                                      )

                                                      /* Here the created ref is used to change the value of an input for offer price because it does not automatically update with setFieldValue */

                                                      const offerTypeaheadInput: any =
                                                        elementOfferInputs.current[
                                                          indexOfTableRowItem
                                                        ]

                                                      if (offerTypeaheadInput) {
                                                        offerTypeaheadInput.inputNode.value = ''

                                                        offerTypeaheadInput.state.text = ''

                                                        offerTypeaheadInput.state.selected = []
                                                      }

                                                      /* Remove the offer price from the implementation cost from this element */

                                                      removeImplementationCostAndServiceOfferPrice(
                                                        tableRowItem
                                                      )
                                                      removeServiceOfferPrice(tableRowItem)
                                                    }
                                                  }}
                                                  defaultInputValue={
                                                    tableRowItem?.bomElementName || ''
                                                  }
                                                  /* Here we traverse the array of TBomElement objects */
                                                  options={
                                                    tableRowItem?.bomElementId &&
                                                    tableRowItem?.bomElementName
                                                      ? []
                                                      : bomElementsState?.bomElements
                                                          ?.filter(
                                                            (bomElement: actionTypes.TBomElement) =>
                                                              !values?.bomElementDetails?.some(
                                                                (
                                                                  bomElementInTable: actionTypes.IBomFullElement
                                                                ) =>
                                                                  bomElement?.id ===
                                                                  bomElementInTable?.bomElementId
                                                              )
                                                          )
                                                          ?.map(
                                                            (
                                                              bomElement: actionTypes.TBomElement
                                                            ) => ({
                                                              ...bomElement,
                                                              name: `${bomElement.name} | MPN: ${
                                                                bomElement?.mpn || 'brak'
                                                              } | DIN: ${
                                                                bomElement?.symbolDin || 'brak'
                                                              }`,
                                                            })
                                                          )
                                                  }
                                                  labelKey="name"
                                                  emptyLabel={
                                                    tableRowItem.bomElementId &&
                                                    tableRowItem.bomElementName
                                                      ? `Wybrany element: ${tableRowItem.bomElementName}`
                                                      : 'Nie znaleziono elementów'
                                                  }
                                                  disabled={isBomChangeOccuring}
                                                >
                                                  {/*
                                                   * The conditionally rendered button for moving element to its proper category in table
                                                   */}

                                                  {tableRowItem?.category ===
                                                    unchosenBomElementCategory && (
                                                    <CTooltip
                                                      content={`${actionTypes.OBomElementCategory[
                                                        Number(
                                                          tableRowItem.categoryPlaceholderBeforeAssignment
                                                        )
                                                      ]?.toUpperCase()}`}
                                                    >
                                                      <CButton
                                                        color="info"
                                                        variant="outline"
                                                        className="ml-3 bom-elements-table-assign-element-to-category-button"
                                                        type="button"
                                                        onClick={() => {
                                                          if (
                                                            tableRowItem?.categoryPlaceholderBeforeAssignment
                                                          ) {
                                                            if (
                                                              isThereOneElementInUnchosenCategory &&
                                                              addNewElementButton
                                                            ) {
                                                              addNewElementButton?.click()
                                                            }

                                                            setFieldValue(
                                                              `bomElementDetails.${indexOfTableRowItem}.${bomElementAutofilledFields[4]}`,
                                                              Number(
                                                                tableRowItem.categoryPlaceholderBeforeAssignment
                                                              ),
                                                              false
                                                            )

                                                            // After moving the element from unchosen category form should again be validated to update the red error fields display
                                                            if (
                                                              didFormValidationOccur &&
                                                              errors?.bomElementDetails &&
                                                              validateForm
                                                            ) {
                                                              setTimeout(
                                                                () => validateForm(),
                                                                delayOfFormValidationCall
                                                              )
                                                            }

                                                            setIsBomModifiedAndUnsaved(true)
                                                          }
                                                        }}
                                                        disabled={
                                                          !tableRowItem?.categoryPlaceholderBeforeAssignment ||
                                                          isBomChangeOccuring
                                                        }
                                                      >
                                                        <CIcon icon={cilExpandDown} />
                                                      </CButton>
                                                    </CTooltip>
                                                  )}
                                                </Typeahead>
                                              </div>
                                            </td>

                                            {/**
                                             *  ELEMENT QUANTITY
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-${tableRowItem?.uuId}-quantity`}
                                            >
                                              <InputGroup>
                                                <FastField
                                                  as={NumberFormat}
                                                  displayType="input"
                                                  thousandSeparator={globalThousandSeparator}
                                                  decimalSeparator={globalDecimalSeparator}
                                                  decimalScale={2}
                                                  fixedDecimalScale={false}
                                                  allowNegative={false}
                                                  allowLeadingZeros={true}
                                                  name={`bomElementDetails.${indexOfTableRowItem}.quantity`}
                                                  value={tableRowItem.quantity}
                                                  placeholder="Ilość"
                                                  className={`form-control text-right ${
                                                    errors.bomElementDetails !== undefined &&
                                                    typeof errors.bomElementDetails !== 'string' &&
                                                    errors.bomElementDetails[indexOfTableRowItem] &&
                                                    errors.bomElementDetails[indexOfTableRowItem]
                                                      ?.quantity
                                                      ? 'invalid-field-without-exclamation'
                                                      : 'currency-input-field'
                                                  } ${
                                                    isBomChangeOccuring && 'table-disabled-input'
                                                  }`}
                                                  onPaste={(
                                                    event: ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    handleNumberFormatPaste(event)
                                                  }}
                                                  disabled={isBomChangeOccuring}
                                                />

                                                <InputGroupAddon addonType="append">
                                                  <InputGroupText
                                                    className={
                                                      errors.bomElementDetails !== undefined &&
                                                      typeof errors.bomElementDetails !==
                                                        'string' &&
                                                      errors.bomElementDetails[
                                                        indexOfTableRowItem
                                                      ] &&
                                                      errors.bomElementDetails[indexOfTableRowItem]
                                                        ?.quantity
                                                        ? 'invalid-measurement'
                                                        : 'disabled-currency'
                                                    }
                                                    style={{
                                                      width: '45px',
                                                    }}
                                                  >
                                                    {tableRowItem.bomElementMeasurementType === 2
                                                      ? 'cm'
                                                      : 'szt.'}
                                                  </InputGroupText>
                                                </InputGroupAddon>
                                              </InputGroup>
                                            </td>

                                            {/**
                                             *  ELEMENT ESTIMATED PRICE
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-${tableRowItem?.uuId}-estimated-price`}
                                            >
                                              <InputGroup>
                                                <FastField
                                                  as={NumberFormat}
                                                  displayType="input"
                                                  thousandSeparator={globalThousandSeparator}
                                                  decimalSeparator={globalDecimalSeparator}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  allowNegative={false}
                                                  allowLeadingZeros={true}
                                                  name={`bomElementDetails.${indexOfTableRowItem}.estimatedPrice`}
                                                  value={tableRowItem?.estimatedPrice}
                                                  placeholder="Cena szacunkowa"
                                                  className={`form-control text-right ${
                                                    errors.bomElementDetails !== undefined &&
                                                    typeof errors.bomElementDetails !== 'string' &&
                                                    errors.bomElementDetails[indexOfTableRowItem] &&
                                                    errors.bomElementDetails[indexOfTableRowItem]
                                                      ?.estimatedPrice
                                                      ? 'invalid-field-without-exclamation'
                                                      : 'currency-input-field'
                                                  } ${
                                                    isBomChangeOccuring && 'table-disabled-input'
                                                  }`}
                                                  onPaste={(
                                                    event: ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    handleNumberFormatPaste(event)
                                                  }}
                                                  disabled={isBomChangeOccuring}
                                                />
                                                <InputGroupAddon
                                                  addonType="append"
                                                  className={
                                                    errors.bomElementDetails !== undefined &&
                                                    typeof errors.bomElementDetails !== 'string' &&
                                                    errors.bomElementDetails[indexOfTableRowItem] &&
                                                    errors.bomElementDetails[indexOfTableRowItem]
                                                      ?.estimatedPrice
                                                      ? 'invalid-native-selector'
                                                      : ''
                                                  }
                                                >
                                                  <CurrencyPicker
                                                    handleChange={handleChange}
                                                    fieldName={`bomElementDetails.${indexOfTableRowItem}.estimatedPriceCurrency`}
                                                    dynamicCurrency={
                                                      tableRowItem?.estimatedPriceCurrency ||
                                                      settingsState?.settings?.otherSettings
                                                        ?.defaultSystemCurrency ||
                                                      globalCurrencyFallback
                                                    }
                                                  />
                                                </InputGroupAddon>
                                              </InputGroup>
                                            </td>

                                            {/**
                                             *  ELEMENT OFFER PRICE
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${tableRowItem?.id}-offer-price`}
                                            >
                                              <InputGroup>
                                                <div className="bom-table-element-offer-typeahead-wrapper">
                                                  <Typeahead
                                                    id={`bomElementDetails.${indexOfTableRowItem}.offer`}
                                                    ref={(HTMLElement: never) => {
                                                      elementOfferInputs.current[
                                                        indexOfTableRowItem
                                                      ] = HTMLElement
                                                    }}
                                                    defaultInputValue={convertNumberToNumericString(
                                                      tableRowItem?.offer?.bomElementPrice
                                                    )}
                                                    placeholder={
                                                      tableRowItem?.offers?.length
                                                        ? 'Wybierz z listy ofert'
                                                        : 'Brak ofert do wyboru!'
                                                    }
                                                    onChange={(
                                                      selectedOffersArray: actionTypes.TLightOfferInTable[]
                                                    ) => {
                                                      let selectedOffer = selectedOffersArray[0]

                                                      if (
                                                        selectedOffer &&
                                                        selectedOffer?.bomElementPrice === null
                                                      ) {
                                                        selectedOffer.bomElementPrice =
                                                          selectedOffer?.bomElementPrice || ' '
                                                      }

                                                      if (selectedOffer) {
                                                        setShouldOfferTypeaheadBeFiltered(false)

                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.offerId`,
                                                          Number(selectedOffer?.id),
                                                          false
                                                        )

                                                        setFieldValue(
                                                          `bomElementDetails.${indexOfTableRowItem}.offer`,
                                                          selectedOffer,
                                                          false
                                                        )

                                                        if (selectedOffer?.implementationCostId) {
                                                          editImplementationCostOnOfferOrElementChange(
                                                            selectedOffer
                                                          )
                                                        }

                                                        if (selectedOffer?.serviceId) {
                                                          editServiceOnOfferOrElementChange(
                                                            selectedOffer
                                                          )
                                                        }
                                                      }
                                                    }}
                                                    onInputChange={(text: string) => {
                                                      if (text.length < 1) {
                                                        const fieldsToEmpty: Array<
                                                          keyof actionTypes.IBomFullElement
                                                        > = ['offer', 'offerId']

                                                        fieldsToEmpty.forEach(
                                                          (
                                                            field: keyof actionTypes.IBomFullElement
                                                          ) =>
                                                            setFieldValue(
                                                              `bomElementDetails.${indexOfTableRowItem}.${field}`,
                                                              null,
                                                              false
                                                            )
                                                        )

                                                        removeImplementationCostAndServiceOfferPrice(
                                                          tableRowItem
                                                        )

                                                        setShouldOfferTypeaheadBeFiltered(false)
                                                      }

                                                      if (text.length > 1) {
                                                        setShouldOfferTypeaheadBeFiltered(true)
                                                      }
                                                    }}
                                                    options={
                                                      tableRowItem?.offers?.sort(
                                                        (
                                                          presentOffer: actionTypes.TLightOfferInTable,
                                                          nextOffer: actionTypes.TLightOfferInTable
                                                        ) =>
                                                          currencyExchanger(
                                                            Number(presentOffer.bomElementPrice),
                                                            presentOffer.bomElementPriceCurrency,
                                                            globalCurrencyFallback,
                                                            exchangeRates
                                                          ) -
                                                          currencyExchanger(
                                                            Number(nextOffer.bomElementPrice),
                                                            nextOffer.bomElementPriceCurrency,
                                                            globalCurrencyFallback,
                                                            exchangeRates
                                                          )
                                                      ) || []
                                                    }
                                                    labelKey={(
                                                      option: actionTypes.TLightOfferInTable
                                                    ) =>
                                                      convertNumberToNumericString(
                                                        option?.bomElementPrice
                                                      )
                                                    }
                                                    filterBy={
                                                      shouldOfferTypeaheadBeFiltered
                                                        ? [
                                                            'bomElementPrice',
                                                            'bomElementPriceCurrency',
                                                            'quantity',
                                                            'supplierName',
                                                          ]
                                                        : () => true
                                                    }
                                                    emptyLabel={'Brak ofert do wyboru!'}
                                                    renderMenuItemChildren={(
                                                      offer: actionTypes.TLightOfferInTable,
                                                      props: TypeaheadMenuProps<actionTypes.TLightOfferInTable>,
                                                      indexOfOfferChild: number
                                                    ) => (
                                                      <option
                                                        className={
                                                          tableRowItem.offerId === offer.id
                                                            ? 'typeahead-already-selected-item-highlight'
                                                            : ''
                                                        }
                                                        key={`offer-dropdown-item-${offer.id}-${indexOfOfferChild}`}
                                                      >
                                                        {convertNumberToNumericString(
                                                          offer?.bomElementPrice
                                                        )}{' '}
                                                        {offer?.bomElementPriceCurrency || ''} |{' '}
                                                        {offer?.quantity || ''}{' '}
                                                        {tableRowItem?.bomElementMeasurementType ===
                                                        2
                                                          ? 'cm.'
                                                          : 'szt.'}{' '}
                                                        | {offer?.supplierName || ''}
                                                        {offer?.implementationCostId
                                                          ? ' | Tooling: ' +
                                                            convertNumberToNumericString(
                                                              offer?.implementationCostPrice
                                                            ) +
                                                            ' ' +
                                                            (offer?.implementationCostPriceCurrency ||
                                                              '')
                                                          : ''}
                                                      </option>
                                                    )}
                                                    disabled={
                                                      !tableRowItem?.offers?.length ||
                                                      isBomChangeOccuring
                                                    }
                                                  />
                                                </div>

                                                {/* Disabled Offer Currency */}
                                                <InputGroupAddon addonType="append">
                                                  <InputGroupText className="disabled-currency">
                                                    {tableRowItem.offer?.bomElementPriceCurrency}
                                                  </InputGroupText>
                                                </InputGroupAddon>
                                              </InputGroup>
                                            </td>

                                            {/**
                                             *  ELEMENT LAST ORDER PRICE
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-${tableRowItem?.uuId}-order-price`}
                                            >
                                              <InputGroup>
                                                <FastField
                                                  as={NumberFormat}
                                                  displayType="input"
                                                  thousandSeparator={globalThousandSeparator}
                                                  decimalSeparator={globalDecimalSeparator}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  allowNegative={false}
                                                  allowLeadingZeros={true}
                                                  name={`bomElementDetails.${indexOfTableRowItem}.lastOrderPrice`}
                                                  value={tableRowItem?.lastOrderPrice || ''}
                                                  placeholder="Cena zamówienia"
                                                  className="form-control text-right table-disabled-input"
                                                  disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                  <InputGroupText className="disabled-currency">
                                                    {tableRowItem?.lastOrderPriceCurrency || ''}
                                                  </InputGroupText>
                                                </InputGroupAddon>
                                              </InputGroup>
                                            </td>

                                            {/**
                                             *  ELEMENT LT FROM
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-${tableRowItem?.uuId}-estimated-lead-time-from`}
                                            >
                                              <InputGroup>
                                                <FastField
                                                  as={NumberFormat}
                                                  displayType="input"
                                                  thousandSeparator={globalThousandSeparator}
                                                  decimalSeparator={globalDecimalSeparator}
                                                  decimalScale={0}
                                                  fixedDecimalScale={false}
                                                  allowNegative={false}
                                                  allowLeadingZeros={false}
                                                  name={`bomElementDetails.${indexOfTableRowItem}.estimatedLeadTimeFrom`}
                                                  value={tableRowItem?.estimatedLeadTimeFrom || ''}
                                                  placeholder="dni"
                                                  className={`form-control text-right ${
                                                    errors.bomElementDetails !== undefined &&
                                                    typeof errors.bomElementDetails !== 'string' &&
                                                    errors.bomElementDetails[indexOfTableRowItem] &&
                                                    errors.bomElementDetails[indexOfTableRowItem]
                                                      ?.estimatedLeadTimeFrom
                                                      ? 'invalid-field-without-exclamation'
                                                      : ''
                                                  }`}
                                                  disabled={isBomChangeOccuring}
                                                />
                                              </InputGroup>
                                            </td>

                                            {/**
                                             *  ELEMENT LT TO
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-${tableRowItem?.uuId}-estimated-lead-time-to`}
                                            >
                                              <InputGroup>
                                                <FastField
                                                  as={NumberFormat}
                                                  displayType="input"
                                                  thousandSeparator={globalThousandSeparator}
                                                  decimalSeparator={globalDecimalSeparator}
                                                  decimalScale={0}
                                                  fixedDecimalScale={false}
                                                  allowNegative={false}
                                                  allowLeadingZeros={false}
                                                  name={`bomElementDetails.${indexOfTableRowItem}.estimatedLeadTimeTo`}
                                                  value={tableRowItem?.estimatedLeadTimeTo || ''}
                                                  placeholder="dni"
                                                  className={`form-control text-right ${
                                                    errors.bomElementDetails !== undefined &&
                                                    typeof errors.bomElementDetails !== 'string' &&
                                                    errors.bomElementDetails[indexOfTableRowItem] &&
                                                    errors.bomElementDetails[indexOfTableRowItem]
                                                      ?.estimatedLeadTimeTo
                                                      ? 'invalid-field-without-exclamation'
                                                      : ''
                                                  }`}
                                                  disabled={isBomChangeOccuring}
                                                />
                                              </InputGroup>
                                            </td>

                                            {/**
                                             *  ELEMENT COST
                                             */}

                                            <td
                                              key={`bom-element-table-cell-${indexOfTableRowItem}-${tableRowItem?.uuId}-cost`}
                                            >
                                              <InputGroup>
                                                <Field
                                                  as={NumberFormat}
                                                  displayType="input"
                                                  thousandSeparator={globalThousandSeparator}
                                                  decimalSeparator={globalDecimalSeparator}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  allowNegative={false}
                                                  allowLeadingZeros={true}
                                                  name={`bomElementDetails.${indexOfTableRowItem}.cost`}
                                                  value={
                                                    tableRowItem?.quantity
                                                      ? tableRowItem?.estimatedPrice ||
                                                        tableRowItem?.offer?.bomElementPrice ||
                                                        tableRowItem?.estimatedPrice
                                                        ? (values?.estimatedQuantity &&
                                                          !values?.isTotalCostPerUnit
                                                            ? Number(
                                                                convertNumericStringToNumber(
                                                                  values.estimatedQuantity
                                                                )
                                                              )
                                                            : 1) *
                                                          (tableRowItem?.bomElementMeasurementType ===
                                                          2
                                                            ? Number(
                                                                convertNumericStringToNumber(
                                                                  tableRowItem.quantity
                                                                )
                                                              ) /
                                                              Number(
                                                                tableRowItem?.bomElementCentimetersInPiece
                                                              )
                                                            : Number(
                                                                convertNumericStringToNumber(
                                                                  tableRowItem.quantity
                                                                )
                                                              )) *
                                                          Number(
                                                            convertNumericStringToNumber(
                                                              tableRowItem?.lastOrderPrice
                                                            ) ||
                                                              convertNumericStringToNumber(
                                                                tableRowItem?.offer?.bomElementPrice
                                                              ) ||
                                                              convertNumericStringToNumber(
                                                                tableRowItem?.estimatedPrice
                                                              )
                                                          )
                                                        : ''
                                                      : ''
                                                  }
                                                  placeholder="Wartość"
                                                  className="form-control text-right table-disabled-input"
                                                  disabled
                                                />

                                                {/* Disabled Currency */}
                                                <InputGroupAddon addonType="append">
                                                  <InputGroupText className="disabled-currency">
                                                    {tableRowItem?.offer
                                                      ? tableRowItem?.offer?.bomElementPriceCurrency
                                                      : tableRowItem?.estimatedPriceCurrency || ''}
                                                  </InputGroupText>
                                                </InputGroupAddon>
                                              </InputGroup>
                                            </td>

                                            {/**
                                             * REMOVE ELEMENT
                                             */}

                                            <td className="text-center">
                                              <CButton
                                                color="danger"
                                                type="button"
                                                variant="outline"
                                                className="select-option-remove-button"
                                                onClick={() => {
                                                  if (
                                                    indexOfTableRowItem ===
                                                      indexOfFirstUnchosenElement &&
                                                    tableRowItem?.bomElementId &&
                                                    addNewElementButton &&
                                                    isThereOneElementInUnchosenCategory
                                                  ) {
                                                    addNewElementButton?.click()
                                                  } else {
                                                    setIsBomModifiedAndUnsaved(true)
                                                  }

                                                  arrayHelpers.remove(indexOfTableRowItem)

                                                  // Delete implementation cost and service offer price when element is removed
                                                  removeImplementationCostAndServiceOfferPrice(
                                                    tableRowItem
                                                  )
                                                }}
                                                disabled={
                                                  isBomChangeOccuring ||
                                                  (values?.bomElementDetails?.filter(
                                                    (
                                                      bomElementToFilter: actionTypes.IBomFullElement
                                                    ) =>
                                                      bomElementToFilter?.category ===
                                                        unchosenBomElementCategory ||
                                                      bomElementToFilter?.category === undefined
                                                  )?.length === 1 &&
                                                    indexOfTableRowItem ===
                                                      indexOfFirstUnchosenElement &&
                                                    !tableRowItem?.bomElementId)
                                                }
                                              >
                                                <i className="cil-trash"></i>
                                              </CButton>
                                            </td>
                                          </tr>
                                        )
                                      }}
                                    </Draggable>
                                  )
                                }
                              }
                            )}

                          {category === unchosenBomElementCategory &&
                            errors.bomElementDetails !== undefined &&
                            typeof errors.bomElementDetails !== 'string' &&
                            errors.bomElementDetails.some(
                              (bomElementError: any) => bomElementError?.category
                            ) &&
                            (isThereOneElementInUnchosenCategory
                              ? values?.bomElementDetails?.find(
                                  (bomElement: actionTypes.IBomFullElement) =>
                                    bomElement?.category === unchosenBomElementCategory
                                )?.bomElementId
                              : true) && (
                              <tr>
                                <td colSpan={11} className="text-danger text-center py-4 ">
                                  Usuń niepotrzebne elementy z powyższej listy lub przenieś je do
                                  BOM za pomocą niebieskiego przycisku obok ich nazwy!
                                </td>
                              </tr>
                            )}

                          {droppableProvided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                )
              })}
          </Table>

          {(errors.bomElementDetails &&
            typeof errors.bomElementDetails === 'string' &&
            (values.bomImplementationCostDetails?.length || values.bomServiceDetails?.length
              ? false
              : !values.bomElementDetails?.length) && (
              <ErrorMessage
                name="bomElementDetails"
                component="div"
                className="text-danger d-flex justify-content-center mb-4"
              />
            )) ||
            null}

          {(errors?.bomElementDetails &&
            typeof errors?.bomElementDetails !== 'string' &&
            values?.bomElementDetails?.length &&
            // filter undefined errors
            errors?.bomElementDetails?.some((error: any) => error) && (
              <div className="text-danger d-flex justify-content-center mb-1 mt-3">
                {errors?.bomElementDetails?.reduce(
                  (accumulator: number, error: any) =>
                    error?.bomElementId ||
                    error?.bomElementName ||
                    error?.estimatedPrice ||
                    error?.quantity ||
                    error?.estimatedLeadTimeFrom?.includes('required') ||
                    error?.estimatedLeadTimeTo?.includes('required')
                      ? accumulator + 1
                      : accumulator,
                  0
                ) > 0 && 'Zaznaczone na czerwono pola w tabeli są wymagane!'}
                {
                  //
                }
                {errors?.bomElementDetails?.reduce(
                  (accumulator: number, error: any) =>
                    error?.estimatedLeadTimeTo?.includes('-1') ? accumulator + 1 : accumulator,

                  0
                ) === 1 && ' Pole "LT do" zawiera wsteczną wartość!'}
                {errors?.bomElementDetails?.reduce(
                  (accumulator: number, error: any) =>
                    error?.estimatedLeadTimeTo?.includes('-1') ? accumulator + 1 : accumulator,

                  0
                ) > 1 && ' Pola "LT do" zawierają wsteczne wartości!'}
              </div>
            )) ||
            null}
        </>
      )}
    />
  )
}
