import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CDataTable } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilZoom } from '@coreui/icons'

import { TNetworkError, TOffer } from '../store/action-types'
import {
  convertQuantityToNumericString,
  convertNumberToNumericString,
  toDateTableValue,
  noSearchResultsText,
} from '../utils'

interface IConnectedOffersTable {
  mode: 'bom-element' | 'implementation-cost' | 'service'
  areItemOffersLoading: boolean
  editedItemOffers: TOffer[] | null
  fetchItemOffersError: TNetworkError
  tenant: string
}

export const ConnectedOffersTable: React.FC<IConnectedOffersTable> = ({
  mode,
  areItemOffersLoading,
  editedItemOffers,
  fetchItemOffersError,
  tenant,
}) => {
  const memoizedTableItems = useMemo(
    () =>
      editedItemOffers && editedItemOffers?.length
        ? editedItemOffers?.map((offer: TOffer) => {
            return {
              ...offer,
              open: '',

              quantity:
                mode === 'implementation-cost'
                  ? 1
                  : convertQuantityToNumericString(offer?.quantity),
              price: convertNumberToNumericString(
                mode === 'bom-element'
                  ? offer?.bomElementPrice
                  : mode === 'implementation-cost'
                  ? offer?.implementationCostPrice
                  : offer.servicePrice
              ),
              currency: convertNumberToNumericString(
                mode === 'bom-element'
                  ? offer?.bomElementPriceCurrency
                  : mode === 'implementation-cost'
                  ? offer?.implementationCostPriceCurrency
                  : offer.servicePriceCurrency
              ),
              expirationDate: toDateTableValue(String(offer?.expirationDate || ''), false),
              number: offer?.number || '',
            }
          })
        : [],
    [editedItemOffers]
  )

  return (
    <div className="connected-offers-data-table-wrapper">
      <CDataTable
        sorterValue={{ column: 'expirationDate', asc: false }}
        loading={areItemOffersLoading}
        border
        sorter
        tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
        striped
        cleaner
        addTableClasses="item-inner-details-table item-inner-details-table--connected-items"
        fields={[
          {
            key: 'open',
            label: 'Podgląd',
            sorter: false,
            filter: false,
            _style: { width: '70px' },
          },
          {
            key: 'quantity',
            label: 'Ilość',
            _style: { width: '110px' },
          },
          {
            key: 'price',
            label: 'Cena',
            _style: { width: '110px' },
          },
          {
            key: 'currency',
            label: 'CCY',
            _style: { width: '40px' },
          },
          {
            key: 'expirationDate',
            label: 'Data',
            _style: { width: '100px' },
          },
          {
            key: 'number',
            label: 'Numer wewnętrzny',
            _style: { width: '1fr' },
          },
        ]}
        items={memoizedTableItems}
        noItemsViewSlot={
          <div className="no-items-in-table">
            {fetchItemOffersError
              ? `Wystąpił błąd ${fetchItemOffersError?.status} podczas pobierania ofert ${
                  mode === 'bom-element'
                    ? 'tego elementu'
                    : mode === 'implementation-cost'
                    ? 'tego toolingu'
                    : 'tej usługi'
                }!`
              : memoizedTableItems?.length
              ? noSearchResultsText
              : `${
                  mode === 'bom-element'
                    ? 'Ten element'
                    : mode === 'implementation-cost'
                    ? 'Ten tooling'
                    : 'Ta usługa'
                } nie posiada obecnie żadnych ofert!`}
          </div>
        }
        scopedSlots={{
          open: (offer: TOffer) => (
            <td>
              <Link
                to={`/${tenant}/offers/edit/${offer.id}/information`}
                className="button info d-flex justify-content-center"
                style={{ padding: '0.4rem 0.75rem' }}
              >
                <CIcon icon={cilZoom} />
              </Link>
            </td>
          ),
        }}
      />
    </div>
  )
}
