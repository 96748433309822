import * as actionTypes from '../action-types'

export interface IImplementationCostsState {
  implementationCosts: actionTypes.TImplementationCost[] | any
  areImplementationCostsLoading: boolean
  fetchImplementationCostsError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState | undefined

  editedImplementationCost: actionTypes.TImplementationCost | null

  isImplementationCostCreating: boolean
  implementationCostCreateError: actionTypes.TNetworkError
  isImplementationCostCreated: boolean

  isImplementationCostDeleting: boolean
  implementationCostDeleteError: actionTypes.TNetworkError
  isImplementationCostDeleted: boolean

  currentlyManagedImplementationCostId: number | null

  isImplementationCostEditSaving: boolean
  implementationCostEditSaveError: actionTypes.TNetworkError
  isImplementationCostEditSaved: boolean

  editedImplementationCostBoms: actionTypes.TImplementationCostBom[] | null
  areImplementationCostBomsLoading: boolean
  fetchImplementationCostBomsError: actionTypes.TNetworkError

  editedImplementationCostOffers: actionTypes.TOffer[] | null
  areImplementationCostOffersLoading: boolean
  fetchImplementationCostOffersError: actionTypes.TNetworkError

  editedImplementationCostOrders: actionTypes.TOrder[] | null
  areImplementationCostOrdersLoading: boolean
  fetchImplementationCostOrdersError: actionTypes.TNetworkError

  editedImplementationCostAttachments: actionTypes.TImplementationCostAttachment[] | null
  areImplementationCostAttachmentsLoading: boolean
  fetchImplementationCostAttachmentsError: actionTypes.TNetworkError

  isImplementationCostAttachmentUploading: boolean
  implementationCostAttachmentUploadingError: actionTypes.TNetworkError
  isImplementationCostAttachmentUploaded: boolean
}

const initialState: IImplementationCostsState = {
  implementationCosts: [],
  areImplementationCostsLoading: false,
  fetchImplementationCostsError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,

  editedImplementationCost: null,

  isImplementationCostCreating: false,
  implementationCostCreateError: null,
  isImplementationCostCreated: false,

  isImplementationCostDeleting: false,
  implementationCostDeleteError: null,
  isImplementationCostDeleted: false,

  currentlyManagedImplementationCostId: null,

  isImplementationCostEditSaving: false,
  implementationCostEditSaveError: null,
  isImplementationCostEditSaved: false,

  editedImplementationCostBoms: null,
  areImplementationCostBomsLoading: false,
  fetchImplementationCostBomsError: null,

  editedImplementationCostOffers: null,
  areImplementationCostOffersLoading: false,
  fetchImplementationCostOffersError: null,

  editedImplementationCostOrders: null,
  areImplementationCostOrdersLoading: false,
  fetchImplementationCostOrdersError: null,

  editedImplementationCostAttachments: null,
  areImplementationCostAttachmentsLoading: false,
  fetchImplementationCostAttachmentsError: null,

  isImplementationCostAttachmentUploading: false,
  implementationCostAttachmentUploadingError: null,
  isImplementationCostAttachmentUploaded: false,
}

export default function implementationCostsReducer(
  state = initialState,
  action: actionTypes.ImplementationCostsActionTypes
): IImplementationCostsState {
  switch (action.type) {
    case actionTypes.FETCH_IMPLEMENTATION_COSTS_REQUESTED: {
      return {
        ...state,
        fetchImplementationCostsError: null,
        areImplementationCostsLoading: true,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COSTS_SUCCEEDED: {
      return {
        ...state,
        implementationCosts: action.implementationCosts,
        areImplementationCostsLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COSTS_ERROR: {
      return {
        ...state,
        fetchImplementationCostsError: action.error,
        areImplementationCostsLoading: false,
      }
    }

    case actionTypes.SET_IMPLEMENTATION_COSTS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_IMPLEMENTATION_COST_REQUESTED: {
      return {
        ...state,
        implementationCostCreateError: null,
        isImplementationCostCreating: true,
        isImplementationCostCreated: false,
      }
    }

    case actionTypes.CREATE_IMPLEMENTATION_COST_SUCCEEDED: {
      return {
        ...state,
        isImplementationCostCreating: false,
        implementationCosts: [...state.implementationCosts, action.implementationCost],
        isImplementationCostCreated: true,
      }
    }

    case actionTypes.CREATE_IMPLEMENTATION_COST_ERROR: {
      return {
        ...state,
        implementationCostCreateError: action.error,
        isImplementationCostCreating: false,
        isImplementationCostCreated: false,
      }
    }

    case actionTypes.EDIT_IMPLEMENTATION_COST_REQUESTED: {
      return {
        ...state,
        implementationCostEditSaveError: null,
        isImplementationCostEditSaving: true,
        isImplementationCostEditSaved: false,
      }
    }

    case actionTypes.EDIT_IMPLEMENTATION_COST_SUCCEEDED: {
      return {
        ...state,
        isImplementationCostEditSaving: false,
        isImplementationCostEditSaved: true,
        implementationCosts: state.implementationCosts?.map(
          (implementationCost: actionTypes.TImplementationCost) => {
            if (implementationCost.id === action.implementationCost.id) {
              return action.implementationCost
            } else {
              return implementationCost
            }
          }
        ),
      }
    }

    case actionTypes.EDIT_IMPLEMENTATION_COST_ERROR: {
      return {
        ...state,
        implementationCostEditSaveError: action.error,
        isImplementationCostEditSaving: false,
        isImplementationCostEditSaved: false,
      }
    }

    case actionTypes.DELETE_IMPLEMENTATION_COST_REQUESTED: {
      return {
        ...state,
        implementationCostDeleteError: null,
        isImplementationCostDeleting: true,
        isImplementationCostDeleted: false,
        currentlyManagedImplementationCostId: action.payload.id,
      }
    }

    case actionTypes.DELETE_IMPLEMENTATION_COST_SUCCEEDED: {
      return {
        ...state,
        isImplementationCostDeleting: false,
        isImplementationCostDeleted: true,
        implementationCosts: state.implementationCosts.filter(
          (implementationCost: actionTypes.TImplementationCost) =>
            implementationCost.id !== action.id
        ),
        editedImplementationCostAttachments: null,
        currentlyManagedImplementationCostId: null,
      }
    }

    case actionTypes.DELETE_IMPLEMENTATION_COST_ERROR: {
      return {
        ...state,
        implementationCostDeleteError: action.error,
        isImplementationCostDeleting: false,
        isImplementationCostDeleted: false,
        currentlyManagedImplementationCostId: null,
      }
    }

    // External tabs

    case actionTypes.FETCH_IMPLEMENTATION_COST_BOMS_REQUESTED: {
      return {
        ...state,
        fetchImplementationCostBomsError: null,
        areImplementationCostBomsLoading: true,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_BOMS_SUCCEEDED: {
      return {
        ...state,
        editedImplementationCostBoms: action.implementationCostBoms,
        areImplementationCostBomsLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_BOMS_ERROR: {
      return {
        ...state,
        fetchImplementationCostBomsError: action.error,
        areImplementationCostBomsLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_REQUESTED: {
      return {
        ...state,
        fetchImplementationCostOffersError: null,
        areImplementationCostOffersLoading: true,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_SUCCEEDED: {
      return {
        ...state,
        editedImplementationCostOffers: action.implementationCostOffers,
        areImplementationCostOffersLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_OFFERS_ERROR: {
      return {
        ...state,
        fetchImplementationCostOffersError: action.error,
        areImplementationCostOffersLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_ORDERS_REQUESTED: {
      return {
        ...state,
        fetchImplementationCostOrdersError: null,
        areImplementationCostOrdersLoading: true,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_ORDERS_SUCCEEDED: {
      return {
        ...state,
        editedImplementationCostOrders: action.implementationCostOrders,
        areImplementationCostOrdersLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_ORDERS_ERROR: {
      return {
        ...state,
        fetchImplementationCostOrdersError: action.error,
        areImplementationCostOrdersLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_ATTACHMENTS_REQUESTED: {
      return {
        ...state,
        fetchImplementationCostAttachmentsError: null,
        areImplementationCostAttachmentsLoading: true,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_ATTACHMENTS_SUCCEEDED: {
      return {
        ...state,
        editedImplementationCostAttachments: action.implementationCostAttachments,
        areImplementationCostAttachmentsLoading: false,
      }
    }

    case actionTypes.FETCH_IMPLEMENTATION_COST_ATTACHMENTS_ERROR: {
      return {
        ...state,
        fetchImplementationCostAttachmentsError: action.error,
        areImplementationCostAttachmentsLoading: false,
      }
    }

    case actionTypes.UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_REQUESTED: {
      return {
        ...state,
        implementationCostAttachmentUploadingError: null,
        isImplementationCostAttachmentUploading: true,
        isImplementationCostAttachmentUploaded: false,
      }
    }

    case actionTypes.UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        isImplementationCostAttachmentUploading: false,
        isImplementationCostAttachmentUploaded: true,
        editedImplementationCostAttachments: state.editedImplementationCostAttachments
          ? [...state.editedImplementationCostAttachments, action.attachment]
          : [action.attachment],
      }
    }

    case actionTypes.UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_ERROR: {
      return {
        ...state,
        implementationCostAttachmentUploadingError: action.error,
        isImplementationCostAttachmentUploading: false,
        isImplementationCostAttachmentUploaded: false,
      }
    }

    case actionTypes.DELETE_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        editedImplementationCostAttachments:
          state.editedImplementationCostAttachments?.filter(
            (attachment: actionTypes.TAttachment) => attachment.id !== action.payload.id
          ) || [],
      }
    }

    case actionTypes.OPEN_IMPLEMENTATION_COST_DETAILS: {
      return {
        ...state,
        implementationCostCreateError: null,
        implementationCostDeleteError: null,
        implementationCostEditSaveError: null,

        isImplementationCostCreating: false,
        isImplementationCostDeleting: false,
        isImplementationCostEditSaving: false,

        editedImplementationCost: action.editedImplementationCost,
        editedImplementationCostAttachments: null,
      }
    }

    case actionTypes.CLOSE_IMPLEMENTATION_COST_DETAILS: {
      return {
        ...state,
        implementationCostCreateError: null,
        implementationCostDeleteError: null,
        implementationCostEditSaveError: null,

        isImplementationCostCreating: false,
        isImplementationCostDeleting: false,
        isImplementationCostEditSaving: false,

        editedImplementationCost: null,

        isImplementationCostCreated: false,
        isImplementationCostEditSaved: false,
        isImplementationCostDeleted: false,

        editedImplementationCostBoms: null,
        areImplementationCostBomsLoading: false,
        fetchImplementationCostBomsError: null,

        editedImplementationCostOffers: null,
        areImplementationCostOffersLoading: false,
        fetchImplementationCostOffersError: null,

        editedImplementationCostAttachments: null,
        areImplementationCostAttachmentsLoading: false,
        fetchImplementationCostAttachmentsError: null,
        isImplementationCostAttachmentUploading: false,
        implementationCostAttachmentUploadingError: null,
        isImplementationCostAttachmentUploaded: false,
      }
    }

    case actionTypes.IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK: {
      return {
        ...state,

        isImplementationCostCreating: false,
        isImplementationCostEditSaving: false,
        isImplementationCostDeleting: false,

        isImplementationCostCreated: false,
        isImplementationCostEditSaved: false,

        implementationCostAttachmentUploadingError: null,
        isImplementationCostAttachmentUploading: false,
        isImplementationCostAttachmentUploaded: false,
      }
    }

    default:
      return state
  }
}
