import { TNetworkError } from './auth'
import { TAdminPayload } from './admin'

export const FETCH_CURRENT_MANUFACTURING_REPORT_REQUESTED =
  'FETCH_CURRENT_MANUFACTURING_REPORT_REQUESTED'
export const FETCH_CURRENT_MANUFACTURING_REPORT_ERROR = 'FETCH_CURRENT_MANUFACTURING_REPORT_ERROR'
export const FETCH_CURRENT_MANUFACTURING_REPORT_SUCCEEDED =
  'FETCH_CURRENT_MANUFACTURING_REPORT_SUCCEEDED'

/*
 * Reports Types
 */
export type TCurrentManufacturingReportTableItem = {
  manufacturingErrandId: number
  manufacturingErrandNumber: string
  assemblyErrandId: number
  assemblyErrandNumber: string
  bomElementId: number
  bomElementName: string
  bomIntermediateId: number
  bomIntermediateName: string
  bomId: number
  bomName: string
  userId: string
  userName: string
  quantity: number | string
}

export type TCurrentManufacturingReport = TCurrentManufacturingReportTableItem[]

export interface IReportTableItem {
  name: string
  description: string
  author: string
  date: string
  uuid: string
  path: string
}

/*
 * Actions interfaces
 */

// FETCH_CURRENT_MANUFACTURING_REPORT

export interface fetchCurrentManufacturingReportAction {
  type: typeof FETCH_CURRENT_MANUFACTURING_REPORT_REQUESTED
  payload: TAdminPayload
}

export interface fetchCurrentManufacturingReportSucceeded {
  type: typeof FETCH_CURRENT_MANUFACTURING_REPORT_SUCCEEDED
  currentManufacturingReport: TCurrentManufacturingReport
}

export interface fetchCurrentManufacturingReportError {
  type: typeof FETCH_CURRENT_MANUFACTURING_REPORT_ERROR
  error: TNetworkError
}

export type ReportsActionTypes =
  | fetchCurrentManufacturingReportAction
  | fetchCurrentManufacturingReportSucceeded
  | fetchCurrentManufacturingReportError
