import { TNetworkError } from './auth'
import {
  TAdminPayload,
  IEditItemOnListPayload,
  ISingleItemActionPayload,
  TFilterState,
  TSorterState,
} from './admin'
import { IBomFull, IBomFullElement } from '.'

// MANUFACTURING_STEPS

export const FETCH_ALL_MANUFACTURING_STEPS_REQUESTED = 'FETCH_ALL_MANUFACTURING_STEPS_REQUESTED'
export const FETCH_ALL_MANUFACTURING_STEPS_ERROR = 'FETCH_ALL_MANUFACTURING_STEPS_ERROR'
export const FETCH_ALL_MANUFACTURING_STEPS_SUCCEEDED = 'FETCH_ALL_MANUFACTURING_STEPS_SUCCEEDED'

export const FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_REQUESTED =
  'FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_REQUESTED'
export const FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_ERROR =
  'FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_ERROR'
export const FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_SUCCEEDED =
  'FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_SUCCEEDED'

export const CREATE_MANUFACTURING_STEPS_REQUESTED = 'CREATE_MANUFACTURING_STEPS_REQUESTED'
export const CREATE_MANUFACTURING_STEPS_SUCCEEDED = 'CREATE_MANUFACTURING_STEPS_SUCCEEDED'
export const CREATE_MANUFACTURING_STEPS_ERROR = 'CREATE_MANUFACTURING_STEPS_ERROR'

export const FETCH_MANUFACTURING_STEPS_DETAILS_REQUESTED =
  'FETCH_MANUFACTURING_STEPS_DETAILS_REQUESTED'
export const FETCH_MANUFACTURING_STEPS_DETAILS_ERROR = 'FETCH_MANUFACTURING_STEPS_DETAILS_ERROR'
export const FETCH_MANUFACTURING_STEPS_DETAILS_SUCCEEDED =
  'FETCH_MANUFACTURING_STEPS_DETAILS_SUCCEEDED'

export const EDIT_MANUFACTURING_STEPS_DETAILS_REQUESTED =
  'EDIT_MANUFACTURING_STEPS_DETAILS_REQUESTED'
export const EDIT_MANUFACTURING_STEPS_DETAILS_SUCCEEDED =
  'EDIT_MANUFACTURING_STEPS_DETAILS_SUCCEEDED'
export const EDIT_MANUFACTURING_STEPS_DETAILS_ERROR = 'EDIT_MANUFACTURING_STEPS_DETAILS_ERROR'

export const DELETE_MANUFACTURING_STEPS_REQUESTED = 'DELETE_MANUFACTURING_STEPS_REQUESTED'
export const DELETE_MANUFACTURING_STEPS_SUCCEEDED = 'DELETE_MANUFACTURING_STEPS_SUCCEEDED'
export const DELETE_MANUFACTURING_STEPS_ERROR = 'DELETE_MANUFACTURING_STEPS_ERROR'

export const OPEN_MANUFACTURING_STEPS_DETAILS = 'OPEN_MANUFACTURING_STEPS_DETAILS'
export const CLOSE_MANUFACTURING_STEPS_DETAILS = 'CLOSE_MANUFACTURING_STEPS_DETAILS'

export const OPEN_MANUFACTURING_STEPS_DETAILS_BOM_MODAL =
  'OPEN_MANUFACTURING_STEPS_DETAILS_BOM_MODAL'
export const CLOSE_MANUFACTURING_STEPS_DETAILS_BOM_MODAL =
  'CLOSE_MANUFACTURING_STEPS_DETAILS_BOM_MODAL'

export const MANUFACTURING_STEPS_DETAILS_ACTIONS_UNLOCK =
  'MANUFACTURING_STEPS_DETAILS_ACTIONS_UNLOCK'

// MANUFACTURING_ACTIVITIES

export const FETCH_MANUFACTURING_ACTIVITIES_REQUESTED = 'FETCH_MANUFACTURING_ACTIVITIES_REQUESTED'
export const FETCH_MANUFACTURING_ACTIVITIES_SUCCEEDED = 'FETCH_MANUFACTURING_ACTIVITIES_SUCCEEDED'
export const FETCH_MANUFACTURING_ACTIVITIES_ERROR = 'FETCH_MANUFACTURING_ACTIVITIES_ERROR'

export const SET_MANUFACTURING_ACTIVITIES_TABLE_FILTER_STATE =
  'SET_MANUFACTURING_ACTIVITIES_TABLE_FILTER_STATE'
export const SET_MANUFACTURING_ACTIVITIES_TABLE_SORTER_STATE =
  'SET_MANUFACTURING_ACTIVITIES_TABLE_SORTER_STATE'

export const CREATE_MANUFACTURING_ACTIVITY_REQUESTED = 'CREATE_MANUFACTURING_ACTIVITY_REQUESTED'
export const CREATE_MANUFACTURING_ACTIVITY_SUCCEEDED = 'CREATE_MANUFACTURING_ACTIVITY_SUCCEEDED'
export const CREATE_MANUFACTURING_ACTIVITY_ERROR = 'CREATE_MANUFACTURING_ACTIVITY_ERROR'

export const EDIT_MANUFACTURING_ACTIVITY_REQUESTED = 'EDIT_MANUFACTURING_ACTIVITY_REQUESTED'
export const EDIT_MANUFACTURING_ACTIVITY_SUCCEEDED = 'EDIT_MANUFACTURING_ACTIVITY_SUCCEEDED'
export const EDIT_MANUFACTURING_ACTIVITY_ERROR = 'EDIT_MANUFACTURING_ACTIVITY_ERROR'

export const DELETE_MANUFACTURING_ACTIVITY_REQUESTED = 'DELETE_MANUFACTURING_ACTIVITY_REQUESTED'
export const DELETE_MANUFACTURING_ACTIVITY_SUCCEEDED = 'DELETE_MANUFACTURING_ACTIVITY_SUCCEEDED'
export const DELETE_MANUFACTURING_ACTIVITY_ERROR = 'DELETE_MANUFACTURING_ACTIVITY_ERROR'

export const OPEN_MANUFACTURING_ACTIVITY_MODAL = 'OPEN_MANUFACTURING_ACTIVITY_MODAL'
export const CLOSE_MANUFACTURING_ACTIVITY_MODAL = 'CLOSE_MANUFACTURING_ACTIVITY_MODAL'

// TEAM_MEMBERS

export const FETCH_TEAM_MEMBERS_REQUESTED = 'FETCH_TEAM_MEMBERS_REQUESTED'
export const FETCH_TEAM_MEMBERS_SUCCEEDED = 'FETCH_TEAM_MEMBERS_SUCCEEDED'
export const FETCH_TEAM_MEMBERS_ERROR = 'FETCH_TEAM_MEMBERS_ERROR'

export const SET_TEAM_MEMBERS_TABLE_FILTER_STATE = 'SET_TEAM_MEMBERS_TABLE_FILTER_STATE'
export const SET_TEAM_MEMBERS_TABLE_SORTER_STATE = 'SET_TEAM_MEMBERS_TABLE_SORTER_STATE'

export const FETCH_SINGLE_TEAM_MEMBER_REQUESTED = 'FETCH_SINGLE_TEAM_MEMBER_REQUESTED'
export const FETCH_SINGLE_TEAM_MEMBER_SUCCEEDED = 'FETCH_SINGLE_TEAM_MEMBER_SUCCEEDED'
export const FETCH_SINGLE_TEAM_MEMBER_ERROR = 'FETCH_SINGLE_TEAM_MEMBER_ERROR'

export const EDIT_TEAM_MEMBER_REQUESTED = 'EDIT_TEAM_MEMBER_REQUESTED'
export const EDIT_TEAM_MEMBER_SUCCEEDED = 'EDIT_TEAM_MEMBER_SUCCEEDED'
export const EDIT_TEAM_MEMBER_ERROR = 'EDIT_TEAM_MEMBER_ERROR'

export const ARCHIVE_TEAM_MEMBER_REQUESTED = 'ARCHIVE_TEAM_MEMBER_REQUESTED'
export const ARCHIVE_TEAM_MEMBER_SUCCEEDED = 'ARCHIVE_TEAM_MEMBER_SUCCEEDED'
export const ARCHIVE_TEAM_MEMBER_ERROR = 'ARCHIVE_TEAM_MEMBER_ERROR'

// MANUFACTURING_ERRANDS

export const FETCH_MANUFACTURING_ERRANDS_REQUESTED = 'FETCH_MANUFACTURING_ERRANDS_REQUESTED'
export const FETCH_MANUFACTURING_ERRANDS_SUCCEEDED = 'FETCH_MANUFACTURING_ERRANDS_SUCCEEDED'
export const FETCH_MANUFACTURING_ERRANDS_ERROR = 'FETCH_MANUFACTURING_ERRANDS_ERROR'

export const SET_MANUFACTURING_ERRANDS_TABLE_FILTER_STATE =
  'SET_MANUFACTURING_ERRANDS_TABLE_FILTER_STATE'
export const SET_MANUFACTURING_ERRANDS_TABLE_SORTER_STATE =
  'SET_MANUFACTURING_ERRANDS_TABLE_SORTER_STATE'

export const CREATE_MANUFACTURING_ERRAND_REQUESTED = 'CREATE_MANUFACTURING_ERRAND_REQUESTED'
export const CREATE_MANUFACTURING_ERRAND_SUCCEEDED = 'CREATE_MANUFACTURING_ERRAND_SUCCEEDED'
export const CREATE_MANUFACTURING_ERRAND_ERROR = 'CREATE_MANUFACTURING_ERRAND_ERROR'

export const EDIT_MANUFACTURING_ERRAND_REQUESTED = 'EDIT_MANUFACTURING_ERRAND_REQUESTED'
export const EDIT_MANUFACTURING_ERRAND_SUCCEEDED = 'EDIT_MANUFACTURING_ERRAND_SUCCEEDED'
export const EDIT_MANUFACTURING_ERRAND_ERROR = 'EDIT_MANUFACTURING_ERRAND_ERROR'

export const DELETE_MANUFACTURING_ERRAND_REQUESTED = 'DELETE_MANUFACTURING_ERRAND_REQUESTED'
export const DELETE_MANUFACTURING_ERRAND_SUCCEEDED = 'DELETE_MANUFACTURING_ERRAND_SUCCEEDED'
export const DELETE_MANUFACTURING_ERRAND_ERROR = 'DELETE_MANUFACTURING_ERRAND_ERROR'

export const OPEN_MANUFACTURING_ERRAND_MODAL = 'OPEN_MANUFACTURING_ERRAND_MODAL'
export const CLOSE_MANUFACTURING_ERRAND_MODAL = 'CLOSE_MANUFACTURING_ERRAND_MODAL'

/*
 * Manufacturing Types
 */

export interface IManufacturingSteps {
  bomId: number
  bomName: string
  manufacturingSteps: IManufacturingStep[]
  name: string
  bomIntermediates: IBomIntermediate[] | []

  id: number
  uuid: string
  canDelete: boolean
}

export interface IManufacturingStep {
  name: string
  bomElements?: TBomElementInManufacturingStep[]
  bomIntermediates: IBomIntermediate[] | []
  manufacturingActivities: IManufacturingActivity[]

  uuid: string
}

export interface IBomIntermediate {
  name: string
  index: number
  quantity: number
  bomElements: TBomElementInManufacturingStep[]
  manufacturingActivities: IManufacturingActivity[]
  bomIntermediates: IBomIntermediate[]
  isAddedFromExisting?: boolean

  uuid: string

  // This is for the list
  bomIntermediateName?: string
  bomIntermediateId?: string | number
}

export type TBomElementInManufacturingStep = {
  bomElementId: number | null | undefined
  bomElementName: string
  quantity: number | string
  index: number
}

export interface IBomElementFromEditableBomTableInModal extends IBomFullElement {
  initialQuantityInBom: number | string | null
  checkbox: boolean
}

/*
 * Actions interfaces
 */

// FETCH_ALL_MANUFACTURING_STEPS

export interface fetchAllManufacturingStepsAction {
  type: typeof FETCH_ALL_MANUFACTURING_STEPS_REQUESTED
  payload: TAdminPayload
}

export interface fetchAllManufacturingStepsSucceeded {
  type: typeof FETCH_ALL_MANUFACTURING_STEPS_SUCCEEDED
  allManufacturingSteps: IManufacturingSteps[]
}

export interface fetchAllManufacturingStepsError {
  type: typeof FETCH_ALL_MANUFACTURING_STEPS_ERROR
  error: TNetworkError
}

export interface ICreateManufacturingStepsPayload extends TAdminPayload {
  manufacturingSteps: IManufacturingSteps
  canDelete: boolean
}

// FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS

export interface fetchSingleBomForManufacturingStepsDetailsAction {
  type: typeof FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleBomForManufacturingStepsDetailsSucceeded {
  type: typeof FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_SUCCEEDED
  bomForManufacturingSteps: IBomFull
}

export interface fetchSingleBomForManufacturingStepsDetailsError {
  type: typeof FETCH_SINGLE_BOM_FOR_MANUFACTURING_STEPS_DETAILS_ERROR
  error: TNetworkError
}

// CREATE_MANUFACTURING_STEPS

export interface createManufacturingStepsAction {
  type: typeof CREATE_MANUFACTURING_STEPS_REQUESTED
  payload: ICreateManufacturingStepsPayload
}

export interface createManufacturingStepsSucceeded {
  type: typeof CREATE_MANUFACTURING_STEPS_SUCCEEDED
  manufacturingStepsDetails: IManufacturingSteps
}

export interface createManufacturingStepsError {
  type: typeof CREATE_MANUFACTURING_STEPS_ERROR
  error: TNetworkError
}

// FETCH_MANUFACTURING_STEPS_DETAILS

export interface fetchManufacturingStepsDetailsAction {
  type: typeof FETCH_MANUFACTURING_STEPS_DETAILS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchManufacturingStepsDetailsSucceeded {
  type: typeof FETCH_MANUFACTURING_STEPS_DETAILS_SUCCEEDED
  manufacturingStepsDetails: IManufacturingSteps
}

export interface fetchManufacturingStepsDetailsError {
  type: typeof FETCH_MANUFACTURING_STEPS_DETAILS_ERROR
  error: TNetworkError
}

// EDIT_MANUFACTURING_STEPS_DETAILS

export interface editManufacturingStepsDetailsAction {
  type: typeof EDIT_MANUFACTURING_STEPS_DETAILS_REQUESTED
  payload: any
}

export interface editManufacturingStepsDetailsSucceeded {
  type: typeof EDIT_MANUFACTURING_STEPS_DETAILS_SUCCEEDED
}

export interface editManufacturingStepsDetailsError {
  type: typeof EDIT_MANUFACTURING_STEPS_DETAILS_ERROR
  error: TNetworkError
}

// DELETE_MANUFACTURING_STEPS

export interface deleteManufacturingStepsAction {
  type: typeof DELETE_MANUFACTURING_STEPS_REQUESTED
  payload: IEditItemOnListPayload
}

export interface deleteManufacturingStepsSucceeded {
  type: typeof DELETE_MANUFACTURING_STEPS_SUCCEEDED
  manufacturingStepsDetails: IManufacturingSteps
}

export interface deleteManufacturingStepsError {
  type: typeof DELETE_MANUFACTURING_STEPS_ERROR
  error: TNetworkError
}

// OPEN CLOSE UNLOCK MANUFACTURING STEPS DETAILS

export interface openManufacturingStepsDetailsAction {
  type: typeof OPEN_MANUFACTURING_STEPS_DETAILS
  manufacturingStepsDetails: IManufacturingSteps | null
}

export interface closeManufacturingStepsDetailsAction {
  type: typeof CLOSE_MANUFACTURING_STEPS_DETAILS
}

export interface manufacturingStepsDetailsActionsUnlockAction {
  type: typeof MANUFACTURING_STEPS_DETAILS_ACTIONS_UNLOCK
}

// MANUFACTURING_ACTIVITIES

export interface IManufacturingActivity {
  name: string
  canDelete: boolean
  id?: number
}

export interface ICreateManufacturingActivityPayload extends TAdminPayload {
  manufacturingActivity: IManufacturingActivity
}

// FETCH_MANUFACTURING_ACTIVITIES_REQUESTED

export interface fetchManufacturingActivitiesAction {
  type: typeof FETCH_MANUFACTURING_ACTIVITIES_REQUESTED
  payload: TAdminPayload
}

export interface fetchManufacturingActivitiesSucceeded {
  type: typeof FETCH_MANUFACTURING_ACTIVITIES_SUCCEEDED
  manufacturingActivities: IManufacturingActivity[]
}

export interface fetchManufacturingActivitiesError {
  type: typeof FETCH_MANUFACTURING_ACTIVITIES_ERROR
  error: TNetworkError
}

// SET_MANUFACTURING_ACTIVITIES_TABLE_FILTER_STATE

export interface setManufacturingActivitiesTableFilterState {
  type: typeof SET_MANUFACTURING_ACTIVITIES_TABLE_FILTER_STATE
  manufacturingActivitiesTableFilterState: TFilterState
}

// SET_MANUFACTURING_ACTIVITIES_TABLE_SORTER_STATE

export interface setManufacturingActivitiesTableSorterState {
  type: typeof SET_MANUFACTURING_ACTIVITIES_TABLE_SORTER_STATE
  manufacturingActivitiesTableSorterState: TSorterState
}

// CREATE_MANUFACTURING_ACTIVITY_REQUESTED

export interface createManufacturingActivityAction {
  type: typeof CREATE_MANUFACTURING_ACTIVITY_REQUESTED
  payload: ICreateManufacturingActivityPayload
}

export interface createManufacturingActivitySucceeded {
  type: typeof CREATE_MANUFACTURING_ACTIVITY_SUCCEEDED
  manufacturingActivity: IManufacturingActivity
}

export interface createManufacturingActivityError {
  type: typeof CREATE_MANUFACTURING_ACTIVITY_ERROR
  error: TNetworkError
}

// EDIT_MANUFACTURING_ACTIVITY_REQUESTED

export interface editManufacturingActivityAction {
  type: typeof EDIT_MANUFACTURING_ACTIVITY_REQUESTED
  payload: ICreateManufacturingActivityPayload
}

export interface editManufacturingActivitySucceeded {
  type: typeof EDIT_MANUFACTURING_ACTIVITY_SUCCEEDED
  manufacturingActivity: IManufacturingActivity
}

export interface editManufacturingActivityError {
  type: typeof EDIT_MANUFACTURING_ACTIVITY_ERROR
  error: TNetworkError
}

// DELETE_MANUFACTURING_ACTIVITY_REQUESTED

export interface deleteManufacturingActivityAction {
  type: typeof DELETE_MANUFACTURING_ACTIVITY_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteManufacturingActivitySucceeded {
  type: typeof DELETE_MANUFACTURING_ACTIVITY_SUCCEEDED
  id: number
}

export interface deleteManufacturingActivityError {
  type: typeof DELETE_MANUFACTURING_ACTIVITY_ERROR
  error: TNetworkError
}

// OPEN_MANUFACTURING_ACTIVITY_MODAL

export interface openManufacturingActivityModalAction {
  type: typeof OPEN_MANUFACTURING_ACTIVITY_MODAL
  editedManufacturingActivity: IManufacturingActivity | null
}

export interface closeManufacturingActivityModalAction {
  type: typeof CLOSE_MANUFACTURING_ACTIVITY_MODAL
}

// TEAM_MEMBERS

export interface ITeamMember {
  generalInformation: ITeamMemberGeneralInformationAndLight
  skills: ITeamMemberSkills[]
  schedule: ITeamMemberSchedule
  vacations: ITeamMemberVacations | null

  id?: number
  uuid?: number
}

export interface ITeamMemberGeneralInformationAndLight {
  name: string
  surname: string
  position: string | null
  email: string
  phone: string | null

  id?: number
  uuid?: number
}

export interface ITeamMemberSkills {
  bomName: string
  id: number

  manufacturingStepsWithSkills: IManufacturingStepWithSkills[]
}

export interface IManufacturingStepWithSkills {
  name: string
  bomIntermediatesWithSkills?: IBomIntermediateWithSkills[] | []
  manufacturingActivitiesWithSkills?: IManufacturingActivityWithSkills[] | []

  uuid: string
}

export interface IBomIntermediateWithSkills
  extends Omit<
    IBomIntermediate,
    | 'quantity'
    | 'bomElements'
    | 'manufacturingActivities'
    | 'isAddedFromExisting'
    | 'bomIntermediates'
  > {
  skillValue: number
  performance?: string
}

export interface IManufacturingActivityWithSkills
  extends Omit<IManufacturingActivity, 'canDelete'> {
  skillValue: number
  performance?: string
}

export interface ITeamMemberSchedule {
  calendar: ITeamMemberDayAtWork[]
}

export interface ITeamMemberDayAtWork {
  task: any
  isOnVacation: boolean
  date: string
}

export interface ITeamMemberVacations {
  currentYear: string
  currentLimit: number
  limitFromPreviousPeriod: number
  vacationDaysLeftToUse: number
  usedVacationDays: number
  plannedVacationDays: number
  notPlannedVacationDays: number
  otherVacationDays: number
}

export interface IEditTeamMemberPayload extends TAdminPayload {
  teamMember: ITeamMember
}

// FETCH_TEAM_MEMBERS

export interface fetchTeamMembersAction {
  type: typeof FETCH_TEAM_MEMBERS_REQUESTED
  payload: TAdminPayload
}

export interface fetchTeamMembersSucceeded {
  type: typeof FETCH_TEAM_MEMBERS_SUCCEEDED
  teamMembers: ITeamMemberGeneralInformationAndLight[]
}

export interface fetchTeamMembersError {
  type: typeof FETCH_TEAM_MEMBERS_ERROR
  error: TNetworkError
}

// SET_TEAM_MEMBERS_TABLE_FILTER_STATE

export interface setTeamMembersTableFilterState {
  type: typeof SET_TEAM_MEMBERS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_TEAM_MEMBERS_TABLE_SORTER_STATE

export interface setTeamMembersTableSorterState {
  type: typeof SET_TEAM_MEMBERS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// FETCH_SINGLE_TEAM_MEMBER

export interface fetchSingleTeamMemberAction {
  type: typeof FETCH_SINGLE_TEAM_MEMBER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleTeamMemberSucceeded {
  type: typeof FETCH_SINGLE_TEAM_MEMBER_SUCCEEDED
  teamMember: ITeamMember
}

export interface fetchSingleTeamMemberError {
  type: typeof FETCH_SINGLE_TEAM_MEMBER_ERROR
  error: TNetworkError
}

// EDIT_TEAM_MEMBER

export interface editTeamMemberAction {
  type: typeof EDIT_TEAM_MEMBER_REQUESTED
  payload: IEditTeamMemberPayload
}

export interface editTeamMemberSucceeded {
  type: typeof EDIT_TEAM_MEMBER_SUCCEEDED
  teamMember: ITeamMember
}

export interface editTeamMemberError {
  type: typeof EDIT_TEAM_MEMBER_ERROR
  error: TNetworkError
}

// ARCHIVE_TEAM_MEMBER

export interface archiveTeamMemberAction {
  type: typeof ARCHIVE_TEAM_MEMBER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface archiveTeamMemberSucceeded {
  type: typeof ARCHIVE_TEAM_MEMBER_SUCCEEDED
  teamMember: ITeamMemberGeneralInformationAndLight
}

export interface archiveTeamMemberError {
  type: typeof ARCHIVE_TEAM_MEMBER_ERROR
  error: TNetworkError
}

export type TManufacturingErrand = {
  manufacturingErrandNumber: string
  bomName: string
  bomId: number
  startDate: string
  endDate: string
  plannedQuantity: number
  readyQuantity: number
  sentQuantity: number
  canDelete: boolean

  bomElements?: IBomFullElement[]
  bomIntermediates?: IBomIntermediate[]

  id: number
  name: string
}

export interface ICreateManufacturingErrandPayload extends TAdminPayload {
  manufacturingErrand: TManufacturingErrand
}

// FETCH_MANUFACTURING_ERRANDS_REQUESTED

export interface fetchManufacturingErrandsAction {
  type: typeof FETCH_MANUFACTURING_ERRANDS_REQUESTED
  payload: TAdminPayload
}

export interface fetchManufacturingErrandsSucceeded {
  type: typeof FETCH_MANUFACTURING_ERRANDS_SUCCEEDED
  manufacturingErrands: TManufacturingErrand[]
}

export interface fetchManufacturingErrandsError {
  type: typeof FETCH_MANUFACTURING_ERRANDS_ERROR
  error: TNetworkError
}

// SET_MANUFACTURING_ERRANDS_TABLE_FILTER_STATE

export interface setManufacturingErrandsTableFilterState {
  type: typeof SET_MANUFACTURING_ERRANDS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_MANUFACTURING_ERRANDS_TABLE_SORTER_STATE

export interface setManufacturingErrandsTableSorterState {
  type: typeof SET_MANUFACTURING_ERRANDS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_MANUFACTURING_ERRAND_REQUESTED

export interface createManufacturingErrandAction {
  type: typeof CREATE_MANUFACTURING_ERRAND_REQUESTED
  payload: ICreateManufacturingErrandPayload
}

export interface createManufacturingErrandSucceeded {
  type: typeof CREATE_MANUFACTURING_ERRAND_SUCCEEDED
  manufacturingErrand: TManufacturingErrand
}

export interface createManufacturingErrandError {
  type: typeof CREATE_MANUFACTURING_ERRAND_ERROR
  error: TNetworkError
}

// EDIT_MANUFACTURING_ERRAND_REQUESTED

export interface editManufacturingErrandAction {
  type: typeof EDIT_MANUFACTURING_ERRAND_REQUESTED
  payload: ICreateManufacturingErrandPayload
}

export interface editManufacturingErrandSucceeded {
  type: typeof EDIT_MANUFACTURING_ERRAND_SUCCEEDED
  manufacturingErrand: TManufacturingErrand
}

export interface editManufacturingErrandError {
  type: typeof EDIT_MANUFACTURING_ERRAND_ERROR
  error: TNetworkError
}

// DELETE_MANUFACTURING_ERRAND_REQUESTED

export interface deleteManufacturingErrandAction {
  type: typeof DELETE_MANUFACTURING_ERRAND_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteManufacturingErrandSucceeded {
  type: typeof DELETE_MANUFACTURING_ERRAND_SUCCEEDED
  id: number
}

export interface deleteManufacturingErrandError {
  type: typeof DELETE_MANUFACTURING_ERRAND_ERROR
  error: TNetworkError
}

// OPEN_MANUFACTURING_ERRAND_MODAL

export interface openManufacturingErrandModalAction {
  type: typeof OPEN_MANUFACTURING_ERRAND_MODAL
  editedManufacturingErrand: TManufacturingErrand | null
}

export interface closeManufacturingErrandModalAction {
  type: typeof CLOSE_MANUFACTURING_ERRAND_MODAL
}

export type ManufacturingActionTypes =
  | fetchAllManufacturingStepsAction
  | fetchAllManufacturingStepsSucceeded
  | fetchAllManufacturingStepsError
  | fetchManufacturingStepsDetailsAction
  | fetchManufacturingStepsDetailsError
  | fetchManufacturingStepsDetailsSucceeded
  | editManufacturingStepsDetailsAction
  | editManufacturingStepsDetailsSucceeded
  | editManufacturingStepsDetailsError
  | createManufacturingStepsAction
  | createManufacturingStepsSucceeded
  | createManufacturingStepsError
  | deleteManufacturingStepsAction
  | deleteManufacturingStepsSucceeded
  | deleteManufacturingStepsError
  | openManufacturingStepsDetailsAction
  | closeManufacturingStepsDetailsAction
  | manufacturingStepsDetailsActionsUnlockAction
  | fetchSingleBomForManufacturingStepsDetailsAction
  | fetchSingleBomForManufacturingStepsDetailsSucceeded
  | fetchSingleBomForManufacturingStepsDetailsError
  | fetchManufacturingActivitiesAction
  | fetchManufacturingActivitiesSucceeded
  | fetchManufacturingActivitiesError
  | setManufacturingActivitiesTableFilterState
  | setManufacturingActivitiesTableSorterState
  | createManufacturingActivityAction
  | createManufacturingActivitySucceeded
  | createManufacturingActivityError
  | editManufacturingActivityAction
  | editManufacturingActivitySucceeded
  | editManufacturingActivityError
  | deleteManufacturingActivityAction
  | deleteManufacturingActivitySucceeded
  | deleteManufacturingActivityError
  | openManufacturingActivityModalAction
  | closeManufacturingActivityModalAction
  | fetchTeamMembersAction
  | fetchTeamMembersSucceeded
  | fetchTeamMembersError
  | setTeamMembersTableFilterState
  | setTeamMembersTableSorterState
  | fetchSingleTeamMemberAction
  | fetchSingleTeamMemberSucceeded
  | fetchSingleTeamMemberError
  | editTeamMemberAction
  | editTeamMemberSucceeded
  | editTeamMemberError
  | archiveTeamMemberAction
  | archiveTeamMemberSucceeded
  | archiveTeamMemberError
  | fetchManufacturingErrandsAction
  | fetchManufacturingErrandsSucceeded
  | fetchManufacturingErrandsError
  | setManufacturingErrandsTableFilterState
  | setManufacturingErrandsTableSorterState
  | createManufacturingErrandAction
  | createManufacturingErrandSucceeded
  | createManufacturingErrandError
  | editManufacturingErrandAction
  | editManufacturingErrandSucceeded
  | editManufacturingErrandError
  | deleteManufacturingErrandAction
  | deleteManufacturingErrandSucceeded
  | deleteManufacturingErrandError
  | openManufacturingErrandModalAction
  | closeManufacturingErrandModalAction
